import React from 'react';

const Terms = () => {
    return (
        <><header class="text-center mb-4">
        <p><strong>SIERRA INTERNATIONAL LLC(<a href="www.sierraparts.com/en-us/">www.sierraparts.com/en-us/</a>)PRIVACY POLICY Last updated: January 26, 2022. </strong><br></br>
        Thank you for accessing the SIERRA INTERNATIONAL LLC (U.S.) Website (the “Website” or “sierraparts.com/en-us/”) operated by SIERRA INTERNATIONAL LLC (“SIERRA”). 
        <br></br>We respect your privacy and want to protect your personal information.<br></br>
        This Privacy Policy applies to the Website as well as SIERRA's offline data collection. … (collectively, the “Website”)</p> <br></br>
        </header>
        <div className="text-page justify">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 offset-md-2">
                            <h1>Terms and Conditions &amp; Privacy Policy</h1>
                            <p>Not open to the general public. All federal, state, local, municipal and provincial laws, taxes, and regulations apply. Void where prohibited.</p>
                            <ol>
                                <li>
                                    General
                                    <ol>
                                        <li>You should keep a copy of these Terms and Conditions and any order you make for your future reference. These Terms and Conditions may be revised from time to time at our discretion. Any changes we make will apply to any orders received after the date of any changes made. You can view an up to date version within the Website.</li>
                                    </ol>
                                </li>
                                <li>
                                    Administrator
                                    <ol>
                                        <li>These Terms and Conditions relate to your use of the Website. References these Terms and Conditions to “we,” “us” and “our” refer to SONAR administrator for the Website, as identified below.</li>
                                    </ol>
                                </li>
                                <li>
                                    Use of Website
                                    <ol>
                                        <li>You are provided access to this Website strictly in accordance with these Terms and Conditions.</li>
                                        <li>You warrant that all the details provided by you on the account registration form (&quot;Personal Information&quot;) are true, accurate, current and complete in all respects and that you will notify us immediately of any changes to your Personal Information.</li>
                                        <li>The Program is a membership-based program and you will be required to submit a security username and password prior to gaining entry into the Program.</li>
                                        <li>You are entirely responsible for maintaining the confidentiality of your username and password and any activities that occur under your account. If you believe that your password has been compromised you should contact us as soon as possible and (subject to security checks) we will change your password. We shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</li>
                                        <li>You agree to fully indemnify us and our officers, directors, employees, agents and suppliers from and against all claims, liability, damages, losses, costs and expenses (including reasonable legal fees) arising out of the breach of these Term and Conditions by you and any other liabilities arising out of your use of this Website, or the use by any other person accessing this Website using your username, password and Personal Information.</li>
                                        <li>We cannot guarantee that use of this Website will be compatible with all hardware and software that may be used by visitors to the Website.</li>
                                        <li>We cannot guarantee periods of availability of the Website or that access to the Website will be uninterrupted. Subject to Section 5, we shall not be liable to you in respect of any claim or loss (including for the avoidance of doubt any loss of profit, loss of business, wasted management time or other consequential loss) incurred by you which arises as a result of any downtime or non-availability of the Website.</li>
                                        <li>We do not warrant or represent the accuracy or completeness of information provided on this Website nor do we guarantee that use of this Website will be uninterrupted, error-free or that the Website and its servers are free of computer viruses or bugs. We shall not be liable for any loss or damage which may arise as a result of using this Website.</li>
                                        <li>You should be aware that this Website is being monitored and may capture information about your visit that will help us improve the quality of our service.</li>
                                    </ol>
                                </li>
                                <li>
                                    Intellectual Property
                                    <ol>
                                        <li>The Website contains material that is owned by or licensed to Sierra. This material includes, but is not limited to, the design, layout, look, content, appearance and graphics. It is protected by intellectual property laws including, but not limited to, copyright.</li>
                                        <li>You may view, use, download and store the material on this Website for personal and research use only. Commercial use is not permitted without Sierra’s prior written consent. The redistribution, re-publication, or otherwise making available of the material on this Website to third parties without Sierra’s prior written consent is prohibited.</li>
                                        <li>Unauthorized use of this Website may give rise to a claim for damages and/or be a criminal offense.</li>
                                    </ol>
                                </li>
                                <li>
                                    Liability (important please note)
                                    <ol>
                                        <li>
                                            We will not be responsible to you for any loss or damage you may suffer (however it may arise, including our negligence) as a result of your access to or use of this website or any materials on this website except where the loss or damage is:
                                            <ul>
                                                <li>Death or personal injury due to our negligence;</li>
                                                <li>Caused by our fraud;</li>
                                                <li>Caused by any other matter for which it would be illegal for us to exclude or attempt to exclude our liability; or</li>
                                                <li>Covered by our returns policy, in which case the provisions of our returns policy will apply.</li>
                                                <li>Subject to the above, you hereby agree to indemnify and hold Sonar Administrator harmless from any liability or damage from bodily injury, including death, or property damage which may rise from your negligent use of the website or willful misconduct due to information gleaning from the website. Sonar shall only be responsible in any situation for that percent of total damages that correspond to negligent, fraud, or illegal activity on behalf of Sonar.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Termination
                                    <ol>
                                        <li>
                                            We make this Website available to you in connection with the SONAR Pro Program, and your access to the Website and participation in the SONAR Pro Program may be terminated at any time without notice. Some circumstances in which your access and participation may be terminated include (but are not limited to):
                                            <ul>
                                                <li>If you are in breach of these Terms and Conditions; or</li>
                                                <li>If Sierra elects to terminate the SONAR Pro Program; or</li>
                                                <li>Where you participate in the Program through a corporate membership:</li>
                                                <li>In the event that you cease to be an employee of the corporate member; or</li>
                                                <li> If our agreement with the corporate member for the provision of the Website should terminate for any reason.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Returns Policy
                                    <ol>
                                        <li>You should inspect the goods as soon as you receive them for defects or damage and to ensure they are correct. In the unlikely event that any item you receive is damaged, faulty or incorrect; you should contact the Customer Service Team as soon as possible for advice.</li>
                                        <li>Please do not return items until instructed to do so. If you are requested to return your reward items, please ensure that they are securely packaged. Please also provide a full description of the fault and return the defective item to us together with any warranties, manuals and accessories. Goods damaged in transit on return cannot be replaced.</li>
                                        <li>All goods are guaranteed against manufacturing defects for a period of twelve months from the date of purchase (or longer if required by law) and will be repaired or replaced by us or monies refunded, at your option, free of charge, provided they have not been altered, misused, improperly installed or connected and that any relevant maintenance requirements have been complied with. Any goods returned will belong to us.</li>
                                    </ol>
                                </li>
                            </ol>
                            <span class="space" id="privacy"></span>
                            <h2 className="text-center">Privacy Policy</h2>
                            <p>Your privacy is important to us. To better protect your privacy, we provide this statement explaining our online information practices and the choices you can make about the way your information is collected and used on the Website.</p>
                            <ol>
                                <li>When you make orders from this Website, we will ask you to input and will collect Personal Information from you such as your name, e-mail address, billing address, delivery address, telephone number, product selections, credit card or other payment information and a password. We need this information in order to process and fulfill your orders, to let you know the status of your order, and to address any queries regarding your order.</li>
                                <li>We will treat all your Personal Information as confidential (although we reserve the right to disclose this information in the circumstances set out below). We will keep it on a secure server and we will fully comply with all applicable USA and Canada Data Protection and consumer legislation in place from time to time.</li>
                                <li>Sierra may use your Personal Information for market research purposes to advise you of special offers and to inform you about products and services available from time to time. If you would prefer not to receive marketing information, you may opt out. The opt in/opt out procedure is explained in Your Profile section of the website.</li>
                                <li> We may also disclose your Personal Information to our appointed third party agents in order to process your order, for example, to courier companies who deliver goods to you on our behalf. These third parties are only authorized to use your information in order for them to perform specific tasks related to your order and they may not use it for any other purposes. However, if you subscribe to other goods or services you may receive an offer or renewal for your subscription directly from a third party.</li>
                                <li>Sierra may disclose your information to our or its professional advisers for the purpose of obtaining professional advice or to other third parties if Sierra has a legal obligation to do so.</li>
                                <li>Sierra reserves the right at all times to monitor your use of the Website to ensure compliance with these Terms and Conditions and the Program Rules. We also reserves the right to monitor, review, retain and/or disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request. Calls to and from the Customer Service Teams (if applicable to this promotion) may be monitored and/or recorded for training and security purposes.</li>
                                <li>You should be aware that if we are requested by the police or any other regulatory or government authority investigating suspected illegal activities to provide your Personal Information and /or User Information, we are entitled do so.</li>
                                <li>You have the right to a copy of the personal information that we keep about you. If you want a copy of this information, or you believe that any information we are holding on you is incorrect or incomplete, please contact the Help Desk using this Website.</li>
                                <li>You should be aware that it is impossible to guarantee the security of any data disclosed via the Internet. However, we have taken all reasonable steps to protect your personal information. For example, we use the HTTP Secure protocol which is designed to protect the privacy and integrity of any data you provide. We also encrypt highly sensitive information such as your social security number/tax ID/credit card numbers. Encryption creates billions of code combinations to protect each transaction made on our Website, so your card details cannot be viewed by anyone else using the Internet.</li>
                            </ol>
                        </div>
                    </div>
                    <footer class="text-center">
                        <p><strong>Contact Information.</strong>  If you have any questions, comments, or complaints concerning our
                        <br></br>privacy practices please contact us at the appropriate address below.  We will attempt to respond to 
                        <br></br>your requests and to provide you with additional privacy-related information.</p> 
                        <br></br>
                        <p>E-Mail Contact: LIT-MarineCS@dometic.com</p>
                        Mail Contact: 1    Sierra Pl, Litchfield, IL 62056 United States
                        <br></br>Phone Contact: 1-800-648-3976      
                    </footer>
                </div>
            </div></>
    );
}

export default Terms;