import React from 'react';
import { Icon } from '../../../../components';
import { LiveDataModel } from '../../../../models/live-data';
import { AppUtils, HistoryUtils } from '../../../../utils';
import convert from '../../../../utils/convert-units';
import RunHistoryPieChart from './views/history/pie';

export const Print = React.forwardRef((props, ref) => {
  const { engine, log, t, metricSystem } = props;

  if (!engine || !log) {
    return <div ref={ref}></div>;
  }
  return (
    <div ref={ref}>
      <div className="f-row justify-content-between align-items-center">
        <h5 className="mb-0">ULTRA Scanner Report</h5>
        <h5><Icon name={'Logo'} height={40} width={80} /></h5>
      </div>
      <hr className="mt-0" />
      <div>
        <div className="f-row">
          <div className="col-6 pl-0">
            {engine && engine.owner ?
              <div className="f-row align-items-center">
                <div className="col-2 text-center"><Icon name={'User'} /></div>
                <div className="col-10">
                  <small className="text-muted">Engine owner</small>
                  <p>{engine.owner.fullName}</p>
                </div>
              </div>
              : null}
            {log && log.date ?
              <div className="f-row align-items-center">
                <div className="col-2 text-center"><Icon name={'Calendar'} /></div>
                <div className="col-10">
                  <small className="text-muted">Report created</small>
                  <p>{t('date.time.format', { date: new Date(log.date) })}</p>
                </div>
              </div>
              : null}
            {log && log.technician ?
              <div className="f-row align-items-center">
                <div className="col-2 text-center"><Icon name={'Wrench'} height={26} width={26} /></div>
                <div className="col-10">
                  <small className="text-muted">Report created by</small>
                  <p className="mb-0">{log.technician.fullName}</p>
                  {log.shop ?
                    <div className="f-row mb-3">
                      {log.shop.name ? <p className="mb-0">{log.shop.name}</p> : null}
                      {log.shop.email ? <p className="mb-0">, {log.shop.email}</p> : null}
                      {log.shop.phone ? <p className="mb-0">, {log.shop.phone}</p> : null}
                    </div>
                    : null}
                </div>
              </div>
              : null}
          </div>
          <div className="col-6">
            {engine && engine.qrCode ?
              <div className="f-row align-items-center">
                <div className="col-2 text-center"><Icon name={'QR'} /></div>
                <div className="col-10">
                  <small className="text-muted">QR Code</small>
                  <p>{engine.qrCode.code}</p>
                </div>
              </div>
              : null}
            {engine && engine.name ?
              <div className="f-row align-items-center">
                <div className="col-2 text-center"><Icon name={'Engine'} /></div>
                <div className="col-10">
                  <small className="text-muted">Engine name</small>
                  <p>{engine.name}</p>
                </div>
              </div>
              : null
            }
            {engine && engine.description ?
              <div className="f-row align-items-center">
                <div className="col-2 text-center"><Icon name={'Info'} /></div>
                <div className="col-10">
                  <small className="text-muted">Description</small>
                  <p className="text-muted">{engine.description}</p>
                </div>
              </div>
              : null
            }
          </div>
        </div>
      </div>
      <br />
      {/* Faults */}
      {log && log.faults ?
        <>
          <br />
          <div className="no-page-break">
            <div className="f-row">
              <div className="col-1 text-center"><Icon name={'Fault'} /></div>
              <div className="col-11 pr-0">
                <h6>{t('menu.fault')}</h6>
                <hr className="mt-0" />
                <div className="rows-two">
                  <div style={{ display: 'grid' }}>
                    {log.faults.map((element, eIndex) => {
                      let content = [];
                      let order = 0;
                      if (element.type === 'ACTIVE') {
                        order = 100
                        content.push(<div className="section" key={'faults-' + eIndex} style={{ order: order }}><p>{t('fault.type.active')}</p></div>)
                      } else if (element.type === 'INACTIVE') {
                        order = 200;
                        content.push(<div className="section" key={'faults-' + eIndex} style={{ order: order }}><p>{t('fault.type.inactive')}</p></div>)
                      } else if (element.type === 'CRITICAL') {
                        order = 300;
                        content.push(<div className="section" key={'faults-' + eIndex} style={{ order: order }}><p>{t('fault.type.critical')}</p></div>)
                      }
                      {
                        element.values.forEach((item, iIndex) => {
                          content.push(
                            <div className="f-row" key={`${eIndex + "-" + iIndex}`} style={{ order: order + iIndex }}>
                              <div className="col col-4 col-sm-3">
                                <p className="mb-0">{item.code}</p>
                              </div>
                              <div className="col col-4 col-sm-5">
                                {item.description && <p className="mb-0">{item.description}</p>}
                              </div>
                              <div className="col col-4 flex-grow"><p className="text-right mb-0">{AppUtils.localizedDuration(t, item.duration)}</p></div>
                            </div>
                          )
                          { iIndex === element.values.length - 1 && content.push(<div key={`${eIndex + "-break"}`} style={{ order: order + element.values.length, marginBottom: 8 }}></div>) }
                        })
                      }
                      return content;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        : null
      }
      {/* Live Data */}
      {log && log.liveData ?
        <>
          <br />
          <div className="no-page-break">
            <div className="f-row">
              <div className="col-1 text-center"><Icon name={'Live'} /></div>
              <div className="col-11">
                <h6>{t('menu.live')}</h6>
                <div className="rows-two">
                  {log.liveData.map((obj, index) => {
                    let content = [];
                    const model = new LiveDataModel(obj.id, t("eim.params." + obj.id));
                    if (obj.counter) {
                      model.name = t("eim.counters." + obj.id)
                    }
                    const quantityUnitId = obj.quantityUnit;
                    const point = obj.data[0];
                    const date = point ? new Date(point.x).getTime() : null;
                    const value = point ? point.y : null;
                    const calculated = AppUtils.recalculateParamValue(quantityUnitId, value, metricSystem, t);
                    const { to, color, paramValue } = calculated;
                    model.addPoint({ y: paramValue, x: date });
                    if (!to) {
                      model.specific = true;
                    }
                    model.quantityUnit = to;
                    content.push(
                      <div className="f-row" key={`live-${index}`} >
                        <div className="col col-6">
                          <p className="mb-0">{model.name}</p>
                        </div>
                        {model.specific ?
                          <div className="col col-6"><p className="text-right mb-0">{model.lastVisiblePoint() != null ? model.lastVisiblePoint().y : '---'}</p></div>
                          :
                          <div className="col col-6"><p className="text-right mb-0">{model.lastVisiblePoint() != null ? (model.lastVisiblePoint().y + ' ' + model.quantityUnit) : '---'}</p></div>
                        }

                      </div>
                    )
                    return content;
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
        : null
      }
      {/* Run History */}
      {log && log.runHistory ?
        <>
          <br />
          <div className="no-page-break">
            <div className="f-row">
              <div className="col-1 text-center"><Icon name={'Pie'} /></div>
              <div className="col-11 pr-0">
                <h6>{t('menu.history')}</h6>
                <div className="row">
                  {[0].map(() => {
                    let data = [];
                    if (log.runHistory.length > 0) {
                      log.runHistory.forEach((el, index) => {
                        if (index > 0) {
                          data.push(el);
                        }
                      })
                    }
                    const total = data.reduce((prev, cur) => {
                      const duration = typeof cur.duration === 'number' ? cur.duration : 0;
                      return prev + duration;
                    }, 0);
                    let content = [];
                    content.push(
                      <div className="col-6" key={`history-data`}>
                        {data.map((item, index) => (
                          <div className="f-row" key={`history-${index}`} >
                            <div className="col col-6 flex-grow-1">
                              <p className="mb-0">{HistoryUtils.rangeToString(item.start, item.end)}</p>
                            </div>
                            <div className="col col-4 ">
                              <p className="text-right mb-0">{AppUtils.localizedDuration(t, item.duration * 60)}</p>
                            </div>
                            <div className="col col-2 d-none d-md-block">
                              <p className="text-right mb-0">{Math.round((item.duration / total) * 100)} {t('quantity.unit.short.percentage')}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                    content.push(<div className="col-6 align-self-center" key={`history-pie`}><RunHistoryPieChart data={data} showHours={false} t={t} total={total} print={true} /></div>);
                    return content;
                  })}
                </div>
              </div>

            </div>
          </div>
        </>
        : null
      }
    </div>
  )
});