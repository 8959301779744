import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const HomeProductCategory = ({title, text, imagePath, url}) => {

    return (
        <div className="category">
            <Link to={url}>
                <figure>
                <img src={imagePath} alt={title} />
                <figcaption>
                    <h4>{title} <FontAwesomeIcon icon={faAngleRight} className="go-to-category" /></h4>
                </figcaption>
                </figure>
            </Link>
            <p>{text}</p>
        </div>
    )

}

HomeProductCategory.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    imagePath: PropTypes.string.isRequired
};
  
export default HomeProductCategory;