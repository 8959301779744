import React, { Fragment, useState, useEffect } from 'react';
import { Form, Button, Col } from "react-bootstrap";
import Moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faTimes, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useParams } from "react-router-dom";
import { WarrantyService } from "../../services";
import { Loading } from '../../components';
import { connect } from "react-redux";
import { isLoggedUser, getLoggedUserToken } from "../../redux/selectors";
import { saveAs } from "file-saver";
import { setAuthorizationHeader } from '../../utils/WarrantyAPI';

const WarrantyRequestView = ({ loggedUser, userToken }) => {

    let { rgaNumber } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showAdditionalData, setShowAdditionalData] = useState(false);
    const [isAddNewClaimActive, setAddNewClaimActive] = useState(false);

    const [status, setStatus] = useState(null);
    const [warrantyType, setWarrantyType] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [dateReceived, setDateReceived] = useState(null);
    const [shopRate, setShopRate] = useState(null);
    const [laborHours, setLaborHours] = useState(null);
    const [laborAmount, setLaborAmount] = useState(0);
    const [otherAmount, setOtherAmount] = useState(null);
    const [claimDescription, setClaimDescription] = useState("");
    const [selectedWarrantyTypeId, setSelectedWarrantyTypeId] = useState(2);
    const [cartItems, setCartItems] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [itemNumber, setItemNumber] = useState();
    const [itemReason, setItemReason] = useState("");
    const [itemQuantity, setItemQuantity] = useState(1);
    const [itemRequestType, setItemRequestType] = useState(null);

    const cancelAddItem = () => {
        setAddNewClaimActive(false);
    }

    useEffect(() => {
        if (loggedUser === false) {
            history.push("/user/login");
        }
    }, [history, loggedUser])

    useEffect(() => {
        setLoading(true);
        setAuthorizationHeader(userToken);
        WarrantyService.showWarrantyRequest(rgaNumber)
            .then(res => {
                if (res.status === 200 || res.status === 302) {
                    const data = res.data;
                    if (data.CreatedDate) {
                        setCreatedDate(Moment(data.CreatedDate).format('MM/DD/yyyy HH:mm'));
                    }
                    if (data.LastUpdated) {
                        setLastUpdated(Moment(data.LastUpdated).format('MM/DD/yyyy HH:mm'));
                    }
                    if (data.DateReceived) {
                        setDateReceived(Moment(data.DateReceived).format('MM/DD/yyyy HH:mm'));
                    }
                    setStatus(data.Status.toUpperCase());
                    setWarrantyType(data.Type)
                    setLaborHours(data.LaborHours);
                    setLaborAmount(data.LaborAmount);
                    setShopRate(data.ShopRate);
                    setOtherAmount(data.OtherAmount);
                    setClaimDescription(data.Description);
                    setSelectedWarrantyTypeId(data.SelectedWarrantyTypeId);
                    if (data.SelectedWarrantyTypeId == "1") {
                        setShowAdditionalData(true);
                    } else {
                        setShowAdditionalData(false);
                    }
                    setCartItems(data.LineItems);
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }, [])

    async function showClaimItem(id) {
        WarrantyService.showClaimItem(id)
            .then(res => {
                if (res.status === 200) {
                    setAddNewClaimActive(true);
                    const data = res.data;
                    setItemNumber(data.Item);
                    setItemReason(data.Reason);
                    setItemQuantity(data.Quantity);
                    setItemRequestType(data.WarrantyRequestType);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    async function downloadAttachment(attachmentId, fileName) {
        await WarrantyService.downloadAttachment(attachmentId, rgaNumber)
        .then(res => {
            if (res.status === 200) {
                saveAs(new Blob([res.data]), fileName);
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }

    return (
        <div className="text-page claim-request">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        <h1>Claim request</h1>
                        <h5>{rgaNumber}</h5>
                        <h5 class="status">Status: {status}</h5>
                        <h5 class="status">Created date: {createdDate}{lastUpdated ? ", Last updated: " + lastUpdated : null}{dateReceived ? ", Received date: " + dateReceived : null}</h5>
                    </div>
                </div>
                {loading === false ? 
                    <div className="row">
                        <div className="col-sm-12 col-xl-10 offset-xl-1">
                            <Form action="">
                                <div className="form-step">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h3>Request Type</h3>
                                        </div>        
                                        <div className="col-md-8 col-lg-6 col-xl-5">
                                            <Form.Group className="filter-dc">
                                                <Form.Check
                                                    type="radio"
                                                    label={warrantyType}
                                                    name="claim"
                                                    value={selectedWarrantyTypeId}
                                                    checked="true"
                                                    id="with"
                                                    inline
                                                />
                                            </Form.Group>
                                            {showAdditionalData === true ?
                                                <Fragment>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Shop Rate</Form.Label>
                                                            <Form.Control type="numeric" name="shopRate" value={shopRate} readOnly />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Labor Hours</Form.Label>
                                                            <Form.Control type="numeric" name="laborHours" value={laborHours} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Labor Amount*</Form.Label>
                                                            <Form.Control type="numeric" name="laborAmount" value={laborAmount} readOnly />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Other/Incidentals</Form.Label>
                                                            <Form.Control type="numeric" name="otherAmount" value={otherAmount} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="12">
                                                            <Form.Label>Claim Description</Form.Label>
                                                            <Form.Control type="textarea" name="claimDescription" value={claimDescription} readOnly />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Fragment>
                                            : null}
                                        </div>                                 
                                    </div>
                                </div>
                                {attachments.length > 0 ?
                                <div className="form-step">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h3>File Attachments</h3>
                                        </div>        
                                        <div className="col-lg-8">
                                            <div className="files-list">
                                                <div className="file-list-header">
                                                    <strong>Attached Files</strong><span>{attachments.length}</span>
                                                </div>
                                                {attachments.map((attachment, index) => 
                                                    <div className="file-line">
                                                    <div className="row" key={index}>
                                                        <div className="col id">
                                                            <span className="success">{index + 1}</span>
                                                        </div>
                                                        <div className="col desc">
                                                            <p>{attachment.Description}</p>
                                                            <em>{attachment.FileName}</em>
                                                        </div>
                                                        <div className="col type">{attachment.MimeType}</div>
                                                        <div className="col filesize">{attachment.FileSize}</div>
                                                        <div className="col action" >
                                                            <Button type="button" className="btn btn-secondary" onClick={(e) => downloadAttachment(attachment.FileAttachmentIdEncrypted, attachment.FileName)} ><FontAwesomeIcon icon={faCloudDownloadAlt} /></Button>
                                                        </div>
                                                    </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>                       
                                    </div>
                                </div>
                                : null}
                                <div className="form-step">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h3>Item List</h3>
                                        </div>        
                                        <div className="col-lg-8">
                                            {isAddNewClaimActive === true ? 
                                                <div className="item-form">
                                                    <h3>Catalog Item</h3>
                                                    <FontAwesomeIcon icon={faTimes} className="close-icon-form" style={{display: 'none'}} />
                                                    <div className="row">
                                                        <div className="col-lg-9">
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Item Number</Form.Label>
                                                                    <Form.Control type="text" name="itemNumber" value={itemNumber} readOnly />                                            
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Reason</Form.Label>
                                                                    <Form.Control type="text" name="itemReason" value={itemReason} readOnly />
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Quantity</Form.Label>
                                                                    <Form.Control type="number" min="1" name="itemQuantity" value={itemQuantity} readOnly />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Request Type</Form.Label>
                                                                    <Form.Control type="text" name="brand" value={itemRequestType} readOnly />
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Link className="cancel ml-2" onClick={cancelAddItem} >Back</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                            {cartItems.length > 0 ?
                                                <div className="files-list">
                                                     {cartItems.map((item, index) =>
                                                        <div className="file-line">
                                                        <div className="row" key={index}>
                                                            <div className="col id">
                                                                <span>{index + 1}</span>
                                                            </div>
                                                            <div className="col desc">
                                                                <p>{item.CartDescription}</p>
                                                                <em>{item.Item}</em>
                                                            </div>
                                                            <div className="col type">{item.WarrantyRequestType}</div>
                                                            <div className="col filesize">Qty {item.Quantity}</div>
                                                            <div className="col action" >
                                                                <Button onClick={(e) => showClaimItem(item.WarrantyDtlId)} className="btn btn-secondary" ><FontAwesomeIcon icon={faInfo} /></Button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                                </div>
                                            : <p className="mt-2">No items added.</p>}
                                        </div>                                 
                                    </div>
                                </div>
                                <div className="form-step">
                                    <div className="row">       
                                        <div className="offset-md-4 col-md-8 col-lg-6 col-xl-5">
                                            <Link to={{
                                                pathname: "/barcode/" + rgaNumber,
                                            }} className="btn btn-default btn-home" >Print packing slip</Link>
                                            <a className="cancel ml-2" href="/warranty-tracker" >Back</a>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                : <Loading />}
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUser, userToken };
}

export default connect(mapStateToProps)(WarrantyRequestView);