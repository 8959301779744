import React, { useEffect, useState } from 'react';
import CustomSwitch from '../../../../../../components/switch';
import { AppUtils, HistoryUtils } from '../../../../../../utils';
import RunHistoryPieChart from './pie';

const History = ({ t, data }) => {

  const [showHours, setShowHours] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    let array = [];
    data.forEach((el, index) => {
      if (index > 0) {
        array.push(el);
      }
    });
    setItems(array);
  }, [data]); 

  if (items.length === 0) {
    return <></>
  }

  const changeShowHours = () => setShowHours(old => !old);

  const total = items.reduce((prev, cur) => {
    const duration = typeof cur.duration === 'number' ? cur.duration : 0;
    return prev + duration;
  }, 0);

  return (
    <div>
      <div className="d-flex flex-direction-row justify-content-between">
        <p>{t('history.display')}</p>
        <div className="pb-2">
          <CustomSwitch 
            onChange={changeShowHours} 
            checked={showHours} 
            labelLeft={t('history.percentage')}
            labelRight={t('history.hours')}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body pt-0 pb-0" >
          <RunHistoryPieChart data={items} t={t} showHours={showHours} total={total} />
          {items.map((item, index) =>
            <div className="row row-ligt" key={item.id} >
              <div className="col col-6 flex-grow-1">
                <p className="mb-0">{HistoryUtils.rangeToString(item.start, item.end)}</p>
              </div>
              <div className="col col-4 ">
                <p className="text-right mb-0">{AppUtils.localizedDuration(t, item.duration * 60)}</p>
              </div>
              <div className="col col-2 list-border-left d-none d-md-block">
                <p className="text-right mb-0">{Math.round((item.duration / total) * 100)} {t('quantity.unit.short.percentage')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default History;