import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { User, Main, Plain, Register, Minimal } from './components/layouts';
import Routes from './routes';
import { ScrollToTop, ErrorBoundary } from './components';
import { PickList, Login, ForgotPassword, Diagrams, Diagram } from './pages';
import { RegistrationStep, ConfirmRegistration, RegistrationInvitation, PasswordReset } from './pages/user';
import Hotjar from '@hotjar/browser';


function App() {
  const siteId = 3867765;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  
  return (
    <Router>
        <ScrollToTop />

        <Switch>

        <Route path='/user/registration/confirm' exact>
          <User>
            <Switch>
              <Route path='/user/registration/confirm' exact component={ConfirmRegistration} />
            </Switch>
          </User>
        </Route>

          <Route path='/user/:path?' >
            <User>
              <Switch>
                <Route path='/user/login' exact render={() => <ErrorBoundary><Login /></ErrorBoundary>} />
                <Route path='/user/forgotpassword' exact render={() => <ErrorBoundary><ForgotPassword /></ErrorBoundary>} />
                <Route path='/user/resetpassword' exact render={() => <ErrorBoundary><PasswordReset /></ErrorBoundary>} />
              </Switch>
            </User>
          </Route>

          <Route path='/registration' >
            <Register>
              <Switch>
                <Route path='/registration' exact render={() => <ErrorBoundary><RegistrationStep /></ErrorBoundary>} />
                <Route path='/registration/:invitationCode' exact render={() => <ErrorBoundary><RegistrationInvitation /></ErrorBoundary>} />
              </Switch>
            </Register>
          </Route>

          <Route path='/picklists' exact >
            <Plain>
              <Switch>
                <Route path='/picklists' exact render={() => <ErrorBoundary><PickList /></ErrorBoundary>} />
              </Switch>
            </Plain>
          </Route>

          <Route path='/diagrams' >
            <Plain>
              <Switch>
                <Route path='/diagrams' exact render={() => <ErrorBoundary><Diagrams /></ErrorBoundary>} />
                <Route path='/diagrams/:diagramId' exact render={() => <ErrorBoundary><Diagram /></ErrorBoundary>} />
              </Switch>
            </Plain>
          </Route>

          <Route path='/account' >
            <Minimal>
              <Switch>
                <Routes />
              </Switch>
            </Minimal>
          </Route>

          <Route path='/order' >
            <Minimal>
              <Switch>
                <Routes />
              </Switch>
            </Minimal>
          </Route>

          <Route path='/ultra' >
            <Minimal>
              <Switch>
                <Routes />
              </Switch>
            </Minimal>
          </Route>

          <Route path='/promotions' >
            <Minimal>
              <Switch>
                <Routes />
              </Switch>
            </Minimal>
          </Route>
          
          <Route>
            <Main>
              <Switch>
                <Routes />
              </Switch>
            </Main>
          </Route>
          

        </Switch>
    </Router>
  );
}

export default App;
