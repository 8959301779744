import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { isLoggedUser } from "../redux/selectors";

const SidebarLinks = ({cn, toggleSidebar, loggedUser}) => {
   
    return(
        <div className={cn}>
            <FontAwesomeIcon icon={faTimes} onClick={ () => toggleSidebar() } className="close-sidebar" />
            <h3>CATEGORIES</h3>
            <ul className="sidebar-categories">
                <li><Link to="/marine">Marine <FontAwesomeIcon icon={faAngleRight} /></Link></li>
                <li><Link to="/power-sports">Power sports <FontAwesomeIcon icon={faAngleRight} /></Link></li>
                <li><Link to="/garden">Garden <FontAwesomeIcon icon={faAngleRight} /></Link></li>
            </ul>
            <h3>In focus</h3>
            <ul>
                {loggedUser === false ?
                    <li>
                        <Link to="/user/login">Login to Sierra Pro</Link>
                    </li>
                : null}
                <li>
                <li>
                    <Link to="/account/user">View Order History</Link>
                </li>
                <li>
                    <Link to="/order/quickorder">Quick Order</Link>
                </li>
                </li>
                <li>
                    <Link to="/diagrams">Diagrams and Exploded Views</Link>
                </li>
                <li>
                    <Link to="/news">News</Link>
                </li>
                <li>
                    <a href="http://www.seastarbom.com" target="_blank">Hydraulic Steering System Builder</a>
                </li>
            </ul>
            <h3>Help</h3>
            <ul>
                <li>
                    <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                    <Link to="/terms#privacy">Privacy Policy</Link>
                </li>
                <li>
                    <Link to="/returns">Return Policy</Link>
                </li>
                {loggedUser === false ?
                    <li><Link to="/warranty-policy">Warranty</Link></li>
                : <li><Link to="/warranty">Warranty</Link></li>}
                
                <li>
                    <Link to="/contact">Contact Us</Link>
                </li>
            </ul>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    return { loggedUser };
}

export default connect(mapStateToProps)(SidebarLinks);