import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import pdf1 from '../documents/SpacerKits.pdf';
import pdf2 from '../documents/FuelPumps.pdf';
import pdf3 from '../documents/Oil.pdf';
import pdf4 from '../documents/FuelFilters.pdf';
import pdf5 from '../documents/StarterSolenoids.pdf';

const HomeSlider = () => {
        return (
                <Carousel controls={false} indicators={false}>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={process.env.REACT_APP_CDN_PATH + "/static/images/slider/nrx-hero-1920x630.png?updatedAt=1719164394687"}
                        alt="SierraParts.com"
                        />
                        <Carousel.Caption>
                            <div className="table">
                                <div className="table-row">
                                    <div className="table-cell">
                                        <h2>Dometic NRX Refrigerators</h2>
                                        <span className="slider-description ">The next generation of marine refrigeration, NRX refrigerators feature premium compressors, </span>
                                        <br></br>
                                        <span className="slider-description ">near silent operation, and multiple sizes to meet boaters' needs.</span>
                                        <a href="https://catalog.sierraparts.com/search?q=nrx" className="slider-read-more" target="_blank"  rel="noreferrer">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
    )
}

export default HomeSlider;