
import React from 'react';
import Image from '../../assets/images/500.svg';

const Error500 = () => {
    return (
        <div className="error-page">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>Error: 500 Unexpected error</h1>
                        <img src={Image} alt="500" />
                        <p>An error occurred and your request couldn`t be completed.<br/>
                        Either check the URL, <a href="/">go home</a>, or feel free to <strong>report this issue</strong>.</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Error500;