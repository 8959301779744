import { PICKLIST_ADD_TO, PICKLIST_REMOVE_FROM, PICKLIST_CHANGE_QUANTITY, PICKLIST_RENAME, PICKLIST_DELETE, USER_LOGIN, USER_LOGOUT, TOGGLE_SWITCH } from "./actionTypes";

export const addToPickList = (pickListId, pickListName, productId, description, modelNumber, quantity, imagePath, loggedUserId) => ({
    type: PICKLIST_ADD_TO,
    payload: {
        pickListId,
        pickListName,
        productId,
        description,
        modelNumber,
        quantity,
        imagePath,
        loggedUserId
    }
})

export const changePickListItemQuantity = (pickListId, productId, quantity) => ({
    type: PICKLIST_CHANGE_QUANTITY,
    payload: {
        pickListId,
        productId,
        quantity
    }
})

export const removeFromPickList = (pickListId, productId) => ({
    type: PICKLIST_REMOVE_FROM,
    payload: {
        pickListId,
        productId
    }
})

export const renamePickList = (pickListId, name) => ({
    type: PICKLIST_RENAME,
    payload: {
        pickListId,
        name
    }
})

export const deletePickList = (pickListId) => ({
    type: PICKLIST_DELETE,
    payload: {
        pickListId
    }
})

export const loginUser = (email, userId, token) => ({
    type: USER_LOGIN,
    payload: {
        email,
        userId,
        token
    }
})

export const logoutUser = () => ({
    type: USER_LOGOUT
})

export const toggleSwitch = () => ({
    type: TOGGLE_SWITCH,
})
