/* eslint-disable import/no-anonymous-default-export */
import API from '../utils/API';

export default {
    async getMakes(marketId) {
        return API.get("/AdvancedSearch/Details/?id=getbrands", {
            params: {
                marketid: marketId
            }
        })
    },
    async getMakesForDiagrams(marketId) {
        return API.get("/AdvancedSearch/Details/?id=getbrands", {
            params: {
                forDiagrams: true
            }
        })
    },
    async getProductDetails(productId) {
        return await API.get("/SierraPartSearch/Details/" + productId);
    },
    async getProductAdditionalInformation(productId, filterAnalytics, filterAnalyticsCategoryId, filterAnalyticsMarketId) {
        return await API.get("/SierraPartPartialSearch/Details/?itemRow=" + productId 
            + (filterAnalytics != null ? filterAnalytics : "")
            + (filterAnalyticsCategoryId != null ? "&filterSetDetails.categoryId=" + filterAnalyticsCategoryId : "")
            + (filterAnalyticsMarketId != null ? "&filterSetDetails.marketId=" + filterAnalyticsMarketId : "")
        );
    },
    async getSimpleSearch(query, itemsPerPage, page) {
        return await API.get("/SimpleSearch/GetProducts/", {
            params: {
                query: query,
                pageSize: itemsPerPage,
                pageNumber: page
            }
        })
    },
    async getEngineDetails(engineId) {
        return await API.get("/EngineDetails/Details/" + engineId);
    },
    async getEngineProductsCategories(engineId, marketId) {
        return await API.get("/CategorySearch/Details/", {
            params: {
                id: engineId,
                marketId: marketId
            }
        })
    },
    async getEngineProducts(engineId, marketId) {
        return await API.get("/ProductSearch/Details/", {
            params: {
                id: engineId,
                marketId: marketId
            }
        })
    },
    async getDiagrams(brandId, searchPattern, itemsPerPage, page) {
        return await API.get("/diagrams/search", {
            params: {
                pageSize: itemsPerPage,
                pageNumber: page,
                brandId: brandId,
                displayName: searchPattern
            }
        })
    },
    async getDiagram(diagramId) {
        return await API.get("/diagrams/details/" + diagramId);
    },
    async getCategoryFilters(categoryName, marketId) {
        return await API.get("/IconFilters/Details/", {
            params: {
                query: categoryName,
                marketId: marketId
            }
        })
    },
    async getProducts(marketId, categoryName, itemsPerPage, page) {
        return await API.get("/FilterSetItems/Details/", {
            params: {
                query: categoryName,
                marketId: marketId,
                pageSize: itemsPerPage,
                pageNumber: page,
            }
        })
    },
    async getProductsFiltered(marketId, categoryName, additionalParams, itemsPerPage, page) {
        return await API.get("/FilterSet/Details/?query=" + categoryName.replace("&", "%26") + additionalParams, {
            params: {
                marketid: marketId,
                pageSize: itemsPerPage,
                pageNumber: page
            }
        })
    },
    async getApplicationInformationBrands(productId) {
        return await API.get("/ApplicationSearch/Details?id=ApplicationInfo", {
            params: {
                itemRow: productId,
            }
        })
    },
    async getApplicationInformationParts(productId, brandId, searchFilter, pageNumber, pageCount) {
        return await API.get("/ApplicationSearch/Details?id=ApplicationDetails", {
            params: {
                itemRow: productId,
                brandIds: brandId,
                searchFilter: searchFilter,
                pageNumber: pageNumber,
                pageCount: pageCount
            }
        })
    },
    async getNewsArticleTypes() {
        return await API.get("/bulletins/GetTypes");
    },
    async getNewsArticles(query, startDate, type, itemsPerPage, pageNumber) {
        return await API.get("/bulletins/search", {
            params: {
                searchTerm: query,
                startDate: startDate,
                type: type,
                pageSize: itemsPerPage,
                pageNumber: pageNumber,
                audList: [1]
            }
        })
    },
    async getLatestArticles(excludeId, itemsPerPage, pageNumber) {
        return await API.get("/bulletins/getunread", {
            params: {
                pageSize: itemsPerPage,
                pageNumber: pageNumber,
                seen: excludeId,
                audList: [1]
            }
        })
    },
    async getNewsArticleDetails(articleId) {
        return await API.get("/bulletins/" + articleId)
    }
}