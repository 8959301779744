import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

const Item = ({productId, engineId, description, categoryChild, categoryParent, saleItem, brandName, modelNumber, startYear, stopYear, horsePower, imagePath, listPrice}) => {
    let url = "#";
    if (productId) {
        url = "/product/" + productId;
    }
    if (engineId) {
        url = "/engine/" + engineId;
    }
    return (
        <Link to={{
            pathname: url,
            state: {
                brandName: brandName
            }   
        }} className="result-item">
            <div className="row">
                <div className="result-image">
                    {imagePath ? 
                        <figure>
                            <img src={process.env.REACT_APP_IMAGE_PATH + "/" + imagePath} alt={description} />
                        </figure>
                    : null}
                </div>
                {productId ? 
                <Fragment>
                    <div className="result-id">
                        <span className="saleItem">{saleItem}</span>
                    </div>
                    <div className="result-description">
                        <h4>{description}</h4>
                        <span>{categoryParent}</span> / <span>{categoryChild}</span>
                        {listPrice ? 
                        <div className="product-price">${listPrice} MSRP</div>
                        : null}
                    </div>
                </Fragment>
                : null}
                {engineId ? 
                <Fragment>
                    <div className="result-id">
                        <span className="saleItem">{engineId}</span>
                    </div>
                    <div className="result-description">
                        <h4>{brandName}</h4>
                        <span>{modelNumber}</span>
                    </div>
                </Fragment>
                : null}
                <div className="result-link">
                    <FontAwesomeIcon icon={faAngleRight} className="go-to-category" />
                </div>

            </div>
        </Link>
    )

}

Item.propTypes = {
    productId: PropTypes.number,
    engineId: PropTypes.number,
    description: PropTypes.string,
    categoryChild: PropTypes.string,
    categoryParent: PropTypes.string,
    saleItem: PropTypes.string,
    brandName: PropTypes.string,
    modelNumber: PropTypes.string,
    startYear: PropTypes.string,
    stopYear: PropTypes.string,
    horsePower: PropTypes.string,
    imagePath: PropTypes.string,
};

export default Item;