import React from 'react';
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReasonsModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">Join the Sierra B2B Portal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <p><em>The Sierra B2B portal is your new resource for accessing the complete Sierra catalog of marine, lawn & garden, and power sport products.</em></p>
                                    {/* <p><em>Subscribe now. It is FREE!</em></p> */}
                              </div>
                        </div>
                  </div>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <h4>Warranty Tracker</h4>
                                    <p>An easy to use tool that allows you to submit and track your warranty claims directly with Sierra.</p>
                              </div>
                        </div>
                  </div>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <h4>New Products</h4>
                                    <p>Be the first to know about new products from Dometic brands.</p>
                              </div>
                        </div>
                  </div>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <h4>Get Listed on our Dealer Locator</h4>
                                    <p>Authorized dealers are added to our dealer locater tool on SierraParts.com</p>
                              </div>
                        </div>
                  </div>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <h4>Cross Reference Parts</h4>
                                    <p>Find Sierra and Dometic competitive part numbers across our entire catalog.</p>
                              </div>
                        </div>
                  </div>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <h4>Diagrams & Exploded Views</h4>
                                    <p>Search our manufacturer database of detailed diagrams and exploded views.</p>
                              </div>
                        </div>
                  </div>
                  <div className="modal-info">
                        <div className="row">
                              <div className="col-lg-12">
                                    <h4>Access Dometic System Builders</h4>
                                    <p>Dometic's system builders make it easy to find all the part you need for SeaStar, Mechanical, Hydraulic, and Optimus (authorized dealers only) steering systems.</p>
                              </div>
                        </div>
                  </div>
            </Modal.Body>
            <Modal.Footer>
                  <div className="row">
                        <div className="col-sm-6 text-left">
                              <Button onClick={props.onHide} className="close-modal">Close</Button>
                        </div>
                        <div className="col-sm-6 text-right">
                              <Link to="/registration" className="btn btn-primary">Subscribe</Link>
                        </div>
                  </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ReasonsModal;