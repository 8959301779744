import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
 
const ReviewRegistration = (props) => {
    const [isUnchecked, setIsUnchecked] = useState(true);
    const [checkboxState, setCheckboxState] = useState({
        model1: { enabled: false, checked: false },
        model2: { enabled: false, checked: false },
        model3: { enabled: false, checked: false }
    });
   
    const handleCheckboxChange = (checkboxId) => {
        setCheckboxState(prevState => ({
            ...prevState,
            [checkboxId]: { ...prevState[checkboxId], checked: !prevState[checkboxId].checked }
        }));
    };
   
    const enableCheckbox = (checkboxId) => {
        setCheckboxState(prevState => ({
            ...prevState,
            [checkboxId]: { ...prevState[checkboxId], enabled: true }
        }));
    };
   
 
    useEffect(() => {
        const isUnchecked = Object.values(checkboxState).every(checkbox => checkbox.checked);
        setIsUnchecked(!isUnchecked);
    }, [checkboxState]);
    //const [creditTermsLabel, setCreditTermsLabel] = useState("");
 
    //     useEffect(() => {
    //         if (props.creditTerms === true) {
    //             setCreditTermsLabel('true');
    //         }
    //         else{
    //             setCreditTermsLabel('false');
    //         }
    //   }, [creditTermsLabel]) // Need this for display purposes
 
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11 offset-md-1">
                    <div className="row">
                        <div className="col-md-3">
                            <h3>REVIEW <br />REGISTRATION PAGE</h3>
                        </div>
                        <div className="col-md-9 rr" >
                            {props.companyName === undefined ? null :
                                <Fragment>
                                    <p className="mt-3"><strong>Company Registration</strong></p>
                                    <table className="table table-sm table-key-value">
                                        <tbody>
                                            <tr>
                                                <td>Company Name</td>
                                                <td>{props.companyName}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone Number</td>
                                                <td>{props.companyPhoneNumber}</td>
                                            </tr>
                                            {props.companyFaxNumber ?
                                                <tr>
                                                    <td>Fax Number</td>
                                                    <td>{props.companyFaxNumber}</td>
                                                </tr>
                                                : null}
                                            {props.companyWebsite ?
                                                <tr>
                                                    <td>Company Website</td>
                                                    <td>{props.companyWebsite}</td>
                                                </tr>
                                                : null}
                                            <tr>
                                                <td>Mailing Address 1</td>
                                                <td>{props.mailingAddress1}</td>
                                            </tr>
                                            {props.mailingAddress2 ?
                                                <tr>
                                                    <td>Mailing Address 2</td>
                                                    <td>{props.mailingAddress2}</td>
                                                </tr>
                                                : null}
                                            {props.mailingCity ?
                                                <tr>
                                                    <td>Mailing City</td>
                                                    <td>{props.mailingCity}</td>
                                                </tr>
                                                : null}
                                            {props.mailingState ?
                                                <tr>
                                                    <td>Mailing State</td>
                                                    <td>{props.mailingState}</td>
                                                </tr>
                                                : null}
                                            {props.mailingPostalCode ?
                                                <tr>
                                                    <td>Mailing Postal Code</td>
                                                    <td>{props.mailingPostalCode}</td>
                                                </tr>
                                                : null}
                                            {props.mailingCountry ?
                                                <tr>
                                                    <td>Mailing Country</td>
                                                    <td>{props.mailingCountry}</td>
                                                </tr>
                                                : null}
                                            <tr>
                                                <td>Shipping Address 1</td>
                                                <td>{props.shippingAddress1}</td>
                                            </tr>
                                            {props.shippingAddress2 ?
                                                <tr>
                                                    <td>Shipping Address 2</td>
                                                    <td>{props.shippingAddress2}</td>
                                                </tr>
                                                : null}
                                            {props.shippingCity ?
                                                <tr>
                                                    <td>Shipping City</td>
                                                    <td>{props.shippingCity}</td>
                                                </tr>
                                                : null}
                                            {props.shippingState ?
                                                <tr>
                                                    <td>Shipping State</td>
                                                    <td>{props.shippingState}</td>
                                                </tr>
                                                : null}
                                            {props.shippingPostalCode ?
                                                <tr>
                                                    <td>Shipping Postal Code</td>
                                                    <td>{props.shippingPostalCode}</td>
                                                </tr>
                                                : null}
                                            {props.shippingCountry ?
                                                <tr>
                                                    <td>Shipping Country</td>
                                                    <td>{props.shippingCountry}</td>
                                                </tr>
                                                : null}
                                            {/* <tr>
                                            <td>Credit Terms</td>
                                            <td>{creditTermsLabel}</td>
                                            </tr> */}
                                            {props.taxExemptId ? 
                                                <tr>
                                                    <td>Tax Exempt Id</td>
                                                    <td>{props.taxExemptId}</td>
                                                </tr>
                                                : null}
                                            <tr>
                                                <td>Reseller Certificate</td>
                                                <td>{props.resellerCertificate?.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Reseller Certificate State</td>
                                                <td>{props.resellerCertificateState}</td>
                                            </tr>
                                            <tr>
                                                <td>Credit Application</td>
                                                <td>{props.creditApplication?.name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Fragment>
                            }
                            <p className="mt-3"><strong>Login Information</strong></p>
                            <table className="table table-sm table-key-value">
                                <tbody>
                                    <tr>
                                        <td>Email Address</td>
                                        <td>{props.email}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className="mt-3"><strong>Personal Information</strong></p>
                            <table className="table table-sm table-key-value">
                                <tbody>
                                    <tr>
                                        <td>Email Address</td>
                                        <td>{props.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>{props.lastName} {props.firstName}</td>
                                    </tr>
                                    {props.homePhone ?
                                        <tr>
                                            <td>Home Phone</td>
                                            <td>{props.homePhone}</td>
                                        </tr>
                                        : null}
                                    {props.mobilePhone ?
                                        <tr>
                                            <td>Mobile Phone</td>
                                            <td>{props.mobilePhone}</td>
                                        </tr>
                                        : null}
                                    {props.officePhone ?
                                        <tr>
                                            <td>Office Phone</td>
                                            <td>{props.officePhone}</td>
                                        </tr>
                                        : null}
                                    {props.extension ?
                                        <tr>
                                            <td>Extension</td>
                                            <td>{props.extension}</td>
                                        </tr>
                                        : null}
                                    {props.salutation ?
                                        <tr>
                                            <td>Salutation</td>
                                            <td>{props.salutation}</td>
                                        </tr>
                                        : null}
                                    {props.jobTitle ?
                                        <tr>
                                            <td>Job Title</td>
                                            <td>{props.jobTitle}</td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td>Mailing Address 1</td>
                                        <td>{props.userMailingAddress1}</td>
                                    </tr>
                                    {props.userMailingAddress2 ?
                                        <tr>
                                            <td>Mailing Address 2</td>
                                            <td>{props.userMailingAddress2}</td>
                                        </tr>
                                        : null}
                                    {props.userMailingCity ?
                                        <tr>
                                            <td>Mailing City</td>
                                            <td>{props.userMailingCity}</td>
                                        </tr>
                                        : null}
                                    {props.userMailingState ?
                                        <tr>
                                            <td>Mailing State</td>
                                            <td>{props.userMailingState}</td>
                                        </tr>
                                        : null}
                                    {props.userMailingPostalCode ?
                                        <tr>
                                            <td>Mailing Postal Code</td>
                                            <td>{props.userMailingPostalCode}</td>
                                        </tr>
                                        : null}
                                    {props.userMailingCountry ?
                                        <tr>
                                            <td>Mailing Country</td>
                                            <td>{props.userMailingCountry}</td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td>Shipping Address 1</td>
                                        <td>{props.userShippingAddress1}</td>
                                    </tr>
                                    {props.userShippingAddress2 ?
                                        <tr>
                                            <td>Shipping Address 2</td>
                                            <td>{props.userShippingAddress2}</td>
                                        </tr>
                                        : null}
                                    {props.userShippingCity ?
                                        <tr>
                                            <td>Shipping City</td>
                                            <td>{props.userShippingCity}</td>
                                        </tr>
                                        : null}
                                    {props.userShippingState ?
                                        <tr>
                                            <td>Shipping State</td>
                                            <td>{props.userShippingState}</td>
                                        </tr>
                                        : null}
                                    {props.userShippingPostalCode ?
                                        <tr>
                                            <td>Shipping Postal Code</td>
                                            <td>{props.userShippingPostalCode}</td>
                                        </tr>
                                        : null}
                                    {props.userShippingCountry ?
                                        <tr>
                                            <td>Shipping Country</td>
                                            <td>{props.userShippingCountry}</td>
                                        </tr>
                                        : null}
                                </tbody>
                            </table>
                            <div className="mt-4 form-group row">                              
                                    <div class="form-check">
                                        <p><strong>Terms and Conditions</strong></p>
                                        <p>I hereby agree to ALL of the terms listed in this application, and hereby request consideration by Dometic Corporation to become an Authorized Commercial Customer and Authorized Service Center.</p>
                                        <p>To complete and submit your application, please click into each document below and verify you've reviewed our terms and conditions.</p>
                                        <h4>Authorized Buyer Application & Agreement</h4>
                                            <input name="agree" type="checkbox" id="model1" class="form-check-input" disabled={!checkboxState.model1.enabled} checked={checkboxState.model1.checked} onChange={() => handleCheckboxChange('model1')} />
                                            <label for="model1" class="form-check-label">
                                                By checking this box, I affirm that I have read and understood the <Link to="/terms" target="_blank" onClick={() => enableCheckbox('model1')}>Authorized Buyer Application and Agreement.</Link>
                                            </label>
 
                                        <h4>Authorized Service Center Agreement</h4>
                                            <input name="agree" type="checkbox" id="model2" class="form-check-input" disabled={!checkboxState.model2.enabled} checked={checkboxState.model2.checked} onChange={() => handleCheckboxChange('model2')} />  
                                            <label for="model2" class="form-check-label">
                                                By checking this box, I affirm that I have read and understood the <Link to="/terms" target="_blank" onClick={() => enableCheckbox('model2')}>Authorized Service Center Agreement.</Link>
                                            </label>
 
                                        <h4>Standard Terms and Conditions</h4>
                                            <input name="agree" type="checkbox" id="model3" class="form-check-input" disabled={!checkboxState.model3.enabled} checked={checkboxState.model3.checked} onChange={() => handleCheckboxChange('model3')} />
                                            <label for="model3" class="form-check-label">
                                                By checking this box, I affirm that I have read and understood the <Link to="/terms" target="_blank" onClick={() => enableCheckbox('model3')}>Standard terms and Conditions of Sale (B2B).</Link>
                                            </label>
 
                                    </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="line-break"></div>
                    <div className="row align-items-center">
                        <div className="col-3">
                            <Link to="#" onClick={(e) => props.reviewGoBack()} className="back-link">Back</Link>
                        </div>
                        <div className="col-9">
                            <Button type="button" onClick={props.saveRegistration} disabled={isUnchecked}>SUBMIT</Button>
                            <a className="cancel" href="/user/login" >Cancel</a>
                        </div>
                        <div className="col-sm-9 offset-sm-3">
                            <p>In the next step, we will create your profile.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default ReviewRegistration;