import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import queryString from 'query-string';
import { FooterCopyright } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services';

const PasswordReset = () => {
    const parsed = queryString.parse(window.location.search);
    const history = useHistory();

    const [code, setCode] = useState(parsed.code);
    const [userId, setUserId] = useState(parsed.userId);

    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
    const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState(null);

    const handleChangePassword = (value) => setPassword(value);
    const handleChangePasswordConfirm = (value) => setPasswordConfirm(value);
    
    async function resetPassword() {
        let isValid = true;
        if ((!password || /^\s*$/.test(password))) {
            setPasswordErrorMessage("Please enter a password.");
            isValid = false;
        } else {
            setPasswordErrorMessage(null);
        }
        if ((!passwordConfirm || /^\s*$/.test(passwordConfirm))) {
            setPasswordConfirmErrorMessage("Please enter a password.");
            isValid = false;
        } else {
            setPasswordConfirmErrorMessage(null);
        }
        if (password && passwordConfirm) {
            if (password !== passwordConfirm) {
                setPasswordConfirmErrorMessage("Passwords are not the same.");
                isValid = false;
            } else {
                if (password.length < 6) {
                    isValid = false;
                    setPasswordErrorMessage("The Password must be at least 6 characters long.");
                }
                if (password.passwordConfirm < 6) {
                    isValid = false;
                    setPasswordConfirmErrorMessage("The Password must be at least 6 characters long.");
                }
            }
        }
        if (isValid) {
            setLoading(true);
            UserService.passwordReset(userId, password, passwordConfirm, code)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.results.responseId === true) {
                        history.push("/user/login");
                    } else {
                        setPasswordErrorMessage(res.data.results.Message);
                    }
                }
                setLoading(false);
            }).catch(error => {
                console.log("Error " + error);
            });
        }
    }

    return (
        <div className="user login-form">
            <Link to="/" className="back"><FontAwesomeIcon icon={faArrowLeft} />Back</Link>
            <h2>Password Reset</h2>
            <p>Please, type in your Email - Username and Password</p>
            <Form>
                <Form.Control type="password" name="password" placeholder="Password" 
                    onChange={(e) => handleChangePassword(e.target.value)} 
                    className={passwordErrorMessage !== null ? "mt-2 is-invalid" : "mt-2"}
                    style={{marginBottom: 0}}
                />
                {passwordErrorMessage ? <div className="invalid-feedback">{passwordErrorMessage}</div> : null}
                <Form.Control type="password" name="confirmPassword" placeholder="Password confirm" 
                    onChange={(e) => handleChangePasswordConfirm(e.target.value)} 
                    className={passwordConfirmErrorMessage !== null ? "mt-2 is-invalid" : "mt-2"}
                    style={{marginBottom: 0}}
                />
                {passwordConfirmErrorMessage ? <div className="invalid-feedback">{passwordConfirmErrorMessage}</div> : null}
                <Button type="button" onClick={resetPassword} className="mt-4" disabled={loading === true ? "disabled" : ""} >Save</Button>
            </Form>
            <div className="row">
                <FooterCopyright />
            </div>
        </div>
    )
}

export default PasswordReset;