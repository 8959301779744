import React, { useEffect, useState } from 'react'
import { faEdit, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  NewUserAPIService from '../../services/NewUserAPIService';
import { setAuthorizationHeader } from '../../utils/NewUserAPI';
import DataTableComponent from '../../components/DataTableComponent';
import { getLoggedUserToken } from '../../redux/selectors';
import { connect } from "react-redux";

const columns = [
    {
        id:1,
        name: 'Name',
        selector: row => row.name,
        sortable:true
    },
    {
        id:2,
        name: 'Address',
        selector: row => row.address1,
        sortable:false
    },
    {
        id:3,
        name: 'City',
        selector: row => row.city,
        sortable:true
    },
    // {
    //     id:4,
    //     cell: (row) => <a type='button' /*href={UpdateShop(row)}*/><FontAwesomeIcon icon={faEdit} className="fa-1x" /></a>,
    //      width: '40px',
    //     sortable:false,

    // },
];

const UpdateShop = (row) => {
    //console.log("Row Id", row.orderId);
    let url = '/ultra/UpdateServiceShop/' + row.id;
    return url;
};

const ServiceShops = (props) => {

    const [ResponseData, setResponseData] = useState([]);
    const [DataLoading, setDataLoading] = useState(false);
    const [SearchText, setSearchText] = useState("Search Service Shops");

    useEffect(() => {
        console.log("ServiceShops: useEffect: props", props);
        //setAuthorizationHeader(props.userToken);
        getShops();
   }, [props.userToken])

   const getShops = () => {
    setDataLoading(true);
    NewUserAPIService.getServiceShops().then(response => {
        console.log("ServiceShops.getShops response: ", response);

        //console.log(response);
        if (response.status === 200) {
            if (response.data.data)
            {
                //console.log(response.data.data);
                console.log(response.data.data);
                setResponseData(response.data.data);
                setDataLoading(false);
                return true;
            }
        }
    }).catch(function (error) {
        console.log("getServiceShops error:");
        console.log(error);
        return error;
    });
}

    return (
        <DataTableComponent dataLoading={DataLoading} columns={columns} data={ResponseData} searchText ={SearchText} sortField={1} sortAsc={false} showQuickAdd={false} showSearch={true}></DataTableComponent>
    );
};
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    return { userToken };
}

export default connect(mapStateToProps)(ServiceShops);