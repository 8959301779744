import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faSearch, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Form, Col } from "react-bootstrap";
import { UserService } from '../../services';
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { Loading, ParticipantStatusChange } from '../../components';
import { logoutUser } from '../../redux/actions';

const ManageUsers  = ({ loggedUserId, userToken, logoutUser }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [partnerId, setPartnerId] = useState();

    useEffect(() => {
        getAccountManagementInfo();
    }, [])

    const getAccountManagementInfo = () => {
        setLoading(true);
        setAuthorizationHeader(userToken);
        UserService.getAccountManagementInfo(loggedUserId)
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === false) {
                    logoutUser();
                    history.push("/user/login");
                } else {
                    const userData = res.data.data.UserModelObject;
                    setUsers(userData.AllUsers);
                    setPartnerId(res.data.data.CompanyModelObject.PartnerId);
                }
            }
            setLoading(false);
        }).catch(error => {
            console.log("Error " + error);
        });
    }

    return (
        <div className="manage-users">
        <div className="container">
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <div className="manage-users-header">
                        <div className="row">
                            <div className="col-sm-8">
                                <h1>Manage Users</h1>
                            </div>
                            {partnerId ?
                                <div className="col-sm-4 text-right">
                                    <Link to={{
                                        pathname: "/profile/users/invite",
                                        state: {
                                            partnerId: partnerId
                                        }
                                    }} ><FontAwesomeIcon icon={faUserPlus} /> Invite User</Link>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="manage-users-form" style={{display: 'none'}}>
                        <div className="row">
                            <div className="col-sm-8">
                                <Form action="" className="ajax-search">
                                    <React.Fragment>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12">
                                                <FontAwesomeIcon icon={faSearch} />
                                                <Form.Control type="text" name="search-wt" placeholder="Filter by name" />
                                            </Form.Group>
                                        </Form.Row>
                                    </React.Fragment>
                                </Form>
                            </div>
                            <div className="col-sm-4 text-right">
                                <Link to="#" className="sort">
                                    Sort By
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    {loading ? <Loading /> :
                        <div className="manage-users-table">                            
                            {users.map((user, index) =>
                                <div className="pl-item">
                                    <div className="row">
                                        <div className="mu-index-index"><strong>{index + 1}</strong></div>
                                        <div className="" style={{flexGrow: 1}}>
                                            <strong>{user.FullName}</strong>
                                        </div>
                                        <div className="status">
                                            <span>{user.StatusDescription}</span>
                                        </div>
                                        <div className="email" >
                                            <span>{user.Email}</span>
                                        </div>
                                        <div className="title" >
                                            <span>Role/Title: {user.JobTitle}</span>
                                        </div>
                                        <div className="actions">
                                            <ParticipantStatusChange 
                                                participantId={user.ParticipantId}
                                                participantFullName={user.FullName}
                                                saveUserStatusChangeCallback={getAccountManagementInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
    );
}

const mapStateToProps = store => {
    const loggedUserId = getLoggedUserId(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUserId, userToken };
}

export default connect(mapStateToProps, { logoutUser })(ManageUsers);