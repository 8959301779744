import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from "react-router-dom";
import { Loading, NewsArticle, ArticleNavigation, ArticleBody } from '../../components';
import { CommonService } from '../../services';
import dateFormat from 'dateformat';
import { HomeHighlightProductItem } from '../../components';
import { Fragment } from 'react';

const Article = () => {

    let { articleId } = useParams();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [body, setBody] = useState();
    const [startDate, setStartDate] = useState();
    const [type, setType] = useState();
    const [fileName, setFileName] = useState();
    const [video, setVideo] = useState();
    const [linkedItem, setLinkedItem] = useState();
    const [expired, setExpired] = useState();
    const [message, setMessage] = useState();
    const [nextArticle, setNextArticle] = useState();
    const [previousArticle, setPreviousArticle] = useState();
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [readArticles, setReadArticles] = useState([]);

    useEffect(() => {
        let storage = [];
        if (localStorage.getItem("readArticles") != null) {
            storage = JSON.parse(localStorage.getItem("readArticles"));
            if (storage.indexOf(articleId) == -1) {
                storage.push(articleId);
                localStorage.setItem("readArticles", JSON.stringify(storage));
            }
        } else {
            storage = [articleId];
            localStorage.setItem("readArticles", JSON.stringify(storage));
        }
        setReadArticles(storage);
    }, [articleId])

    useEffect(() => {
        getNewsDetails();
    }, [articleId]);

    async function getNewsDetails() {
        CommonService.getNewsArticleDetails(articleId)
            .then(response => {
                if (response.status === 200) {
                    const expired = response.data.expired ? response.data.expired : false;
                    setExpired(expired);
                    setMessage(response.data.message ? response.data.message : '');
                    if (!expired) {
                        const articleResponse = response.data;
                        setTitle(articleResponse.Title);
                        setDescription(articleResponse.description);

                        if (articleResponse.Body != "undefined") {
                            setBody(articleResponse.Body);
                        }

                        setStartDate(articleResponse.StartDate);
                        setType(articleResponse.Type);
                        setVideo(articleResponse.VideoUrl ? articleResponse.VideoUrl.split('watch?v=')[1] : false);

                        if (articleResponse.BulletinLinks != null && articleResponse.BulletinLinks.length > 0) {
                            setLinkedItem(articleResponse.BulletinLinks);
                        }

                        setStartDate(articleResponse.StartDate);
                        setFileName(articleResponse.FileName);
                        if (articleResponse.NextArticle != 0) {
                            setNextArticle(articleResponse.NextArticle);
                        }
                        if (articleResponse.PreviousArticle != 0) {
                            setPreviousArticle(articleResponse.PreviousArticle);
                        }
                        getRelatedArticles();
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    const getRelatedArticles = () => {
        CommonService.getLatestArticles(articleId, 6, 1)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data.data.Bulletins;
                    if (response.data.data.TotalItems > 0) {
                        setRelatedArticles(data);
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="news new-article">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link></li>
                            <li className="divider">/</li>
                            <li><Link to="/news">News & Announcements</Link></li>
                        </ul>
                    </div>
                    {expired == false ?
                        <ArticleNavigation
                            type={type}
                            previousArticle={previousArticle}
                            nextArticle={nextArticle}
                        />
                        : null}
                    <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        {expired ?
                            <div className="article-intro">
                                <p>{message}</p>
                            </div>
                            :
                            <div>
                                <h1>{title}</h1>
                                <div className="meta">
                                    {type ?
                                        <span className="category">{type}</span>
                                        : null
                                    }
                                    <span className="date">{dateFormat(startDate, "mmmm dd, yyyy")}</span>
                                </div>
                                {description ?
                                    <div className="article-intro">
                                        <p>{description}</p>
                                    </div>
                                    : null
                                }
                                <div className="article-intro">
                                    {linkedItem && linkedItem.length > 0 ?
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <ArticleBody
                                                        body={body}
                                                        video={video}
                                                        title={title}
                                                        fileName={fileName}
                                                    />
                                                </div>

                                            </div>
                                            <strong className="stronk-title">Related products</strong>
                                            <div className="row linked-prod">

                                                
                                                
                                                {linkedItem.map((item, index) =>
                                                    <div className="col-lg-3">
                                                        <HomeHighlightProductItem
                                                            productId={item.ItemRow}
                                                        />
                                                    </div>
                                                )}


                                            </div>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ArticleBody
                                                        body={body}
                                                        video={video}
                                                        title={title}
                                                        fileName={fileName}
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {relatedArticles.length > 0 ?
                <div className="related-articles">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="row">
                                    <div className="col-12">
                                        <h2>LATEST News & Announcements</h2>
                                    </div>
                                    {relatedArticles.map((item, index) =>
                                        <NewsArticle
                                            id={item.id}
                                            className={readArticles.indexOf('' + item.id) > -1 ? 'read article-line' : 'article-line'}
                                            title={item.title}
                                            type={item.type}
                                            startDate={dateFormat(item.startDate, "mmmm dd, yyyy")}
                                            description={item.description}
                                            key={index}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    )
}

export default Article;