import NewUserAPI from '../utils/NewUserAPI';
 
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getServiceShops() {
        return NewUserAPI.get('/serviceshops/getbycompany');
    },

    async updateServiceShop() {
        return NewUserAPI.post('/serviceshops/update');
    },

    async getServiceShopById(guid) {
        return NewUserAPI.get('/serviceshops/getbyguid/' + guid);
    },
}