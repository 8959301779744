export const PERMISSIONS = {
    OPInquiryOnly: 1,
    OPFullAccess: 2,
    OPMultiFullAccess: 3,
    WarrantyFullAccess: 4,
    OP3PartyBill: 5,
    OBRFullAccess: 6,
    SierraAdmin: 7,
    ShoppingCartAccess: 8,
    AddShippingAccess: 9,
    HasUltraAccess: 10
}