import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { Layout } from '../../../components';
import { UltraService } from '../../../../services';
import Icon from "../../../components/icon";
import * as LogFunctions from './log/functions';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ProfileEngine from "./views/engine";
import ProfileLog from "./views/log";
import { setClientToken } from "../../../utils/ScannerAPI";
// import '../../../styles/css/custom.css';
// import '../../../styles/scss/custom.scss';

const RED = '#dc3545', YELLOW = '#ffc107', GRAY = '#6c757d';

const Profiles = (props) => {

  const { t } = useTranslation();
  const [engines, setEngines] = useState(null);
  const [logs, setLogs] = useState(null);
  const [enginesLoader, setEnginesLoader] = useState(false);
  const [logsLoader, setLogsLoader] = useState(false);
  const [, setReload] = useState(0);
  const [searchText, setSearchText] = useState('');
  
  const activeEngine = useRef(null);
  const engineOwnerEmail = useRef(false);
  const engineResultsPrevPage = useRef(false);
  const engineResultsNextPage = useRef(false);
  const engineResultsCurrentPage = useRef(0);
  const logResultsPrevPage = useRef(false);
  const logResultsNextPage = useRef(false);
  const pageSize = 10;

  useEffect(() => {
    localStorage.setItem("kp_token", props.ClientToken);
    setClientToken(props.ClientToken);
}, [props.ClientToken]);

  const getLogs = (engine, pageNumber) => {
    activeEngine.current = engine;
    engineOwnerEmail.current = engine.ownerEmail;
    if (pageNumber === 0) {
      logResultsPrevPage.current = false;
      logResultsNextPage.current = false;
    } else {
      logResultsPrevPage.current = pageNumber - 1;
    }
    setLogsLoader(true);
    UltraService.getLogsForEngine(engine.id, pageSize, pageNumber).then(response => {
      let logs = [];
      if (response.status === 200 && response.data.length > 0) {
        response.data.forEach((item, index) => {
          let color = '';
          const liveData = item.liveData;
          const faults = item.faults;
          const history = item.runHistory;
          const technician = item.technician ? item.technician : null;
          const shop = item.shop ? item.shop : null;
          if (technician) {
            if (faults) {
              const critical = faults.filter(el => el.type === 'CRITICAL');
              const active = faults.filter(el => el.type === 'ACTIVE');
              const inactive = faults.filter(el => el.type === 'INACTIVE');
              if (critical.length > 0) {
                color = RED;
              } else if (active.length > 0) {
                color = RED
              } else if (inactive.length > 0) {
                color = YELLOW;
              }
            }
            if (!color) {
              color = GRAY;
            }
          }
          const viewsArray = LogFunctions.getViews(liveData, faults, history, t);
          logs.push({ viewsData: viewsArray, id: item.id, date: item.date, comment: item.comment, technician: technician, shop: shop, color: color });
        });

        UltraService.getLogsForEngine(engine.id, pageSize, pageNumber + 1).then(response => {
          logResultsNextPage.current = (response.status === 200 && response.data.length > 0) ? pageNumber + 1 : false;
          setReload(old => old + 1);
        });
        setLogs(logs);
      } else {
        logResultsPrevPage.current = false;
        logResultsNextPage.current = false;
        setLogs([]);
      }
      setLogsLoader(false);
    }).catch(error => console.log('Error while getting logs', error));
  }

  const getEngines = useCallback((e, pageNumber, searchPattern) => {
    e.preventDefault();
    engineResultsCurrentPage.current = pageNumber;
    if (pageNumber === 0) {
      setEngines(null);
      engineResultsPrevPage.current = false;
      engineResultsNextPage.current = false;
    } else {
      engineResultsPrevPage.current = pageNumber - 1;
    }
    activeEngine.current = null;
    setEnginesLoader(true);
    setLogs(null);
    if (searchPattern !== '') {
      searchEngines(searchPattern, pageSize, pageNumber);
    } else {
      engineResultsPrevPage.current = false;
      engineResultsNextPage.current = false;
      setEngines(null);
      setEnginesLoader(false);
    }
  }, [pageSize]);

  const searchEngines = useCallback((searchText, pageSize, pageNumber) => {
    UltraService.searchEngines(searchText, pageSize, pageNumber).then(response => {
      if (response.status === 200 && response.data.length > 0) {
        let enginesArray = [];
        response.data.forEach((item, index) => {
          let ownerEmail = item.owner && item.owner.email ? item.owner.email : false;
          let ownerName = item.owner && item.owner.fullName ? item.owner.fullName : false;
          let ownerPhone = item.owner && item.owner.phoneNumber ? item.owner.phoneNumber : false;
          let ownerChange = item.ownerChange && item.ownerChange.status == 'PENDING' ? item.ownerChange : false;
          enginesArray.push({ id: item.id, ownerEmail: ownerEmail, name: item.name, qrCode: item.qrCode.code, ownerName: ownerName, createdDate: item.createdDate, ownerPhone: ownerPhone, connectivityId: item.connectivityId, ownerChange: ownerChange })
        });
        UltraService.searchEngines(searchText, pageSize, pageNumber + 1).then(response => {
          engineResultsNextPage.current = (response.status === 200 && response.data.length > 0) ? pageNumber + 1 : false;
          setReload(old => old + 1);
        });
        setEngines(enginesArray);
      } else {
        engineResultsPrevPage.current = false;
        engineResultsNextPage.current = false;
        setEngines([]);
      }
      setEnginesLoader(false);
    }).catch(error => console.log('Error while getting Engines', error));
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col col-12 col-lg-6 mb-3">
          <h2>{t('profiles.heading')}</h2>
          <p>{t('profiles.details')}</p>
          <form onSubmit={(e) => getEngines(e, 0, searchText)}>
            <div className="search-input">
              <input type="text" placeholder={t('profiles.form.search.input.placeholder')} onChange={(e) => setSearchText(e.target.value)} />
              <button className="btn btn-small btn-ultra" type="submit"><Icon name="Search" width={16} height={16} /><span>{t('profiles.form.search.submit')}</span></button>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <ProfileEngine
          activeEngine={activeEngine.current}
          loading={enginesLoader}
          engines={engines}
          getEngines={getEngines}
          pageNumber={engineResultsCurrentPage.current}
          prevPage={engineResultsPrevPage.current}
          nextPage={engineResultsNextPage.current}
          getLogs={getLogs}
          searchText={searchText}
        />
        <ProfileLog 
          activeEngine={activeEngine.current}
          loading={logsLoader}
          logs={logs}
          getLogs={getLogs}
          prevPage={logResultsPrevPage.current}
          nextPage={logResultsNextPage.current}
        />
      </div>
    </Layout>
  )
}

export default Profiles;