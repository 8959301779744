import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { UserService } from '../../services';
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { useHistory } from "react-router-dom";
import { Loading, ParticipantPermissionsChange } from '../../components';
import { logoutUser } from '../../redux/actions';
import { Form, Col } from "react-bootstrap";

const ParticipantPermissions = ({ loggedUserId, userToken, logoutUser }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [usersPermissions, setUsersPermissions] = useState([]);



    useEffect(() => {
        getAccountManagementInfoWithPermissions();
    }, [])

    const getAccountManagementInfoWithPermissions = () => {
        setLoading(true);
        setAuthorizationHeader(userToken);
        UserService.getAccountManagementInfoWithPermissions(loggedUserId)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.results.responseId === false) {
                        logoutUser();
                        history.push("/user/login");
                    } else {
                        const userData = res.data.data.ParticipantPermissionsModelObject;
                        setUsersPermissions(userData.AllPermissions.filter(user => { return (user.StatusDescription === "Active") })) // Filter for Active users only
                    }
                }
                setLoading(false);
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    return (
        <div className="manage-users">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="manage-users-header">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h1>Participant Permissions</h1>
                                </div>
                            </div>
                        </div>
                        {loading ? <Loading /> :
                            <div className="manage-users-table">
                                {usersPermissions.map((user, index) =>
                                    <div className="pl-item">
                                        <div className="row">
                                            <div className="mu-index-index"><strong>{index + 1}</strong></div>
                                            <div className="col-sm-3">
                                                <strong>{user.FullName}</strong>
                                            </div>
                                            <div className="col-sm-1 status">
                                                <span>{user.StatusDescription}</span>
                                            </div>
                                            <div className="col-sm-3">
                                                <span>{user.Email}</span>
                                            </div>
                                            <Form.Group>
                                                <Col className="col-sm-auto">
                                                    <span>Warranty</span>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={user.HasWarrantyAccess}
                                                        disabled
                                                        id="warranty"
                                                    />
                                                </Col>
                                                <Col className="col-sm-auto">
                                                    <span>Order</span>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={user.ShoppingCartAccess}
                                                        disabled
                                                        id="order"
                                                    />
                                                </Col>
                                                <Col className="col-sm-auto">
                                                    <span>Ship-to</span>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={user.AddShippingAccess}
                                                        disabled
                                                        id="ship"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <div className="col-sm-auto">
                                                <div className="actions">
                                                    <ParticipantPermissionsChange
                                                        participantId={user.ParticipantId}
                                                        participantFullName={user.FullName}
                                                        warrantyAccess={user.HasWarrantyAccess}
                                                        shoppingCartAccess={user.ShoppingCartAccess}
                                                        addShippingAccess={user.AddShippingAccess}
                                                        saveUserPermissionsCallback={getAccountManagementInfoWithPermissions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUserId = getLoggedUserId(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUserId, userToken };
}

export default connect(mapStateToProps, { logoutUser })(ParticipantPermissions);