import React, { useEffect, useState, createContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { CatalogService } from '../services';
import { setCatalogAuthorizationHeader } from '../utils/NewCatalogAPI';

export const ShoppingCartContext = createContext();

const ShoppingCartConsumer = (props) => {

    return (
        <ShoppingCartContext.Consumer>
            {value => <ShoppingCartDetail refreshCount={value} userToken={props.userToken} />}
        </ShoppingCartContext.Consumer>
    )
}
export default ShoppingCartConsumer;

const ShoppingCartDetail = (props) => {
    const [itemCount, setItemCount] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        console.log("ShoppingCartDetail: useEffect2:");
        setItemCount(null);
        
        setCatalogAuthorizationHeader(props.userToken);
        
        CatalogService.getCartCount().then(response => {
            if (response.status === 200) {
                //console.log("ShoppingCartDetail: useEffect: response:", response);
                if (response.data.data) {
                    setItemCount(response.data.data.itemCount);
                }
            }
        }).catch(function (error) {
            console.log(error);
            return error;
        });
        
    }, [props.refreshCount])

    return (
        <div className='cartbadge' value={itemCount}><FontAwesomeIcon icon={faShoppingCart} size='lg'/></div>
    )
}