import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { ProductsList } from '../../components/garden';
import { HomeHighlightProductItem } from '../../components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const GardenCategory = () => {
    let { category } = useParams();
    const [categoryName, setCategoryName] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("gardenCategories") != null) {
            const gardenCategories = JSON.parse(localStorage.getItem("gardenCategories"));
            // eslint-disable-next-line eqeqeq
            const filtered = gardenCategories.filter(item => item.id == category);
            if (filtered && filtered[0]) {
                setCategoryName(filtered[0].name);
            }
        }
    }, [category])
    
    const products = {
        19: [10167, 11089, 14956, 10214], //DECK AND DRIVE
        20: [14543, 14521, 14551, 14534], //Internal Engine Systems
        21: [140405, 16308, 11614, 140378], //Tires, Tubes, & Wheels
        22: [14088, 14090, 137817, 137786], //Oils, Chemicals, & Fuel
        23: [13041, 13499, 137736, 137738], //Tools & Shop Supplies
        24: [152442, 153665, 105725, 115021], //Handheld
        25: [16235, 12336, 135781, 12136], //Blades
        26: [85288, 9205, 9147, 85472] //Air Filters
    };
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <FontAwesomeIcon icon={faAngleRight} />,
        prevArrow: <FontAwesomeIcon icon={faAngleLeft} />,
        responsive: [
          {
            breakpoint: 1469,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    

    return (
    <div className="category">
        <div className={"fixed-parallax category-garden"}></div>
        <div className={"category-intro background-title"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="category-name">{categoryName}</h1>
                            </div>
                            {products[category] ?
                                <div className="col-sm-12">
                                    {/* <Slider {...settings} >
                                        {products[category].map((productId, index) =>
                                            <div key={index}>
                                                <HomeHighlightProductItem 
                                                    key={index}
                                                    productId={productId}
                                                />
                                            </div>
                                        )}
                                    </Slider> */}
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="search-results white-background" id="search-results" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        {categoryName ?
                            <ProductsList categoryName={categoryName} categoryId={category} />
                        : null}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
  
export default GardenCategory;