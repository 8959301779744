import { useState, useEffect, React } from 'react';
import { Link, useLocation } from "react-router-dom";
import { MenuDropdown, MenuDetailedSearch, MenuSimpleSearch } from '../components';
import { Navbar, Nav } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { connect } from "react-redux";
import { isLoggedUser, getLoggedUserToken } from "../redux/selectors";
import oemCableRecallpdf from "../documents/AN2023001_CableRecall_OEM_rD.pdf";
import amCableRecallpdf from "../documents/Cable Recall_Combined_AM.pdf";

import ShoppingCartConsumer from './ShoppingCartMenuComponent';

const Menu = ({ hideDetailedSearch, hideAlerts, loggedUser, userToken }) => {
    const menuDDs = [
        ['Marine', 'marine', process.env.REACT_APP_MARINE_ID],
        ['Lawn & Garden', 'garden', process.env.REACT_APP_GARDEN_ID],
        ['Power Sports', 'power-sports', process.env.REACT_APP_POWERSPORTS_ID]
    ];
    
    
    //console.log("menu: CartCount: ", CartCount());

    const location = useLocation();
    
    let market = location.pathname.split('/')[1];
    let activeMarket = '';

    if (["marine", "garden", "power-sports"].includes(market)) {
        window.sessionStorage.setItem("lastCategoryPath", market);
    }

    if (window.sessionStorage.getItem("lastCategoryPath") !== null && ["marine", "garden", "power-sports", "detailed-search"].includes(market)) {
        activeMarket = window.sessionStorage.getItem("lastCategoryPath");
    }

    const alertStyles = {
        backgroundColor: '#c71818',
        color: 'white'

    };
    const alertLink = {
        color: 'white'

    };
    const warningStyles = {
        backgroundColor: '#FCD12A',
        color: 'black'

    };
    const warningLink = {
        color: 'black'

    };
    return (
        <div className="menu">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <Navbar collapseOnSelect expand="lg">
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto only-desktop">
                                    {menuDDs.map((menuDD, menuIndex) =>
                                        <MenuDropdown
                                            key={menuIndex}
                                            categoryName={menuDD[0]}
                                            categoryLink={menuDD[1]}
                                            marketId={menuDD[2]}
                                            className={activeMarket === menuDD[1] ? 'active-market' : ''}
                                        />
                                    )}
                                </Nav>
                                <Nav>
                                    <MenuSimpleSearch />
                                    {loggedUser === true ?
                                        <Link to="/order/orderCart/0"><ShoppingCartConsumer userToken={userToken}></ShoppingCartConsumer></Link>
                                        : null
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
            
                        {hideDetailedSearch ? null : <MenuDetailedSearch />}

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { userToken, loggedUser };
}

export default connect(mapStateToProps)(Menu);