import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import Moment from "moment";
import wt from '../../assets/images/nav/warranty.jpg';
import wp from '../../assets/images/nav/warranty-policy.jpg';
import cr from '../../assets/images/nav/claim-request.jpg';
import { Form, Button, Row, Col } from "react-bootstrap";
import { WarrantyService } from '../../services';
import { Loading, NoResults } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { isLoggedUser, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/WarrantyAPI';

const WarrantyTracker = ({ loggedUser, userToken }) => {
    const itemsPerPage = 10
    const history = useHistory();
    const [rgaNumber, setRgaNumber] = useState("");
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [warranties, setWarranties] = useState([]);
    const [loading, setLoading] = useState(false);
    const [numberOfWarranties, setNumberOfWarranties] = useState(0);
    const [page, setPage] = useState(1);

    const [searchByRga, setSearchByRga] = useState(false);
    const [searchByPeriod, setSearchByPeriod] = useState(true);


    const handleRgaNumberChange = (value) => setRgaNumber(value);
    const handleDateToChange = (value) => { setDateTo(value) };
    const handleDateFromChange = (value) => setDateFrom(value);

    useEffect(() => {
        if (loggedUser === false) {
            history.push("/user/login");
        }
    }, [history, loggedUser])

    useEffect(() => {
        searchWarranties();
    }, [page])

    const handleSearchByRga = () => {
        setSearchByRga(true);
        setSearchByPeriod(false);
    }
    const handleSearchByPeriod = () => {
        setSearchByRga(false);
        setSearchByPeriod(true);
        setRgaNumber("");
    }

    async function searchWarranties() {
        setLoading(true);
        setAuthorizationHeader(userToken);
        await WarrantyService.search(rgaNumber, Moment(dateFrom).format('MM/DD/yyyy'), Moment(dateTo).format('MM/DD/yyyy'), page)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.SearchResults) {
                        setWarranties(res.data.SearchResults);
                    }
                    setNumberOfWarranties(res.data.totalRows);
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    const goToPage = (page) => {
        setPage(page);
    }

    return (
        <div className="text-page">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        <h1>Warranty Tracker</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-10 offset-lg-1 col-xl-6 offset-xl-3">
                        <Form action="" className="tracker-search">
                            <React.Fragment>
                                <Form.Group as={Row} className="filter-dc">
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label="Search by RGA"
                                            name="search_type"
                                            id="rb_rga"
                                            checked={searchByRga ? "checked" : ""}
                                            onChange={handleSearchByRga}
                                            inline 
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Search by Period"
                                            name="search_type"
                                            id="rb_period"
                                            checked={searchByPeriod ? "checked" : ""}
                                            onChange={handleSearchByPeriod}
                                            inline 
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Row className="align-items-end">
                                    {searchByRga ? 
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>RGA Number</Form.Label>
                                            <Form.Control type="text" name="rga" onChange={(e) => handleRgaNumberChange(e.target.value)} />
                                        </Form.Group>
                                    : null}
                                    {searchByPeriod ?
                                    <Fragment>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>From Date</Form.Label>
                                            <DatePicker
                                                locale="en"
                                                selected={dateFrom}
                                                onChange={date => handleDateFromChange(date)}
                                                maxDate={new Date()}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Label>To Date</Form.Label>
                                            <DatePicker
                                                locale="en"
                                                selected={dateTo}
                                                onChange={date => handleDateToChange(date)}
                                                maxDate={new Date()}
                                            />
                                        </Form.Group>
                                    </Fragment>
                                    : null}
                                    <Form.Group as={Col} md="3" className="text-right">
                                        <Button type="button" onClick={searchWarranties} className="search-warranties">SEARCH</Button>
                                    </Form.Group>
                                </Form.Row>
                            </React.Fragment>

                        </Form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-lg-10 offset-lg-1">
                        <div className="tracker-list">
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 text-right">
                                    <Link to="/claim-request" id="addCL" >Add new - Claim Request</Link>
                                </div>
                                <div className="col-sm-12">
                                    {loading === true ? <Loading /> :
                                        <Fragment>
                                            {warranties.length > 0 ?
                                                <Fragment>
                                                    <div className="table">
                                                        <div className="tr head">
                                                            <div className="td index">
                                                                <span>&nbsp;</span>
                                                            </div>
                                                            <div className="td title">
                                                                <span>RGA Number / Status</span>
                                                            </div>
                                                            <div className="td type">
                                                                <span>Request Type</span>
                                                            </div>
                                                            <div className="td type">
                                                                <span>Labor Refund</span>
                                                            </div>
                                                            <div className="td date">
                                                                <span>Created</span>
                                                            </div>
                                                            <div className="td date">
                                                                <span>Submitted</span>
                                                            </div>
                                                            <div className="td date">
                                                                <span>Last Update</span>
                                                            </div>
                                                            <div className="td action">
                                                                <span>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                        {warranties.map((item, index) =>
                                                            <div className="tr" key={index} >
                                                                <div className="td index">
                                                                    <em>{index + 1}</em>
                                                                </div>
                                                                <div className="td title">
                                                                    <strong>
                                                                        <Link to={{
                                                                            pathname: "/warranty/" + item.RgaNumber
                                                                        }} >{item.RgaNumber}</Link></strong>
                                                                    <br />
                                                                    <span class="status">{item.Status.toUpperCase()}</span>
                                                                </div>
                                                                <div className="td type">
                                                                    <span>{item.RequestType}</span>
                                                                </div>
                                                                <div className="td type">
                                                                    <span>{item.LaborRefund}</span>
                                                                </div>
                                                                <div className="td date">
                                                                    {item.CreatedDate ? <span>{Moment(item.CreatedDate).format('MM/DD/yyyy HH:mm')}</span> : null}
                                                                </div>
                                                                <div className="td date">
                                                                    {item.DateSubmitted ? <span>{Moment(item.DateSubmitted).format('MM/DD/yyyy HH:mm')}</span> : null}
                                                                </div>
                                                                <div className="td date">
                                                                    {item.LastUpdate ? <span>{Moment(item.LastUpdate).format('MM/DD/yyyy HH:mm')}</span> : null}
                                                                </div>
                                                                <div className="td action">
                                                                    <Link to="#" className="edit" style={{ display: 'none' }}>
                                                                        <FontAwesomeIcon icon={faPen} />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {numberOfWarranties > itemsPerPage ? (
                                                        <div className="text-center mt-2">
                                                            <Pagination
                                                                itemClass="page-item"
                                                                linkClass="page-link"
                                                                activePage={page}
                                                                itemsCountPerPage={itemsPerPage}
                                                                totalItemsCount={numberOfWarranties}
                                                                pageRangeDisplayed={5}
                                                                onChange={goToPage}
                                                            />
                                                        </div>
                                                        ) : null}
                                                </Fragment>
                                                : <NoResults />}
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        <div className="warranty-boxes">
                            <div className="row">
                                <div className="col-lg-4">
                                    <Link to="/warranty">
                                        <img src={wt} alt="warranty" />
                                        <strong>Warranty</strong>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link to="/warranty-policy">
                                        <img src={wp} alt="warranty-policy" />
                                        <strong>Warranty Policy</strong>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link to="/claim-request">
                                        <img src={cr} alt="claim-request" />
                                        <strong>Claim Request</strong>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUser, userToken };
}

export default connect(mapStateToProps)(WarrantyTracker);