import { useState, useEffect } from "react";
import convert from '../../../../../../utils/convert-units';
import { LiveDataModel } from "../../../../../../models/live-data";
import { AppUtils } from "../../../../../../utils";
import CustomSwitch from "../../../../../../components/switch";
import { useDispatch } from "react-redux";
import { setMetricSystem } from "../../../../../../redux/slice";

const LiveView = ({ t, data, noTitle, metricSystem }) => {

  const [liveData, setLiveData] = useState(null);
  const [changeMetricSystem, setChangeMetricSystem] = useState(metricSystem === 'metric');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!metricSystem) {
      return;
    }
    if (!data || data.length === 0) {
      return;
    }
    let models = [];
    data.forEach(obj => {
      if (!obj.data) {
        return;
      }
      const model = new LiveDataModel(obj.id, t("eim.params." + obj.id));
      if (obj.counter) {
        model.name = t("eim.counters." + obj.id)
      }
      const quantityUnitId = obj.quantityUnit;
      const point = obj.data[0];
      const date = point ? new Date(point.x).getTime() : null;
      const value = point ? point.y : null;
      const calculated = AppUtils.recalculateParamValue(quantityUnitId, value, metricSystem, t);
      const { to, color, paramValue } = calculated;
      model.addPoint({ y: paramValue, x: date });
      if (!to) {
        model.specific = true;
      }
      model.quantityUnit = to;
      model.color = color;
      models.push(model);
    });
    setLiveData(models);
  }, [metricSystem]);

  const onMetricSystemSwitch = () => {
    dispatch(setMetricSystem(metricSystem === 'imperial' ? 'metric' : 'imperial'));
    setChangeMetricSystem(old => !old);
  }

  if (!liveData) {
    return <></>
  }
  return (
    <div>
      <div className="d-flex flex-direction-row justify-content-between">
        <p>{!noTitle && t('live.display')}</p>
        <div className="pb-2">
          <CustomSwitch 
            onChange={onMetricSystemSwitch} 
            checked={changeMetricSystem} 
            labelLeft={t('metric.system.imperial')}
            labelRight={t('metric.system.metric')}
          />
        </div>
      </div>
      
      <div className="card">
        <div className="card-body pt-0 pb-0" >
        {liveData.map((item, index) =>
          <div className="row row-ligt" key={item.id} >
            <div className="col col-6">
              <p className="mb-0">{item.name}</p>
            </div>
            {item.specific ?
              <div className="col col-6"><p className="text-center mb-0">{item.lastVisiblePoint() != null ? item.lastVisiblePoint().y : '---'}</p></div>
            :
              <> 
                <div className="col col-3"><p className="text-right mb-0">{item.lastVisiblePoint() != null ? item.lastVisiblePoint().y : '---'}</p></div>
                <div className="col col-3"><p className="mb-0">{item.quantityUnit}</p></div>
              </>
            }
            
          </div>
        )}
        </div>
      </div>
    </div>
  )
}

export default LiveView;