import axios from "axios";

let UserAPI = axios.create({
    baseURL: process.env.REACT_APP_USER_API_URL,
    headers: { 'Content-Type': 'application/json' }
});

export const setAuthorizationHeader = token => {
    if (token) {
        UserAPI.defaults.headers.Authorization = `Bearer ${token}`;
    }
};

export default UserAPI;