import React, { Component } from 'react';

class NewsletterForm extends Component {
    constructor(props){
      super(props);
  
      this.state = {
        fields: {},
        errors: {}
      }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        console.log(fields);

        
        if(!fields["agreement"]){
          formIsValid = false;
            errors["email"] = "Email is not valid";
        }
    
        //Email
        if(!fields["email"]){
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
    
        if(typeof fields["email"] !== "undefined"){
          let lastAtPos = fields["email"].lastIndexOf('@');
          let lastDotPos = fields["email"].lastIndexOf('.');
    
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "Email is not valid";
          }
        }
    
    
    
        this.setState({errors: errors});
        return formIsValid;
      }
    
      contactSubmit(e){
        if(!this.handleValidation()){
            e.preventDefault();
        }
    console.log(this.state.fields["agreement"])
      }
    
      handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
      }
    
      render(){
        return (
        <div className="footer-newsletter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 text-center">
                        <h4>Subscribe to get news</h4>
                        <form action="https://gmail.us7.list-manage.com/subscribe/post" method="POST" onSubmit={this.contactSubmit.bind(this)}>
                            <input type="hidden" name="u" value="087bce17e6fdc7285f90076be" />
                            <input type="hidden" name="id" value="e1d6b0f26f" />
                            <div className="newsletter-input">
                                <input type="text" placeholder="Enter e-mail" name="MERGE0" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                <span className="error">{this.state.errors["email"]}</span>
                                <button type="submit">SUBSCRIBE</button>
                            </div>
                            <div className="newsletter-input">
                                <label>
                                    <input type="checkbox" name="group[80109][1]" onChange={this.handleChange.bind(this, "agreement")} /><span>Yes, I want to receive email newsletters with special promotions.<br/> I can unsubscribe at any time. Please note our <a href="#">Privacy Policy</a>.</span>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}}

export default NewsletterForm;