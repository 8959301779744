import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changePickListItemQuantity } from '../../redux/actions';
import { PickListItemDelete } from '..';
import { Link } from 'react-router-dom';
import { UserService } from '../../services';
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/UserAPI';

const PickListItem = ({index, pickListId, id, pickListItemId, description, modelNumber, quantity, imagePath, price, callBackFunction, loggedUserId, userToken, changePickListItemQuantity}) => {
    const url = "/product/" + id;
    const [productQuantity, setProductQuantity] = useState(quantity);

    useEffect(() => {
        setProductQuantity(quantity);
    }, [pickListId, pickListItemId, quantity])

    const handleChangeQuantity = (value) => {
        setProductQuantity(value);
        if (loggedUserId) {
            setAuthorizationHeader(userToken);
            UserService.changePickListItemQuantity(pickListItemId, value)
            .then(res => {
                if (res.status === 200) {
    
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            changePickListItemQuantity(pickListId, id, value);
        }
    }

    return (
        <div className="pl-item">
        <div className="row">
            <div className="pl-index"><span>{index + 1}</span></div>
            <div className="result-image">
                <figure>
                    {imagePath ? <img src={process.env.REACT_APP_IMAGE_PATH + "/" + imagePath} alt={description} />: null}
                </figure>
            </div>
            <div className="col all">
                <div className="result-id">
                    <span className="saleItem" style={{whiteSpace: 'nowrap'}}>
                        <Link to={url} >{modelNumber}</Link>
                        <br/>
                        <span>{price ? '$'+price : null}</span>
                    </span>
                </div>
                <div className="result-description">
                    <h4>{description}</h4>
                </div>
                <div className="result-id" >
                    <input type="text" value={productQuantity} onChange={(e) => handleChangeQuantity(e.target.value)} />
                </div>
            </div>
            <div className="result-action text-center">
                <PickListItemDelete 
                    pickListId={pickListId}
                    productId={pickListItemId}
                    callBackFunction={callBackFunction}
                />
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    const loggedUserId = getLoggedUserId(state);
    const userToken = getLoggedUserToken(state);
    return { loggedUserId, userToken };
}

PickListItem.propTypes = {
    pickListId: PropTypes.number.isRequired,
    pickListItemId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    modelNumber: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    imagePath: PropTypes.string,
    price: PropTypes.string
};

export default connect(mapStateToProps, { changePickListItemQuantity })(PickListItem);