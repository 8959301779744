import axios from "axios";

let CatalogAPI = axios.create({
    baseURL: process.env.REACT_APP_NEW_CATALOG_API_URL,
    responseType: "json",
    headers: { 'Content-Type': 'application/json' }
});


export const setCatalogAuthorizationHeader = token => {
    if (token) {
        //console.log('setCatalogAuthorizationHeader - ' + token);
        CatalogAPI.defaults.headers.Authorization = `Bearer ${token}`;
    }
};

export default CatalogAPI;