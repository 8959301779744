import palette from './../assets/colors/run-history.json';

export function mapColors(data) {
  return data.map((el, i) => {
    var fill = palette[data.length];
    if (!fill) {
      fill = palette[0];
      i = 0;
    }
    return { ...el, svg: { fill: fill[i] } }
  })
}

export function commaNumber(num, comma = ',') {
  if (isNaN(num)) {
    return '--.-';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma);
}

export function rangeToString(from, to, units = 'rpm') {
  if (isNaN(from)) {
    return '---';
  }
  return commaNumber(from) + '–' + commaNumber(to) + ' ' + units;
}

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
