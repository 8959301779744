import React, { Fragment, useState, useEffect } from 'react';
import {Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AddressDetails, Loading } from '../../components';
import { UserService } from '../../services';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { logoutUser } from '../../redux/actions';
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/UserAPI';
 
 
const PersonalProfile  = ({ loggedUserId, userToken, logoutUser }) => {
        const history = useHistory();
 
        const [loading, setLoading] = useState(true);
        const [participantId, setParticipantId] = useState();
        const [partnerId, setPartnerId] = useState();
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [homePhone, setHomePhone] = useState("");
        const [mobilePhone, setMobilePhone] = useState("");
        const [officePhone, setOfficePhone] = useState("");
        const [extension, setExtension] = useState("");
        const [salutation, setSalutation] = useState("");
        const [jobTitle, setJobTitle] = useState("");
        const [birthDate, setBirthDate] = useState("");
        const [maxDate, setMaxDate] = useState(new Date());
        const [email, setEmail] = useState("");
        const [userMailingAddress1, setUserMailingAddress1] = useState("");
        const [userMailingAddress2, setUserMailingAddress2] = useState("");
        const [userMailingCity, setUserMailingCity] = useState("");
        const [userMailingState, setUserMailingState] = useState("");
        const [userMailingPostalCode, setUserMailingPostalCode] = useState("");
        const [userMailingCountry, setUserMailingCountry] = useState("USA");
        const [userShippingAsMailingAddress, setUserShippingAsMailingAddress] = useState(false);
        const [userShippingAddress1, setUserShippingAddress1] = useState("");
        const [userShippingAddress2, setUserShippingAddress2] = useState("");
        const [userShippingCity, setUserShippingCity] = useState("");
        const [userShippingState, setUserShippingState] = useState("");
        const [userShippingPostalCode, setUserShippingPostalCode] = useState("");
        const [userShippingCountry, setUserShippingCountry] = useState(null);
 
        const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
        const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
        const [homePhoneErrorMessage, setHomePhoneErrorMessage] = useState("");
        const [mobilePhoneErrorMessage, setMobilePhoneErrorMessage] = useState("");
        const [officePhoneErrorMessage, setOfficePhoneErrorMessage] = useState("");
        const [extensionErrorMessage, setExtensionErrorMessage] = useState("");
        const [mailingAddress1ErrorMessage, setMailingAddress1ErrorMessage] = useState(null);
        const [mailingCityErrorMessage, setMailingCityErrorMessage] = useState(null);
        const [mailingStateErrorMessage, setMailingStateErrorMessage] = useState(null);
        const [mailingPostalCodeErrorMessage, setMailingPostalCodeErrorMessage] = useState(null);
        const [shippingAddress1ErrorMessage, setShippingAddress1ErrorMessage] = useState(null);
        const [shippingCityErrorMessage, setShippingCityErrorMessage] = useState(null);
        const [shippingStateErrorMessage, setShippingStateErrorMessage] = useState(null);
        const [shippingPostalCodeErrorMessage, setShippingPostalCodeErrorMessage] = useState(null);
 
        const handleChangeFirstName = (value) => setFirstName(value);
        const handleChangeLastName = (value) => setLastName(value);
        const handleChangeHomePhone = (value) => setHomePhone(value);
        const handleChangeMobilePhone = (value) => setMobilePhone(value);
        const handleChangeOfficePhone = (value) => setOfficePhone(value);
        const handleChangeExtension = (value) => setExtension(value);
        const handleChangeSalutation = (value) => setSalutation(value);
        const handleChangeJobTitle = (value) => setJobTitle(value);
        const handleChangeBirthDate = (value) => setBirthDate(value);
        const handleChangeMailingAddress1 = (value) => setUserMailingAddress1(value);
        const handleChangeMailingAddress2 = (value) => setUserMailingAddress2(value);
        const handleChangeMailingCity = (value) => setUserMailingCity(value);
        const handleChangeMailingState = (value) => setUserMailingState(value);
        const handleChangeMailingCountry = (value) => setUserMailingCountry(value);
        const handleChangeMailingPostalCode = (value) => setUserMailingPostalCode(value);
        const handleChangeShippingAddress1 = (value) => setUserShippingAddress1(value);
        const handleChangeShippingAddress2 = (value) => setUserShippingAddress2(value);
        const handleChangeShippingCity = (value) => setUserShippingCity(value);
        const handleChangeShippingState = (value) => setUserShippingState(value);
        const handleChangeShippingPostalCode = (value) => setUserShippingPostalCode(value);
        const handleChangeShippingCountry = (value) => setUserShippingCountry(value);
        const toggleShippingAsMailingAddress = () => setUserShippingAsMailingAddress(!userShippingAsMailingAddress);
 
        useEffect(() => {
                let maxDate = new Date();
                maxDate.setFullYear(maxDate.getFullYear() - 18);
                setMaxDate(maxDate);
                setAuthorizationHeader(userToken);
                UserService.getUserInfo()
                .then(res => {
                        if (res.data.results.responseId === false) {
                                logoutUser();
                                history.push("/user/login");
                        } else {
                                var userInfo = res.data.data;
                                setEmail(userInfo.Email);
                                setFirstName(userInfo.ProfileNameFirst);
                                setLastName(userInfo.ProfileNameLast);
                                setPartnerId(userInfo.PartnerHdrId);
                        }
                }).catch(error => {
                        console.log("Error " + error);
                });
                
                UserService.getAccountManagementInfo(loggedUserId)
                .then(res => {
                        if (res.status === 200) {
                                if (res.data.results.responseId === false) {
                                        logoutUser();
                                        history.push("/user/login");
                                } else {
                                        const userData = res.data.data.UserModelObject;
                                        setParticipantId(userData.Id);
                                        setSalutation(userData.GetProfileNameVo.Salutation);
                                        setBirthDate(userData.DoB == null ? userData.DoB : new Date(userData.DoB));
                                        setHomePhone(userData.PhoneNo);
                                        setMobilePhone(userData.MobilePhoneNo);
                                        setExtension(userData.Extension)
                                        setOfficePhone(userData.Phone);
                                        setUserMailingAddress1(userData.ShipAddress1);
                                        setUserMailingAddress2(userData.Address2);
                                        setUserMailingCity(userData.City);
                                        setUserMailingState(userData.State);
                                        setUserMailingPostalCode(userData.Zip);
                                        setUserMailingCountry(userData.Country.SelectedId);
                                        setUserShippingAddress1(userData.ShipAddress1);
                                        setUserShippingAddress2(userData.ShipAddress2);
                                        setUserShippingCity(userData.ShipCity);
                                        setUserShippingState(userData.ShipState);
                                        setUserShippingPostalCode(userData.ShipZip);
                                        setUserShippingCountry(userData.ShipCountry.SelectedId);
                                        setJobTitle(userData.JobTitle.SelectedId);
                                }
                        }
                        setLoading(false);
                }).catch(error => {
                        console.log("Error " + error);
                });
        }, [])
 
        useEffect(() => {
                if (userShippingAsMailingAddress === true) {
                      setUserShippingAddress1(userMailingAddress1);
                      setUserShippingAddress2(userMailingAddress2);
                      setUserShippingCity(userMailingCity);
                      setUserShippingState(userMailingState);
                      setUserShippingPostalCode(userMailingPostalCode);
                      setUserShippingCountry(userMailingCountry);
                }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [userShippingAsMailingAddress])
 
        async function submitPersonalInformation() {
                let isValid = true;
                if ((!firstName || /^\s*$/.test(firstName))) {
                        setFirstNameErrorMessage("Please enter a first name.");
                        isValid = false;
                } else {
                        setFirstNameErrorMessage(null);
                }
                if ((!lastName || /^\s*$/.test(lastName))) {
                        setLastNameErrorMessage("Please enter a last name.");
                        isValid = false;
                } else {
                        setLastNameErrorMessage(null);
                }
                if ((!userMailingAddress1 || /^\s*$/.test(userMailingAddress1))) {
                        setMailingAddress1ErrorMessage("Please enter a address.");
                        isValid = false;
                } else {
                        setMailingAddress1ErrorMessage(null);
                }
                if ((!userMailingCity || /^\s*$/.test(userMailingCity))) {
                        setMailingCityErrorMessage("Please enter a city.");
                        isValid = false;
                } else {
                        setMailingCityErrorMessage(null);
                }
                if ((!userMailingState || /^\s*$/.test(userMailingState))) {
                        setMailingStateErrorMessage("Please enter a state.");
                        isValid = false;
                } else {
                        setMailingStateErrorMessage(null);
                }
                if ((!userMailingPostalCode || /^\s*$/.test(userMailingPostalCode))) {
                        setMailingPostalCodeErrorMessage("Please enter a postal code.");
                        isValid = false;
                } else {
                        setMailingPostalCodeErrorMessage(null);
                }
                if ((!userShippingAddress1 || /^\s*$/.test(userShippingAddress1))) {
                        setShippingAddress1ErrorMessage("Please enter a address.");
                        isValid = false;
                } else {
                        setShippingAddress1ErrorMessage(null);
                }
                if ((!userShippingCity || /^\s*$/.test(userShippingCity))) {
                        setShippingCityErrorMessage("Please enter a city.");
                        isValid = false;
                } else {
                        setShippingCityErrorMessage(null);
                }
                if ((!userShippingState || /^\s*$/.test(userShippingState))) {
                        setShippingStateErrorMessage("Please enter a state.");
                        isValid = false;
                } else {
                        setShippingStateErrorMessage(null);
                }
                if ((!userShippingPostalCode || /^\s*$/.test(userShippingPostalCode))) {
                        setShippingPostalCodeErrorMessage("Please enter a postal code.");
                        isValid = false;
                } else {
                        setShippingPostalCodeErrorMessage(null);
                }
                if (homePhone && !/^\d+$/.test(homePhone)) {
                        setHomePhoneErrorMessage("Please enter a valid phone number.");
                        isValid = false;
                } else {
                        setHomePhoneErrorMessage(null);
                }
                if (mobilePhone && !/^\d+$/.test(mobilePhone)) {
                        setMobilePhoneErrorMessage("Please enter a valid mobile number.");
                        isValid = false;
                } else {
                        setMobilePhoneErrorMessage(null);
                }
                if (officePhone && !/^\d+$/.test(officePhone)) {
                        setOfficePhoneErrorMessage("Please enter a valid office number.");
                        isValid = false;
                } else {
                        setOfficePhoneErrorMessage(null);
                }
                if (extension && !/^\d+$/.test(extension)) {
                        setExtensionErrorMessage("Please enter a valid extension number.");
                        isValid = false;
                } else {
                        setExtensionErrorMessage(null);
                }
                if (isValid) {
                       
                        UserService.saveProfilePersonalInformation(loggedUserId, participantId, partnerId, firstName, lastName, salutation, email,
                                userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingCountry, userMailingPostalCode,
                                userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingCountry, userShippingPostalCode,
                                homePhone, officePhone, extension, mobilePhone, jobTitle, getFormattedDate(birthDate))
                        .then(res => {
                                if (res.status === 200) {
                                        if (res.data.results.responseId === true) {
                                                history.push("/profile");
                                        }
                                }
                        }).catch(error => {
                                console.log("Error " + error);
                        });
                }
        }
 
 
        return(
                <div className="registration-form">
                <div className="container">
                        <div className="row">
                        <div className="col-md-11 offset-md-1">
                                <div className="title">
                                <h1>Personal Profile</h1>
                                </div>
                                {loading === false ?
                                <Fragment>
                                        <div className="line-break"></div>
                                        <div className="row">
                                                <div className="col-sm-3">
                                                        <h3>PROFILE information</h3>
                                                </div>
                                                <div className="col-sm-9">
                                                        <Form.Group as={Row}>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>First Name*</Form.Label>
                                                                        <Form.Control type="text" name="firstName" value={firstName} onChange={(e) => handleChangeFirstName(e.target.value)} className={firstNameErrorMessage ? "is-invalid" : ""} />
                                                                        {firstNameErrorMessage ? <div className="invalid-feedback">{firstNameErrorMessage}</div> : null}
                                                                </Col>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Last Name*</Form.Label>
                                                                        <Form.Control type="text" name="lastName" value={lastName} onChange={(e) => handleChangeLastName(e.target.value)} className={lastNameErrorMessage ? "is-invalid" : ""} />
                                                                        {lastNameErrorMessage ? <div className="invalid-feedback">{lastNameErrorMessage}</div> : null}
                                                                </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Email Address</Form.Label>
                                                                        <Form.Control type="text" name="emailAddress" value={email} readOnly />
                                                                </Col>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Salutation</Form.Label>
                                                                        <Form.Control type="text" name="salutation" value={salutation} onChange={(e) => handleChangeSalutation(e.target.value)} maxLength="5" />
                                                                </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Home Phone</Form.Label>
                                                                        <Form.Control type="text" name="homePhone" value={homePhone} onChange={(e) => handleChangeHomePhone(e.target.value)} className={homePhoneErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                                        {homePhoneErrorMessage ? <div className="invalid-feedback">{homePhoneErrorMessage}</div> : null}
                                                                </Col>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Mobile Phone</Form.Label>
                                                                        <Form.Control type="text" name="mobilePhone" value={mobilePhone} onChange={(e) => handleChangeMobilePhone(e.target.value)} className={mobilePhoneErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                                        {mobilePhoneErrorMessage ? <div className="invalid-feedback">{mobilePhoneErrorMessage}</div> : null}
                                                                </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Office Phone</Form.Label>
                                                                        <Form.Control type="text" name="officePhone" value={officePhone} onChange={(e) => handleChangeOfficePhone(e.target.value)} className={officePhoneErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                                        {officePhoneErrorMessage ? <div className="invalid-feedback">{officePhoneErrorMessage}</div> : null}
                                                                </Col>
                                                                <Col className="col-sm-6 col-lg-4">
                                                                        <Form.Label>Extension</Form.Label>
                                                                        <Form.Control type="tel" name="extension" value={extension} onChange={(e) => handleChangeExtension(e.target.value)} className={extensionErrorMessage ? "is-invalid" : ""} maxLength="5" placeholder="Enter extension" />  
                                                                </Col>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Date of Birth</Form.Label>
                                                                        <DatePicker
                                                                                locale="en"
                                                                                selected={birthDate}
                                                                                onChange={date => handleChangeBirthDate(date)}
                                                                                maxDate={maxDate}
                                                                                minDate ={new Date('January 1, 1900')}
                                                                        />
                                                                </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                                <Col className="col-sm-4">
                                                                        <Form.Label>Job Title</Form.Label>
                                                                        <Form.Control as="select" onChange={e => handleChangeJobTitle(e.target.value)} >
                                                                                <option value="">Select a Job Title</option>
                                                                                <option value="Business Owner" selected={jobTitle === "Business Owner" ? "selected" : ""} >Business Owner</option>
                                                                                <option value="Office Manager" selected={jobTitle === "Office Manager" ? "selected" : ""} >Office Manager</option>
                                                                                <option value="Parts Counter" selected={jobTitle === "Parts Counter" ? "selected" : ""}  >Parts Counter</option>
                                                                                <option value="Technician" selected={jobTitle === "Technician" ? "selected" : ""} >Technician</option>
                                                                                <option value="Receptionist" selected={jobTitle === "Receptionist" ? "selected" : ""} >Receptionist</option>
                                                                                <option value="Sales Representative" selected={jobTitle === "Sales Representative" ? "selected" : ""} >Sales Representative</option>
                                                                                <option value="Customer Service" selected={jobTitle === "Customer Service" ? "selected" : ""} >Customer Service</option>
                                                                                <option value="Other" selected={jobTitle === "Other" ? "selected" : ""} >Other</option>
                                                                        </Form.Control>
                                                                </Col>
                                                        </Form.Group>
                                                        <div className="line-break"></div>
                                                        <AddressDetails
                                                                mailingAddress1={userMailingAddress1}
                                                                mailingAddress1ErrorMessage={mailingAddress1ErrorMessage}
                                                                mailingAddress2={userMailingAddress2}
                                                                mailingCity={userMailingCity}
                                                                mailingCityErrorMessage={mailingCityErrorMessage}
                                                                mailingState={userMailingState}
                                                                mailingStateErrorMessage={mailingStateErrorMessage}
                                                                mailingPostalCode={userMailingPostalCode}
                                                                mailingPostalCodeErrorMessage={mailingPostalCodeErrorMessage}
                                                                mailingCountry={userMailingCountry}
                                                                shippingAddress1={userShippingAddress1}
                                                                shippingAddress1ErrorMessage={shippingAddress1ErrorMessage}
                                                                shippingAddress2={userShippingAddress2}
                                                                shippingCity={userShippingCity}
                                                                shippingCityErrorMessage={shippingCityErrorMessage}
                                                                shippingState={userShippingState}
                                                                shippingStateErrorMessage={shippingStateErrorMessage}
                                                                shippingPostalCode={userShippingPostalCode}
                                                                shippingPostalCodeErrorMessage={shippingPostalCodeErrorMessage}
                                                                shippingCountry={userShippingCountry}
                                                                handleChangeMailingAddress1={handleChangeMailingAddress1}
                                                                handleChangeMailingAddress2={handleChangeMailingAddress2}
                                                                handleChangeMailingCity={handleChangeMailingCity}
                                                                handleChangeMailingState={handleChangeMailingState}
                                                                handleChangeMailingCountry={handleChangeMailingCountry}
                                                                handleChangeMailingPostalCode={handleChangeMailingPostalCode}
                                                                handleChangeShippingAddress1={handleChangeShippingAddress1}
                                                                handleChangeShippingAddress2={handleChangeShippingAddress2}
                                                                handleChangeShippingCity={handleChangeShippingCity}
                                                                handleChangeShippingState={handleChangeShippingState}
                                                                handleChangeShippingPostalCode={handleChangeShippingPostalCode}
                                                                handleChangeShippingCountry={handleChangeShippingCountry}
                                                                toggleShippingAsMailingAddress={toggleShippingAsMailingAddress}
                                                        />
                                                </div>
                                        </div>
                                        <div className="line-break"></div>
                                        <div className="row">
                                        <div className="col-sm-9 offset-sm-3">
                                                <Button type="button" onClick={submitPersonalInformation} >Save</Button>
                                                <a className="cancel" href="/profile" >Cancel</a>
                                        </div>
                                        </div>
                                </Fragment>
                                : <Loading />}
                        </div>
                        </div>
                </div>
                </div>
        );
 
}
 
const mapStateToProps = store => {
        const loggedUserId = getLoggedUserId(store);
        const userToken = getLoggedUserToken(store);
        return { loggedUserId, userToken };
}
 
const getFormattedDate = date => {
        if (date != null){
                let year = date.getFullYear();
                let month = (1 + date.getMonth()).toString().padStart(2, '0');
                let day = date.getDate().toString().padStart(2, '0');
             
                return month + '/' + day + '/' + year;
        }
        return null;
    }
   
export default connect(mapStateToProps, { logoutUser })(PersonalProfile);