import React, { Fragment, useState } from 'react';
import { Form, Row, Col, Button } from "react-bootstrap";
import { CatalogService, UserService } from '../services';
import Spinner from './Spinner';


const AddressDetails = (props) => {
        const [userMailingAddress1, setUserMailingAddress1] = useState("");
        const [userMailingAddress2, setUserMailingAddress2] = useState("");
        const [userMailingCity, setUserMailingCity] = useState("");
        const [userMailingState, setUserMailingState] = useState("");
        const [userMailingPostalCode, setUserMailingPostalCode] = useState("");
        const [userMailingCountry, setUserMailingCountry] = useState("US");
        const [userAddressName, setUserAddressName] = useState("");
        const [mailingAddress1ErrorMessage, setMailingAddress1ErrorMessage] = useState(null);
        const [mailingCityErrorMessage, setMailingCityErrorMessage] = useState(null);
        const [mailingStateErrorMessage, setMailingStateErrorMessage] = useState(null);
        const [mailingPostalCodeErrorMessage, setMailingPostalCodeErrorMessage] = useState(null);
        const [addressNameErrorMessage, setAddressNameErrorMessage] = useState(null);
        const [Saving, setSaving] = useState(false);
        const [validationErrorMessage, setValidationErrorMessage] = useState(null);

        const handleChangeMailingAddress1 = (value) => setUserMailingAddress1(value);
        const handleChangeMailingAddress2 = (value) => setUserMailingAddress2(value);
        const handleChangeMailingCity = (value) => setUserMailingCity(value);
        const handleChangeMailingState = (value) => setUserMailingState(value);
        const handleChangeMailingCountry = (value) => {setUserMailingCountry(value); console.log(value);}
        const handleChangeMailingPostalCode = (value) => setUserMailingPostalCode(value);
        const handleChangeAddressName = (value) => setUserAddressName(value);

        async function validateAddress() {
            let isValid = true;
            if ((!userMailingAddress1 || /^\s*$/.test(userMailingAddress1) || userMailingAddress1.length > 60)) {
                    setMailingAddress1ErrorMessage("Please enter a valid address.");
                    isValid = false;
            } else {
                    setMailingAddress1ErrorMessage(null);
            }
            if ((!userMailingCity || /^\s*$/.test(userMailingCity) || userMailingCity.length > 15)) {
                    setMailingCityErrorMessage("Please select a city.");
                    isValid = false;
            } else {
                    setMailingCityErrorMessage(null);
            }
            if ((!userMailingState || /^\s*$/.test(userMailingState))) {
                    setMailingStateErrorMessage("Please select a state.");
                    isValid = false;
            } else {
                    setMailingStateErrorMessage(null);
            }
            if ((!userMailingPostalCode || /^\s*$/.test(userMailingPostalCode) || userMailingPostalCode.length > 12)) {
                    setMailingPostalCodeErrorMessage("Please enter a valid postal code.");
                    isValid = false;
            }else {
                setMailingPostalCodeErrorMessage(null);
            }
            if ((!userAddressName || /^\s*$/.test(userAddressName) || userAddressName.length > 60)) {
                setAddressNameErrorMessage("Please enter an address name.");
                isValid = false;
            }else {
                setAddressNameErrorMessage(null);
            }
            if (isValid){
                setSaving(true);
                const updateAddress = async () => {
                        try {
                                const validated = await validateAddressEasyPost(userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingCountry, userMailingPostalCode);
                                const { street1, street2, city, state, zip, country } = validated.data.results;
                                handleChangeMailingAddress1(street1);
                                handleChangeMailingAddress2(street2);
                                handleChangeMailingCity(city);
                                handleChangeMailingState(state);
                                handleChangeMailingPostalCode(zip);
                                handleChangeMailingCountry(country);

                                CatalogService.addNewAddress(props.orderId, 
                                props.custId,
                                userAddressName, 
                                street1, 
                                street2, 
                                state, 
                                city, 
                                zip, 
                                country).then(res => {                                        
                                        console.log("AddressDetailsSimple.CatalogService.addNewAddress.res: ", res);
                                        if (res.status==200 && res.data.data.length > 0)
                                        {
                                                handleSave(res.data.data);
                                        }
                                        else{
                                                console.log("AddressDetailsSimple.CatalogService.addNewAddress.errorSaving");
                                        }
                                });
                        } catch (error) {
                                isValid = false;                               
                        }
                };
                await updateAddress();              
            }
        }

        async function validateAddressEasyPost(street1, street2, city, state, country, zip) {
                try {
                        setValidationErrorMessage(null);
                        const res = await UserService.validateAddress(street1, street2, city, state, country, zip);
                        console.log(res);
                        if (res.status === 200 && res.data.results.responseId != false) {
                                console.log("Address has been verified");
                                return res;
                        } else {
                                setSaving(false);
                                setValidationErrorMessage("Please verify address details.");
                                throw new Error("Address validation failed");
                        }      
                }
                catch (error) {
                      console.log(error);
                      throw error;
                };
          }

        const handleClose = () => {
                props.handleAddAddressClose();
        }

        const handleSave = (shipToId) => {
                console.log("AddressDetailsSimple.handleSave.shipToId: ", shipToId);
                setTimeout(function () {
                        props.handleAddAddressClose(shipToId);
                        setSaving(false);
                }, 5000);
        }

        return (
                <Fragment>
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>Address Name*</Form.Label>
                                        <Form.Control type="text" name="addressName" value={userAddressName} onChange={(e) => handleChangeAddressName(e.target.value)} className={addressNameErrorMessage ? "is-invalid" : ""} />
                                        {addressNameErrorMessage ? <div className="invalid-feedback">{addressNameErrorMessage}</div> : null}
                                </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>Address 1*</Form.Label>
                                        <Form.Control type="text" name="address1" value={userMailingAddress1} onChange={(e) => handleChangeMailingAddress1(e.target.value)} className={mailingAddress1ErrorMessage ? "is-invalid" : ""} />
                                        {mailingAddress1ErrorMessage ? <div className="invalid-feedback">{mailingAddress1ErrorMessage}</div> : null}
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control type="text" name="address2" value={userMailingAddress2} onChange={(e) => handleChangeMailingAddress2(e.target.value)} />
                                </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>Country*</Form.Label>
                                        <Form.Control
                                                as="select"
                                                name="mailingCountry"
                                                value="US"
                                                disabled="disabled"
                                                onChange={(e) => handleChangeMailingCountry(e.target.value)}
                                        >
                                        <option value="US" disabled selected>United States of America</option>

                                        </Form.Control>
                                </Col>

                                <Col className="col-sm-4">
                                        <Form.Label>State*</Form.Label>
                                        <Form.Control type="text" name="state" value={userMailingState} onChange={(e) => handleChangeMailingState(e.target.value)} className={mailingStateErrorMessage ? "is-invalid" : ""} />
                                        
                                        {mailingStateErrorMessage ? <div className="invalid-feedback">{mailingStateErrorMessage}</div> : null}
                                </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>City*</Form.Label>
                                        <Form.Control type="text" name="city" value={userMailingCity} onChange={(e) => handleChangeMailingCity(e.target.value)} className={mailingCityErrorMessage ? "is-invalid" : ""} />
                                        {mailingCityErrorMessage ? <div className="invalid-feedback">{mailingCityErrorMessage}</div> : null}
                                </Col>

                                <Col className="col-sm-4">
                                        <Form.Label>Postal Code*</Form.Label>
                                        <Form.Control type="text" name="postalCode" value={userMailingPostalCode} onChange={(e) => handleChangeMailingPostalCode(e.target.value)} className={mailingPostalCodeErrorMessage ? "is-invalid" : ""} />
                                        {mailingPostalCodeErrorMessage ? <div className="invalid-feedback">{mailingPostalCodeErrorMessage}</div> : null}
                                </Col>
                        </Form.Group>
                        {validationErrorMessage ? <Form.Group as={Row}><Col className="col-sm-4"><div className="text-danger">{validationErrorMessage}</div></Col></Form.Group> : null}                      

                        <div>
                                <br/>
                        <div className='float-left'>
                                <Button size="md" variant='danger' onClick={handleClose}>Cancel</Button>
                        </div>
                        <div className='float-right'>
                        {Saving ? <Spinner  animation="border" displayText="Saving..." size="sm" showSpinner={Saving}/>
                                : 
                                <Button size="md" onClick={validateAddress}>Save</Button>
                                
                        }
                        </div>
                    </div>
                </Fragment>
        )
}

export default AddressDetails;