import React, {Component} from 'react';
import { HomeSlider, HomeProductCategories, HomeMarineSection, HomeGardenSection, HomePowerSportsSection, ShopMarineBlock } from '../components';

class Homepage extends Component {
    render() {
        return (
            <div className="homepage">
            <HomeSlider />
            
            <HomeProductCategories />
            <HomeMarineSection />
            <HomeGardenSection />
            <HomePowerSportsSection />
            
            <ShopMarineBlock />

                <img src={process.env.REACT_APP_CDN_PATH + "/static/images/ilu/Home-page-parallax-marine.jpg"} class="only-ios" />
            </div>
        );
    }
}

export default Homepage;