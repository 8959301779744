var angle = {
  rad: {
    to_anchor: 180/Math.PI
  }, 
  deg: {
    to_anchor: 1
  }, 
  grad: {
    to_anchor: 9/10
  }, 
  arcmin: {
    to_anchor: 1/60
  }, 
  arcsec: {
    to_anchor: 1/3600
  }
};

module.exports = {
  metric: angle, 
  _anchors: {
    metric: {
      unit: 'deg', 
      ratio: 1
    }
  }
};
