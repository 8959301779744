import { Link } from "react-router-dom";
import React from 'react';
import { isLoggedUser } from "../redux/selectors";
import { connect } from "react-redux";

const Contact = ({ loggedUser }) => {
      return(
        <div className="contact">
            <div className="contact-info text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>Contact</h1>
                                    <p>Please get in touch and our expert support team will answer all your questions</p>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ci-box">
                                    <h2>Technical Support</h2>
                                    <p>Contact Number: <br/>
                                    <strong>1-800-730-4082</strong></p>
                                    <p>E-Mail: <br/>
                                    <strong>marinetech@dometic.com</strong></p>
                                    <p>Hours of Operation: 
                                    Monday – Friday 
                                    7:30 am – 4:30 pm CST</p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ci-box">
                                    <h2>Customer Service</h2>
                                    <p>Phone: <strong>877-663-8396</strong><br/>
                                    Fax: <strong>217-324-4396</strong></p>
                                    <p>Hours of Operation: 
                                    Monday – Friday 
                                    7:30 am – 4:30 pm CST</p>
                                    <p>E-Mail: <br/>
                                    <strong>LIT-MarineCS@dometic.com</strong></p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ci-box">
                                    <h2>Warranty & Returns</h2>
                                    <p>{loggedUser === false ?
                                            <strong><Link to="/warranty-policy">Learn about our warranty, claim request and access warranty tracker.</Link></strong>
                                        : <strong><Link to="/warranty">Learn about our warranty, claim request and access warranty tracker.</Link></strong>}
                                    </p>
                                    <p><strong><Link to="/returns/">Return Policy</Link></strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    return { loggedUser };
}

export default connect(mapStateToProps)(Contact);