import React, { Fragment, useState, useEffect } from 'react';
import { Form, Button, Col, ProgressBar } from "react-bootstrap";
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen, faAngleDown, faTimes, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from "react-router-dom";
import { WarrantyService } from "../../services";
import { Loading, ClaimItemDelete, ClaimAttachmentDelete } from '../../components';
import { connect } from "react-redux";
import { isLoggedUser, getLoggedUserToken } from "../../redux/selectors";
import { saveAs } from "file-saver";
import { stripHtml } from "string-strip-html";
import { setAuthorizationHeader } from '../../utils/WarrantyAPI';

const ClaimRequest = ({ loggedUser, userToken }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showAdditionalData, setShowAdditionalData] = useState(false);
    const [isAddNewClaimActive, setAddNewClaimActive] = useState(false);

    const [rgaNumber, setRgaNumber] = useState("");
    const [warrantyTypes, setWarrantyTypes] = useState([]);
    const [shopRate, setShopRate] = useState("");
    const [laborHours, setLaborHours] = useState("");
    const [laborAmount, setLaborAmount] = useState(0);
    const [laborAmountErrorMessage, setLaborAmountErrorMessage] = useState();
    const [otherAmount, setOtherAmount] = useState("");
    const [claimDescription, setClaimDescription] = useState("");
    const [selectedWarrantyTypeId, setSelectedWarrantyTypeId] = useState(2);

    const [cartItems, setCartItems] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [attachmentsErrorMessage, setAttachmentsErrorMessage] = useState();
    const [itemNumber, setItemNumber] = useState();
    const [itemNumberErrorMessage, setItemNumberErrorMessage] = useState();
    const [itemReason, setItemReason] = useState("");
    const [itemReasonErrorMessage, setItemReasonErrorMessage] = useState();
    const [itemQuantity, setItemQuantity] = useState(1);
    const [itemQuantityErrorMessage, setItemQuantityErrorMessage] = useState();
    const [itemRequestTypeId, setItemRequestTypeId] = useState(1);

    const handleChangeShopRate = (value) => setShopRate(value);
    const handleChangeLaborHours = (value) => setLaborHours(value);
    const handleChangeLaborAmount = (value) => setLaborAmount(value);
    const handleChangeOtherAmount = (value) => setOtherAmount(value);
    const handleChangeClaimDescription = (value) => setClaimDescription(value);
    const handleChangeWarrantyType = (value) => {
        setSelectedWarrantyTypeId(value);
        if (value === "1") {
            setShowAdditionalData(true);
        } else {
            setShowAdditionalData(false);
        }
    }
    const handleChangeItemNumber = (value) => setItemNumber(value);
    const handleChangeItemReason = (value) => setItemReason(value);
    const handleChangeItemQuantity = (value) => setItemQuantity(value);
    const handleChangeItemRequestType = (value) => setItemRequestTypeId(value);
    const handleToggleAddItem = () => {
        setAddNewClaimActive(!isAddNewClaimActive);
    }

    const cancelAddItem = () => {
        setAddNewClaimActive(false);
        setItemNumber("");
        setItemReason("");
        setItemQuantity(1);
        setItemRequestTypeId(1);
        setItemNumberErrorMessage(null);
        setItemReasonErrorMessage(null);
        setItemQuantityErrorMessage(null);
    }

    useEffect(() => {
        if (loggedUser === false) {
            history.push("/user/login");
        }
    }, [history, loggedUser])

    useEffect(() => {
        setLoading(true);
        setAuthorizationHeader(userToken);
        WarrantyService.createWarrantyRequest()
            .then(res => {
                if (res.status === 200 || res.status === 302) {
                    const data = res.data.data;
                    setRgaNumber(data.RgaNumber);
                    setShopRate(data.ShopRate);
                    setLaborHours(data.LaborHours);
                    setOtherAmount(data.OtherAmount);
                    setClaimDescription(data.ClaimDescription);
                    setWarrantyTypes(data.WarrantyType.Items.filter(item => item.Value !== ""));
                    setSelectedWarrantyTypeId(2);
                    setLoading(false);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }, [])

    async function submitItem() {
        let isValid = true;
        if (itemNumber === undefined) {
            setItemNumberErrorMessage("Please enter a item number");
            isValid = false;
        }
        if (isValid === true) {
            setItemNumberErrorMessage(null);
            setItemReasonErrorMessage(null);
            setItemQuantityErrorMessage(null);
            await WarrantyService.saveClaimItem(rgaNumber, itemNumber, itemQuantity, itemReason, itemRequestTypeId)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.result !== undefined) {
                        setItemNumberErrorMessage(res.data.result);
                    } else {
                        if (res.data.success === true) {
                            setRgaNumber(res.data.maintainWarrantyRequest.Data.data.RgaNumber);
                            cancelAddItem();
                            getWarrantyRequest();
                        }
                    }
                }
            }).catch(error => {
                console.log("Error " + error);
            });
        }
    }

    async function getWarrantyRequest() {
        WarrantyService.getWarrantyRequest(rgaNumber)
            .then(res => {
                if (res.status === 200) {
                    setRgaNumber(res.data.data.RgaNumber);
                    setCartItems(res.data.data.CartItems);
                    setAttachments(res.data.data.AttachmentList);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    async function submitClaim() {
        let isValid = true;
        // eslint-disable-next-line eqeqeq
        if (selectedWarrantyTypeId == 1) {
            if (laborAmount == undefined || laborAmount < 0) {
                setLaborAmountErrorMessage("Please enter a labor amount");
                isValid = false;
            }
        }
        if (isValid === true) {
            await WarrantyService.saveClaim(rgaNumber, selectedWarrantyTypeId, laborAmount, shopRate, laborHours, otherAmount, claimDescription)
                .then(res => {
                    if (res.status === 200) {
                        history.push("/barcode/" + res.data.submitted.RgaNum); 
                    }
                }).catch(error => {
                    console.log("Error " + error);
                });
        }
    }

    async function editClaimItem(itemId) {
        await WarrantyService.getClaimItem(itemId)
            .then(res => {
                if (res.status === 200) {
                    setAddNewClaimActive(true);
                    setItemNumber(res.data.data.Item);
                    setItemReason(res.data.data.Reason);
                    setItemQuantity(res.data.data.Quantity);
                    setItemRequestTypeId(res.data.data.RequestType.SelectedId);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    async function deleteClaimItem(itemId) {
        await WarrantyService.deleteClaimItem(rgaNumber, itemId)
            .then(res => {
                if (res.status === 200) {
                    getWarrantyRequest();
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    async function deleteAttachment(attachmentId) {
        await WarrantyService.deleteAttachment(attachmentId, rgaNumber)
            .then(res => {
                if (res.status === 200) {
                    setRgaNumber(res.data.buildWarrantyRequestForm.RgaNumber);
                    getWarrantyRequest();
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    async function downloadAttachment(attachmentId, fileName) {
        await WarrantyService.downloadAttachment(attachmentId, rgaNumber)
        .then(res => {
            if (res.status === 200) {
                saveAs(new Blob([res.data]), fileName);
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }

    async function uploadAttachments(acceptedFiles) {
        acceptedFiles.forEach((file) => {
            WarrantyService.uploadFile(rgaNumber, file)
            .then(res => {
                if (res.data.maintainWarrantyRequest) {
                    setRgaNumber(res.data.maintainWarrantyRequest.Data.data.RgaNumber);
                    getWarrantyRequest();
                    setAttachmentsErrorMessage(null);
                } else {
                    setAttachmentsErrorMessage(stripHtml(res.data.result).result);
                }
            })
            .catch(err => {
                console.log(err);
            })
        })
    }

    return (
        <div className="text-page claim-request">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        <h1>Claim request</h1>
                        <h5>New</h5>
                    </div>
                </div>
                {loading === false ? 
                    <div className="row">
                        <div className="col-sm-12 col-xl-10 offset-xl-1">
                            <Form action="">
                                <div className="form-step">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h2>STEP 1</h2>
                                            <h3>Request Type</h3>
                                        </div>        
                                        <div className="col-md-8 col-lg-6 col-xl-5">
                                            <Form.Group className="filter-dc">
                                                {warrantyTypes.map((item, index) => 
                                                    <Form.Check
                                                        key={index}
                                                        type="radio"
                                                        label={item.Text}
                                                        name="claim"
                                                        value={item.Value}
                                                        // eslint-disable-next-line eqeqeq
                                                        checked={item.Value == selectedWarrantyTypeId}
                                                        onChange={(e) => handleChangeWarrantyType(e.target.value)}
                                                        id="with"
                                                        inline
                                                    />
                                                )}
                                            </Form.Group>
                                            {showAdditionalData === true ?
                                                <Fragment>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Shop Rate</Form.Label>
                                                            <Form.Control type="numeric" name="shopRate" value={shopRate} onChange={(e) => handleChangeShopRate(e.target.value)} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Labor Hours</Form.Label>
                                                            <Form.Control type="numeric" name="laborHours" value={laborHours} onChange={(e) => handleChangeLaborHours(e.target.value)} />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Labor Amount*</Form.Label>
                                                            <Form.Control type="numeric" name="laborAmount" value={laborAmount} onChange={(e) => handleChangeLaborAmount(e.target.value)} className={laborAmountErrorMessage ? "is-invalid" : null} />
                                                            {laborAmountErrorMessage ? <div className="invalid-feedback">{laborAmountErrorMessage}</div> : null}
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <Form.Label>Other/Incidentals</Form.Label>
                                                            <Form.Control type="numeric" name="otherAmount" value={otherAmount} onChange={(e) => handleChangeOtherAmount(e.target.value)} />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="12">
                                                            <Form.Label>Claim Description</Form.Label>
                                                            <Form.Control type="textarea" name="claimDescription" value={claimDescription} onChange={(e) => handleChangeClaimDescription(e.target.value)} />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Fragment>
                                            : null}
                                        </div>                                 
                                    </div>
                                </div>
                                {rgaNumber !== "" ?
                                <div className="form-step">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h2>STEP 2</h2>
                                            <h3>File Attachments</h3>
                                            <p>You must provide the following invoices:</p>
                                            <p>Distributor invoice showing part number of purchased item being returned</p>
                                            <p>Original customer billed invoice</p>
                                            <p>Estimate or repair invoice itemizing parts and labor requested for reimbursement</p>
                                        </div>        
                                        <div className="col-lg-8">
                                            <Dropzone onDrop={acceptedFiles => uploadAttachments(acceptedFiles)} >
                                                {({getRootProps, getInputProps}) => (
                                                    <section className="dd-upload">
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <p><FontAwesomeIcon icon={faUpload} className="fa-2x" />Drag files here or click <u>browse</u></p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <p className="text-danger mt-2">{attachmentsErrorMessage}</p>
                                            
                                            {attachments.length > 0 ? 
                                                <div className="files-list">
                                                    <div className="file-list-header">
                                                        <strong>Attached Files</strong><span>{attachments.length}</span>
                                                    </div>
                                                    {attachments.map((attachment, index) => 
                                                        <div className="file-line">
                                                        <div className="row" key={index}>
                                                            <div className="col id">
                                                                <span className="success">{index + 1}</span>
                                                            </div>
                                                            <div className="col desc">
                                                                <p>{attachment.Description}</p>
                                                                <em>{attachment.FileName}</em>
                                                            </div>
                                                            <div className="col type">{attachment.MimeType}</div>
                                                            <div className="col filesize">{attachment.FileSize}</div>
                                                            <div className="col action">
                                                                <Button type="button" className="btn btn-secondary" onClick={(e) => downloadAttachment(attachment.FileAttachmentIdEncrypted, attachment.FileName)} ><FontAwesomeIcon icon={faCloudDownloadAlt} /></Button>
                                                                <ClaimAttachmentDelete
                                                                    attachmentId={attachment.FileAttachmentIdEncrypted}
                                                                    deleteAttachment={deleteAttachment}
                                                                />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                                    
                                                    <div style={{display: 'none'}}>
                                                        <strong>45%</strong>
                                                        <ProgressBar now={45} />
                                                    </div>
                                                </div>
                                            : null} 
                                        </div>                       
                                    </div>
                                </div>
                                : null}
                                <div className="form-step">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h2>STEP 3</h2>
                                            <h3>Item List</h3>
                                        </div>        
                                        <div className="col-lg-8">
                                            {isAddNewClaimActive === false ? 
                                                <Link id="addItem" onClick={handleToggleAddItem} ><FontAwesomeIcon icon={faAngleDown} /><span>Add item</span></Link>
                                            : null}
                                            {isAddNewClaimActive === true ? 
                                                <div className="item-form">
                                                    <h3>Add Catalog Item</h3>
                                                    <FontAwesomeIcon icon={faTimes} className="close-icon-form" style={{display: 'none'}} />
                                                    <div className="row">
                                                        <div className="col-lg-9">
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Item Number*</Form.Label>
                                                                    <Form.Control type="text" name="itemNumber" value={itemNumber} onChange={(e) => handleChangeItemNumber(e.target.value)} className={itemNumberErrorMessage ? "is-invalid" : null} />
                                                                    {itemNumberErrorMessage ? <div className="invalid-feedback">{itemNumberErrorMessage}</div> : null}
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Reason</Form.Label>
                                                                    <Form.Control type="text" name="itemReason" value={itemReason} onChange={(e) => handleChangeItemReason(e.target.value)} className={itemReasonErrorMessage ? "is-invalid" : null} />
                                                                    {itemReasonErrorMessage ? <div className="invalid-feedback">{itemReasonErrorMessage}</div> : null}
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Quantity*</Form.Label>
                                                                    <Form.Control type="number" min="1" name="itemQuantity" value={itemQuantity} onChange={(e) => handleChangeItemQuantity(e.target.value)} className={itemQuantityErrorMessage ? "is-invalid" : null} />
                                                                    {itemQuantityErrorMessage ? <div className="invalid-feedback">{itemQuantityErrorMessage}</div> : null}
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Label>Request Type*</Form.Label>
                                                                    <Form.Control as="select" name="brand" onChange={(e) => handleChangeItemRequestType(e.target.value)} >
                                                                        <option value="1" selected={itemRequestTypeId == 1 ? true : false }>Credit</option>
                                                                        <option value="2" selected={itemRequestTypeId == 2 ? true : false }>Replacement</option>
                                                                        <option value="3" selected={itemRequestTypeId == 3 ? true : false }>Core Credit</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Button type="button" className="search-warranties" onClick={submitItem} >Add item</Button>
                                                            <Link className="cancel ml-2" onClick={cancelAddItem} >Cancel</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            : null}
                                            {cartItems.length > 0 ?
                                                <div className="files-list">
                                                     {cartItems.map((item, index) =>
                                                        <div className="file-line">
                                                        <div className="row" key={index}>
                                                            <div className="col id">
                                                                <span>{index + 1}</span>
                                                            </div>
                                                            <div className="col desc">
                                                                <p>{item.CartDescription}</p>
                                                                <em>{item.CartItem}</em>
                                                            </div>
                                                            <div className="col type">{item.CartRequestType}</div>
                                                            <div className="col filesize">Qty {item.CartQuantity}</div>
                                                            <div className="col action">
                                                                <Button onClick={(e) => editClaimItem(item.CartItemIdEncrypted)} className="btn btn-secondary" ><FontAwesomeIcon icon={faPen} /></Button>
                                                                <ClaimItemDelete
                                                                    cartItemId={item.CartItemIdEncrypted}
                                                                    deleteClaimItem={deleteClaimItem}
                                                                />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                                </div>
                                            : <p className="mt-2">No items added.</p>}
                                        </div>                                 
                                    </div>
                                </div>
                                <div className="form-step">
                                    <div className="row">       
                                        <div className="offset-md-4 col-md-8 col-lg-6 col-xl-5">
                                            <Button type="button" onClick={submitClaim} className="search-warranties" disabled={cartItems.length === 0 ? "disabled" : ""} >Save</Button>
                                            <a className="cancel ml-2" href="/warranty" >Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                : <Loading />}
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUser, userToken };
}

export default connect(mapStateToProps)(ClaimRequest);