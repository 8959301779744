import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Loading } from '../../components';
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import wt from '../../assets/images/nav/warranty-tracker.jpg';
import wp from '../../assets/images/nav/warranty-policy.jpg';
import cr from '../../assets/images/nav/claim-request.jpg';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { UserService } from '../../services';

const Warranty = ({ loggedUserId, userToken }) => {
    const history = useHistory();

    const [warrantyAccess, setWarrantyAccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log("loggedUserId: " + loggedUserId);
        console.log("userToken: " + userToken);
        
        if (loggedUserId) {
            setLoading(true);
            setAuthorizationHeader(userToken);
            UserService.getAccountManagementInfo(loggedUserId)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.results.responseId === false) {
                        history.push("/user/login");
                    } else {
                        const data = res.data.data;
                        const userData = data.UserModelObject;
                        const permissionsData = res.data.data.ParticipantPermissionsModelObject.AllPermissions.filter(item => item.ParticipantId === userData.Id);
                        if (permissionsData !== undefined) {
                            setWarrantyAccess(permissionsData[0].HasWarrantyAccess);
                        }
                    }
                }
                setLoading(false);
            }).catch(error => {
                console.log("Error " + error);
            });
        } else {
            history.push("/user/login");
        }
  }, [history, loggedUserId])

    return (
        <div className="text-page">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        {loading === false ?
                            <Fragment>
                                <h1>Warranty</h1>
                                <p>To process your warranty claim you will need to provide a copy of the distributor invoice for each part being returned. Claims with Labor will also require a copy of the Original Customer Billed Invoice and an Estimate or Repair Invoice itemizing the parts and labor requested in the claim.</p>
                                <p>You may also call for our Parcel Account Number as a courtesy.</p>
                                <div className="warranty-boxes">
                                    <div className="row">
                                        {warrantyAccess === false ? null :
                                            <div className="col-lg-4">
                                                <Link to="/warranty-tracker">
                                                    <img src={wt} alt="warranty-tracker" />
                                                    <strong>Warranty Tracker</strong>
                                                </Link>
                                            </div>
                                        }
                                        <div className="col-lg-4">
                                            <Link to="/warranty-policy">
                                                <img src={wp} alt="warranty-policy" />
                                                <strong>Warranty Policy</strong>
                                            </Link>
                                        </div>
                                        {warrantyAccess === false ? null :
                                            <div className="col-lg-4">
                                                <Link to="/claim-request">
                                                    <img src={cr} alt="claim-request" />
                                                    <strong>Claim Request</strong>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Fragment>
                            : <Loading />}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUserId = getLoggedUserId(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUserId, userToken };
}

export default connect(mapStateToProps)(Warranty);