import React, { useState } from 'react';
import { Form, Button, Row, Col } from "react-bootstrap";
import { AddressDetails } from '../../../components';
import states from '../../../assets/states.json';
 
const CompanyInformation = (props) => {
 
      const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");
      const [companyPhoneNumberErrorMessage, setCompanyPhoneNumberErrorMessage] = useState("");
      const [companyFaxNumberErrorMessage, setCompanyFaxNumberErrorMessage] = useState("");
 
      const [mailingAddressErrorMessage, setMailingAddressErrorMessage] = useState(null);
      const [mailingAddress1ErrorMessage, setMailingAddress1ErrorMessage] = useState(null);
      const [mailingCityErrorMessage, setMailingCityErrorMessage] = useState(null);
      const [mailingStateErrorMessage, setMailingStateErrorMessage] = useState(null);
      const [mailingCountryErrorMessage, setMailingCountryErrorMessage] = useState(null);
      const [mailingPostalCodeErrorMessage, setMailingPostalCodeErrorMessage] = useState(null);
 
      const [shippingAddressErrorMessage, setShippingAddressErrorMessage] = useState(null);
      const [shippingAddress1ErrorMessage, setShippingAddress1ErrorMessage] = useState(null);
      const [shippingCityErrorMessage, setShippingCityErrorMessage] = useState(null);
      const [shippingStateErrorMessage, setShippingStateErrorMessage] = useState(null);
      const [shippingCountryErrorMessage, setShippingCountryErrorMessage] = useState(null);
      const [shippingPostalCodeErrorMessage, setShippingPostalCodeErrorMessage] = useState(null);
 
      //const updatedMailingStates = csc.getStatesOfCountry(props.mailingCountry);
      const [resellerCertificateStateErrorMessage, setResellerCertificateStateErrorMessage] = useState("");
 
      //const [creditTermsErrorMessage, setCreditTermsErrorMessage] = useState("");
 
      //const [creditTermsErrorMessage, setCreditTermsErrorMessage] = useState("");
      const [taxExemptIdErrorMessage, setTaxExemptIdErrorMessage] = useState(null);
      const [creditApplicationErrorMessage, setCreditApplicationErrorMessage] = useState("");
      const [resellerCertificateErrorMessage, setResellerCertificateErrorMessage] = useState("");
 
      // if (props.creditTerms) {
      //       if (!props.creditApplication) {
      //         setCreditApplicationErrorMessage("Please upload a Credit Application file.");
      //         isValid = false;
      //       } else {
      //         setCreditApplicationErrorMessage(null);
      //       }
      // }
 
      async function validateCompanyInformation() {
            let isValid = true;
       
            // Helper function to validate fields and set error messages
            const validateField = (condition, setErrorMessage, errorMessage) => {
                if (condition) {
                    setErrorMessage(errorMessage);
                    isValid = false;
                } else {
                    setErrorMessage(null);
                }
            };
       
            // Validate fields
            validateField(!props.companyName || /^\s*$/.test(props.companyName), setCompanyNameErrorMessage, "Please enter a company name.");
            validateField(!props.companyPhoneNumber || !/^\d+$/.test(props.companyPhoneNumber), setCompanyPhoneNumberErrorMessage, "Please enter a valid company phone number.");
            validateField(props.companyFaxNumber && !/^\d+$/.test(props.companyFaxNumber), setCompanyFaxNumberErrorMessage, "Please enter a valid company fax number.");
            validateField(!props.mailingAddress1 || /^\s*$/.test(props.mailingAddress1), setMailingAddress1ErrorMessage, "Please enter a mailing address.");
            validateField(!props.mailingCountry, setMailingCountryErrorMessage, "Please enter a country.");
            validateField(!props.mailingCity, setMailingCityErrorMessage, "Please enter a city.");
            validateField(!props.mailingState, setMailingStateErrorMessage, "Please enter a state.");
            validateField(!props.mailingPostalCode || /^\s*$/.test(props.mailingPostalCode), setMailingPostalCodeErrorMessage, "Please enter a postal code.");
            validateField(!props.shippingAddress1 || /^\s*$/.test(props.shippingAddress1), setShippingAddress1ErrorMessage, "Please enter a shipping address.");
            validateField(!props.shippingCountry, setShippingCountryErrorMessage, "Please enter a country.");
            validateField(!props.shippingCity, setShippingCityErrorMessage, "Please enter a city.");
            validateField(!props.shippingState, setShippingStateErrorMessage, "Please enter a state.");
            validateField(!props.shippingPostalCode || /^\s*$/.test(props.shippingPostalCode), setShippingPostalCodeErrorMessage, "Please enter a postal code.");
            validateField(props.taxExemptId && /^\s*$/.test(props.taxExemptId), setTaxExemptIdErrorMessage, "Please enter a valid tax exempt ID.");
            validateField(props.resellerCertificateState && !props.resellerCertificate, setResellerCertificateErrorMessage, "Please upload a Reseller Certificate.");
            validateField(!props.resellerCertificateState && props.resellerCertificate, setResellerCertificateStateErrorMessage, "Please select a Reseller Certificate State.");
       
            if (isValid) {
                const updateAddress = async (type, address) => {
                    try {
                        await props.validateAddress(...address).then(res => {
                              if (res.status === 200 && res.data.results.responseId != false){
                                    const { street1, street2, city, state, zip, country } = res.data.results;
                                    props[`handleChange${type}Address1`](street1);
                                    props[`handleChange${type}Address2`](street2);
                                    props[`handleChange${type}City`](city);
                                    props[`handleChange${type}State`](state);
                                    props[`handleChange${type}PostalCode`](zip);
                                    props[`handleChange${type}Country`](country);
                              }
                              else {
                                    if (type === "Shipping") {
                                          setShippingAddressErrorMessage(`Please verify your ${type.toLowerCase()} address`);
                                    } else if (type === "Mailing") {
                                          setMailingAddressErrorMessage(`Please verify your ${type.toLowerCase()} address`);
                                    }
                                    isValid = false;
                              }
                        });                       
                    } catch (error) {
                        if (type === "Shipping") {
                            setShippingAddressErrorMessage(`Please verify your ${type.toLowerCase()} address`);
                        } else if (type === "Mailing") {
                            setMailingAddressErrorMessage(`Please verify your ${type.toLowerCase()} address`);
                        }
                        isValid = false;
                    }
                };
       
                const mailingAddress = [
                    props.mailingAddress1, props.mailingAddress2, props.mailingCity,
                    props.mailingState, props.mailingCountry, props.mailingPostalCode
                ];
       
                await updateAddress("Mailing", mailingAddress);
       
                if (props.shippingAsMailingAddress) {
                    await updateAddress("Shipping", mailingAddress);
                } else {
                    const shippingAddress = [
                        props.shippingAddress1, props.shippingAddress2, props.shippingCity,
                        props.shippingState, props.shippingCountry, props.shippingPostalCode
                    ];
                    await updateAddress("Shipping", shippingAddress);
                }
       
                if (isValid) {
                    props.validateCompanyInformation();
                }
            }
      }
 
      return (
            <div className="row">
                  <div className="col-md-11 offset-md-1">
                        <div className="row">
                              <div className="col-md-3">
                                    <h3>Company Registration</h3>
                              </div>
                              <div className="col-md-9">
                                    <Form.Group as={Row}>
                                          <Col className="col-sm-6 col-lg-4">
                                                <Form.Label>Company Name*</Form.Label>
                                                <Form.Control type="text" name="companyName" value={props.companyName} onChange={(e) => props.handleChangeCompanyName(e.target.value)} className={companyNameErrorMessage ? "is-invalid" : ""} />
                                                {companyNameErrorMessage ? <div className="invalid-feedback">{companyNameErrorMessage}</div> : null}
                                          </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                          <Col className="col-sm-6 col-lg-4">
                                                <Form.Label>Phone Number*</Form.Label>
                                                <Form.Control type="text" name="companyPhoneNumber" value={props.companyPhoneNumber} onChange={(e) => props.handleChangeCompanyPhoneNumber(e.target.value)} className={companyPhoneNumberErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                {companyPhoneNumberErrorMessage ? <div className="invalid-feedback">{companyPhoneNumberErrorMessage}</div> : null}
                                          </Col>
                                          <Col className="col-sm-6 col-lg-4">
                                                <Form.Label>Fax Number</Form.Label>
                                                <Form.Control type="text" name="companyFaxNumber" value={props.companyFaxNumber} onChange={(e) => props.handleChangeCompanyFaxNumber(e.target.value)} className={companyFaxNumberErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                {companyFaxNumberErrorMessage ? <div className="invalid-feedback">{companyFaxNumberErrorMessage}</div> : null}
                                          </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                          <Col className="col-sm-6 col-lg-4">
                                                <Form.Label>Company Website</Form.Label>
                                                <Form.Control type="text" name="companyWebsite" value={props.companyWebsite} onChange={(e) => props.handleChangeCompanyWebsite(e.target.value)} />
                                          </Col>
                                    </Form.Group>
                                    <div className="line-break"></div>
                                    <AddressDetails
                                          mailingAddress1={props.mailingAddress1}
                                          mailingAddress1ErrorMessage={mailingAddress1ErrorMessage}
                                          mailingAddressErrorMessage={mailingAddressErrorMessage}
                                          mailingAddress2={props.mailingAddress2}
                                          mailingCity={props.mailingCity}
                                          mailingCityErrorMessage={mailingCityErrorMessage}
                                          mailingState={props.mailingState}
                                          mailingStateErrorMessage={mailingStateErrorMessage}
                                          mailingPostalCode={props.mailingPostalCode}
                                          mailingPostalCodeErrorMessage={mailingPostalCodeErrorMessage}
                                          mailingCountry={props.mailingCountry}
                                          mailingCountryErrorMessage={mailingCountryErrorMessage}
                                          shippingAddress1={props.shippingAddress1}
                                          shippingAddress1ErrorMessage={shippingAddress1ErrorMessage}
                                          shippingAddressErrorMessage={shippingAddressErrorMessage}
                                          shippingAddress2={props.shippingAddress2}
                                          shippingCity={props.shippingCity}
                                          shippingCityErrorMessage={shippingCityErrorMessage}
                                          shippingState={props.shippingState}
                                          shippingStateErrorMessage={shippingStateErrorMessage}
                                          shippingPostalCode={props.shippingPostalCode}
                                          shippingPostalCodeErrorMessage={shippingPostalCodeErrorMessage}
                                          shippingCountry={props.shippingCountry}
                                          shippingCountryErrorMessage={shippingCountryErrorMessage}
                                          shippingAsMailingAddress={props.shippingAsMailingAddress}
                                          handleChangeMailingAddress1={props.handleChangeMailingAddress1}
                                          handleChangeMailingAddress2={props.handleChangeMailingAddress2}
                                          handleChangeMailingCity={props.handleChangeMailingCity}
                                          handleChangeMailingState={props.handleChangeMailingState}
                                          handleChangeMailingCountry={props.handleChangeMailingCountry}
                                          handleChangeMailingPostalCode={props.handleChangeMailingPostalCode}
                                          handleChangeShippingAddress1={props.handleChangeShippingAddress1}
                                          handleChangeShippingAddress2={props.handleChangeShippingAddress2}
                                          handleChangeShippingCity={props.handleChangeShippingCity}
                                          handleChangeShippingState={props.handleChangeShippingState}
                                          handleChangeShippingPostalCode={props.handleChangeShippingPostalCode}
                                          handleChangeShippingCountry={props.handleChangeShippingCountry}
                                          toggleShippingAsMailingAddress={props.toggleShippingAsMailingAddress}
                                    />
                                    <div className="line-break"></div>
                                    <Form.Group as={Row}>
                                          {/* <Col className="col-sm-6 col-lg-4">
                                          {/* <Col className="col-sm-6 col-lg-4">
                                                <Form.Label>Credit Terms*</Form.Label>
                                                <Form.Check
                                                      type={"radio"}
                                                      id={1}
                                                      label={"Yes"}
                                                      name={"creditTerms"}
                                                      checked={props.creditTerms === true}
                                                      onChange={(e) => props.handleChangeCreditTerms(true)}
                                                      className={creditTermsErrorMessage ? "is-invalid" : ""}
                                                />
                                                <Form.Check
                                                      type={"radio"}
                                                      id={2}
                                                      label={"No"}
                                                      name={"creditTerms"}
                                                      checked={props.creditTerms === false}
                                                      onChange={(e) => props.handleChangeCreditTerms(false)}
                                                      className={creditTermsErrorMessage ? "is-invalid" : ""}
                                                />
                                                {creditTermsErrorMessage ? <div className="invalid-feedback">{creditTermsErrorMessage}</div> : null}
                                          </Col> */}
                                          <Col className="col-sm-6 col-lg-4">
                                                <Form.Label>Tax Exempt Id</Form.Label>
                                                <Form.Control type="text" name="taxExemptId" value={props.taxExemptId} onChange={(e) => props.handleChangeTaxExemptId(e.target.value)} className={taxExemptIdErrorMessage ? "is-invalid" : ""} />
                                                {taxExemptIdErrorMessage ? <div className="invalid-feedback">{taxExemptIdErrorMessage}</div> : null}
                                          </Col>
                                          <Col  className='col-sm-6 col-lg-4'>
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                      as="select"
                                                      name="resellerCertificateState"
                                                      value={props.resellerCertificateState}
                                                      onChange={(e) => props.handleChangeResellerCertificateState(e.target.value)}
                                                      className={resellerCertificateStateErrorMessage ? "is-invalid" : ""}
                                                >
                                                <option value="" disabled selected>Please select a state</option>
                                                {states.map((state, index) =>
                                                      <option value={state.abbreviation} key={index} selected={props.resellerCertificateState === state.abbreviation ? true : false }>{state.name}</option>
                                                )}
                                                </Form.Control>  
                                                {resellerCertificateStateErrorMessage ? <div className="invalid-feedback">{resellerCertificateStateErrorMessage}</div> : null}
                             
                                                <Form.Label>Reseller Certificate</Form.Label>
                                                <Form.File
                                                      name="resellerCertificate"
                                                      onChange={(e) => props.handleChangeResellerCertificate(e)}
                                                      className={resellerCertificateErrorMessage ? "is-invalid" : ""}
                                                      accept=".docx, .pdf"
                                                />
                                                {resellerCertificateErrorMessage ? <div className="invalid-feedback">{resellerCertificateErrorMessage}</div> : null}
                                          </Col>
                                    </Form.Group>
                                    {/* <div className="line-break"></div> */}
                                    <Form.Group as={Row}>                                        
                                          <Col style = {{ display: 'none' }}className="col-sm-6 col-lg-4">
                                                <Form.Label>Credit Application</Form.Label>
                                                <Form.File
                                                      name="creditApplication"
                                                      onChange={(e) => props.handleChangeCreditApplication(e)}
                                                      className={creditApplicationErrorMessage ? "is-invalid" : ""}
                                                      accept=".docx, .pdf"
                                                />
                                                {creditApplicationErrorMessage ? <div className="invalid-feedback">{creditApplicationErrorMessage}</div> : null}
                                          </Col>
                                    </Form.Group>
                              </div>
                        </div>
                        <div className="line-break"></div>
                        <div className="row">
                              <div className="col-md-9 offset-md-3">
                                    <Button type="button" onClick={validateCompanyInformation} >NEXT</Button>
                                    <a className="cancel" href="/user/login" >Cancel</a>
                                    <p>In the next step, we will create your account login.</p>
                              </div>
                        </div>
                  </div>
            </div>
      )
}
 
export default CompanyInformation;