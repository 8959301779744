import React from 'react';
import { Link } from "react-router-dom";
import FooterCopyright from '../../components/FooterCopyright';

const ConfirmRegistration  = () => {
        
      return(
            <div className="user confirm">
                <h2>Account<br/>
                successfully<br/>
                created.</h2>
                <p>Thank you for registering!<br/>
                Your registration is being reviewed by the Sonar Team.<br/>
                You will receive an email once your account is activated.</p>
                <Link to="/user/login" className="go-to">Login</Link>
                  <div className="row">
                        <FooterCopyright />
                  </div>
            </div>
      );

}
export default ConfirmRegistration;