export const getPickLists = (store, loggedUserId) => {
    let pickLists = store.picklists.picklists.filter(obj => obj.loggedUserId === loggedUserId);
    if (pickLists === undefined) {
        pickLists = [];
    }
    return pickLists;
}

export const getPickListLastInsertId = store => {
    return store.picklists.lastInsertId;
}

export const isLoggedUser = store => {
    return store.login.logged;
}

export const getLoggedUserEmail = store => {
    if (store.login.loggedUser !== undefined) {
        return store.login.loggedUser !== null ? store.login.loggedUser.email : null;
    }
    return null;
}

export const getLoggedUserName = store => {
    if (store.login.loggedUser !== undefined) {
        return store.login.loggedUser !== null ? store.login.loggedUser.email : null;
    }
    return null;
}

export const getLoggedUserId = store => {
    if (store.login.loggedUser !== undefined) {
        return store.login.loggedUser !== null ? store.login.loggedUser.userId : null;
    }
    return null;
}

export const getLoggedUserToken = store => {
    return store.login.userToken;
}