import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import EngineCommon from './EngineCommon';

const Engine = ({brandName, modelNumber, engineId, startYear, stopYear, horsepower, stroke, cylinders, marketId}) => {
    const engineUrl = "/engine/" + engineId;

    return (
        <Link to={{
            pathname: engineUrl,
            state: {
                brandName: brandName,
                marketId: marketId
            }   
        }} className="result-item" >
            <div className="row">
                <EngineCommon 
                    brandName={brandName}
                    modelNumber={modelNumber}
                    startYear={startYear}
                    stopYear={stopYear}
                    horsepower={horsepower}
                    stroke={stroke}
                    cylinders={cylinders}
                />
                <div className="result-link">
                    <FontAwesomeIcon icon={faAngleRight} className="go-to-category" />
                </div>
            </div>
        </Link>
    )
}

Engine.propTypes = {
    brandName: PropTypes.string.isRequired,
    modelNumber: PropTypes.string.isRequired,
    engineId: PropTypes.number.isRequired
};

export default Engine;