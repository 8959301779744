import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import FilterComponent from './FilterComponent';
import { InputGroup, Button } from 'react-bootstrap';
import Spinner from './Spinner';

const CustomLoader = () => (
    	<div style={{ padding: '24px' }}>
    		<Spinner showSpinner={true}/>
    		<div>Loading...</div>
    	</div>
    );

const DataTableComponent = (props) => {

    const [Columns, SetColumns] = useState([]);
    const [TableData, SetData] = useState([]);
    const [SortField, SetSortField] = useState(1);
    const [SortAsc, SetSortAsc] = useState(true);
    const [ShowSearch, SetShowSearch] = useState(true);
    const [FilterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [Sku, SetSku] = useState(null);
    const [SearchText, setSearchText] = useState('');
    const [DataLoading, setDataLoading] = useState(false);
    const [DataLoadingSpinner, setDataLoadingSpinner] = useState(false);
    const [FilteredData, setFilteredData] = useState([]);
    const [ShowAddAddress, setShowAddAddress] = useState(false);
    const [ShowAddPromotion, setShowAddPromotion] = useState(false);

    useEffect(() => {
        SetColumns(props.columns);
        SetSortAsc(props.sortAsc);
        SetSortField(props.sortField);
        SetData(props.data);
        setSearchText(props.searchText);

        if (props.showSearch==false)
            SetShowSearch(false);

        setFilteredData(props.data);

    }, [props.data]);

    useEffect(() => {
        setDataLoading(props.dataLoading);
        if (props.showAddAddress===true)
            setShowAddAddress(true);

        if (props.showAddPromotion==true)
            setShowAddPromotion(true);

        if (props.dataLoading==true)
            setDataLoadingSpinner(true);
        else{
            
            const timeout = setTimeout(()=>{
                setDataLoadingSpinner(false);
            }, 1000);
            return () => clearTimeout(timeout);
        }
        
    }, [props.dataLoading]);

    useEffect(() => {
        const _filteredData = TableData.filter(function search(row)
            {
                return Object.keys(row).some((key) => {
                    if(typeof row[key]!="object") {
                        //console.log(row[key]);
                        return row[key].toString().toLowerCase().includes(FilterText.toLowerCase());
                    }
                    else if (row[key] && typeof row[key]==="object"){
                        return search(row[key]);
                    }
                    return false;
                })
            }
            //item => item[FilterField] == null ? item : item[FilterField] && item[FilterField].toString().toLowerCase().includes(FilterText.toLowerCase()),
        );
        setFilteredData(_filteredData);

    }, [FilterText]);

    const subHeaderComponentMemo = React.useMemo(() => {
    
        const handleClear = () => {
            if (FilterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        const handleClick = (e) => {
            //console.log("handleClick");
            if (Sku!=null&&Sku.length>0)
            {
                props.handleAddClick(e, Sku); 
                SetSku('');
            }
        }

        const handleAdd = () => {
            props.handleAddAddress();
        }

        const handleAddPromotion = () => {
            props.handleAddPromotion();
        }
 
        return (
            <div className='col-sm-12'>
                <div className='float-left'>
                    <div className='row' hidden={props.showQuickAdd===false}>
                        <InputGroup className="mb-3">
                            <input value={Sku} onChange={e => SetSku(e.target.value)} placeholder="Item Number/Sku" aria-label="Item Number/Sku" style={{width:'250px'}}/>
                            <InputGroup.Append>
                                <Button size="sm" onClick={(e) => handleClick(e)}>Add</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </div>
                <div hidden={props.showSearch===false}>
                    <Button hidden={ShowAddAddress===false} variant="primary" size="sm" className="float-left" onClick={handleAdd}>New Address</Button>
                    <Button hidden={ShowAddPromotion===false} variant="primary" size="sm" className="float-left" onClick={handleAddPromotion}>New Promotion</Button>                  
                    <FilterComponent hidden={props.showSearch===false} onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={FilterText} searchText={SearchText} />                  
                </div>
            </div>
        );
    }, [FilterText, resetPaginationToggle, Sku, SearchText]);

    return (
        <DataTable
            striped
            responsive
            columns={Columns}
            data={FilteredData}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader={ShowSearch}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            highlightOnHover
            defaultSortAsc={SortAsc}
            defaultSortFieldId={SortField}
            progressPending={DataLoadingSpinner}
            progressComponent={<CustomLoader />}
            
        />
    );

}

export default DataTableComponent;