import palette from "../assets/colors/run-history.json";
import convert from './convert-units';

export function humanReadableTime(seconds) {
  var sec_num = seconds;
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var sec = sec_num - (hours * 3600) - (minutes * 60);
  return [sec, minutes, hours];
}

export function localizedDuration(t, seconds) {
  var time = '';
  var arr = humanReadableTime(seconds);
  for (var i = arr.length - 1; i >= 0; i--) {
    if (arr[i] > 0) {
      if (time) {
        time += ' ';
      }
      time += arr[i] + '' + t('date.human.short.' + i);
    }
  }
  if (!time) {
    time = 0 + ' ' + t('date.human.short.' + 0);
  }
  return time;
}

export function localizedDurationUnits(t, seconds) {
  var time = '';
  var arr = humanReadableTime(seconds);
  for (var i = arr.length - 1; i >= 0; i--) {
    if (arr[i] > 0) {
      if (time) {
        time += ' ';
      }
      time += t('date.human.short.' + i);
    }
  }
  if (!time) {
    time = ' ' + t('date.human.short.' + 0);
  }
  return time;
}

export function localizedDurationValue(seconds) {
  var time = '';
  var arr = humanReadableTime(seconds);
  for (var i = arr.length - 1; i >= 0; i--) {
    if (arr[i] > 0) {
      if (time) {
        time += '.';
      }
      time += arr[i];
    }
  }
  return time;
}

export function localizedDurationUnit(seconds) {
  var arr = humanReadableTime(seconds);
  var index, time = '';
  for (var i = arr.length - 1; i >= 0; i--) {
    if (arr[i] > 0) {
      if (time) {
        time += '.';
      }
      time += arr[i];
      if (!index) {   
        index = i;
      }
    }
  }
  return 'date.human.long.' + index;
}

export function convertValue(value, unitId) {
  if (!value || !unitId) {
    return value;
  }
  var obj;
  const convertValues = require('../assets/data/convert-values.json');
  Object.keys(convertValues).some((id, uIndex) => {     
    if (id === unitId) {
      obj = convertValues[id];
      return obj != null;
    }
    return false;
  });
  if (!obj) {
    return value;
  }
  if (obj.method === 'divide') {
    return value / obj.value;
  } else if (obj.method === 'convert') {
    if (obj.value === 'HEX') {
      return parseInt(value).toString(16);
    }
  }
  return value;
}

export function mapColors(data) {
  return data.map((el, i) => {
    return { ...el, fill: palette[data.length][i] };
  });
}

export function commaNumber(num, comma = ",") {
  if (isNaN(num)) {
    return "--.-";
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma);
}

export function rangeToString(from, to, units = "rpm") {
  if (isNaN(from)) {
    return "---";
  }
  return commaNumber(from) + "–" + commaNumber(to) + " " + units;
}

export function recalculateParamValue(quantityUnitId, paramValue, metricSystem, t) {
  let color, to;
  if (quantityUnitId) {
    if (quantityUnitId == 3000) {
      color = t("eim.units.custom." + paramValue + ".color");
      paramValue = t("eim.units.custom." + paramValue + ".name");
    } else if (quantityUnitId >= 5000) {
      if (quantityUnitId == 5001) {
        paramValue = t("eim.units." + quantityUnitId);
      } else if (quantityUnitId == 5000 || quantityUnitId == 5002 || quantityUnitId == 5003) {
        paramValue = t("eim.units." + quantityUnitId) + paramValue;
      }
    } else {
      paramValue = convertValue(paramValue, quantityUnitId);
      let from = t("eim.units." + quantityUnitId + ".eim");
      to = t("eim.units." + quantityUnitId + "." + metricSystem);
      if (from && to && typeof paramValue !== 'string') {
        paramValue = convert(paramValue).from(`${from}`).to(`${to}`);
        if (paramValue % 1 != 0) {
          paramValue = paramValue.toFixed(2);
        }
      }
    }
  }
  return { paramValue: paramValue, color: color, to: to };
}