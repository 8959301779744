export const Engine = require('./engine.svg');
export const Live = require('./live.svg');
export const Fault = require('./fault.svg');
export const Pie = require('./pie.svg');
export const Print = require('./print.svg');
export const CaretRight = require('./caret-right.svg');
export const Freeze = require('./freeze.svg');
export const Back = require('./back.svg');
export const Calendar = require('./calendar.svg');
export const Info = require('./info.svg');
export const Logo = require('./logo.svg');
export const User = require('./user.svg');
export const Preview = require('./preview.svg');
export const QR = require('./qr.svg');
export const Search = require('./search.svg');
export const News = require('./qr.svg');
export const Top = require('./top.svg');
export const Share = require('./share.svg');
export const Wrench = require('./wrench.svg');
export const Edit = require('./edit.svg');
export const ArrowDown = require('./arrow-down.svg');