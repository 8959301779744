import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getLoggedUserId } from "../../redux/selectors";
import { deletePickList } from "../../redux/actions";
import { UserService } from '../../services';

const PickListDelete = ({ pickListId, callBackFunction, loggedUserId, deletePickList }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);

    const handleConfirm = () => {
        if (loggedUserId) {
            UserService.deletePickList(pickListId)
            .then(res => {
                if (res.status === 200) {
                    handleClose();
                    callBackFunction();
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            deletePickList(pickListId);
            handleClose();
            callBackFunction();
        }
    }

    return (
        <Fragment>
            <button className="btn btn-secondary ml-1" onClick={handleShow} title="Delete pick list" ><FontAwesomeIcon icon={faTrash} className="go-to-category" /></button>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Are you sure want to delete pick list?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm} >
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = state => {
    const loggedUserId = getLoggedUserId(state);
    return { loggedUserId };
}

PickListDelete.propTypes = {
    pickListId: PropTypes.number.isRequired
}

export default connect(mapStateToProps, { deletePickList })(PickListDelete);