import { useEffect, useState } from 'react'
import { setAuthorizationHeader } from '../utils/UserAPI';
import UserService from '../services/UserService';
import { getLoggedUserToken, isLoggedUser } from '../redux/selectors';
import { useHistory } from 'react-router-dom';
import { CatalogService } from '../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import DataTableComponent from '../components/DataTableComponent';
import { connect } from "react-redux";
import Card from 'react-bootstrap/Card';
import { Button, Col, Row, Form, Modal, Alert } from 'react-bootstrap';
import Spinner from '../components/Spinner';

const Promotions = (props) => {
    const [UserInfo, setUserInfo] = useState(null);
    const history = useHistory();
    const [ShowModal, setShowModal] = useState(false);
    const [Promotions, setPromotions] = useState([]);
    const [DataLoading, setDataLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("Great job!");
    const [variant, setVariant] = useState("success");
    const [Saving, setSaving] = useState(false);
    const [PromotionTypes, setPromotionTypes] = useState([]);
    const [RateTypes, setRateTypes] = useState([]);

    const [PromotionId, setPromotionId] = useState(0);
    const [PromotionName, setPromotionName] = useState("");
    const [BadgeText, setBadgeText] = useState("");
    const [BannerText, setBannerText] = useState("");
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [DiscountCode, setDiscountCode] = useState(null);
    const [MaxCustomerUse, setMaxCustomerUse] = useState(1);
    const [PromotionTypeId, setPromotionTypeId] = useState(null);
    const [RateTypeId, setRateTypeId] = useState(null);
    const [RateValue, setRateValue] = useState(null);
    const [Threshold, setThreshold] = useState(null);
    const [Description, setDescription] = useState("");

    const [PromotionNameErrorMessage, setPromotionNameErrorMessage] = useState(null);
    const [BadgeTextErrorMessage, setBadgeTextErrorMessage] = useState(null);
    const [BannerTextErrorMessage, setBannerTextErrorMessage] = useState(null);
    const [StartDateErrorMessage, setStartDateErrorMessage] = useState(null);
    const [EndDateErrorMessage, setEndDateErrorMessage] = useState(null);
    const [DiscountCodeErrorMessage, setDiscountCodeErrorMessage] = useState(null);
    const [MaxCustomerUseErrorMessage, setMaxCustomerUseErrorMessage] = useState(null);
    const [PromotionTypeIdErrorMessage, setPromotionTypeIdErrorMessage] = useState(null);
    const [RateTypeIdErrorMessage, setRateTypeIdErrorMessage] = useState(null);
    const [RateValueErrorMessage, setRateValueErrorMessage] = useState(null);
    const [ThresholdErrorMessage, setThresholdErrorMessage] = useState(null);
    const [ShowConfirmModal, setShowConfirmModal] = useState(null);

    const handleChangePromotionId = (value) => setPromotionId(value);
    const handleChangePromotionName = (value) => setPromotionName(value);
    const handleChangeBadgeText = (value) => setBadgeText(value);
    const handleChangeBannerText = (value) => setBannerText(value);
    const handleChangeStartDate = (value) => setStartDate(value);
    const handleChangeEndDate = (value) => setEndDate(value);
    const handleChangeDiscountCode = (value) => setDiscountCode(value);
    const handleChangeMaxCustomerUse = (value) => setMaxCustomerUse(value);
    const handleChangePromotionTypeId = (value) => setPromotionTypeId(value);
    const handleChangeRateTypeId = (value) => setRateTypeId(value);
    const handleChangeRateValue = (value) => setRateValue(value);
    const handleChangeThreshold = (value) => setThreshold(value);
    const handleChangeDescription = (value) => setDescription(value);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const handleCloseConfirm = () => setShowConfirmModal(false);
    const handleShowConfirm = () => {
        setShowConfirmModal(true);
        setShowModal(false);
    }
    const handleCancel = () => {
        setShowModal(true);
        setShowConfirmModal(false);
    }

    const columns = [
        {
            id:1,
            cell: (row) => <a type='button' onClick={(e)=>handleButtonClick(e, row)}><FontAwesomeIcon icon={faEdit} className="fa-1x" /></a>,
            width: '3%',
            sortable:false,
    
        },
        {
            id:10,
            name: 'Name',
            //width: '14%',
            selector: row => row.promotionName ?? "",
            sortable:false
        },
        {
            id:2,
            name: 'Start Date',
            //width: '14%',
            selector: row => new Date(row.startDate).toLocaleDateString("en-US", {timeZone: 'UTC'}),
            sortable:true
        },
        {
            id:3,
            name: 'End Date',
            //width: '14%',
            selector: row => new Date(row.endDate).toLocaleDateString("en-US", {timeZone: 'UTC'}),
            sortable:true
        },
        // {
        //     id:4,
        //     name: 'Discount Code',
        //     //width: '25%',
        //     selector: row => row.discountCode,
        //     sortable:true
        // },
        // {
        //     id:5,
        //     name: 'Uses',
        //     width: '7%',
        //     selector: row => row.maxCustomerUse,
        //     sortable:true
        // },
        {
            id:6,
            name: 'Promotion Type',
           // width: '11%',
            selector: row => getPromoType(row.promotionTypeId),
            sortable:true
        },
        {
            id:7,
            name: 'Rate Type',
           // width: '11%',
            selector: row => getRateType(row.rateTypeId),
            sortable:true
        },
        {
            id:8,
            name: 'Discount',
           // width: '11%',
            selector: row => row.rateTypeId == 1 ? "$" + row.rateValue.toString() + ".00" + " off": row.rateValue.toString() + "%" + " off",
            sortable:false
        },
        {
            id:9,
            name: 'Threshold',
           // width: '11%',
            selector: row => row.threshold == null ? "N/A" : "$" + row.threshold,
            sortable:false
        },
    ];

    useEffect(() => {
        if (props.loggedUser)
        {
            setAuthorizationHeader(props.userToken);

            if (UserInfo==null)
            {
                console.log("userInfo.get: ", props.userToken);
                UserService.getUserInfo().then(user => {
                    console.log("userInfo.get.response: ", user);
                    if (user.status == 200)
                        setUserInfo(user.data.data);

                });
            
            }
            getTypes();       
        }
        else{
            history.push("/user/login");
        }

    }, [props.userToken]);

    useEffect(() => {
        if (RateTypes != null && PromotionTypes != null){
            getPromotions();
        }

    }, [RateTypes], [PromotionTypes]);

    const getPromotions = () => {
        CatalogService.getPromotions().then(response => {
            console.log("Promotions.getPromotions.Response:", response);

        //console.log(response);
        if (response.status === 200) {
            if (response.data.data)
            {
                console.log(response.data.data);
                setPromotions(response.data.data);
                setDataLoading(false);
                return true;
            }
        }
        }).catch(function (error) {
            console.log("Promotions.getPromotions.Error:");
            console.log(error);
            return error;
        });
    }

    useEffect(() => {
        if (UserInfo != null)
        {
            if (UserInfo.IsAdmin==false)
                history.push("/user/login");
        }

    }, [UserInfo]);

    const handleButtonClick = (e, row) => {
        e.preventDefault();
        handleChangePromotion(row);
        handleShow();
        
    }

    const handleChangePromotion = (row) => {
        handleChangePromotionId(row.promotionId);
        handleChangePromotionName(row.promotionName);
        handleChangeBadgeText(row.badgeText);
        handleChangeBannerText(row.bannerText);
        handleChangeStartDate(new Date(row.startDate).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }));
        handleChangeEndDate(new Date(row.endDate).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }));
        handleChangeDiscountCode(row.discountCode);
        handleChangeMaxCustomerUse(row.maxCustomerUse);
        handleChangePromotionTypeId(row.promotionTypeId);
        handleChangeRateTypeId(row.rateTypeId);
        handleChangeRateValue(row.rateValue);
        handleChangeThreshold(row.threshold);
        handleChangeDescription(row.description);
    }

    const handleClearPromotion = () => {
        handleChangePromotionId(0);
        handleChangePromotionName(null);
        handleChangeBadgeText(null);
        handleChangeBannerText(null);
        handleChangeStartDate(null);
        handleChangeEndDate(null);
        handleChangeDiscountCode(null);
        handleChangeMaxCustomerUse(null);
        handleChangePromotionTypeId(null);
        handleChangeRateTypeId(null);
        handleChangeRateValue(null);
        handleChangeThreshold(null); 
        handleChangeDescription(null);
    }

    const handleAddPromotion = () => {
        handleClearPromotion();
        setShowModal(true);
    }

    const getPromoType = (id) => {
        if (PromotionTypes[id-1] != null)
            return PromotionTypes[id-1].description;        
    }

    const getRateType = (id) => {
        return RateTypes[id-1].description;
    }

    const getTypes = () => {
        CatalogService.getTypes().then(res => {
            if (res.status == 200){
                console.log("Got promotion types: ", res.data.data);
                setPromotionTypes(res.data.data);
            }
        });
        
        CatalogService.getRateTypes().then(res => {
            if (res.status == 200){
                setRateTypes(res.data.data);
                console.log("Got rate types:", res.data.data);
            }
        })
    }

    const savePromotion = () => {
        setSaving(true);
        CatalogService.savePromotion(PromotionId, PromotionName, BadgeText, BannerText, StartDate, EndDate, DiscountCode, MaxCustomerUse, 
            PromotionTypeId, RateTypeId, RateValue, Threshold, StartDate, 'placeholder', EndDate, 'placeholder', Description).then(res => {
                setSaving(false);
                handleCloseConfirm();
                setShowAlert(true);
                setMessage("Successfully saved promotion");
                setVariant("success");
                getPromotions();
            });
    }

    return (
        <div className="container">
        <hr/>
        <div className="row">
            <div className="col-lg-12">
                <Card>
                    <Card.Body>
                        <Card.Title>Manage Promotions</Card.Title>
                        <DataTableComponent dataLoading={DataLoading} columns={columns} data={Promotions} searchText={'Search Promotions'} sortField={7} sortAsc={false} showQuickAdd={false} showSearch={true} showAddPromotion={true} handleAddPromotion={() => handleAddPromotion()}></DataTableComponent>
                    </Card.Body>
                </Card>
                <Modal show={ShowConfirmModal} onHide = {handleCancel} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{PromotionId > 0 ? "Edit" : "Create"} promotion <b>{PromotionName}</b> to offer a <b>{getPromoType(PromotionTypeId)}</b> discount of <b>{RateTypeId == 1 ? "$" : null}{RateValue}{RateTypeId == 2 ? "%" : null}</b> {Threshold != null ? "on orders of " : null}<b>{Threshold != null ? "$" + Threshold : null}</b>{Threshold != null ? " or greater" : null} {MaxCustomerUse != null ? "up to " : null}<b>{MaxCustomerUse != null ? MaxCustomerUse : null}</b>{MaxCustomerUse != null ? " times per partner" : null} between the dates <b>{new Date(StartDate).toLocaleDateString('en-US', { /*year: 'numeric', month: '2-digit', day: '2-digit',*/ timeZone: 'UTC' })}</b> and <b>{new Date(EndDate).toLocaleDateString('en-US', { /*year: 'numeric', month: '2-digit', day: '2-digit',*/ timeZone: 'UTC' })}</b>{DiscountCode != null ? " with discount code " : null}<b>{DiscountCode != null ? DiscountCode : null }</b>?</p>
                        <div>
                                    <br/>
                            <div className='float-left'>
                                    <Button size="md" variant='danger' onClick={handleCancel}>Cancel</Button>
                            </div>
                            <div className='float-right'>
                            {Saving ? <Spinner  animation="border" displayText="Saving..." size="sm" showSpinner={Saving}/>
                                    : 
                                    <Button size="md" onClick={savePromotion}>Confirm</Button>                                   
                            }
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>

                <Modal show={ShowModal} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{PromotionId > 0 ? "Edit Promotion" : "New Promotion"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        <Form.Group as={Row}>
                                    <Col className="col-sm-4">
                                            <Form.Label>Promotion Name*</Form.Label>
                                            <Form.Control type="text" name="promotionName" value={PromotionName} onChange={(e) => handleChangePromotionName(e.target.value)} className={PromotionNameErrorMessage ? "is-invalid" : ""} />
                                            {PromotionNameErrorMessage ? <div className="invalid-feedback">{PromotionNameErrorMessage}</div> : null}
                                    </Col>

                                    <Col className="col-sm-4">
                                            <Form.Label>Promotion Type*</Form.Label>
                                            <Form.Control
                                                    as="select"
                                                    name="PromotionType"
                                                    onChange={(e) => handleChangePromotionTypeId(e.target.value)}
                                            >
                                                <option value="" disabled selected>Please select a promotion type</option>
                                                {PromotionTypes.map((promotion, index) =>
                                                    <option value={promotion.promotionTypeId} key={index} selected={PromotionTypeId === promotion.promotionTypeId ? true : false }>{promotion.description}</option>
                                                )}
                                            </Form.Control>
                                    </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                    <Col className="col-sm-4">
                                            <Form.Label>Badge Text</Form.Label>
                                            <Form.Control type="text" name="badgeText" value={BadgeText} onChange={(e) => handleChangeBadgeText(e.target.value)} className={BadgeTextErrorMessage ? "is-invalid" : ""} />
                                            {BadgeTextErrorMessage ? <div className="invalid-feedback">{BadgeTextErrorMessage}</div> : null}
                                    </Col>
                                    <Col className="col-sm-4">
                                            <Form.Label>Banner Text</Form.Label>
                                            <Form.Control type="text" name="bannerText" value={BannerText} onChange={(e) => handleChangeBannerText(e.target.value)} />
                                    </Col>
                            </Form.Group>

                            <Form.Group as={Row}>                              
                                    <Col className="col-sm-4">
                                            <Form.Label>Rate Type*</Form.Label>
                                            <Form.Control
                                                    as="select"
                                                    name="RateType"
                                                    onChange={(e) => handleChangeRateTypeId(e.target.value)}
                                            >
                                                <option value="" disabled selected>Please select a rate type</option>
                                                {RateTypes.map((rate, index) =>
                                                    <option value={rate.rateTypeId} key={index} selected={RateTypeId === rate.rateTypeId ? true : false }>{rate.description}</option>
                                                )}
                                            </Form.Control>
                                    </Col>

                                    <Col className="col-sm-4">
                                            <Form.Label>Discount Value* <span className="text-muted">{RateTypeId == 1 ? "(Dollar Amount)" : null}{RateTypeId == 2 ? "(Percent)" : null}</span></Form.Label>
                                            <Form.Control type="number" name="rateValue" value={RateValue} onChange={(e) => handleChangeRateValue(e.target.value)} className={RateValueErrorMessage ? "is-invalid" : ""} />
                                            
                                            {RateValueErrorMessage ? <div className="invalid-feedback">{RateValueErrorMessage}</div> : null}
                                    </Col>
                            </Form.Group>


                            <Form.Group as={Row}>
                                    <Col className="col-sm-4">
                                            <Form.Label># Times Redeemable</Form.Label>
                                            <Form.Control type="number" name="maxCustomerUse" value={MaxCustomerUse} onChange={(e) => handleChangeMaxCustomerUse(e.target.value)} className={MaxCustomerUseErrorMessage ? "is-invalid" : ""} />
                                            {MaxCustomerUseErrorMessage ? <div className="invalid-feedback">{MaxCustomerUseErrorMessage}</div> : null}
                                    </Col>

                                    <Col className="col-sm-4">
                                            <Form.Label>Threshold</Form.Label>
                                            <Form.Control type="number" name="threshold" value={Threshold} onChange={(e) => handleChangeThreshold(e.target.value)} className={ThresholdErrorMessage ? "is-invalid" : ""} />
                                            {ThresholdErrorMessage ? <div className="invalid-feedback">{ThresholdErrorMessage}</div> : null}
                                    </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                    <Col className="col-sm-4">
                                            <Form.Label>Start Date*</Form.Label>
                                            <Form.Control type="date" name="startDate" value={StartDate} onChange={(e) => handleChangeStartDate(e.target.value)} className={StartDateErrorMessage ? "is-invalid" : ""} />
                                            {StartDateErrorMessage ? <div className="invalid-feedback">{StartDateErrorMessage}</div> : null}
                                    </Col>

                                    <Col className="col-sm-4">
                                            <Form.Label>End Date*</Form.Label>
                                            <Form.Control type="date" name="endDate" value={EndDate} onChange={(e) => handleChangeEndDate(e.target.value)} className={EndDateErrorMessage ? "is-invalid" : ""} />
                                            {EndDateErrorMessage ? <div className="invalid-feedback">{EndDateErrorMessage}</div> : null}
                                    </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                    <Col className="col-sm-4">
                                            <Form.Label>Discount Code</Form.Label>
                                            <Form.Control type="text" name="discountCode" disabled={PromotionTypeId > 2} value={DiscountCode} onChange={(e) => handleChangeDiscountCode(e.target.value)} className={DiscountCodeErrorMessage ? "is-invalid" : ""} />
                                            {DiscountCodeErrorMessage ? <div className="invalid-feedback">{DiscountCodeErrorMessage}</div> : null}
                                    </Col>
                                    <Col className="col-sm-4">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control type="text" name="bannerText" value={Description} onChange={(e) => handleChangeDescription(e.target.value)} />
                                    </Col>
                            </Form.Group>
                            <div>
                                    <br/>
                            <div className='float-left'>
                                    <Button size="md" variant='danger' onClick={handleClose}>Cancel</Button>
                            </div>
                            <div className='float-right'>
                            {Saving ? <Spinner  animation="border" displayText="Saving..." size="sm" showSpinner={Saving}/>
                                    : 
                                    <Button size="md" onClick={handleShowConfirm}>Save</Button>
                                    
                            }
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
                <Alert show={showAlert} variant={variant}>
                    {message}
                </Alert>
            </div>
        </div>
        <br></br>
    </div>
    );
}

const mapStateToProps = state => {
    const loggedUser = isLoggedUser(state);
    const userToken = getLoggedUserToken(state);
    return { userToken, loggedUser };
}

export default connect(mapStateToProps)(Promotions);