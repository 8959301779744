import React from 'react';
import HomeHighlightProductItem from '../HomeHighlightProductItem';
import { Link } from "react-router-dom";

const HomeGardenSection = () => {
    const products = [14268, 153665, 9205, 137786];

    return (
        <div>
            <div className="parallax background-title garden-bg">
                <h2>Garden</h2>
            </div>
            <section className="home-section category-grid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <div className="col-sm-7">
                                    <h5>New products highlight</h5>
                                </div>
                                <div className="col-sm-5 text-right">
                                    <p>
                                        <Link to="/garden">Search All Categories</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                {products.map((productId, index) =>
                                    <div className="col-md-6 col-lg-3" key={index}>
                                        <HomeHighlightProductItem 
                                            key={index}
                                            productId={productId}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomeGardenSection;