export class Fault {
  private _id: number;
  private _code!: string;
  private _name!: string;
  private _description!: string;
  private _active!: boolean;
  private _duration!: string;
  private _groupId!: number;
  private _freezeFrame!: [];

  constructor(id: number, groupId: number) {
    this._id = id;
    this.groupId = groupId;
  }

  public get id() { return this._id; }
  public set id(val: number) { this._id = val; }

  public get code() { return this._code; }
  public set code(val: string) { this._code = val; }

  public get name() { return this._name; }
  public set name(val: string) { this._name = val; }

  public get description() { return this._description; }
  public set description(val: string) { this._description = val; }

  public get active() { return this._active; }
  public set active(val: boolean) { this._active = val; }

  public get duration() { return this._duration; }
  public set duration(val: string) { this._duration = val; }

  public get groupId() { return this._groupId; }
  public set groupId(val: number) { this._groupId = val; }

  public get freezeFrame() { return this._freezeFrame; }
  public set freezeFrame(val: []) { this._freezeFrame = val; }

  public getJSON(): object {
    return {
      id: this._id,
      code: this._code,
      name: this._name,
      description: this._description,
      active: this._active,
      duration: this._duration,
      groupId: this._groupId,
      freezeFrame: this._freezeFrame
    };
  }
}