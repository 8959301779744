import React, { useCallback, useRef, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon, Loader } from '../../../../components';
import CustomSwitch from '../../../../components/switch';
import  UltraAPI  from '../../../../utils/ScannerAPI';

const ProfileLog = ({ activeEngine, loading, getLogs, logs, prevPage, nextPage, }) => {

  const { t } = useTranslation();
  const [shareLinkModalVisible, setShareLinkModalVisible] = useState(false);
  const activeLog = useRef(null);

  const loadModal = useCallback((log) => {
    activeLog.current = log;
    setShareLinkModalVisible(true);
  }, []);

  const resetModal = useCallback(() => {
    activeLog.current = null;
    setShareLinkModalVisible(false);
  }, []);

  return (
    <>
      <div className="col col-12 col-lg-6 mb-5">
        {loading ? <span className="d-block" style={{marginTop: 24}}><Loader /></span>
        : !logs ? null 
        : logs.length > 0 ?
          <div className="card-body">
            {logs.map((log, index) => <LogItem index={index} log={log} loadModal={() => loadModal(log)} t={t} />)}
            <div className="row">
              <div className="col-6 text-right">
                {prevPage !== false ? <a onClick={(e) => getLogs(activeEngine, prevPage)}>« {t('profiles.pagination.prev')}</a> : null}
              </div>
              <div className="col-6">
                {nextPage !== false ? <a onClick={(e) => getLogs(activeEngine, nextPage)}>{t('profiles.pagination.next')} »</a> : null}
              </div>
            </div>
          </div>
          : logs.length === 0 ? <span className="d-block" style={{marginTop: 24}}>{t('profiles.logs.empty')}</span>
          : null
        }
      </div>
      {shareLinkModalVisible ? 
        <ShareLogModal
          activeLogId={activeLog.current ? activeLog.current.id : null}
          engineOwnerEmail={activeEngine ? activeEngine.ownerEmail : null}
          visible={shareLinkModalVisible}
          onHide={resetModal}
          t={t}
        />
      : null}
    </>
  )
}

const LogItem = ({ index, log, loadModal, t }) => (
  <div className="log" key={"log" + index}>
    <div className={"row row-ligt justify-content-center align-items-center"} style={{ borderLeftColor: log.color }}>
      <div className="col-12">
        <div className="d-md-flex mb-2">
          <span className="d-md-flex pr-2 flex-grow-1 align-self-center">{log.id}</span>
          <Link to={"log/" + log.id + "/preview" } rel="noreferrer" className="btn btn-ultra align-self-center mx-1">
            <div className="d-flex align-items-center btn-icon">
              <Icon name="Preview" width={16} height={16} />
            </div>
          </Link>
          <button className="btn btn-ultra align-self-center mx-1" onClick={() => loadModal(log.id)}>
            <div className="d-flex align-items-center">
              <Icon name="Share" width={16} height={16} />
            </div>
          </button>
        </div>
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <p><Icon name="Calendar" /></p>
            <p className="pl-2">{t('date.format', { date: new Date(log.date) })}</p>
          </div>
        </div>
        {log.viewsData.length > 0 ?
          <div className="row">
            {log.viewsData.map((viewData, index) =>
              <div className="col-md-4" key={`${'view-' + index}`}>
                <div className="row">
                  <div className="col flex-grow-0 pr-0"><Icon name={viewData.icon} /></div>
                  <p className="col flex-grow-1 program-details">
                    <span>{viewData.name}</span><br />
                    <small>{viewData.description}</small>
                  </p>
                </div>
              </div>
            )}
          </div>
          : null}
        {log.comment ?
          <div className="d-flex align-items-center">
            <p><Icon name="Info" /></p>
            <p className="pl-2">{log.comment}</p>
          </div>
          : null}
        {log.technician ?
          <div className="d-flex align-items-center">
            <p><Icon name="Wrench" /></p>
            <div className="pl-2">
              <p className="mb-0">{log.technician.fullName}</p>
              {log.shop ?
                <p>
                  {log.shop.name ? <span>{log.shop.name}</span> : null}
                  {log.shop.email ? <span>, {log.shop.email}</span> : null}
                  {log.shop.phone ? <span>, {log.shop.phone}</span> : null}
                </p>
                : null}
            </div>
          </div>
          : null}
      </div>
    </div>
  </div>
)


const ShareLogModal = ({ visible, onHide, engineOwnerEmail, activeLogId, t }) => {
  
  const daysOffset7 = new Date();
  const daysOffset30 = new Date();
  const daysOffset90 = new Date();
  daysOffset7.setDate(daysOffset7.getDate() + 7);
  daysOffset30.setDate(daysOffset30.getDate() + 30);
  daysOffset90.setDate(daysOffset90.getDate() + 90);

  const [ownerRecipient, setOwnerRecipient] = useState(false);
  const [email, setEmail] = useState(engineOwnerEmail);
  const [formError, setFormError] = useState(null);
  const [formLoader, setFormLoader] = useState(false);
  const [toDate, setToDate] = useState(daysOffset30.getTime());
  const [logLink, setLogLink] = useState(null);

  const changeRecipient = useCallback(() => {
    setOwnerRecipient(ownerRecipient !== true);
    setEmail(ownerRecipient === false ? '' : engineOwnerEmail);
  }, [ownerRecipient]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    setFormLoader(true);
    UltraAPI.shareLog(email, activeLogId, toDate).then(response => {
      if (response.status === 200 && response.data !== '') {
        setLogLink(response.data);
      }
    }).catch(error => setFormError(t('profiles.form.error')));
    setFormLoader(false);
  }, [email, toDate]);

  return (
    <Modal show={visible} onHide={onHide} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" centered>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">{t('profiles.modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formLoader ? <Loader /> : logLink ?
          <div className="text-center">
            <a href={logLink} className="btn btn-primary mx-2" target="_blank" rel="noreferrer">{t('profiles.modal.view')}</a>
            <button className="btn btn-primary mx-2" onClick={() => { navigator.clipboard.writeText(logLink) }}>{t('profiles.modal.copy')}</button>
          </div>
          :
          <Form onSubmit={onSubmit}>
            {formError ? <div className="alert-danger mb-3">{formError}</div> : null}
            {engineOwnerEmail ?
              <div className="row mb-3">
                <div className="col text-left">
                  <span>{t('profiles.modal.form.recipient')}:</span>
                </div>
                <div className="col text-right">
                  <CustomSwitch
                    onChange={changeRecipient}
                    checked={ownerRecipient}
                    labelLeft={t('profiles.modal.form.options.left')}
                    labelRight={t('profiles.modal.form.options.right')}
                  />
                </div>
              </div>
              : null}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t('profiles.modal.form.email.label')}:</Form.Label>
              {ownerRecipient ?
                <Form.Control type="email" placeholder={t('profiles.modal.form.email.placeholder')} required onChange={(e) => setEmail(e.target.value)} />
                : <span className="d-block">{email  + 'test'}</span>
              }
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="d-block">{t('profiles.modal.form.valid.label')}:</Form.Label>
              <select onChange={(e) => setToDate(e.target.value)} className="form-control">
                <option value={daysOffset7.getTime()}>{t('profiles.modal.form.valid.options.1')}</option>
                <option value={daysOffset30.getTime()} selected>{t('profiles.modal.form.valid.options.2')}</option>
                <option value={daysOffset90.getTime()}>{t('profiles.modal.form.valid.options.3')}</option>
              </select>
            </Form.Group>
            <Button variant="primary" type="submit">{t('profiles.modal.form.submit')}</Button>
          </Form>
        }
      </Modal.Body>
    </Modal>
  )
}

export default ProfileLog;