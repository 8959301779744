import ScannerAPI from '../ultra/utils/ScannerAPI';

export default {

  async login(username, password) {
    return ScannerAPI.post('/v1/auth/login', { username: username, password: password, userType: 'TECHNICIAN' });
  },

  async log(logId) {
    return ScannerAPI.get('/v1/engine/logs/' + logId);
  },

  logInfo(id) {
    return ScannerAPI.get('/v1/engine/logs/share/' + id);
  },

  getEngineInfo(engineProfileId) {
    return ScannerAPI.get('/v1/engine/profiles/' + engineProfileId);
  },

  searchEngines(term, pageSize, pageNumber) {
    return ScannerAPI.post('/v1/engine/profiles', { searchPattern: term, pageSize:pageSize, pageNumber:pageNumber });
  },

  getLogsForEngine(engineProfileId, pageSize, pageNumber) {
    return ScannerAPI.post('/v1/engine/logs', { engineProfileId: engineProfileId, pageSize:pageSize, pageNumber:pageNumber });
  },

  async shareLog(emailTo, logId, validTo) {
    return ScannerAPI.post('/v1/engine/logs/share', { emailTo: emailTo, logId: logId, validTo: validTo });
  },

  async getTopFaults(id) {
    return ScannerAPI.post('/v1/reports/top10faults', { connectivityId: id });
  },

  async listEngines() {
    return ScannerAPI.get('/v1/objects/engines');
  },

  async loadOwners() {
    return ScannerAPI.post('/v1/users/owners');
  },

  async changeOwner(engineProfileId, ownerId) {
    return ScannerAPI.post('/v1/engine/profiles/owner/change', { engineProfileId: engineProfileId, ownerId: ownerId });
  },

  async cancelRequest(id) {
    return ScannerAPI.get('/v1/engine/profiles/owner/change/' + id + '/cancel');
  },

  async externalLogin(res) {
    return ScannerAPI.post(
        '/v1/auth/login/external',
        res
    )
  },

}