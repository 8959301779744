import React, { useState, useEffect, Fragment, setState } from 'react'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image, Spinner } from 'react-bootstrap';

import DataTableComponent from '../DataTableComponent';

const OrderItems = (props) => {

    const [ItemData, SetItemData] = useState([]);
    const [EditMode, SetEditMode] = useState(false);
    const [DisplayQty, SetDisplayQty] = useState(false);
    const [ShowQuickAdd, SetShowQuickAdd] = useState(props.showQuickAdd);
    const ImageBaseUrl = process.env.REACT_APP_IMAGE_PATH + "/tr:h-50/"; //'https://media.sierraparts.com/productImages/product/tr:h-50/';

    const [SearchText, setSearchText] = useState('Search Item');
    const [DataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        SetItemData(props.itemData);

        if (props.editMode)
            SetEditMode(props.editMode);

        if (props.showInventoryQty)
            SetDisplayQty(props.showInventoryQty);

        console.log("OrderItems.props", props);
        console.log("OrderItems.props.itemData", props.itemData);

        checkImages(props.itemData);
    }, [props.itemData]);

    useEffect(() => {
        setDataLoading(props.dataLoading);
    }, [props.dataLoading]);

    const checkImages = (data) => {
        data.forEach(item => {
            if (item.itemDetail.productImages != null){
                item.itemDetail.productImages = item.itemDetail.productImages.filter(item => item.filename.indexOf("pdf") < 0);
                if (item.itemDetail.productImages[0] != null && item.itemDetail.productImages[0].filename != null) {
                    let img=ImageBaseUrl + item.itemDetail.productImages[0].filename;
                    validateImage(img).then(validImg=>{
                        if (!validImg)
                        {
                            item.itemDetail.productImages[0].filename = item.itemDetail.productImages[0].filename.includes("JPG") ? 
                                item.itemDetail.productImages[0].filename.replace("JPG", "jpg") : 
                                item.itemDetail.productImages[0].filename.replace("jpg", "JPG"); 
                        }
                    });
                }
            }           
        });
    }

    const validateImage = (image) => {
        return fetch(image).then(res=>{
            if (res.status!==200)
            {
                return false;
            }
            else
            {
                return true;
            }
        
        })
        .then(validImg=>{
            return validImg;
        })
    };

    const getImage = (row) => {
        var image = "NoImage.jpg";
        var images = row.itemDetail.productImages;
        if (images != null && images.length > 0){
            if (images[0].filename != null && images[0].filename.length > 0){
                image = images[0].filename;
            }
        }

        return image;
    }

    const columns = [
        {
            id:5,
            cell: (row) => <a className='text-danger' type='button' onClick={e=>handleDeleteItem(e,row)}><FontAwesomeIcon icon={faTrashAlt} size="lg" /></a>,
            sortable:false,
            width:'50px',
            omit:EditMode==false
        },
        {
            id:10,
            cell: (row) => <Image src={ImageBaseUrl + getImage(row)} rounded />,
            sortable:false
        },
        {
            id:20,
            name: 'Item',
            selector: row => row.itemDetail.saleItem,
            sortable:true
        },
        {
            id:30,
            name: 'Description',
            selector: row => row.itemDetail.descShort,
            sortable:true
        },
        {
            id:31,
            name: 'Inventory',
            selector: (row) => row.inventoryQtyDisplay==-1?<Spinner animation="border" size="sm" />:row.inventoryQtyDisplay,
            sortable: true,
            omit:DisplayQty==false
        },
        {
            id:40,
            name: 'Quantity',
            selector: row => row.quantity,
            sortable:true,
            omit:EditMode==true
        },
        {
            id: 41,
            name: 'Quantity',
            cell: (row) => (
                <div>
                    <input
                        className={row.isValid === false ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm'}
                        type="text"
                        value={row.quantity}
                        onChange={(e) => handleQtyChange(e, row)}
                        //style={{ width: '50px'}}
                        disabled={row.inventoryQty === -1}
                    />
                    {row.isValid === false && (
                        <div className="invalid-feedback">
                            {row.errorMessage || 'Invalid Qty.'}
                        </div>
                    )}                 
                </div>
            ),
            sortable: false,
            omit: EditMode == false
        },
        {
            id:42,
            name: 'Case Quantity',
            selector: row => row.itemDetail.outerPack,
            sortable:true,
            omit:true
        },
        {
            id:50,
            name: 'Unit Price',
            selector: (row) => row.price==-1?<Spinner animation="border" size="sm" />:row.price?.toFixed(2),
            sortable: true
        },
        {
            id:60,
            name: 'Total',
            selector: row => row.subTotal?.toFixed(2),
            sortable:true
        },
    ];

    const handleDeleteItem = (e,row) => {
        console.log("orderItems: handleDeleteItem: ", row);
        props.handleDeleteItem(e, row);    
    
    };

    const handleQtyChange = (e, row) => {
        console.log("orderItems: handleQtyChange");
        props.handleQtyChange(e, row);    
    }

    return (
        <DataTableComponent dataLoading={DataLoading} columns={columns} data={ItemData} searchText={SearchText} sortField={40} sortAsc={true} showQuickAdd={ShowQuickAdd} showSearch={props.showSearch} handleAddClick={props.handleAddClick}></DataTableComponent>
    );
};

export default OrderItems;