var metric;

metric = {
  ea: {
    to_anchor: 1
  },
  dz: {
    to_anchor: 12
  }
};

module.exports = {
  metric: metric, 
  imperial: {}, 
  _anchors: {
    metric: {
      unit: 'ea', 
      ratio: 1
    }
  }
};
