import Icon from '../../../../../components/icon';

const Menu = ({ activeView, views, changeActiveView, t, log }) => (
  <div className="aside">
    <div className="row align-items-center">
      <div className="col col-2"><p className="text-align-center"><Icon name="Calendar" width={26} height={26} /></p></div>
      <div className="col col-10">
        <p>{t('date.format', { date: new Date(log.date) })}</p>
      </div>
    </div>
    {views.map((item, index) => <a onClick={() => changeActiveView(item)} key={`menu-item-${index}`}><MenuItem item={item} selected={activeView === item} /></a>)}
    {log.comment ? 
      <div className="row align-items-center pt-2">
        <div className="col col-2"><p className="text-align-center"><Icon name="Info" /></p></div>
        <div className="col col-10"><p>{log.comment}</p></div>
      </div>
    : null}
    {log.technician ? 
      <div className="row align-items-center pt-2">
        <div className="col col-2"><p className="text-align-center"><Icon name="User" /></p></div>
        <div className="col col-10"><p>{log.technician.fullName}</p></div>
      </div>
    : null}
  </div>
);

const MenuItem = ({ item, selected }) => {
  var className="row row-ligt justify-content-center align-items-center";
  if (selected) {
    className += " bg-primary";
  }
  return (
    <div className={className}>
      <div className="col col-2 flex-grow-1"><Icon name={item.icon} /></div>
      <div className="col col-7 d-md-block">
        <span>{item.name}</span><br/>
        <small>{item.description}</small>
      </div>
      <div className="col col-3 text-right"><Icon name="CaretRight" /></div>
    </div>
  );
}

export default Menu;