import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { Form, Col, Row, Button } from "react-bootstrap";
import { validate } from 'email-validator';
import { UserService } from '../../services';
import { connect } from "react-redux";
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { Loading } from '../../components';
import { logoutUser } from '../../redux/actions';

const InviteUsers = ({ loggedUserId, userToken, logoutUser }) => {
    const data = useLocation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [partnerId, setPartnerId] = useState(data.state !== undefined ? data.state.partnerId : null);
    const [invites, setInvites] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [message, setMessage] = useState("You have been invited to join SONAR.");
    const [invitationResentMessage, setInvitationResentMessage] = useState(false);

    const handleChangeFirstName = (value) => setFirstName(value);
    const handleChangeLastName = (value) => setLastName(value);
    const handleChangeEmailAddress = (value) => setEmailAddress(value);
    const handleChangeMessage = (value) => setMessage(value);

    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState(null);
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState(null);
    const [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState(null);
    const [messageErrorMessage, setMessageErrorMessage] = useState(null);

    useEffect(() => {
        if (partnerId === null) {
            getUserInfo();
        } else {
            getInvites();
        }
    }, [])

    const getUserInfo = () => {
        setLoading(true);
        setAuthorizationHeader(userToken);

        UserService.getUserInfo()
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === false) {
                    logoutUser();
                    history.push("/user/login");
                } else {
                    setPartnerId(res.data.data.PartnerHdrId);
                    getInvites();
                }
            }
            setLoading(false);
        }).catch(error => {
            console.log("Error " + error);
        });
    }

    const getInvites = () => {
        if (partnerId !== undefined) {
            UserService.getInvites(partnerId)
            .then(res => {
                if (res.status === 200) {
                    setInvites(res.data.data.AllInvites);
                }
            }).catch(error => {
                console.log("Error " + error);
            });
        }
    }

    const sendInvite = () => {
        let isValid = true;
        if ((!firstName || /^\s*$/.test(firstName))) {
            setFirstNameErrorMessage("First Name is a mandatory field.");
            isValid = false;
        } else {
            setFirstNameErrorMessage(null);
        }
        if ((!lastName || /^\s*$/.test(lastName))) {
            setLastNameErrorMessage("Last Name is a mandatory field.");
            isValid = false;
        } else {
            setLastNameErrorMessage(null);
        }
        if (emailAddress === "") {
            setEmailAddressErrorMessage("Email is a mandatory field");
            isValid = false;
        } else if (!validate(emailAddress)) {
            setEmailAddressErrorMessage("Please enter a valid email address");
            isValid = false;
        } else {
            setEmailAddressErrorMessage(null);
        }
        if ((!message || /^\s*$/.test(message))) {
            setMessageErrorMessage("Please enter a message.");
            isValid = false;
        } else {
            setMessageErrorMessage(null);
        }
        if (isValid) {
            UserService.sendInvite(loggedUserId, firstName, lastName, emailAddress, message)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.results.responseId === true) {
                        setFirstName("");
                        setLastName("");
                        setEmailAddress("");
                        setEmailAddressErrorMessage(null);
                        getInvites();
                    } else {
                        setEmailAddressErrorMessage(res.data.results.Message);
                    }
                }
            }).catch(error => {
                console.log("Error " + error);
            });
        }
    }

    const resendInvite = (id) => {
        UserService.resendInvite(id)
        .then(res => {
            if (res.status === 200) {
                setInvitationResentMessage(res.data.results.Message);
                setTimeout(() => {
                    setInvitationResentMessage(null);
                }, 3000);
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }

    return (
        <div className="registration-form invite-users">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="manage-users-header">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h1>Invite user</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {loading ? <Loading /> :
                <div className="row">
                <div className="col-md-11 offset-md-1">
                    <div className="row">
                        <div className="col-sm-3">
                            <h3>INVITE</h3>
                        </div>
                        <div className="col-sm-9">
                            <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="firstName" value={firstName} onChange={e => handleChangeFirstName(e.target.value)} className={firstNameErrorMessage ? "is-invalid" : ""} />
                                    {firstNameErrorMessage ? <div className="invalid-feedback">{firstNameErrorMessage}</div> : null}
                                </Col>
                                <Col className="col-sm-4">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="lastName" value={lastName} onChange={e => handleChangeLastName(e.target.value)} className={lastNameErrorMessage ? "is-invalid" : ""} />
                                    {lastNameErrorMessage ? <div className="invalid-feedback">{lastNameErrorMessage}</div> : null}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="text" name="emailAddress" value={emailAddress} onChange={e => handleChangeEmailAddress(e.target.value)} className={emailAddressErrorMessage ? "is-invalid" : ""} />
                                    {emailAddressErrorMessage ? <div className="invalid-feedback">{emailAddressErrorMessage}</div> : null}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col className="col-sm-8">
                                    <Form.Label>Message</Form.Label>
                                    <textarea name="message" value={message} onChange={e => handleChangeMessage(e.target.value)}  className={messageErrorMessage ? "form-control is-invalid" : "form-control"} ></textarea>
                                    {messageErrorMessage ? <div className="invalid-feedback">{messageErrorMessage}</div> : null}
                                </Col>
                            </Form.Group>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-9 offset-sm-3">
                                <Button type="button" onClick={sendInvite} >Send</Button>
                                <a className="cancel" href="/profile" >Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
                
            {invites.length > 0 ?
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="line-break"></div>
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>MANAGE INVITES</h3>
                            </div>
                        </div>
                        {invitationResentMessage ?
                        <div className="row">
                            <div className="col-sm">
                                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                    {invitationResentMessage}
                                </div>
                            </div>
                        </div>
                        : null}
                        <div className="manage-users-table">
                            {invites.map((item, index) =>
                                <div className="pl-item" key={index} >
                                    <div className="row">
                                        <div className="mu-index-index"><strong>{index + 1}</strong></div>
                                        <div className="" style={{flexGrow: 1}} >
                                            <strong>{item.Name}</strong>
                                        </div>
                                        <div className="status">
                                            <span>{item.DateJoined ? "ACTIVE" : ""}</span>
                                        </div>
                                        <div className="email" >
                                            <span>{item.Email}</span>
                                        </div>
                                        <div className="actions">
                                            {item.DateJoined ? null :
                                                <Button onClick={(e) => resendInvite(item.Id)} className="repeat">
                                                    <span><FontAwesomeIcon icon={faRedo} /></span>
                                                    Send again
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}                                        
                        </div>
                    </div>
                </div>
            </div>
            : null}
        </div>
    )
}

const mapStateToProps = store => {
    const loggedUserId = getLoggedUserId(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUserId, userToken };
}

export default connect(mapStateToProps, { logoutUser })(InviteUsers);