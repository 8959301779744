/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment, useContext} from 'react'
import { Link, useParams } from 'react-router-dom';
import OrderItems from '../../components/Orders/OrderItems';
import { Button, Modal, Alert } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatalogService from '../../services/CatalogService';
import FourthShiftService from '../../services/FourthShiftService';
import { getLoggedUserToken, isLoggedUser } from '../../redux/selectors';
import { connect } from "react-redux";
import { setCatalogAuthorizationHeader } from '../../utils/NewCatalogAPI';
import { useHistory } from 'react-router-dom';
import { ShoppingCartContext } from '../../components/ShoppingCartMenuComponent';
import { setFourthshiftAuthorizationHeader } from '../../utils/FourthShiftAPI';

const OrderDetail = (props) => {
    
    let { orderGuid, status } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [OrderGuid, setOrderGuid] = useState(null);
    const [OrderStatus, setOrderStatus] = useState(null);
    const [OrderDetail, setOrderDetail] = useState(null);
    const [OrderTotalItems, setOrderTotalItems] = useState(null);
    const [OrderDate, setOrderDate] = useState(null);
    const [OrderTotal, setOrderTotal] = useState(null);
    const [DataLoading, setDataLoading] = useState(false);
    const [PartnerInfo, setPartnerInfo] = useState(null);
    const [ShowSuccessModal, setShowSuccessModal] = useState(false); 
    const [ShowStatusBanner, setShowStatusBanner] = useState(false);
    const [refreshCart, setRefreshCart] = useContext(ShoppingCartContext);
    const [ShippingInfo, setShippingInfo] = useState(null);
    
    const history = useHistory();

    useEffect(() => {
        if (props.loggedUser)
        {
            console.log("OrderDetail.useEffect1", orderGuid);
            console.log("OrderDetail.useEffect1.status", status);
            if (OrderStatus == null && status?.length>0)
            {
                setOrderStatus(status);
            }
            
            //console.log("OrderDetail.props", props);
            if (OrderGuid == null && orderGuid?.length>0)
            {
                setCatalogAuthorizationHeader(props.userToken);
                setFourthshiftAuthorizationHeader(props.userToken);
                setDataLoading(true);
                setOrderGuid(orderGuid);
                getOrder(orderGuid);
            }
        }
        else{
            history.push("/user/login");
        }


    }, [orderGuid]);

   useEffect(() => {
        if (OrderDetail!=null)
        {

            if (OrderDetail.orderItems?.items)
                setItemData(OrderDetail.orderItems?.items);

            let itemCountLabel = " items";
            if (OrderDetail.itemCount==1)
                itemCountLabel = " item";

            setOrderTotalItems("(" + OrderDetail.itemCount + itemCountLabel + ")");

            let _shippingCost = OrderDetail.shippingCost ?? 0;
            let _orderTotal = (OrderDetail.subTotal + _shippingCost).toFixed(2);
            setOrderTotal(_orderTotal);

            let date = new Date(OrderDetail.updateDate)
            let orderDate = date.toLocaleString("en-US");
            setOrderDate(orderDate);

            setPartnerInfo(OrderDetail.partner);
            setDataLoading(false);

            getAddress(OrderDetail.partner.custId, OrderDetail.shipToId);

            //TODO:  change color of status?
            if (OrderStatus)
            {
                console.log("OrderStatus = ", OrderStatus);
                if (OrderStatus=='success')
                {
                    setShowSuccessModal(true);
                    if (OrderDetail && OrderDetail.orderStatusDesc=="Cart")
                        OrderDetail.orderStatusDesc = "Order Received";

                    if (OrderDetail.paymentTypeId==1){
                        CatalogService.updateOrderStatus(OrderDetail.orderGuid, 12).then(response => {
                            console.log("OrderDetail.OrderStatus.Success.updateOrderStatus:");
                            setRefreshCart(); 
                        }).catch(function (error) {
                            console.log("CreditCardPayment.handleAdyenResponse.updateOrderStatus.Error:");
                            console.log(error);
                            return error;
                        });
                    }                    
                }
            }
        }
    }, [OrderDetail])

   const getOrder = (gId) => {
        console.log("OrderDetail.getOrder");
        CatalogService.getOrder(gId).then(response => {
            console.log("OrderDetail.getOrder.Response:");

            //console.log(response);
            if (response.status === 200) {
                if (response.data.data)
                {
                    console.log(response.data.data);
                    setOrderDetail(response.data.data);

                    return true;
                }
            }
        }).catch(function (error) {
            console.log("OrderDetail.getOrder.Error:");
            console.log(error);
            return error;
        });
    }

    const getAddress = (custId, shipToId) => {
        console.log("OrderDetail.getAddress");
        FourthShiftService.getAddress(custId, shipToId).then(response => {
            if (response.status == 200){
                if (response.data.data){
                    console.log("OrderDetail.getAddress response: ", response.data.data);
                    setShippingInfo(response.data.data);                   
                }
            }
        }).catch(function (error) {
            console.log("OrderDetail.getAddress.Error:");
            console.log(error);
            return error;
        });
    }

    const handleClose = () => {
        setShowSuccessModal(false);
        setShowStatusBanner(true);
        history.replace("/order/orderDetail/" + OrderGuid);
    }

    const displayStatuses = ["Order Received", "Payment Processing"];
    const DangerStatuses = ["Order Deleted", "Order Cancelled", "Payment Failure", "Manual Review"];

    return (
        <div className="container">
            {
                ShowStatusBanner==false ?
                    <div></div>
                :
                    displayStatuses.includes(OrderDetail?.orderStatusDesc) ?
                        <div className='text-center'>
                        <Alert variant='success'>Order successfully submitted, current order status is: {OrderDetail?.orderStatusDesc}.</Alert>
                        </div>
                    :
                        <div className='text-center'>
                            <Alert variant='success'>Order successfully submitted.</Alert>
                        </div>
            }
            <hr/>
            <div className="col-lg-12 row">
                <Link to={"/account/user/"}><FontAwesomeIcon icon={faArrowLeft} className="fa-1x" /> Back to Order History</Link>
            </div>
            <div className="row">
                <div className="col-lg-12">

                    <div className=''>
                        <Card>
                            <Card.Body>
                                {/* <Card.Title>Order Detail</Card.Title> */}
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <Card>
                                            <Card.Body>
                                            <Card.Title>Order Detail</Card.Title>
                                                <div className='small'>
                                                    <Table borderless size="sm">
                                                        <tr>
                                                            <td>Order Date:</td>
                                                            <td>{OrderDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Invoice #:</td>
                                                            <td>{OrderDetail?.invoiceNumber ?? ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Reference #:</td>
                                                            <td>{OrderDetail?.coNumber ?? ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Shipping:</td>
                                                            <td>{OrderDetail?.shippingCost.toFixed(2)}</td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td>Order Total:</td>
                                                            <td>{OrderTotal} {OrderTotalItems}</td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td>Order Status:</td>
                                                            <td className={DangerStatuses.includes(OrderDetail?.orderStatusDesc) ? "text-danger" : "text-success"}>{OrderDetail?.orderStatusDesc}</td>
                                                        </tr>
                                                    </Table>
                                                    <div className='text-center'>
                                                        <Button size="sm" block href={"/order/quickorder/"+OrderGuid}>Re-order</Button>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4">
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Shipping Info</Card.Title>
                                                <address className='small'>
                                                    {ShippingInfo?.shipToName!=null?<div>{ShippingInfo?.shipToName}<br /></div>:""}   
                                                    {ShippingInfo?.shipToAddress1!=null?<div>{ShippingInfo?.shipToAddress1}<br /></div>:""}
                                                    {ShippingInfo?.shipToAddress2!=null&&ShippingInfo?.shipToAddress2!=""?<div>{ShippingInfo?.shipToAddress2}<br /></div>:""}
                                                    {ShippingInfo?.shipToCity}, {ShippingInfo?.shipToState} {ShippingInfo?.shipToZip}<br />
                                                </address>
                                                <p className='small text-muted'>
                                                    {OrderDetail?.createdBy}
                                                    <br /><div className='small'>{new Date(OrderDetail?.updateDate).toLocaleString("en-US")}</div>
                                                </p>
                                                <p className='small text-muted'>
                                                    Tracking #: {OrderDetail?.trackingID ?? ""}
                                                </p>
                                                <p className='small text-muted'>
                                                    Customer PO #: {OrderDetail?.customerPONumber ?? ""}
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4">
                                        <Card>
                                            <Card.Body>
                                            <Card.Title>Billing Info</Card.Title>
                                                <address className='small'>
                                                    {PartnerInfo?.partnerName!=null?<div>{PartnerInfo?.partnerName}<br /></div>:""}   
                                                    {PartnerInfo?.shipAddress1!=null?<div>{PartnerInfo?.shipAddress1}<br /></div>:""}
                                                    {PartnerInfo?.shipAddress2!=null?<div>{PartnerInfo?.shipAddress2}<br /></div>:""}
                                                    {PartnerInfo?.shipCity}, {PartnerInfo?.shipState} {PartnerInfo?.shipZip}<br />
                                                    {PartnerInfo?.phoneNo!=null?<div>{PartnerInfo?.phoneNo}<br /></div>:""}
                                                </address>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                <Fragment>
                                    <OrderItems dataLoading={DataLoading} editMode={false} itemData={ItemData} showQuickAdd={false} showInventoryQty={false}></OrderItems>
                                </Fragment>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <br></br>
            <Modal show={ShowSuccessModal} onHide={handleClose} className="" size="">
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Shopping Cart Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-3'>
                    <div className='text-center'>
                        <h3 className='text-success'>Your order has been received!</h3>
                        <img src="/logo192.png" />
                        <br /><br />
                        <p className='text-muted'>Payment completion pending.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className='float-right'><Button size="sm" variant='primary' onClick={handleClose}>View Order</Button></div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
        
    )
};
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    const loggedUser = isLoggedUser(state);
    return { userToken, loggedUser };
};
export default connect(mapStateToProps)(OrderDetail);