import React, { Fragment, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../services';
import PropTypes from "prop-types";

const ParticipantStatusChange = (props) => {

    const [showDialog, setShowDialog] = useState(false);
    const [allowedStatuses, setAllowedStatuses] = useState([]);
    const [selectedStatusId, setSelectedStatusId] = useState();

    useEffect(() => {
        UserService.getUserStatusInfo(props.participantId)
        .then(res => {
            if (res.status === 200) {
                const allowedStatuses = res.data.data.PartnerStatus.Items.filter(item => item.Value !== "");
                setAllowedStatuses(allowedStatuses);
                setSelectedStatusId(res.data.data.SelectedPartnerStatusId);
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }, [props.participantId])

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);
    const handleChangeStatus = (value) => setSelectedStatusId(value);
    
    const handleConfirm = () => {
        UserService.saveUserStatusChange(props.participantId, selectedStatusId)
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === true) {
                    handleClose();
                    props.saveUserStatusChangeCallback();
                }
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }

    return (
        <Fragment>
            <div className="picklist-actions">
                <Button className="btn btn-secondary" onClick={handleShow} ><FontAwesomeIcon icon={faPen} /></Button>
            </div>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Change Partner Status for {props.participantFullName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group as={Row}>
                        <Col>
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" name="country" onChange={(e) => handleChangeStatus(e.target.value)} >
                                {allowedStatuses.map((item, index) => 
                                    <option value={item.Value} key={index} selected={selectedStatusId === item.Value ? true : false }>{item.Text}</option>
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm} >
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

ParticipantStatusChange.propTypes = {
    participantId: PropTypes.string.isRequired,
    participantFullName: PropTypes.string.isRequired,
    saveUserStatusChangeCallback: PropTypes.func.isRequired
};

export default ParticipantStatusChange;