import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import icon from '../../assets/images/file/icons/pdf.png';

const NewsArticle = ({id, className, title, type, startDate, description, fileName}) => {

    return (
        <div className="col-md-6 col-lg-4">
            <Link to={"/news/article/" + id} className={className}>
                <div className="article-list-item">
                    <div className="item-top">
                        <h3>{title}</h3>
                        <div className="article-meta">
                            {type ?
                                <span className="category">{type}</span>
                                : null
                            }
                            <span className="date">{startDate}</span>
                        </div>
                        <div className="article-line-intro">
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="article-files">
                        {fileName ? 
                            <span>
                                <span className="file-icon">
                                    <img src={icon} alt="pdf" />
                                </span>
                                <span className="file-name">{fileName}</span>
                            </span>
                        : null}
                    </div>
                </div>
            </Link>
        </div>
    )

}

NewsArticle.propTypes = {
    id: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};


export default NewsArticle;