import React, { useState } from 'react';
import {Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { validate } from 'email-validator';

const CreateLogin = (props) => {

        const [emailErrorMessage, setEmailErrorMessage] = useState(props.emailErrorMessage);
        const [emailConfirmErrorMessage, setEmailConfirmErrorMessage] = useState("");
        const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
        const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState("");

        async function validateLoginInformation() {
                let isValid = true;
                if ((!props.email || /^\s*$/.test(props.email))) {
                        setEmailErrorMessage("Please enter a email.");
                        isValid = false;
                } else if (!validate(props.email)) {
                        setEmailErrorMessage("Please enter valid email address");
                        isValid = false;
                } else {
                        setEmailErrorMessage(null);
                }

                if (!props.emailConfirm || /^\s*$/.test(props.emailConfirm)) {
                        setEmailConfirmErrorMessage("Please confirm your email.");
                        isValid = false;
                } else if (props.email !== props.emailConfirm) {
                        setEmailConfirmErrorMessage("Emails do not match.");
                        isValid = false;
                } else {
                        setEmailConfirmErrorMessage(null);
                }

                if ((!props.password || /^\s*$/.test(props.password))) {
                        setPasswordErrorMessage("Please enter a password.");
                        isValid = false;
                } else if (props.password.length < 6) {
                        setPasswordErrorMessage("Password must be at least 6 characters long.");
                        isValid = false;
                } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(props.password)) {
                        setPasswordErrorMessage("Password must contain at least one special character.");
                        isValid = false;
                } else if (!/\d/.test(props.password)) {
                        setPasswordErrorMessage("Password must contain at least one digit.");
                        isValid = false;
                }
                else {
                        setPasswordErrorMessage(null);
                }

                if (!props.passwordConfirm || /^\s*$/.test(props.passwordConfirm)) {
                        setPasswordConfirmErrorMessage("Please confirm your password.");
                        isValid = false;
                } else if (props.password !== props.passwordConfirm) {
                        setPasswordConfirmErrorMessage("Passwords do not match.");
                        isValid = false;
                } else if (props.passwordConfirm.length < 6) {
                        setPasswordConfirmErrorMessage("Password must be at least 6 characters long.");
                        isValid = false;
                } else {
                        setPasswordConfirmErrorMessage(null);
                }
                if (isValid) {
                        props.validateLoginInformation();
                }
        }

        return (
                <div className="container">
                        <div className="row">
                                <div className="col-md-11 offset-md-1">
                                        <div className="row">
                                                <div className="col-md-3">
                                                        <h3>CREATE LOGIN</h3>
                                                </div>
                                                <div className="col-md-9">
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-6 col-lg-4">
                                                                <Form.Label>Email Address*</Form.Label>
                                                                <Form.Control type="text" name="email" value={props.email} onChange={(e) => props.handleChangeEmail(e.target.value)} className={emailErrorMessage ? "is-invalid" : ""} readOnly={props.emailReadonly === "true" ? "readOnly" : ""} />
                                                                {emailErrorMessage ? <div className="invalid-feedback">{emailErrorMessage}</div> : null}
                                                        </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-6 col-lg-4">
                                                                <Form.Label>Confirm Email Address*</Form.Label>
                                                                <Form.Control type="text" name="email" value={props.emailConfirm} onChange={(e) => props.handleChangeEmailConfirm(e.target.value)} className={emailConfirmErrorMessage ? "is-invalid" : ""} readOnly={props.emailReadonly === "true" ? "readOnly" : ""} />
                                                                {emailConfirmErrorMessage ? <div className="invalid-feedback">{emailConfirmErrorMessage}</div> : null}
                                                        </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-6 col-lg-4">
                                                                <Form.Label>Password*</Form.Label>
                                                                <Form.Control type="password" name="password" value={props.password} onChange={(e) => props.handleChangePassword(e.target.value)} className={passwordErrorMessage ? "is-invalid" : ""} />
                                                                {passwordErrorMessage ? <div className="invalid-feedback">{passwordErrorMessage}</div> : null}
                                                        </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-6 col-lg-4">
                                                                <Form.Label>Retype Password*</Form.Label>
                                                                <Form.Control type="password" name="passwordConfirm" value={props.passwordConfirm} onChange={(e) => props.handleChangePasswordConfirm(e.target.value)} className={passwordConfirmErrorMessage ? "is-invalid" : ""} />
                                                                {passwordConfirmErrorMessage ? <div className="invalid-feedback">{passwordConfirmErrorMessage}</div> : null}
                                                        </Col>
                                                        </Form.Group>
                                                </div>
                                        </div>
                                        <div className="line-break"></div>
                                        <div className="row align-items-center">
                                                <div className="col-3">
                                                        {props.hideBack === undefined ?
                                                                <Link to="#" onClick={(e) => props.changeStep(1)} className="back-link">Back</Link>
                                                        : null}
                                                </div>
                                                <div className="col-9">
                                                        <Button type="button" onClick={validateLoginInformation} >NEXT</Button>
                                                        <a className="cancel" href="/user/login" >Cancel</a>
                                                </div>
                                                <div className="col-sm-9 offset-sm-3">
                                                <p>In the next step, we will create your profile.</p>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        )
}

export default CreateLogin;