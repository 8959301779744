import React from 'react'
import { ErrorBoundary } from '.';

const BaseComponent = ({ children }) => {
    return (
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
    )
}

export default BaseComponent;