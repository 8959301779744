import { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Layout } from "../../../../components";
import { UltraService } from "../../../../../services";
import * as LogFunctions from "./functions";
import LogContent from "./content";

const Log = (props) => {

  const { t } = useTranslation();

  const [activeView, setActiveView] = useState(null);
  const [log, setLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [engine, setEngine] = useState(null);
  const views = useRef([]);

  useEffect(() => {
    setTimeout(() => {
      getLog();
    }, 500)
  }, []);

  const changeActiveView = useCallback((item) => setActiveView(item), []);

  const getLog = useCallback(() => {
    UltraService.log(props.match.params.id).then(response => {
      if (response.status === 200) {
        UltraService.getEngineInfo(response.data.engineProfileId).then(eiResponse => {
          if (eiResponse.status === 200) {
            setEngine(eiResponse.data);
          }
        });
        const liveData = response.data.liveData;
        const faults = response.data.faults;
        const history = response.data.runHistory;
        const viewsArray = LogFunctions.getViews(liveData, faults, history, t);
        views.current = viewsArray;
        setLog(response.data);
        setActiveView(views.current[0]);
      }
      setLoading(false)
    }).catch(error => {
      console.log('Error while getting log', error)
      setLoading(false);
    });
  }, []);

  return (
    <Layout>
      <LogContent
        activeView={activeView}
        changeActiveView={changeActiveView}
        views={views.current}
        engine={engine}
        log={log}
        t={t}
        showBack={true}
        loading={loading}
      />
    </Layout>
  )
}

export default Log;
