import React, { useCallback, useRef, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from 'react-i18next';

import { Icon, Loader } from '../../../../components';
import { UltraService } from '../../../../../services';

const ProfileEngine = ({ loading, engines, getEngines, pageNumber, prevPage, nextPage, getLogs, activeEngine, searchText }) => {

  const { t } = useTranslation();
  const [changeOwnerModalVisible, setChangeOwnerModalVisible] = useState(false);
  const [owners, setOwners] = useState([]);
  const ownerChangeEngine = useRef(null);
  const owner = useRef(null);
  const nextOwnerId = useRef(null);

  const loadChangeOwnerModal = useCallback((value) => {
    setChangeOwnerModalVisible(true);
    ownerChangeEngine.current = value;
    owner.current = ({ "name": value.ownerName, "email": value.ownerEmail, "phone": value.ownerPhone });
    let owners = [];
    UltraService.loadOwners().then(response => {
      if (response.status === 200) {
        response.data.forEach((item, index) => {
          owners.push({ id: item.id, name: item.fullName, address: item.fullAddress1, mobile: item.mobilePhone });
        });
      }
    });
    setOwners(owners);
  }, []);

  const hideChangeOwnerModal = useCallback(() => {
    ownerChangeEngine.current = null;
    owner.current = null;
    nextOwnerId.current = null;
    setChangeOwnerModalVisible(false);
  }, []);

  const onSubmitChangeOwner = useCallback((event) => {
    event.preventDefault();
    UltraService.changeOwner(ownerChangeEngine.current.id, nextOwnerId.current).then(response => {
      hideChangeOwnerModal();
      getEngines(event, pageNumber, searchText);
    });
  }, [pageNumber, searchText]);

  const changeOwner = useCallback((value) => {
    if (value.length > 0) {
      nextOwnerId.current = value[0].id;
    }
  }, []);

  const cancelChangeOwnerRequest = useCallback((event, id) => {
    UltraService.cancelRequest(id).then(response => {
      hideChangeOwnerModal();
      getEngines(event, pageNumber, searchText);
    });
  }, [pageNumber, searchText]);

  return (
    <>
      <div className="col col-12 col-lg-6 mb-5">
        {loading ? <span className="d-block" style={{marginTop: 24}}><Loader /></span> :
          engines == null ? <span className="d-block" style={{marginTop: 24}}>{t('profiles.form.empty')}</span> :
          engines.length == 0 ? <span className="d-block" style={{marginTop: 24}}>{t('profiles.empty')}</span>
            :
            <div className="card-body">
              {engines.map((engine, index) =>
                <div className="position-relative" key={'engine' + index}>
                  <div className={activeEngine === engine ? "engine active" : "engine"} key={`${'engine-' + index}`} onClick={(e) => getLogs(engine, 0)}>
                    <div className="row row-ligt justify-content-center align-items-center">
                      <div className="col-8 pb-0">
                        <h5>{engine.name}</h5>
                        <p className="d-flex align-items-center mb-0">
                          <Icon name="QR" />
                          <span className="align-self-end mx-2">{engine.qrCode}</span>
                        </p>
                      </div>
                      <div className="col-4 text-right align-self-start">
                        <span>{t('date.format', { date: new Date(engine.createdDate) })}</span>
                      </div>
                      {engine.ownerChange ?
                        <div className="d-flex flex-grow-1 align-items-center">
                          <div className="d-flex flex-grow-1 align-items-center">
                            <div className="col-5 d-flex align-items-center">
                              {engine.ownerName ?
                                <p className="d-flex align-items-center">
                                  <Icon name="User" />
                                  <span className="align-self-end mx-1 pl-2">
                                    {engine.ownerName}
                                    {engine.ownerEmail ? ', ' + engine.ownerEmail : null}
                                    {engine.ownerPhone ? ', ' + engine.ownerPhone : null}
                                  </span>
                                </p>
                                : null}
                            </div>
                            <div className="col-1 d-flex align-items-center justify-content-center">
                              <Icon name="CaretRight" width={16} height={16} />
                            </div>
                            <div className="col-4 d-flex justify-content-end  align-items-center">
                              {engine.ownerChange.nextOwner ?
                                <p className="d-flex align-items-center">
                                  <span className="align-self-end mx-1 pr-2">
                                    {engine.ownerChange.nextOwner.fullName}
                                    {engine.ownerChange.nextOwner.email ? ', ' + engine.ownerChange.nextOwner.email : null}
                                  </span>
                                </p>
                                : null}
                            </div>
                          </div>
                        </div>
                        :
                        <div className="d-flex flex-grow-1  align-items-center">
                          <div className="col-6 d-flex">
                            {engine.ownerName ?
                              <p className="d-flex align-items-center">
                                <Icon name="User" />
                                <span className="align-self-end mx-1">
                                  {engine.ownerName}
                                  {engine.ownerEmail ? ', ' + engine.ownerEmail : null}
                                  {engine.ownerPhone ? ', ' + engine.ownerPhone : null}
                                </span>
                              </p>
                              : null}
                          </div>
                        </div>
                      }
                      {engine.ownerChange ?
                    <a className="change m-3" onClick={(e) => cancelChangeOwnerRequest(e, engine.ownerChange.id)}><span>X</span></a>
                    :
                    <a className="change m-3" onClick={(e) => loadChangeOwnerModal(engine)}><Icon name="Edit" width={16} height={16} /></a>
                  }
                    </div>                    
                  </div>                  
                </div>
              )}
              <div className="row">
                <div className="col-6 text-right">
                  {prevPage !== false ?
                    <a onClick={(e) => getEngines(e, prevPage)}>« {t('profiles.pagination.prev')}</a>
                    : null
                  }
                </div>
                <div className="col-6">
                  {nextPage !== false ?
                    <a onClick={(e) => getEngines(e, nextPage)}>{t('profiles.pagination.next')} »</a>
                    : null
                  }
                </div>
              </div>
            </div>
        }
      </div>
      {changeOwnerModalVisible ?
        <ChangeOwnerModal
          changeOwner={changeOwner}
          data={owners}
          visible={changeOwnerModalVisible}
          onHide={hideChangeOwnerModal}
          onSubmit={onSubmitChangeOwner}
          owner={owner.current}
          t={t}
        />
        : null}
    </>
  )
}

const ChangeOwnerModal = ({ visible, onHide, onSubmit, changeOwner, data, owner, t }) => (
  <Modal show={visible} onHide={onHide} size="lg"  aria-labelledby="owner-change-title" centered>
    <Modal.Header closeButton>
      <Modal.Title id="owner-change-title">{t('profiles.modal.owner_change_title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="formFromEmail">
          {owner ?
            <span className="d-block">
              {t('profiles.modal.form.change_from')}: {owner.name}
              {owner.email ? <span>, {owner.email}</span> : null}
            </span>
            : null}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formToEmail">
          <Form.Label>{t('profiles.modal.form.change_to')}:</Form.Label>
          <div className="search-input">
            <Typeahead
              id='owners'
              className='form-control'
              onChange={changeOwner}
              options={data}
              labelKey={option => {
                let displayText = option.name;
                if (option.address) { displayText += ", " + option.address }
                if (option.mobilePhone) { displayText += ", " + option.mobilePhone }
                return displayText;
              }}
              placeholder={t('profiles.form.new_owner.input.placeholder')}
            />
          </div>
        </Form.Group>
        <Button variant="primary" type="submit">{t('profiles.modal.form.submit')}</Button>
      </Form>
    </Modal.Body>
  </Modal>
)

export default ProfileEngine;