import { USER_LOGIN, USER_LOGOUT, TOGGLE_SWITCH } from "../actionTypes";

const initialState = {
    logged: false,
    loggedUser: null,
    loginTime: null,
    userToken: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {

    switch (action.type) {
        case USER_LOGIN: {
            const { email, userId, token } = action.payload;
            return {
                ...state,
                logged: true,
                loggedUser: {
                    email: email,
                    userId: userId,
                },
                loginTime: new Date(),
                userToken: token,
                toggleSwitchEnabled: false,
            };
        }
        case USER_LOGOUT: {
            return {
                ...state,
                logged: false,
                loggedUser: null,
                loginTime: null,
                userToken: null,
            };
        }
        case TOGGLE_SWITCH: {
            return {
                ...state,
                toggleSwitchEnabled: !state.toggleSwitchEnabled,
            };
        }
        default:
            return state;
    }

}