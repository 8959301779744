export function getViews(liveData, faults, history, t) {
	let viewsArray = [];
	if (liveData) {
		viewsArray.push({ icon: 'Live', name: t('menu.live'), description: t('log.live.details', { numOfParameters: liveData.length }) });
	}
	if (faults) {
    let description = '';
		faults.forEach((item, index) => {
      if (item.values.length > 0) {
        if (item.type === 'ACTIVE') {
          if (description.length > 0) { description += ', '; }
          description += item.values.length + t('log.faults.active');
        } else if (item.type === 'INACTIVE') {
          if (description.length > 0) { description += ', '; }
          description += item.values.length + t('log.faults.inactive')
        } else if (item.type === 'CRITICAL') {
          if (description.length > 0) { description += ', '; }
          description += item.values.length + t('log.faults.critical')
        }
      }
		})
		viewsArray.push({ icon: 'Fault', name: t('menu.fault'), description: description });
	}
	if (history) {
		let hours = 0;
		history.forEach((obj, index) => {
      if (index > 0) {
        hours += typeof obj.duration === 'number' ? obj.duration / 60 : 0;
      }
		});
    if (hours % 1 != 0) {
      hours = hours.toFixed();
    }
		viewsArray.push({ icon: 'Pie', name: t('menu.history'), description: t('log.history.details', { hours: hours }) });
	}
	return viewsArray;
}