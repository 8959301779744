import Switch from "react-switch";

const CustomSwitch = ({ onChange, checked, labelLeft, labelRight }) => (
	<span className="d-flex flex-direction-row justify-content-center align-items-center">
		{labelLeft && <span className="px-2">{labelLeft}</span>}
		<Switch 
			onChange={onChange} 
			checked={checked} 
			checkedIcon={false}
			uncheckedIcon={false}
			onColor={'#0082b5'}
			height={16}
			width={34}
			handleDiameter={12}
		/>
		{labelRight && <span className="px-2">{labelRight}</span>}
	</span>
)
export default CustomSwitch;