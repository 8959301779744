import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header';
import Footer from '../../components/Footer';

const Layout = ({ children, onlyChildren }) => {

  const {t} = useTranslation();
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }

  return (
    <>
    <div className = "containerUltra">
      <div className="container mt-5 mb-5">
        <div className={theme + " layout content"}>
          {children}
        </div>
      </div>
    </div>
    </>
  )
}

export default Layout;