import axios from "axios";

let WarrantyAPI = axios.create({
    baseURL: process.env.REACT_APP_WARRANTY_API_URL,
    responseType: "json"
});

export const setAuthorizationHeader = token => {
    if (token) {
        console.log("setAuthorizationHeader warranty");
        WarrantyAPI.defaults.headers.Authorization = `${token}`;
    }
};

export default WarrantyAPI;