import { Fragment } from 'react';
import { Form, Row, Col } from "react-bootstrap";
import countries from '../assets/countries.json';
 
 
const AddressDetails = (props) => {
 
        return (
                <Fragment>
                        <h4>Mailing Address</h4>
                        {props.mailingAddressErrorMessage && <div className="text-danger">{props.mailingAddressErrorMessage}</div>}
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>Address 1*</Form.Label>
                                        <Form.Control type="text" name="address1" value={props.mailingAddress1} onChange={(e) => props.handleChangeMailingAddress1(e.target.value)} className={props.mailingAddress1ErrorMessage ? "is-invalid" : ""} />
                                        {props.mailingAddress1ErrorMessage ? <div className="invalid-feedback">{props.mailingAddress1ErrorMessage}</div> : null}
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control type="text" name="address2" value={props.mailingAddress2} onChange={(e) => props.handleChangeMailingAddress2(e.target.value)} />
                                </Col>
                        </Form.Group>
 
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>Country*</Form.Label>
                                        <Form.Control as="select" name="country" onChange={(e) => props.handleChangeMailingCountry(e.target.value)} value={props.mailingCountry} >
                                        <option value="" disabled selected>Please select a country</option>
                                        {countries.map((country, index) =>
                                                <option value={country.alpha2Code} key={index} selected={props.mailingCountry === country.alpha2Code ? true : false }>{country.name}</option>
                                        )}
                                        </Form.Control>      
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>State*</Form.Label>
                                        <Form.Control type="text" name="state" value={props.mailingState} onChange={(e) => props.handleChangeMailingState(e.target.value)} className={props.mailingStateErrorMessage ? "is-invalid" : ""} />
                                        {props.mailingStateErrorMessage ? <div className="invalid-feedback">{props.mailingStateErrorMessage}</div> : null}
                                </Col>
                        </Form.Group>
 
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>City*</Form.Label>
                                        <Form.Control type="text" name="city" value={props.mailingCity} onChange={(e) => props.handleChangeMailingCity(e.target.value)} className={props.mailingCityErrorMessage ? "is-invalid" : ""} />
                                        {props.mailingCityErrorMessage ? <div className="invalid-feedback">{props.mailingCityErrorMessage}</div> : null}
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>Postal Code*</Form.Label>
                                        <Form.Control type="text" name="postalCode" value={props.mailingPostalCode} onChange={(e) => props.handleChangeMailingPostalCode(e.target.value)} className={props.mailingPostalCodeErrorMessage ? "is-invalid" : ""} />
                                        {props.mailingPostalCodeErrorMessage ? <div className="invalid-feedback">{props.mailingPostalCodeErrorMessage}</div> : null}
                                </Col>      
                        </Form.Group>
 
                        <h4>Shipping Address </h4>
                        {props.shippingAddressErrorMessage && <div className="text-danger">{props.shippingAddressErrorMessage}</div>}
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Check
                                                type="checkbox"
                                                label="Same as Mailing Address"
                                                name="shipping_address"
                                                checked={props.shippingAsMailingAddress}
                                                onChange={props.toggleShippingAsMailingAddress}
                                                id="model"
                                        />
                                </Col>
                        </Form.Group>
 
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>Address 1*</Form.Label>
                                        <Form.Control type="text" name="shippingAddress1" value={props.shippingAddress1} onChange={(e) => props.handleChangeShippingAddress1(e.target.value)} className={props.shippingAddress1ErrorMessage ? "is-invalid" : ""} />
                                        {props.shippingAddress1ErrorMessage ? <div className="invalid-feedback">{props.shippingAddress1ErrorMessage}</div> : null}
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control type="text" name="shippingAddress2" value={props.shippingAddress2} onChange={(e) => props.handleChangeShippingAddress2(e.target.value)} />
                                </Col>
                        </Form.Group>
 
                        <Form.Group as={Row}>
                        <Col className="col-sm-4">
                                        <Form.Label>Country*</Form.Label>
                                        <Form.Control as="select" name="country" onChange={(e) => props.handleChangeShippingCountry(e.target.value)} value={props.shippingCountry} >
                                        <option value="" disabled selected>Please select a country</option>
                                        {countries.map((country, index) =>
                                                <option value={country.alpha2Code} key={index} selected={props.shippingCountry === country.alpha2Code ? true : false }>{country.name}</option>
                                        )}
                                        </Form.Control>      
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>State*</Form.Label>
                                        <Form.Control type="text" name="state" value={props.shippingState} onChange={(e) => props.handleChangeShippingState(e.target.value)} className={props.shippingStateErrorMessage ? "is-invalid" : ""} />
                                        {props.shippingStateErrorMessage ? <div className="invalid-feedback">{props.shippingStateErrorMessage}</div> : null}
                                </Col>
                        </Form.Group>
 
                        <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                        <Form.Label>City*</Form.Label>
                                        <Form.Control type="text" name="city" value={props.shippingCity} onChange={(e) => props.handleChangeShippingCity(e.target.value)} className={props.shippingCityErrorMessage ? "is-invalid" : ""} />
                                        {props.shippingCityErrorMessage ? <div className="invalid-feedback">{props.shippingCityErrorMessage}</div> : null}
                                </Col>
                                <Col className="col-sm-4">
                                        <Form.Label>Postal Code*</Form.Label>
                                        <Form.Control type="text" name="shippingPostalCode" value={props.shippingPostalCode} onChange={(e) => props.handleChangeShippingPostalCode(e.target.value)} className={props.shippingPostalCodeErrorMessage ? "is-invalid" : ""} />
                                        {props.shippingPostalCodeErrorMessage ? <div className="invalid-feedback">{props.shippingPostalCodeErrorMessage}</div> : null}
                                </Col>
                               
                        </Form.Group>          
                </Fragment>
        )
}
 
export default AddressDetails;