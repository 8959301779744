import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { ProductsList } from '../../components/marine';
import { HomeHighlightProductItem } from '../../components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const MarineCategory = () => {
    let { category } = useParams();
    const [categoryName, setCategoryName] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("marineCategories") != null) {
            const marineCategories = JSON.parse(localStorage.getItem("marineCategories"));
            // eslint-disable-next-line eqeqeq
            const filtered = marineCategories.filter(item => item.id == category);
            if (filtered) {
                setCategoryName(filtered[0].name);
            }
        }
    }, [category])
    
    const products = {
        1: [178419, 178465, 178631, 178622],
        2: [182511, 4939, 183058, 182522], 
        3: [1165, 6433, 851, 43822],
        4: [24449, 183412, 25272, 24402], 
        5: [182652, 182623, 182620, 182619], 
        6: [181081, 183135, 181763, 181052], 
        7: [2831, 1065, 6746, 180644], 
        8: [177937, 140796, 180968, 177939], 
        9: [186624, 186651, 186645, 186629], 
        10: [182153, 182149, 182231, 182401], 
        11: [137813, 135419, 19818, 19621], 
        12: [180726, 183182, 183224, 175771], 
        13: [180628, 168983, 168989, 83413], 
        18: [185288, 185402, 183548, 183064]
    };
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <FontAwesomeIcon icon={faAngleRight} />,
        prevArrow: <FontAwesomeIcon icon={faAngleLeft} />,
        responsive: [
          {
            breakpoint: 1469,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    

    return (
        <div className="category">
        <div className={"fixed-parallax category-marine"}></div>
        <div className={"category-intro background-title" }>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="category-name">{categoryName}</h1>
                            </div>
                            {/* {products[category] ?
                                <div className="col-sm-12">
                                    <Slider {...settings} >
                                        {products[category].map((productId, index) =>
                                            <div key={index}>
                                                <HomeHighlightProductItem 
                                                    key={index}
                                                    productId={productId}
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            : null} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="search-results white-background" id="search-results" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        {categoryName ?
                            <ProductsList categoryName={categoryName} categoryId={category} />
                        : null}
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
  
export default MarineCategory;