import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Orders from '../../components/Orders/Orders';
import { isLoggedUser, getLoggedUserToken } from '../../redux/selectors';
import { UserService } from '../../services';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { useHistory } from 'react-router-dom';

const UserAccount = (props) => {
    const [UserInfo, setUserInfo] = useState(null);
    const history = useHistory();

    useEffect(() => {
        console.log("user.userInfo.loggedUser: ", props.loggedUser);

        if (props.loggedUser)
        {
            //console.log("user.userInfo: ", props.userToken);
            setAuthorizationHeader(props.userToken);
            
            UserService.getUserInfo().then(user => {
                console.log("user.userInfo.get.response: ", user.data.data);
                if (user.status == 200)
                    setUserInfo(user.data.data);

            });
        }
        else{
            history.push("/user/login");
        }

    }, [props.userToken]);

    return (
    <div className="container">
        <hr/>
        <div className="row">
            <div className="col-lg-12">
                <Card>
                    <Card.Body>
                        <Card.Title>{UserInfo?.PartnerHdrPartnerName} - Order History</Card.Title>
                        <Orders isAdmin={false} searchText={"Search Invoice"}></Orders>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <br></br>
    </div>
    )
};

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { userToken, loggedUser };
};

export default connect(mapStateToProps)(UserAccount);