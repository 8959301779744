import React from 'react';
import logo from '../logo.svg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons';
import FooterCopyright from './FooterCopyright';
import { connect } from "react-redux";
import { isLoggedUser } from "../redux/selectors";
import { HashLink } from 'react-router-hash-link';

const Footer = ({ loggedUser }) => {
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <footer className="App-footer">
            <div className="footer-links">
                <div className="container relative">
                    <FontAwesomeIcon icon={faArrowAltCircleUp} onClick={scrollTop} className="scrollTop" />

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-3">
                                    <Link to="/" className="footer-logo">
                                        <img src={logo} alt="logo" />
                                    </Link>

                                  
                                </div>
                                <div className="col-md-2">&nbsp;</div>
                                <div className="col-md-2">
                                    <ul>
                                        <li>CATEGORIES</li>
                                        <li><Link to="/marine">Marine</Link></li>
                                        <li><Link to="/power-sports">Power sports</Link></li>
                                        <li><Link to="/garden">Garden</Link></li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <ul>
                                        <li>IN FOCUS</li>
                                        {loggedUser === false ?
                                            <li><Link to="/user/login">Login to Sierra Pro</Link></li>
                                            : null}
                                        <li><Link to="/account/user">View Order History</Link></li>
                                        <li><Link to="/order/quickorder">Quick Order</Link></li>
                                        <li><Link to="/diagrams">Diagrams and Exploded Views</Link></li>
                                        <li>
                                            <a href="http://www.seastarbom.com" target="_blank" rel="noreferrer">Hydraulic Steering System Builder</a>
                                        </li>

                                    </ul>
                                </div>
                                <div className="col-md-2">
                                    <ul>
                                        <li>HELP</li>
                                        <li><Link to="/terms/">Terms & Conditions</Link></li>
                                        <li><HashLink to="/terms#privacy">Privacy Policy</HashLink></li>
                                        <li><Link to="/returns/">Return Policy</Link></li>
                                        {loggedUser === false ?
                                            <li><Link to="/warranty-policy">Warranty</Link></li>
                                            : <li><Link to="/warranty">Warranty</Link></li>}
                                        <li><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterCopyright />
        </footer>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    return { loggedUser };
}

export default connect(mapStateToProps)(Footer);