/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useParams, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import Switch from 'react-switch';
import { ProductSlider, Loading } from '../components';
import { CommonService, CatalogService } from '../services';
import Prop65Image from '../assets/images/product/prop65-default.png';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSleigh } from '@fortawesome/free-solid-svg-icons';
import { isLoggedUser, getLoggedUserToken } from "../redux/selectors";
import { connect,useDispatch, useSelector  } from "react-redux";
import AppendHead from "react-append-head";
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { setCatalogAuthorizationHeader } from '../utils/NewCatalogAPI';
import { setAuthorizationHeader } from '../utils/UserAPI';
import { UserService } from '../services';
import { FourthShiftService } from '../services';
import { toggleSwitch } from '../redux/actions';
import Spinner from '../components/Spinner';
import ReactGA from 'react-ga4';
import { PERMISSIONS } from '../utils/constants';
import { ShoppingCartContext } from '../components/ShoppingCartMenuComponent';

const Product = ({ loggedUser, userToken }) => {
    const data = useLocation()
    let { productId } = useParams();
    const itemsPerPage = 10

    const [saleItem, setSaleItem] = useState()
    const [categoryParent, setCategoryParent] = useState()
    const [categoryChild, setCategoryChild] = useState()
    const [description, setDescription] = useState()
    const [images, setImages] = useState([])
    const [productDetails, setProductDetails] = useState([])
    const [productSpecs, setProductSpecs] = useState([])
    const [productAvailability, setProductAvailability] = useState()
    const [productCanonical, setProductCanonical] = useState([])
    const [containsDetails, setDetails] = useState([])
    const [useWith, setUseWith] = useState([])
    const [interchangeDetails, setInterchangeDetails] = useState([])
    const [documentDetails, setDocumentDetails] = useState([])
    const [applicationInformationCategories, setApplicationInformationCategories] = useState([])
    const [prop65, setProp65] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [filterString, setFilterString] = useState('');
    const [page, setPage] = useState(1);
    const [pagedParts, setPagedParts] = useState([]);
    const [loadingParts, setLoadingParts] = useState(true);
    const [loadingAvailability, setLoadingAvailability] = useState(true);
    const [numberOfParts, setNumberOfParts] = useState(0);
    const [defaultTab, setDefaultTab] = useState('');
    const [activeBrand, setActiveBrand] = useState(false);
    const [filterAnalytics, setFilterAnalytics] = useState(data.state !== undefined ? data.state.filterAnalytics : null);
    const [filterAnalyticsCategoryId, setFilterAnalyticsCategoryId] = useState(data.state !== undefined ? data.state.filterAnalyticsCategoryId : null);
    const [filterAnalyticsMarketId, setFilterAnalyticsMarketId] = useState(data.state !== undefined ? data.state.filterAnalyticsMarketId : null);
    const [UserInfo, setUserInfo] = useState(null);
    const [CustItemPrice, setCustItemPrice] = useState(null);
    const [Item, setItem] = useState(null);
    const [HasCartAccess, setHasCartAccess] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const toggleSwitchEnabled = useSelector(state => state.login.toggleSwitchEnabled);
    const [showDialog, setShowDialog] = useState(false);
    const [orderGuid, setOrderGuid] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [quantityErrorMessage, setQuantityErrorMessage] = useState(null);
    const [minQuantity, setMinQuantity] = useState(1);
    const [priceLoading, setPriceLoading] = useState(false);
    const [RefreshCart, setRefreshCart] = useContext(ShoppingCartContext);
    const [productMOQ, setProductMOQ] = useState(null);
    const [standardPack, setStandardPack] = useState(null);

    useEffect(() => {
        setCatalogAuthorizationHeader(userToken);
        setAuthorizationHeader(userToken);

        if (UserInfo==null)
        {
            UserService.getUserInfo().then(user => {
                console.log("product.userInfo.get.response: ", user.data.data);
                if (user.status == 200)
                {
                    setUserInfo(user.data.data);
                    
                    if (user.data.data?.Permissions.some(i=>i.PermissionGroupsId==PERMISSIONS.ShoppingCartAccess))
                        setHasCartAccess(true);
                    else
                        setPriceLoading(false);
                }

        });
           
    }}, [userToken]);

    useEffect(() => {
        CommonService.getProductDetails(productId)
            .then(response => {
                if (response.status === 200) {
                    const product = response.data.Data[0];
                    console.log("getProductDetails: ", product);
                    setItem(product.item);
                    setSaleItem(product.sale_item);
                    setCategoryParent(product.categoryParent);
                    setCategoryChild(product.categoryChild);
                    setDescription(product.descriptionShort);
                    setImages(product.imageDetails.filter(item => item.fileName.indexOf("pdf") < 0));
                    setProp65(product.isProp65);
                    setLoading(false);
                    getAdditionalInformation(product.sale_item);
                    getApplicationInformationBrands();
                    if (loggedUser)
                        getProductAvailability(product.item);
                    getProductCanonical(productId);
                    
                }
            }).catch(function (error) {
                console.log(error);
            });
    }, [productId]);

    
    useEffect(() => {
        console.log("useEffect.getPrice: ", UserInfo);

        if (UserInfo!=null && Item!=null && UserInfo.PartnerHdrCustId !=null)
            getPrice(Item);

    }, [UserInfo, Item]);

    useEffect(() => {
        setDefaultTab(defaultTab);
    }, [defaultTab]);

    useEffect(() => {
        if (productMOQ != null && standardPack != null){
            let actualMinQuantity = productMOQ == 0 ? standardPack : productMOQ;
            setMinQuantity(actualMinQuantity);
            setQuantity(actualMinQuantity);
        }       
    }, [standardPack, productMOQ]);

    useEffect(() => {
        checkImage(images);
    }, [images]);

    useEffect(() => {
        checkQuantityError();
    }, [quantity, minQuantity, productAvailability]);

    useEffect(() => {
        const timeOutId = setTimeout(() => getApplicationInformationParts(productId, activeBrand, filterString, page, itemsPerPage), 500);
        return () => clearTimeout(timeOutId);
    }, [filterString]);

    const getApplicationInformationBrands = () => {
        CommonService.getApplicationInformationBrands(productId)
            .then(response => {
                if (response.status === 200) {
                    setLoadingCategories(false)
                    let tmpAIC = [];
                    let total = 0;
                    response.data.Data.map((item, index) => {
                        total = total + item.itemCount;
                    });
                    tmpAIC.push({
                        itemCount: total,
                        brandId: false,
                        brand: "All Manufacturers"
                    })
                    tmpAIC = tmpAIC.concat(response.data.Data);
                    setApplicationInformationCategories(tmpAIC);
                    setActiveBrand(tmpAIC[0].brandId)
                    getApplicationInformationParts(productId, activeBrand, filterString, page, itemsPerPage);
                }
            }).catch(function (error) {
                console.log(error);
                setLoadingCategories(false);
            });
    }

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);
    
    const handleToggleSwitch = () => {
        dispatch(toggleSwitch());
    };

    const validateImage = (image) => {
        return fetch(image).then(res=>{
            if (res.status!==200)
            {
                return false;
            }
            else
            {
                return true;
            }
        
        })
        .then(validImg=>{
            return validImg;
        })
    };

    const checkImage = (img) => {
        if (img != null && img[0] != null && img[0].fileName != null) {
            let displayImg = process.env.REACT_APP_IMAGE_PATH + "/tr:h-400/" + img[0].fileName;
            console.log("Checking image ", displayImg);
            validateImage(displayImg).then(validImg=>{
                if (!validImg)
                {
                    img[0].fileName = img[0].fileName.includes("JPG") ? 
                        img[0].fileName.replace("JPG", "jpg") : 
                        img[0].fileName.replace("jpg", "JPG"); 
                }
            });
        }
    }


    const getPrice = (itemNum) => {
        console.log("product.getPrice", itemNum);
        setPriceLoading(true);
        return FourthShiftService.getPrice(UserInfo.PartnerHdrCustId, itemNum).then(response => {
            
            if (response.status === 200) {
                if (response.data.data)
                {
                    console.log("product.getPrice.Response:", response.data.data);
                    setCustItemPrice(response.data.data);
                    setPriceLoading(false);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("product.getPrice.Error:");
            console.log(error);
            return error;
        });
    }

    const getApplicationInformationParts = (productId, brand, searchFilter, page, itemsPerPage) => {
        CommonService.getApplicationInformationParts(productId, brand, searchFilter, page, itemsPerPage)
            .then(response => {
                if (response.status === 200) {
                    //console.log(response.data)
                    setLoadingParts(false);
                    setPagedParts(response.data.Data);
                    setNumberOfParts(response.data.Data[0]?.totalRows);
                }
            }).catch(function (error) {
                console.log(error);
            });
        setActiveBrand(brand);
    }

    const getProductAvailability = async (itemId) => {
        FourthShiftService.getItemAvailability(itemId)
            .then(response => {
                if (response.status === 200) {
                    let _availability = "N/A";
                    let _productMOQ = 0;
                    if (response.data)
                    {
                        if (response.data.data) {
                            console.log("getProductAvailability.res: ", response.data.data);
                            let _productData = response.data.data;
                            if (_productData.availableQty && !isNaN(_productData.availableQty)){
                                _availability = _productData.availableQty;
                            }

                            _productMOQ = _productData.moq;
                        }
                        else {
                            _availability = 'Out of stock';
                        }
                    }
                    setLoadingAvailability(false);
                    setProductAvailability(_availability);
                    setProductMOQ(_productMOQ);
                }
            }).catch(function (error) {
                console.log(error);
                setLoadingAvailability(false);
                setProductAvailability("N/A");
            });
    }

    const getProductCanonical = async (id) => {
        setProductCanonical("");
         CatalogService.getItem(id)
             .then(response => {
                setProductCanonical("");
                 if (response.status === 200) {
                    console.log('getProductCanonical: success');
                    //console.log(response.data);
                    if (response.data && response.data.data)
                    {
                        let _data = response.data.data;
                        if (_data.itemCanonicalUrl)
                            setProductCanonical(_data.itemCanonicalUrl);
                    }
                 }
             }).catch(function (error) {
                 console.log('getProductCanonical: error');
                 console.log(error);
             });
        
    }

    async function getAdditionalInformation(saleItem) {
        CommonService.getProductAdditionalInformation(productId, filterAnalytics, filterAnalyticsCategoryId, filterAnalyticsMarketId)
            .then(response => {
                if (response.status === 200) {
                    const item = response.data.Data[0];
                    let pDetails = [];
                    if (item.productDetails) {
                        item.productDetails.forEach(detail => {
                            let showStrongValue = detail.attribute.toUpperCase() == "_WARNING" ? true : false;
                            pDetails.push({
                                attribute: detail.attribute,
                                attributeValue: detail.attributeValue,
                                strongValue: showStrongValue
                            })
                        });
                    }
                    let uWith = [];
                    if (item.useWithDetails) {
                        item.useWithDetails.forEach(detail => {
                            uWith.push({
                                id: detail.itemRow,
                                image: detail.image,
                                title: detail.saleItem,
                                description: detail.description
                            })
                        });
                    }
                    setProductDetails(pDetails);
                    setUseWith(uWith);
                    setProductSpecs(item.productSpecs);
                    setStandardPack(item.productSpecs[0]?.standard_packaging);                   

                    let iDetails = [];
                    item.interchangeDetails.forEach(item => {
                        let iBrandDetails = iDetails.find(obj => obj.brandName === item.brandName);
                        let isNew = false;
                        if (iBrandDetails === undefined) {
                            isNew = true;
                            iBrandDetails = {
                                brandName: item.brandName,
                                items: []
                            };
                        }
                        iBrandDetails.items = [...iBrandDetails.items, item.oeNumber];
                        if (isNew) {
                            iDetails.push(iBrandDetails);
                        }
                    })
                    setInterchangeDetails(iDetails);

                    setDetails(item.containsDetails);

                    setDocumentDetails(item.documentDetails);

                    switch (true) {

                        case (pDetails.length > 0 && defaultTab != 'details'):
                            setDefaultTab('details');
                            break;

                        case (
                            pDetails.length == 0 &&
                            item.productSpecs.length > 0 &&
                            defaultTab != 'specs' &&
                            defaultTab != 'details'):
                            setDefaultTab('interchange');
                            break;

                        case (
                            item.productSpecs.length == 0 &&
                            iDetails.length > 0 &&
                            defaultTab != 'interchange' &&
                            defaultTab == 'specs' &&
                            defaultTab != 'details'):
                            setDefaultTab('interchange');
                            break;

                        case (
                            iDetails.length == 0 &&
                            item.containsDetails.length > 0 &&
                            defaultTab != 'containsDetails' &&
                            defaultTab != 'interchange' &&
                            defaultTab != 'specs' &&
                            defaultTab != 'details'):
                            setDefaultTab('containsDetails');
                            break;

                        case (
                            item.containsDetails.length == 0 &&
                            item.documentDetails.length > 0 &&
                            defaultTab != 'documentDetails' &&
                            defaultTab != 'containsDetails' &&
                            defaultTab != 'interchange' &&
                            defaultTab != 'specs' &&
                            defaultTab != 'details'):
                            setDefaultTab('documentDetails');
                            break;

                        default:
                            setDefaultTab('details');
                            break;
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    const goToPage = (page) => {
        setPage(page);
        getApplicationInformationParts(productId, activeBrand, filterString, page, itemsPerPage);
    }

    const filterByCategory = (brandId) => {
        setPage(1);
        setActiveBrand(brandId);
        getApplicationInformationParts(productId, brandId, filterString, page, itemsPerPage);
    }

    const handleAddToCart = (e) => {
        console.log("orderCheckout.handleAddToCart");
        
        e.preventDefault();
        
        ReactGA.event({
            category: 'User',
            action: 'product_add_to_cart'
        });

        CatalogService.saveOrderItem(UserInfo.PartnerHdrId, 0, 0, "", productId, quantity, CustItemPrice.itemPrice)
            .then(response => {
                if (response.status==200) {
                    let responseGuid = response.data.data.orderGuid;
                    setOrderGuid(responseGuid);
                    setRefreshCart();
                    handleShow();
                }
        }).catch(error => {
            console.log("handleAddToCart: saveOrderItem: error:");
            console.log(error);
        });

    }

    const handleViewCart = (e) => {
        console.log("orderCheckout.handleViewCart");

        e.preventDefault();

        history.push("/order/orderCart/" + orderGuid);
    }

    const handleOnChangeQuantity = (value) => {
        setQuantity(value);     
    }

    const checkQuantityError = () => {
        if (quantity % minQuantity != 0 || quantity > productAvailability)
            setQuantityErrorMessage("Error");
        else setQuantityErrorMessage(null);
    }

    return (
        loading === false ?
            
            <div className="product">
                <div className="container product-top-info">
                    <div className="row">
                        
                            {productCanonical.length > 0 ?
                                    <AppendHead><link rel="canonical" href={productCanonical} /></AppendHead>
                                    : null}
                        
                        <div className="col-sm-6 col-md-4 offset-md-1">
                            {HasCartAccess && loggedUser && UserInfo?.PartnerHdrCustId != null && (
                            <div>
                                    <Switch
                                        checked={toggleSwitchEnabled}
                                        onChange={handleToggleSwitch}
                                        width={45}
                                        height={20}
                                        offColor='#008800'
                                        onColor='#cc0202'
                                        checkedIcon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '0.6em' }}>ON</div>}
                                        uncheckedIcon={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '0.6em' }}>OFF</div>}
                                    />                                    
                            </div>
                            )}
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <span>ITEM:</span>
                                            <h1>{saleItem}</h1>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <span>DESCRIPTION:</span>
                                            {description}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <span>CATEGORY:</span>
                                            {categoryParent} / {categoryChild}
                                        </td>
                                    </tr>
                                    {loggedUser ? 
                                        <tr>
                                            <td colSpan="2"> 
                                                    <span>AVAILABILITY:</span>
                                                    {loadingAvailability ? 
                                                        <div style={{ padding: '0px'}}>
                                                            <Spinner displaySize = "16px" animation="border" size="sm" showSpinner={loadingAvailability}/>
                                                        </div>
                                                        : productAvailability}
                                            </td>
                                        </tr>
                                    : null}
                                </tbody>
                            </table>
                          
                            
                                <div>
                                    {(CustItemPrice?.itemPrice != null && toggleSwitchEnabled) && (
                                        <div>
                                            <span className="price">
                                                {HasCartAccess && toggleSwitchEnabled && (
                                                    priceLoading ? 
                                                    <div style={{ padding: '5px'}}>
                                                        <Spinner displaySize = "16px" animation="border" size="sm" showSpinner={priceLoading}/>
                                                    </div>
                                                    : 
                                                    <div>
                                                        ${CustItemPrice.itemPrice.toFixed(2)}
                                                    </div>
                                                )}
                                            </span>
                                        </div>
                                    )}

                                    {productSpecs[0]?.listPrice == null ? 
                                        <div style={{ padding: '5px'}}>
                                            <Spinner displaySize = "16px" animation="border" size="sm" showSpinner={(productSpecs[0]?.listPrice==null)}/>
                                        </div>
                                        : 
                                        <div>
                                            <span className="price">
                                                ${productSpecs[0]?.listPrice.toFixed(2)} MSRP / {productSpecs[0]?.um}
                                            </span>
                                        </div>
                                    }

                                    {HasCartAccess ?
                                        productAvailability > 0 && CustItemPrice?.itemPrice != null ? 
                                            <div>
                                                <Button variant="primary" disabled={quantityErrorMessage != null} onClick={handleAddToCart}>
                                                    Add to Cart
                                                </Button>
                                                <br></br>
                                                <label className="mr-2 mt-2" style={{color: '#505050', fontSize: '15px'}}>QUANTITY:</label>
                                                <input type="number" min={minQuantity} step={minQuantity} className={quantityErrorMessage != null ? "form-control form-control-sm is-invalid w-25" : "form-control form-control-sm w-25"} value={quantity} onChange={(e) => handleOnChangeQuantity(e.target.value)} />
                                                <p className="mt-2" style={{color: '#505050'}}>Minimum Order Quantity: {minQuantity} </p>
                                            </div>
                                            : 
                                            (loadingAvailability || priceLoading) ? null :
                                                <strong style={{ display: 'block', marginTop: '20px', fontSize: '1.2em' }}>Purchase from the distributor or partner of your choice</strong>
                                        :
                                        null
                                        }

                                    <br /><br />
                                </div>
             

                            {prop65 === true ?
                                <img src={Prop65Image} alt="prop65" className="mb-3" />
                                : null}
                        </div>
                        {images.length > 0 ?
                            <div className="col-sm-6 col-sm-4 offset-md-1">
                                <ProductSlider images={images} />
                            </div>
                            : null
                        }
                    </div>

                    <div className="row" >
                        <div className="col-sm-12 col-md-10 offset-md-1" >
                            <Tabs id="product-tab" activeKey={defaultTab} onSelect={(k) => setDefaultTab(k)}>
                                {productDetails.length > 0 ?
                                    <Tab eventKey="details" title="Details">
                                        {productDetails.map((item, index) =>
                                            item.strongValue === true ? <p key={index} ><strong>{item.attribute !== '_WARNING' ? item.attribute + ":" : ""} {item.attributeValue}</strong></p>
                                                : <p key={index} >{item.attribute !== '_FEATURE' ? item.attribute + ":" : ""} {item.attributeValue}</p>
                                        )}
                                        {UserInfo?.PartnerHdrCustId == null && productSpecs.length > 0 && productSpecs[0].sdc != null && loggedUser !== false ?
                                            <p>Sdc: {productSpecs[0].sdc}</p>
                                            : null}
                                    </Tab>
                                    : null}
                                {interchangeDetails.length > 0 > 0 ?
                                    <Tab eventKey="interchange" title="Interchange">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                {interchangeDetails.map((item, index) =>
                                                    <Fragment>

                                                        <p key={index} className="lead">{item.brandName}</p>
                                                        <table className="table table-sm">
                                                            <tbody>
                                                                {item.items.map((interChange, iIndex) =>
                                                                    <tr key={iIndex}>
                                                                        <td className="ic-td">{interChange}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </Tab>
                                    : null}
                                {useWith.length > 0 ?
                                    <Tab eventKey="use" title="Use With">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="results">
                                                    {useWith.map((item, index) =>
                                                        <div className="row" key={index} >
                                                            <div className="result-image">
                                                                <figure>
                                                                    {item.image != '' ?
                                                                        <img src={process.env.REACT_APP_IMAGE_PATH + "/tr:h-400/" + item.image} alt={item.description} />
                                                                        : null}
                                                                </figure>
                                                            </div>
                                                            <div className="result-description">
                                                                <h4>
                                                                    <Link to={{
                                                                        pathname: "/product/" + item.id
                                                                    }} >{item.title}</Link>
                                                                </h4>{item.description}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    : null}
                                {documentDetails.length > 0 ?
                                    <Tab eventKey="documentDetails" title="Document details">
                                        <div className="row" >
                                            <div className="col" >
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <td>Description</td>
                                                            <td>Last updated</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {documentDetails.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.fileName ?
                                                                        <a href={process.env.REACT_APP_DOCUMENTS_PATH + "/" + item.docType + "/" + item.fileName} >{item.docDescription}</a>
                                                                        : item.docDescription}
                                                                </td>
                                                                <td>{item.LastUpdated}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                    : null}
                                {containsDetails.length > 0 ?
                                    <Tab eventKey="containsDetails" title="Contains details">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="results">
                                                    {containsDetails.map((item, index) =>
                                                        <div className="row" key={index} >
                                                            <div className="result-image">
                                                                <figure>
                                                                    {item.image != null && item.image.indexOf("image") < 0 && item.image != "No Image" ?
                                                                        <img src={process.env.REACT_APP_IMAGE_PATH + "/" + item.image} alt={item.nssDesc} />
                                                                        : null}
                                                                    {item.image == "No Image" ?
                                                                        <img src="https://media.sierraparts.com/4.36ef5a05_UzbFNEPV5Q9.png?" alt={item.nssDesc} />
                                                                        : null}
                                                                </figure>
                                                            </div>
                                                            <div className="result-description">
                                                                <h4>
                                                                    <Link to={{
                                                                        pathname: "/product/" + item.itemRow
                                                                    }} >{item.item}</Link>
                                                                </h4>{item.nssDesc}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    : null}
                                {productSpecs.length > 0 ?
                                    <Tab eventKey="specs" title="Specs">
                                        {productSpecs.map((item, index) =>
                                            <div className="row" key={index} >
                                                <div className="col-sm-6" >
                                                    <p className="lead" >Packaging Dimensions</p>
                                                    <table className="table table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td className="w-25" >Weight</td>
                                                                <td>{item.weight}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Height</td>
                                                                <td>{item.height}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Depth</td>
                                                                <td>{item.depth}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Width</td>
                                                                <td>{item.width}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-sm-6" >
                                                    <p className="lead" >Product Details</p>
                                                    <table className="table table-sm">
                                                        <tbody>
                                                            <tr>
                                                                <td className="w-25" >Unit of measure</td>
                                                                <td>{item.um}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Item status</td>
                                                                <td>{item.itemStatus}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >UPC</td>
                                                                <td>{item.upc}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Case packaging</td>
                                                                <td>{item.case_packaging}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Standard packaging</td>
                                                                <td>{item.standard_packaging}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >HSC</td>
                                                                <td>{item.hsc}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >ECCN</td>
                                                                <td>{item.eccn}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-25" >Origin</td>
                                                                <td>{item.origin}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </Tab>
                                    : null}
                            </Tabs>
                        </div>
                    </div>
                </div>
                {applicationInformationCategories.length > 0 && applicationInformationCategories[0].itemCount > 0 ?
                    <div className="container">
                        <div className="application-information">
                            <div className="title">
                                <h2>Application Information</h2>
                            </div>
                            <div className="row flex">
                                <div className="col-sm-3 offset-lg-1">
                                    <aside>
                                        {loadingCategories ? <Loading /> :
                                            <Fragment>
                                                <ul>
                                                    {applicationInformationCategories.length > 0 ?
                                                        applicationInformationCategories.map((aiCategory, cindex) =>
                                                            <li key={cindex} className={activeBrand == aiCategory.brandId ? "active-ap-in" : ""}>
                                                                <button href="#" onClick={() => filterByCategory(aiCategory.brandId)}>
                                                                    <span className="text-left">{aiCategory.brand}</span>
                                                                </button>
                                                                <span>{aiCategory.itemCount}</span>
                                                            </li>
                                                        ) : null}
                                                </ul>
                                            </Fragment>
                                        }
                                    </aside>
                                </div>
                                <div className="col-sm-9 col-lg-7">

                                    {loadingParts ? <Loading /> :
                                        <Fragment>
                                            <div className="filter-input mt-2">
                                                <input className="form-control" type="text" name="filter" placeholder="Filter Engines" value={filterString} onChange={event => {
                                                    setFilterString(event.target.value);
                                                }} />
                                            </div>

                                            <div className="results ai-results">

                                                <div className="theading">
                                                    <div className="row">
                                                        <div className="col model-name">
                                                            <span>MODEL/ Manufacturer</span>
                                                        </div>
                                                        <div className="col years-range">
                                                            <span>YEAR</span>
                                                        </div>
                                                        <div className="col icon">
                                                            <span>HP</span>
                                                        </div>
                                                        <div className="col serials-range">
                                                            <span>Serial range</span>
                                                        </div>
                                                        <div className="col icon"></div>
                                                    </div>
                                                </div>
                                                {pagedParts.length > 0 ? pagedParts.map((part, index) =>
                                                    <Link to={{
                                                        pathname: "/engine/" + part.engineRow,
                                                        state: {
                                                            brandName: activeBrand
                                                        }
                                                    }} key={index}
                                                        className="result-item simple">
                                                        <div className="row">
                                                            <div className="col model-name">
                                                                <strong>{part.ModelNum}</strong>
                                                            </div>
                                                            <div className="col years-range">
                                                                <span>{part.Start}-{part.Stop}</span>
                                                            </div>
                                                            <div className="col icon">
                                                                <span>{part.HP}</span>
                                                            </div>
                                                            <div className="col serials-range">
                                                                <span>{part.StartSerialSearch} THRU {part.StopSerialSearch}</span>
                                                            </div>
                                                            <div className="col icon">
                                                                <FontAwesomeIcon icon={faAngleRight} className="go-to-category" />
                                                            </div>
                                                        </div>
                                                        {part.Note ?
                                                            <div className="row">
                                                                <div className="col-sm-12 text-center">
                                                                    <em>{part.Note}</em>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </Link>
                                                ) : null}
                                            </div>
                                            {numberOfParts > itemsPerPage ? (
                                                <div className="text-center mt-2">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={page}
                                                        itemsCountPerPage={itemsPerPage}
                                                        totalItemsCount={numberOfParts}
                                                        pageRangeDisplayed={5}
                                                        onChange={goToPage}
                                                    />
                                                </div>
                                            ) : null}
                                        </Fragment>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                : null}
            <Modal show={showDialog} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Item added to cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{quantity}x {description} added to your cart.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Keep Shopping</Button>
                    <Button variant="primary" onClick={handleViewCart}>View Cart</Button>
                </Modal.Footer>
            </Modal>
            </div>
            
            : <Loading />
    )
}
const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUser, userToken };
    
}
export default connect(mapStateToProps)(Product);