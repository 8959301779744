import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import FaultsView from "./views/faults/view";
import LiveView from "./views/live/view";
import HistoryView from "./views/history/view";
import Menu from "./views/menu";
import { Print } from './print';
import { Icon, Loader } from '../../../../components';
import Redirect from '../../redirect';

const LogContent = ({ engine, log, t, views, activeView, changeActiveView, showBack = false, loading }) => {

  const history = useHistory();
  //const metricSystem = useSelector(state => state.metricSystem);
  const metricSystem = 'imperial';
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  if (loading) {
    return <Loader />
  }

  if (!log) {
    return <Redirect />
  }
  return (
    <>
      {showBack ? <a href="#" onClick={history.goBack}>« <small>{t('go.back')}</small></a> : null}
      <div className="row">
        <div className="col-md-7">
          <h2 className="d-md-inline-block mr-md-3">
            <span>{t('log.preview')}</span>
            <span className="ml-2">
              <div style={{ display: 'none' }}><Print t={t} engine={engine} log={log} ref={componentRef} metricSystem={metricSystem} /></div>
              <button className="btn btn-ultra align-self-center mx-1" onClick={handlePrint}>
                <div className="d-flex align-items-center">
                  <Icon name="Print" width={16} height={16} />
                </div>
              </button>
            </span>
          </h2>
          {engine ? <div><p className="text-muted">{t('log.engine', { engine: engine.name, id: log.engineProfileId })}</p></div> : null}
        </div>
        <div className="col-md-5">
          <div className="alert bg-primary">
            <p className="mb-0"><Trans i18nKey={'log.info'} t={t} /><br />
              <Link to={'https://catalog.sierraparts.com/'}>{t('log.more')}</Link>
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col col-12 col-md-4 mb-5">
          <p>{t('log.content')}</p>
          <div className="card">
            <div className="card-body">
              <Menu views={views} activeView={activeView} changeActiveView={changeActiveView} t={t} log={log} />
            </div>
          </div>
        </div>
        <div className="col col-12 col-md-8">
          {activeView && activeView.icon === "Fault" && <FaultsView t={t} data={log.faults} metricSystem={metricSystem} />}
          {activeView && activeView.icon === "Live" && <LiveView t={t} data={log.liveData} metricSystem={metricSystem} />}
          {activeView && activeView.icon === "Pie" && <HistoryView t={t} data={log.runHistory} metricSystem={metricSystem} />}
        </div>
      </div>
    </>
  )
}

export default LogContent;