/* eslint-disable no-unused-vars */
import { useState, useEffect, Fragment, useContext} from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import OrderItems from '../../components/Orders/OrderItems';
import { Button, Modal, Alert } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatalogService from '../../services/CatalogService';
import { getLoggedUserToken, isLoggedUser } from '../../redux/selectors';
import { UserService } from '../../services';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { connect } from "react-redux";
import { setCatalogAuthorizationHeader } from '../../utils/NewCatalogAPI';
import BillingAddress from '../../components/Orders/BillingAddress';
import { FourthShiftService } from '../../services';
import Spinner from '../../components/Spinner';
import { ShoppingCartContext } from '../../components/ShoppingCartMenuComponent';
import CreditCardPayment from '../../components/Orders/CreditCardPayment';
import BillingInfo from '../../components/Orders/BillingInfo';
import ShippingServiceModal from '../../components/Orders/ShippingServices';
import ReactGA from 'react-ga4';
import { PERMISSIONS } from '../../utils/constants';
import AddressDetailsSimple from '../../components/AddressDetailsSimple';
import { setFourthshiftAuthorizationHeader } from '../../utils/FourthShiftAPI';

const OrderCheckout = (props) => {
    
    let { orderGuid } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [OrderGuid, setOrderGuid] = useState(null);
    const [OrderDetail, setOrderDetail] = useState(null);
    const [OrderSubTotal, setOrderSubTotal] = useState(null);
    const [OrderTotalItems, setOrderTotalItems] = useState(null);
    const [OrderShippingTotal, setOrderShippingTotal] = useState(null);
    const [OrderTotal, setOrderTotal] = useState(null);
    const [PartnerInfo, setPartnerInfo] = useState(null);
    const [ShipToInfo, setShipToInfo] = useState(null);
    const [DataLoading, setDataLoading] = useState(true);
    const [ShowSpinnerShipping, setShowSpinnerShipping] = useState(false);
    const [ShowSpinnerShippingCost, setShowSpinnerShippingCost] = useState(false);
    const [PayButtonEnabled, setPayButtonEnabled] = useState(false);
    const [show, setShow] = useState(false);
    const [showCreditCardPayment, setShowCreditCardPayment] = useState(false);
    const [showShippingServices, setShowShippingServices] = useState(false);
    const [showBillingInfo, setShowBillingInfo] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloseCC = () => setShowCreditCardPayment(false);
    const handleCloseBillingInfo = () => setShowBillingInfo(false);
    const handleCloseShippingServices = () => setShowShippingServices(false);
    const [refreshCart, setRefreshCart] = useContext(ShoppingCartContext);
    const [UserInfo, setUserInfo] = useState(null);
    const [PaymentType, setPaymentType] = useState(null);
    const [NewPaymentType, setNewPaymentType] = useState(null);
    const [ShowPaymentProcessingSpinner, setShowPaymentProcessingSpinner] = useState(false);
    const [CalculateShipping, setCalculateShipping] = useState(false);
    const [FreeShipping, setFreeShipping] = useState(false);
    const [ShippingServices, setShippingServices] = useState([]);
    const [SelectedShippingService, setSelectedShippingService] = useState(null);
    const [PONumber, setPONumber] = useState(null);
    const [InvalidOrder, setInvalidOrder] = useState(null);

    const [ShowModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [variant, setVariant] = useState("");
    const [newShipTo, setNewShipTo] = useState("");
    const [RestrictedItems, setRestrictedItems] = useState([]);

    const [CheckShippingFlag, setCheckShippingFlag] = useState(false);
    const [HasShippingError, setHasShippingError] = useState(false);
    const [CheckHazmatFlag, setCheckHazmatFlag] = useState(false);
    const [HasHazmatError, setHasHazmatError] = useState(false);

    const [DiscountCode, setDiscountCode] = useState("");
    const [Promotion, setPromotion] = useState(null);
    const [ApplyingDiscountSpinner, setApplyingDiscountSpinner] = useState(false);
    const [Discount, setDiscount] = useState(null);
    const [OrderTotalNoDiscount, setOrderTotalNoDiscount] = useState(null);
    const [SitewidePromotions, setSitewidePromotions] = useState([]);
    const [showPromoAlert, setShowPromoAlert] = useState(false);
    const [promoMessage, setPromoMessage] = useState("");
    

    const history = useHistory();

    useEffect(() => {
        if (props.loggedUser)
        {
            setAuthorizationHeader(props.userToken);
            setCatalogAuthorizationHeader(props.userToken);
            setFourthshiftAuthorizationHeader(props.userToken);
        }
        else{
            history.push("/user/login");
        }
 
    }, [props.userToken]);

    useEffect(() => {
        console.log("orderCheckout.useEffect.CheckShipping.CheckHazmat:", CheckShippingFlag, CheckHazmatFlag, HasShippingError, HasHazmatError);
        setShowSpinnerShippingCost((CheckShippingFlag==false));

        if (CheckShippingFlag && CheckHazmatFlag && !HasShippingError && !HasHazmatError)
        {
            setPayButtonEnabled(true);
        }
        else {
            setPayButtonEnabled(false);
        }
    }, [CheckShippingFlag, CheckHazmatFlag]);

    useEffect(() => {
        if (newShipTo.length > 0)
            getAddressStatus(newShipTo);
 
    }, [newShipTo]);

    // useEffect(() => { !USED FOR PROMOTIONS!
    //     if (Discount != null)
    //         setOrderTotal((OrderTotalNoDiscount - Discount).toFixed(2));
    //     else if (Discount == null && OrderTotalNoDiscount != null)
    //         setOrderTotal(OrderTotalNoDiscount);           
    // }, [Discount]);


    useEffect(() => {
        console.log("OrderDetail.useEffect2.orderGuid:", orderGuid);
        console.log("OrderDetail.useEffect2.UserInfo:", UserInfo);

        if (orderGuid?.length>0)
        {
            setDataLoading(true);
            setOrderGuid(orderGuid);
            getOrder(orderGuid);
        }

   }, [orderGuid]);

//    useEffect(() => { !USED FOR PROMOTIONS!
//         console.log("Checking sitewide");
//         console.log("SWP: ", SitewidePromotions);
//         if (SitewidePromotions.orderPromotions != null && SitewidePromotions.orderPromotions.length > 0){
//             if (SitewidePromotions.orderPromotions[0].discountValue != null)
//                 setPromotion(SitewidePromotions.orderPromotions[0]);     
//         }
                  
//    }, [SitewidePromotions])

   useEffect(() => {
        console.log("useEffect: OrderDetail:");
        if (OrderDetail!=null)
        {
            if (UserInfo==null)
            {
                UserService.getUserInfo().then(user => {
                    console.log("orderCheckout.userInfo.get.response: ", user.data.data);
                    if (user.status == 200)
                        setUserInfo(user.data.data);
    
                });
            }

            setInvalidOrder(false);
            if (OrderDetail?.orderStatusDesc !== "Cart"){
                setInvalidOrder(true);
                console.log("setInvalidOrder: true");
            }   

            if (OrderDetail.shippingServiceDetail && OrderDetail.shippingServiceDetail.length>0)
                setSelectedShippingService(JSON.parse(OrderDetail.shippingServiceDetail))

            setItemData(OrderDetail.orderItems?.items);

            setOrderSubTotal(OrderDetail.subTotal.toFixed(2));

            let itemCountLabel = " items";
            if (OrderDetail.itemCount==1)
                itemCountLabel = " item";

            setOrderTotalItems("(" + OrderDetail.itemCount + itemCountLabel + ")");

            setPartnerInfo(OrderDetail.partner);

            setPaymentType(OrderDetail.paymentTypeId);

            setPONumber(OrderDetail.customerPONumber);
            
            setDataLoading(false);

        }
    }, [OrderDetail])

    useEffect(() => {
        if (UserInfo!=null)
        {
            console.log("OrderDetail?.shipToId", OrderDetail?.shipToId);
            setShowSpinnerShipping(true);
            //Calculate Shipping
            checkShipping();

            if (OrderDetail?.shipToId==null)
                saveAddress(OrderGuid, '000000'); //set default shipTo
            else
                getAddress(OrderDetail.shipToId);

            if (OrderDetail.pendingShipToId != null)
                getAddressStatus(OrderDetail.pendingShipToId);
        }
    }, [UserInfo])

    // useEffect(() => { !USED FOR PROMOTIONS!
    //     if (Promotion != null)
    //         applyDiscount();
    // }, [Promotion])

    useEffect(() => {
        console.log("OrderCheckout.CalculateShipping.ShipToInfo", CalculateShipping, ShipToInfo);
        if (CalculateShipping && ShipToInfo)
        {
            console.log("OrderCheckout.CalculateShipping:getShippingEstimate");
            setCheckShippingFlag(false);
            //setPayButtonEnabled(false);
            CatalogService.getShippingEstimate(orderGuid, 
                        ShipToInfo.shipToZip,
                        ShipToInfo.shipToState,
                        ShipToInfo.shipToCity,
                        (ShipToInfo.addressType=="R")
                        ).then(res=>{
                            //TODO:  temporary Fed Ex failure
                            //res.data.data = {"status":{"success":false,"messages":["No shipping services available.","FedEx rates error - FedEx service is not currently available to this origin / destination combination. Enter new information or contact FedEx Customer Service."]},"services":[{"status":{"success":false,"messages":["FedEx rates error - FedEx service is not currently available to this origin / destination combination. Enter new information or contact FedEx Customer Service."]},"carrier":"FedEx","service":"Ground","value":0,"currency":null,"weight":0,"weightUnit":null,"rate":0,"listRate":0,"delivery":null,"packing":null},{"status":{"success":false,"messages":["FedEx rates error - FedEx service is not currently available to this origin / destination combination. Enter new information or contact FedEx Customer Service."]},"carrier":"FedEx","service":"Ground Home Delivery","value":0,"currency":null,"weight":0,"weightUnit":null,"rate":0,"listRate":0,"delivery":null,"packing":null}]}
                            //console.log("getShippingEstimate:res", res);
                            console.log("OrderCheckout.CalculateShipping:getShippingEstimate:res", res.data.data);

                            if (res.status === 200 && 
                                res.data.data.status.success == true && 
                                res.data.data.services && 
                                res.data.data.services.length>0)
                            {
                                setShippingServices(res.data.data.services);
                                setSelectedShippingService(res.data.data);
                                checkHazmatRestrictions(OrderDetail.orderId);
                            }
                            else {
                                let _error = "";
                                if (res.data.data?.status?.messages && res.data.data?.status?.messages.length>0)
                                    _error = res.data.data.status.messages.toLocaleString();

                                console.log("OrderCheckout.CalculateShipping:getShippingEstimate:err", _error);
                                CatalogService.updateShippingDetail(orderGuid, JSON.stringify(res.data.data));
                                handleShippingEstimateFailure(_error);     
                            }          
                        }).catch((err) => {
                            console.log("Shipping Estimate Failure:", err.response);
                            let _error = "";
                            if (err.response?.data?.title)
                                _error = err.response?.data?.title;

                            handleShippingEstimateFailure(_error);
                        })
        }
        else {
            if (ShipToInfo){
                console.log("OrderCheckout.ShipToInfo 2", ShipToInfo);
                checkHazmatRestrictions(OrderDetail.orderId); 
                // if (Promotion != null){ !USED FOR PROMOTIONS!
                //     if (Promotion.discountCode != null){
                //         console.log("Getting by code" + Promotion.discountCode);
                //         getPromotionByDiscountCode(Promotion.discountCode);
                //     }
                //     else {
                //         console.log("GETTING PROMOTIONS SITEWIDE");
                //         getPromotionsSiteWide();
                //     }                                    
                // }          
            }
                      
        }
        
    }, [CalculateShipping, ShipToInfo])

    useEffect(() => {
        console.log("OrderCheckout.FreeShipping: ", FreeShipping);
        if (FreeShipping)
        {
            let _free = {
                "services": [{
                    "status": {
                        "success": true,
                        "messages": null
                    },
                    "carrier": "Free",
                    "service": "Shipping",
                    "rate": 0,
                }],
                "status": {
                    "messages": null,
                    "success": true
                }               
            }
            setSelectedShippingService(_free);
        }
            
    }, [FreeShipping])

    useEffect(() => {

        if (SelectedShippingService)
        {
            let _selectedShippingService = SelectedShippingService;
            console.log("OrderCheckout.SelectedShippingService: ", _selectedShippingService);

            //TODO:  check this
            if (OrderDetail.pendingShipToId == null && RestrictedItems.length == 0)
                setShowAlert(false);

            if (_selectedShippingService?.status?.success==true)
            {
                //pre-select the cheapest rate
                var service;
                if (_selectedShippingService.services)
                {
                    service = _selectedShippingService.services.filter(f => f.status.success == true).reduce(function(prev, curr) {
                        return prev.rate < curr.rate ? prev : curr;
                    });
                }
                else{
                    service = _selectedShippingService;
                }

                console.log("OrderCheckout.SelectedShippingService.Service: ", service);
                let _selectedCarrier = service.carrier+"."+service.service;
                let _selectedRate = service.rate;
                let _shippingServiceDetail = JSON.parse(OrderDetail.shippingServiceDetail);
                let _orderCarrier = "";
                let _orderRate = null;

                if (_shippingServiceDetail){
                    _orderCarrier = _shippingServiceDetail.carrier+"."+_shippingServiceDetail.service;
                    _orderRate = _shippingServiceDetail.rate;
                }

                console.log("OrderCheckout.SelectedShippingService: check: ", _selectedCarrier, _orderCarrier);
                console.log("OrderCheckout.SelectedShippingService.Rate: check:  ", _selectedRate, _orderRate);

                setOrderShippingTotal(service.rate.toFixed(2));
                setCheckShippingFlag(true);                
                //setPayButtonEnabled(true);

                if (_selectedCarrier != _orderCarrier || 
                    _selectedRate != _orderRate)
                {
                    CatalogService.updateShippingDetail(OrderGuid, JSON.stringify(_selectedShippingService), _selectedRate).then(response => {
                        console.log("orderCheckout.useEffect.SelectedShippingService:res:");
            
                        if (response.status === 200) {
                            if (response.data.data)
                            {
                                //handlePromotionUpdate(); !USED FOR PROMOTIONS!
                                return true;
                                
                            }
                        }
                    }).catch(function (error) {
                        console.log("orderCheckout.useEffect.SelectedShippingService.Error:");
                        console.log(error);
                        //alert("Error saving Shipping Service.")
                        return error;
                    });
                }
                else {
                    //handlePromotionUpdate(); !USED FOR PROMOTIONS!    
                }
            }
            else{
                //set error message
                handleShippingEstimateFailure();
                return false;
            }
        }
            
    }, [SelectedShippingService])

    useEffect(() => {
        console.log("OrderCheckout.OrderShippingTotal: ", OrderShippingTotal);
        
        if (OrderShippingTotal)
        {
            let _totalPrice = parseFloat(OrderDetail.subTotal);
            let _shipping = parseFloat(OrderShippingTotal);
            let _orderTotal = (_totalPrice + _shipping).toFixed(2);
            setOrderTotal(_orderTotal); 
            setOrderTotalNoDiscount(_orderTotal);
            //applyDiscount();
        }
            
    }, [OrderShippingTotal])

    const checkShipping = () =>{
        console.log("orderCheckout.checkShipping");
        setCheckShippingFlag(false);
        if (UserInfo?.PartnerHdrShipThreshold && OrderDetail?.subTotal>0)
        {
            //setPayButtonEnabled(false);
            if (UserInfo?.PartnerHdrShipThreshold>0 && (UserInfo?.PartnerHdrShipThreshold<=OrderDetail.subTotal))
                setFreeShipping(true);
            else 
                setCalculateShipping(true);
        }
    }

    const applyDiscount = () => {
        console.log("orderCheckout.applyDiscount.Promotion: ", Promotion);
        console.log("orderCheckout.applyDiscount.OrderTotalNoDiscount: ", OrderTotalNoDiscount);
              
        if (Promotion != null){
            setShowPromoAlert(false);
            setPromoMessage("");

            if (Promotion.discountValue != null) 
            {
                setDiscount(Promotion.discountValue.toFixed(2));
                console.log("Discount: ", Promotion.discountValue);
                setApplyingDiscountSpinner(false);
                setDiscountCode("");
            }
            else 
            {
                setPromotion(null);
                setApplyingDiscountSpinner(false);
                setDiscountCode("");
                setDiscount(null);
                if (Promotion.promotionTypeId < 3) //Don't show alerts for sitewide promotions
                {
                    if (!Promotion.canUse)
                        setPromoMessage("You have already redeemed this discount code.");
                    else
                        setPromoMessage("This order does not meet the minimum cost requirement to apply this discount.");
                    setShowPromoAlert(true);
                }               
            }            
        }
    }

    const saveAddress = (orderGuid, shipToId) => {
        
        console.log("orderCheckout.saveAddress", shipToId);
        setShow(false);
        setShowAlert(false);
        setShowSpinnerShipping(true);

        return CatalogService.updateOrderShipTo(orderGuid, shipToId).then(response => {
            console.log("orderCheckout.saveAddress.Response:", response);

            if (response.status === 200) {
                if (response.data.data)
                {
                    console.log(response.data.data);
                    getAddress(response.data.data);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("orderCheckout.saveAddress.Error:");
            console.log(error);
            return error;
        });
        
    }

    const savePONumber = (poNumber) => {
        
        console.log("orderCheckout.savePONumber", poNumber);
        return CatalogService.savePONumber(OrderGuid, poNumber).then(response => {
            console.log("orderCheckout.savePONumber.Response:", response);

            if (response.status === 200) {
                if (response.data.data)
                {
                    console.log(response.data.data);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("orderCheckout.savePONumber.Error:");
            console.log(error);
            return error;
        });
        
    }

    const getAddress = (id) => {
        
        console.log("orderCheckout.getAddress", id);
        setHasShippingError(false);

        return FourthShiftService.getAddress(UserInfo?.PartnerHdrCustId, id).then(response => {
            console.log("orderCheckout.getAddress.Response:");

            if (response.status === 200) {
                if (response.data.data)
                {
                    console.log(response.data.data);
                    setShipToInfo(response.data.data);
                    setShowSpinnerShipping(false);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("orderCheckout.getAddress.Error:");
            console.log(error);
            setShowSpinnerShipping(false);
            handleShippingEstimateFailure("Error retrieving address.");
            return error;
        });
        
    }

    const getAddressStatus = (_newShipTo) => {
        CatalogService.getAddressStatus(OrderDetail.orderId, UserInfo.PartnerHdrCustId)
        .then(res => {
            console.log("getAddressStatus: ", res);
            if (res.data.data)
            {
                if (_newShipTo.length>0)
                    saveAddress(OrderGuid, _newShipTo);

                setMessage("New address added successfully!");
                setVariant("success");
            }
            else{
                setMessage("A new address is currently being processed.");
                setVariant("danger");
            }
            setShowAlert(true);
        });
    }

   const getOrder = (gId) => {
        console.log("OrderDetail.getOrder");
        CatalogService.getOrder(gId).then(response => {
            console.log("OrderDetail.getOrder.Response:");

            if (response.status === 200) {
                if (response.data.data)
                {
                    console.log(response.data.data);
                    setOrderDetail(response.data.data);

                    return true;
                }
            }
        }).catch(function (error) {
            console.log("OrderDetail.getOrder.Error:");
            console.log(error);
            return error;
        });
    }

    const handlePaymentButtonClick = (e) => {
        e.preventDefault();
        console.log("orderCheckout.handlePaymentButtonClick: ", PaymentType);
        if (process.env.REACT_APP_ENV == "Dev" && PaymentType==1)
        {
            if (PONumber !== null && PONumber.length >= 1) 
                savePONumber(PONumber);
            console.log("orderCheckout.handlePaymentButtonClick: Dev");
            history.push("/order/orderDetail/" + OrderDetail.orderGuid + "/success");
            ReactGA.event({
                category: 'User',
                action: 'payment_button_click'
            });
        } 
        else 
        {
            if (PaymentType==2)
                handleCompletePaymentClick(e);
            else
                setShowCreditCardPayment(true);
                
            if (PONumber !== null && PONumber.length >= 1) 
                savePONumber(PONumber);
            
            ReactGA.event({
                category: 'User',
                action: 'payment_button_click'
            });  
        } 
    }

    const handleCompletePaymentClick = (e) => {
        console.log("orderCheckout.handlePurchase: ", OrderGuid);
        setShowPaymentProcessingSpinner(true);
        
        e.preventDefault();

        //TODO: disable button to prevent double click

        if (OrderGuid != null)
        {
            //Moving to Status 13:  Charge on Account
            CatalogService.updateOrderStatus(OrderGuid, 13).then(response => {
                console.log("orderCheckout.handlePurchase.Response:");

                if (response.status === 200) {
                    if (response.data.data)
                    {
                        setRefreshCart();
                        history.push("/order/orderDetail/" + OrderGuid + "/success");  
                        return true;
                    }
                }
            }).catch(function (error) {
                console.log("orderCheckout.handlePurchase.Error:");
                console.log(error);
                return error;
            });
            
        }
    }
    
    const handleShippingServiceSave = (e) => {
        e.preventDefault();
        handleCloseShippingServices();
    }

    const handleShippingEstimateFailure = (errMessage="") => {
        console.log("orderCheckout.handleShippingEstimateFailure", errMessage);
        let _message = "Shipping Error:  Shipping service unavailable."
        if (errMessage.length>0)
            _message = "Shipping Error: " + errMessage;

        setMessage(_message);
        setVariant("danger");
        setShowAlert(true);
        setOrderShippingTotal(null);
        setHasShippingError(true);
        setCheckShippingFlag(true);
        
    }

    const handleNewShippingService = (s) => {
        let _service = ShippingServices.filter(f => (f.carrier+"."+f.service)==s);
        console.log("handleNewShippingService: ", _service[0]);
        setSelectedShippingService(_service[0]);
    }

    const handleAddAddress = () => {
        setShowModal(true);
        setShow(false);
    }

    const handleAddAddressClose = (shipToId) => {
        console.log("orderCheckout.handleAddAddressClose: shipToId", shipToId);
        if (shipToId != null && shipToId.length > 0)
            setNewShipTo(shipToId);

        setShowModal(false);
        setShow(false);
    }

    const handleAddressSave = (e, addressId) => {
        e.preventDefault();

        console.log("handleShippingInfo.modal.save:", addressId);
        saveAddress(OrderGuid, addressId);
    }

    const handleBillingInfoSave = (e) => {
        e.preventDefault();
        console.log("handleBillingInfoSave.modal.save:");

        if (NewPaymentType!=PaymentType)
        {
            console.log("handleBillingInfoSave:save:");
            
            CatalogService.updatePaymentType(OrderGuid, NewPaymentType).then(response => {
                console.log("orderCheckout.handleBillingInfoSave.Response:");

                if (response.status === 200) {
                    if (response.data.data)
                    {
                        setPaymentType(NewPaymentType);
                        return true;
                    }
                }
            }).catch(function (error) {
                console.log("orderCheckout.handleBillingInfoSave.Error:");
                console.log(error);
                return error;
            });
        }

        setShowBillingInfo(false);
    }

    const checkHazmatRestrictions = (orderId) => {
        setHasHazmatError(false);
        setRestrictedItems([]);
        setCheckHazmatFlag(false);

        CatalogService.checkHazmatRestrictions(orderId).then(res => {

            let _restrictedItems = res.data.data;
            console.log("orderCheckout.checkHazmatRestrictions: ", _restrictedItems);
            
            if (_restrictedItems.length > 0) {
                let _restricted = _restrictedItems.map(function(i, index){
                    if (index == 0)
                        return i.sale_Item;
                    return " " + i.sale_Item;
                });
                setRestrictedItems(_restrictedItems);  
                setHasHazmatError(true);
                handleShippingEstimateFailure("This item(s) is restricted and can not ship by air to your location. Please choose a different shipping location." + " (" + _restricted + ")");
            }  
            setCheckHazmatFlag(true);  
                               
        });       
    }

    function isObject(val) {
        if (val === null) { return false;}
        return ( (typeof val === 'function') || (typeof val === 'object') );
    }

    const getPromotionByDiscountCode = (discountCode) => {
        setApplyingDiscountSpinner(true);
        if (isObject(discountCode)){
            console.log(DiscountCode);
            discountCode = DiscountCode;
        }
            
        try {
            CatalogService.getPromotionByDiscountCode(discountCode, OrderDetail.orderGuid).then(res => {
                if (res.status == 200 && res.data.data.promotionId > 0){
                    console.log("Got Promotion:");
                    console.log(res.data.data);
                    setPromotion(res.data.data);
                }
                else {
                    setApplyingDiscountSpinner(false);
                    setDiscountCode("");
                    setDiscount(null);
                    setPromotion(null);
                }
            });
        }
        catch {
            setApplyingDiscountSpinner(false);
        }
    }

    const getPromotionsSiteWide = () => {
        CatalogService.getPromotionsSiteWide(OrderDetail.orderGuid).then(res => {
            if (res.status == 200){
                console.log("Got: ", res.data.data);
                setPromotion(res.data.data);
            }
        });
    }

    const handlePromotionUpdate = () => {
        if (Promotion != null){
            if (Promotion.discountCode != null){
                getPromotionByDiscountCode(Promotion.discountCode);
            }
            else {;
                getPromotionsSiteWide();
            }                                    
        }
        else {
            getPromotionsSiteWide();
        }
    }
      
    return (
        <div className="container">
            {
            InvalidOrder==false ?
            <div>
            <hr/>
            <div className="col-lg-12 row">
                <Link to={"/order/orderCart/"+OrderGuid}><FontAwesomeIcon icon={faArrowLeft} className="fa-1x" /> Back to Cart</Link>
            </div>
            <div className="row">
                <div className="col-lg-12">

                    <div className=''>
                        <Card>
                            <Card.Body>
                                {/* <Card.Title>Order Detail</Card.Title> */}
                                <div className='row pb-3'>
                                    <div className="col-lg-4">
                                        <Card>
                                            <Card.Body className='pb-1'>
                                            <Card.Title>Order Summary</Card.Title>
                                                <div className='small'>
                                                    <Table borderless size="sm">
                                                        <tr>
                                                            <td className='p-0'>Items:</td>
                                                            <td className='p-0'>${OrderSubTotal} {OrderTotalItems}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='p-0 align-middle'>
                                                                {/* {FreeShipping ? null : <span><a onClick={(e)=>setShowShippingServices(true)} type="button" className='text-primary'><FontAwesomeIcon icon={faEdit} className="fa-1x" /></a>&nbsp;</span>}  */}
                                                                Shipping:
                                                            </td>
                                                            <td className='p-0 align-middle'>{ ShowSpinnerShippingCost ? <div style={{ padding: '0px'}}>
                                                                <Spinner displaySize = "14px" animation="border" size="sm" showSpinner={ShowSpinnerShippingCost}/>
                                                            </div>
                                                            :
                                                            FreeShipping ? <label className='text-muted m-0'>Free Shipping</label> : 
                                                                    <div className=''>
                                                                        ${OrderShippingTotal}&nbsp;
                                                                        {/* {SelectedShippingService ? <a href="#" onClick={(e)=>setShowShippingServices(true)} className=' text-muted'>({SelectedShippingService.carrier} {SelectedShippingService.service})</a> : null} */}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        { Promotion ? 
                                                            <tr>
                                                                <td className='p-0'>Promotion active:</td>
                                                                <td className='p-0 text-success'>{Promotion.promotionName}</td>
                                                            </tr>
                                                            : 
                                                            null
                                                        }
                                                        { Discount ?
                                                        <tr>                                       
                                                            <td className='p-0'>Discount:</td>
                                                            <td className='p-0 text-danger'>-${Discount}</td>
                                                        </tr>
                                                        :
                                                        null
                                                        }
                                                        <tr>
                                                            <td className='p-0'><b>Order Total:</b></td>
                                                            <td className='p-0'><b>${OrderTotal}</b></td>
                                                        </tr>
                                                    </Table>
                                                    <div className='text-center pt-2'>
                                                        {ShowPaymentProcessingSpinner ?
                                                            <div style={{ padding: '0px' }}>
                                                                <Spinner displayText={"Processing...."} showSpinner={ShowPaymentProcessingSpinner}/>
                                                            </div>
                                                            :
                                                            PayButtonEnabled ?
                                                            <Button size="sm"  block onClick={(e) => handlePaymentButtonClick(e)}>{PaymentType==2?"Charge on Account":"Pay with Credit Card"}</Button>
                                                            :
                                                            <Button size="sm" disabled="disabled" block >{PaymentType==2?"Charge on Account":"Pay with Credit Card"}</Button>
                                                        }
                                                    </div>
                                                    <p className='small text-muted m-0 pt-1'>
                                                        {OrderDetail?.createdBy}
                                                        <br /><div className='small'>{new Date(OrderDetail?.updateDate).toLocaleString("en-US")}</div>
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4">
                                        <Card>
                                            <Card.Body className="pb-4">
                                                <Card.Title>Shipping To
                                                    <div className='float-right' >
                                                        <a onClick={(e)=>setShow(true)} type="button" className='text-primary'><FontAwesomeIcon icon={faEdit} className="fa-1x" /></a>
                                                    </div>
                                                </Card.Title>
                                                {ShowSpinnerShipping ?
                                                <div style={{ padding: '24px' }}>
                                                    <Spinner showSpinner={ShowSpinnerShipping}/>
                                                </div>
                                                :
                                                <div>
                                                    <address className='small'>
                                                        {ShipToInfo?.shipToName!=null?<div>{ShipToInfo?.shipToName}<br /></div>:""}   
                                                        {ShipToInfo?.shipToAddress1!=null && ShipToInfo?.shipToAddress1.length>0?<div>{ShipToInfo?.shipToAddress1}<br /></div>:""}
                                                        {ShipToInfo?.shipToAddress2!=null && ShipToInfo?.shipToAddress2.length>0?<div>{ShipToInfo?.shipToAddress2}<br /></div>:""}
                                                        {ShipToInfo?.shipToCity!=null && ShipToInfo?.shipToCity.length>0?
                                                        <div>{ShipToInfo?.shipToCity}, {ShipToInfo?.shipToState} {ShipToInfo?.shipToZip}<br /></div>:""
                                                        }
                                                    </address>
                                                    <p className='m-0 pt-1 small'>
                                                        <input type="text" placeholder='Optional PO Number' value={PONumber} onInput={e => setPONumber(e.target.value)} className='form-control form-control-sm' ></input>
                                                    </p>
                                                </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4">
                                        <Card>
                                            <Card.Body>
                                            <Card.Title>Billing Info                                                     
                                            </Card.Title>
                                                <address className='small'>
                                                {PartnerInfo?.partnerName!=null?<div>{PartnerInfo?.partnerName}<br /></div>:""}   
                                                    {PartnerInfo?.shipAddress1!=null?<div>{PartnerInfo?.shipAddress1}<br /></div>:""}
                                                    {PartnerInfo?.shipAddress2!=null?<div>{PartnerInfo?.shipAddress2}<br /></div>:""}
                                                    {PartnerInfo?.shipCity}, {PartnerInfo?.shipState} {PartnerInfo?.shipZip}<br />
                                                    {PartnerInfo?.phoneNo!=null?<div>{PartnerInfo?.phoneNo}<br /></div>:""}
                                                </address>
                                                <div className='p-0 small col-sm-12'>
                                                    <p className='text-danger'><a onClick={(e)=>setShowBillingInfo(true)} type="button" className='text-primary'><FontAwesomeIcon icon={faEdit} className="fa-1x" /></a>&nbsp;
                                                    {PaymentType==2 ? 
                                                        "Charge on Account"
                                                        : "Paying with Credit Card"
                                                    }
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                                {/* <div className="row"> !USED FOR PROMOTIONS!
                                    <div className="col-lg-4">
                                        <input type="text" placeholder='Enter Discount Code' value={DiscountCode} onInput={e => setDiscountCode(e.target.value)} className='form-control form-control-sm my-2 w-50' ></input>
                                        {ApplyingDiscountSpinner ? <Spinner displaySize = "14px" animation="border" size="sm" displayText="Applying..." showSpinner={ApplyingDiscountSpinner}/>
                                        : 
                                        <Button classname="btn-sm" disabled={DiscountCode == null || DiscountCode == "" || ShowSpinnerShippingCost} onClick={getPromotionByDiscountCode}>Apply</Button>
                                        }
                                        
                                    </div>
                                </div> */}
                                <Fragment>
                                    <OrderItems dataLoading={DataLoading} editMode={false} itemData={ItemData} showQuickAdd={false} showSearch={false} showInventoryQty={false}></OrderItems>
                                </Fragment>
                            </Card.Body>
                        </Card>
                        <Alert className="mt-2 my-2" show={showAlert} variant={variant}>
                        {message}
                    </Alert>
                    </div>
                </div>
            </div>
            <br></br>
            <Modal show={ShowModal} onHide={handleAddAddressClose} className="" size="xl">
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Add Address</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-2'>
                    <AddressDetailsSimple 
                        custId={UserInfo?.PartnerHdrCustId}
                        handleAddAddressClose={(shipToId) => handleAddAddressClose(shipToId)}
                        //newShipTo={handleSetNewShipTo}
                        orderId={OrderDetail.orderId}
                    />
                </Modal.Body>
            </Modal>
            
            <Modal show={show} onHide={handleClose} className="" size="xl">
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Ship To Address</Modal.Title>
                    <Alert className="ml-5 my-0" show={showAlert} variant={variant}>
                        {message}
                    </Alert>
                </Modal.Header>
                <Modal.Body className='py-0'>
                    <BillingAddress handleAddAddress={() => handleAddAddress()} addAddressPermission={UserInfo?.Permissions.some(x => x.PermissionGroupsId===PERMISSIONS.AddShippingAccess)} handleAddressSave={handleAddressSave} custId={UserInfo?.PartnerHdrCustId}></BillingAddress>
                </Modal.Body>
            </Modal>
            
            <Modal show={showBillingInfo} onHide={handleCloseBillingInfo} className="" size="">
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Billing Info</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-0'>
                    <BillingInfo paymentType={PaymentType} newPaymentType={(p)=>setNewPaymentType(p)}></BillingInfo>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className='float-left'><Button size="sm" variant='danger' onClick={handleCloseBillingInfo}>Cancel</Button></div>
                        <div className='float-right'><Button size="sm" onClick={handleBillingInfoSave}>Save</Button></div>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showShippingServices} onHide={handleCloseShippingServices} className="" size="lg">
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Shipping Services</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-0'>
                    <ShippingServiceModal shippingServices={ShippingServices} 
                                        selectedService={SelectedShippingService?.carrier+"."+SelectedShippingService?.service}
                                        newSelectedService={handleNewShippingService}
                                        ></ShippingServiceModal>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className='float-left'><Button size="sm" variant='danger' onClick={handleCloseShippingServices}>Cancel</Button></div>
                        <div className='float-right'><Button size="sm" onClick={handleShippingServiceSave}>Save</Button></div>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={showCreditCardPayment} onHide={handleCloseCC} className="" size="">
                <Modal.Header closeButton className="py-2">
                    <Modal.Title>Credit Card Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-0'>
                    <CreditCardPayment custId={UserInfo?.PartnerHdrCustId} orderTotal={OrderTotal} orderDetail={OrderDetail}></CreditCardPayment>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <div className='float-right'><Button size="sm" variant='primary' onClick={handleCloseCC}>Cancel</Button></div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* <div> !USED FOR PROMOTIONS!
                <Alert show={showPromoAlert} variant='danger'>
                        {promoMessage}
                </Alert>
            </div> */}
            </div>
            :
            InvalidOrder ? 
            <div className='text-center'>
                <Alert variant='primary'>This is an invalid order for checkout.</Alert>
            </div>
            :
            <div></div>
        }
        </div>
    )
};
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    const loggedUser = isLoggedUser(state);
    return { userToken, loggedUser };
};
export default connect(mapStateToProps)(OrderCheckout);