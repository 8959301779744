import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { getLoggedUserId } from "../../redux/selectors";
import { renamePickList } from "../../redux/actions";
import { UserService } from '../../services';

const PickListRename = ({ pickListId, pickListTitle, callBackFunction, loggedUserId, renamePickList }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [pickListName, setPickListName] = useState(pickListTitle);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setPickListName(pickListTitle);
    }, [pickListId, pickListTitle])

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);

    const handleOnChangePickListName = (value) => {
        setPickListName(value);
    }

    const handleRenamePickList = () => {
        if (pickListName !== "") {
            if (loggedUserId) {
                UserService.renamePickList(pickListId, loggedUserId, pickListName)
                .then(res => {
                    if (res.status === 200) {
                        handleClose();
                        callBackFunction(pickListId);
                    }
                }).catch(function(error) {
                    console.log(error);
                });
            } else {
                renamePickList(pickListId, pickListName);
                handleClose();
                callBackFunction(pickListId);
            }
        } else {
            setErrorMessage("Name is mandatory field");
        }
    }

    return (
        <Fragment>
            <button className="btn btn-secondary" onClick={handleShow} title="Rename pick list" ><FontAwesomeIcon icon={faPencilAlt} className="go-to-category" /></button>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Rename pick List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="form-group">
                        <label>Pick list name</label>
                        <input type="text" className={errorMessage == null ? 'form-control' : 'form-control is-invalid'} value={pickListName} onChange={(e) => handleOnChangePickListName(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleRenamePickList}>
                    Save
                </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = state => {
    const loggedUserId = getLoggedUserId(state);
    return { loggedUserId };
}

PickListRename.propTypes = {
    pickListId: PropTypes.number.isRequired,
    pickListTitle: PropTypes.string.isRequired
}

export default connect(mapStateToProps, { renamePickList })(PickListRename);