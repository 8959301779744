import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CommonService } from '../services';

const HomeHighlightProductItem = ({productId}) => {
    const [saleItem, setSaleItem] = useState()
    const [description, setDescription] = useState()
    const [images, setImages] = useState([])

    useEffect(() => {
        CommonService.getProductDetails(productId)
            .then(response => {
                if (response.status === 200) {
                    const product = response.data.Data[0]
                    setSaleItem(product.sale_item)
                    setDescription(product.descriptionShort)
                    setImages(product.imageDetails.filter(item => item.fileName.indexOf("pdf") < 0))
                }
            }).catch(function(error) {
                console.log(error);
            });
    }, [productId])
    
    return (
        <Link className="product-item" to={"/product/" + productId} >
            {images.length > 0 ?
                <figure>
                    <img src={process.env.REACT_APP_IMAGE_PATH + "/tr:h-170/" + images[0].fileName} alt={saleItem} />
                </figure>
                :
                <figure>
                    <img src="https://media.sierraparts.com/4.36ef5a05_UzbFNEPV5Q9.png?" alt={saleItem} />
                </figure>
            }
            <p>{saleItem}</p>
            <span>{description}</span>
        </Link>
    )

}

HomeHighlightProductItem.propTypes = {
    productId: PropTypes.number.isRequired
};

export default HomeHighlightProductItem;