/* eslint-disable eqeqeq */
import { PICKLIST_ADD_TO, PICKLIST_REMOVE_FROM, PICKLIST_CHANGE_QUANTITY, PICKLIST_RENAME, PICKLIST_DELETE } from "../actionTypes";

const initialState = {
    picklists: [],
    lastInsertId: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {

    switch (action.type) {
        case PICKLIST_ADD_TO: {
            console.log("Add to picklist");
            const { pickListId, pickListName, productId, description, modelNumber, quantity, imagePath, loggedUserId } = action.payload;
            let id = pickListId;
            if (id == "-1") {
                id = state.lastInsertId + 1;
            }
            console.log("Picklist ID " + id);
            let picklist = state.picklists.find(obj => obj.id == id);
            let isNewPickList = false;
            console.log("Picklist " + picklist);
            if (picklist === undefined) {
                picklist = {};
                isNewPickList = true;
                picklist.id = id;
                picklist.loggedUserId = loggedUserId;
                let title = pickListName;
                if (title === null) {
                    title = "Pick list " + new Date().toLocaleString("en-US");
                }
                picklist.title = title;
                picklist.products = [];
            }
            let product = picklist.products.find(obj => obj.productId == productId);
            console.log("Product " + product);
            if (product === undefined) {
                product = {};
                product.productId = productId;
                product.description = description;
                product.modelNumber = modelNumber;
                product.quantity = parseInt(quantity);
                product.imagePath = imagePath;
                picklist.products = [...picklist.products, product];
            } else {
                product.quantity = parseInt(product.quantity) + parseInt(quantity);
            }
            console.log("Is new picklist: " + isNewPickList);
            console.log("Save to state: " + picklist.products.length);
            if (isNewPickList) {
                return {
                    ...state,
                    picklists: [...state.picklists, picklist],
                    lastInsertId: id
                };
            }
            return {
                ...state
            }
        }
        case PICKLIST_CHANGE_QUANTITY: {
            const { pickListId, productId, quantity } = action.payload;
            let picklist = state.picklists.find(obj => obj.id == pickListId);
            if (picklist !== undefined) {
                let product = picklist.products.find(obj => obj.productId === productId);
                if (product !== undefined) {
                    product.quantity = quantity;
                }
            }
            return {
                ...state
            }
        }
        case PICKLIST_REMOVE_FROM: {
            const { pickListId, productId } = action.payload;
            let picklist = state.picklists.find(obj => obj.id == pickListId);
            if (picklist !== undefined) {
                const nProducts = picklist.products.filter(item => item.productId != productId);
                picklist.products = nProducts;
            }
            return {
                ...state
            };
        }
        case PICKLIST_RENAME: {
            const { pickListId, name } = action.payload;
            let picklists = state.picklists;
            let picklist = picklists.find(obj => obj.id == pickListId);
            if (picklist !== undefined) {
                let nPicklist =  picklist;
                nPicklist.title = name;
                picklists = picklists.filter(item => item.id != pickListId);
                picklists.push(nPicklist);
            }
            return {
                ...state,
                picklists: [...picklists]
            }
        }
        case PICKLIST_DELETE: {
            const { pickListId } = action.payload;
            return {
                ...state,
                picklists: [...state.picklists.filter(item => item.id != pickListId)]
            };
        }
        default:
            return state;
    }

}