import NewUserAPI from '../utils/NewUserAPI';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async submitNewEntry(entry) {
        return NewUserAPI.post('/tourney/submitentry', entry)
    },
    async getEntryByCode(entryCode) {
        return await NewUserAPI.get('/tourney/getentry/' + entryCode);
    },
    async getEntryByEmail(email) {
        return await NewUserAPI.get('/tourney/getentrybyemail?email=' + email);
    },
   

}