var metric, imperial;

metric = {
  'min/km': {
    to_anchor: 0.06
  },
  's/m': {
    to_anchor: 1
  }
}

imperial = {
  'min/mi': {
    to_anchor: 0.0113636
  },
  's/ft': {
    to_anchor: 1
  }
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 's/m', 
      ratio: 0.3048
    }, 
    imperial: {
      unit: 's/ft', 
      ratio: 1/0.3048
    }
  }
};
