var metric;

metric = {
  ppm: {
    to_anchor: 1
  }, 
  ppb: {
    to_anchor: .001
  }, 
  ppt: {
    to_anchor: .000001
  }, 
  ppq: {
    to_anchor: .000000001
  }
};

module.exports = {
  metric: metric, 
  imperial: {}, 
  _anchors: {
    metric: {
      unit: 'ppm', 
      ratio: .000001
    }
  }
};
