import React, { Fragment, useState, useEffect } from 'react';
import {Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AddressDetails, Loading } from '../../components';
import { UserService } from '../../services';
import { connect } from "react-redux";
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { logoutUser } from '../../redux/actions';

const CompanyProfile  = ({ loggedUserId, userToken, logoutUser }) => {
        const history = useHistory();

        const [loading, setLoading] = useState(true);
        const [editAllowed, setEditAllowed] = useState(false);
        const [partnerId, setPartnerId] = useState();
        const [companyName, setCompanyName] = useState("");
        const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
        const [companyWebsite, setCompanyWebsite] = useState("");
        const [companyFaxNumber, setCompanyFaxNumber] = useState("");
        const [mailingAddress1, setMailingAddress1] = useState("");
        const [mailingAddress2, setMailingAddress2] = useState("");
        const [mailingCity, setMailingCity] = useState("");
        const [mailingState, setMailingState] = useState("");
        const [mailingPostalCode, setMailingPostalCode] = useState("");
        const [mailingCountry, setMailingCountry] = useState("USA");
        const [shippingAsMailingAddress, setShippingAsMailingAddress] = useState(false);
        const [shippingAddress1, setShippingAddress1] = useState("");
        const [shippingAddress2, setShippingAddress2] = useState("");
        const [shippingCity, setShippingCity] = useState("");
        const [shippingState, setShippingState] = useState("");
        const [shippingPostalCode, setShippingPostalCode] = useState("");
        const [shippingCountry, setShippingCountry] = useState(null);

        const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");
        const [companyPhoneNumberErrorMessage, setCompanyPhoneNumberErrorMessage] = useState("");
        const [companyFaxNumberErrorMessage, setCompanyFaxNumberErrorMessage] = useState("");
        const [mailingAddress1ErrorMessage, setMailingAddress1ErrorMessage] = useState(null);
        const [mailingCityErrorMessage, setMailingCityErrorMessage] = useState(null);
        const [mailingStateErrorMessage, setMailingStateErrorMessage] = useState(null);
        const [mailingPostalCodeErrorMessage, setMailingPostalCodeErrorMessage] = useState(null);

        const [shippingAddress1ErrorMessage, setShippingAddress1ErrorMessage] = useState(null);
        const [shippingCityErrorMessage, setShippingCityErrorMessage] = useState(null);
        const [shippingStateErrorMessage, setShippingStateErrorMessage] = useState(null);
        const [shippingPostalCodeErrorMessage, setShippingPostalCodeErrorMessage] = useState(null);

        const handleChangeCompanyName = (value) => setCompanyName(value);
        const handleChangeCompanyPhoneNumber = (value) => setCompanyPhoneNumber(value);
        const handleChangeCompanyWebsite = (value) => setCompanyWebsite(value);
        const handleChangeCompanyFaxNumber = (value) => setCompanyFaxNumber(value);
        const handleChangeMailingAddress1 = (value) => setMailingAddress1(value);
        const handleChangeMailingAddress2 = (value) => setMailingAddress2(value);
        const handleChangeMailingCity = (value) => setMailingCity(value);
        const handleChangeMailingState = (value) => setMailingState(value);
        const handleChangeMailingCountry = (value) => setMailingCountry(value);
        const handleChangeMailingPostalCode = (value) => setMailingPostalCode(value);
        const handleChangeShippingAddress1 = (value) => setShippingAddress1(value);
        const handleChangeShippingAddress2 = (value) => setShippingAddress2(value);
        const handleChangeShippingCity = (value) => setShippingCity(value);
        const handleChangeShippingState = (value) => setShippingState(value);
        const handleChangeShippingPostalCode = (value) => setShippingPostalCode(value);
        const handleChangeShippingCountry = (value) => setShippingCountry(value);
        const toggleShippingAsMailingAddress = () => setShippingAsMailingAddress(!shippingAsMailingAddress);

        useEffect(() => {
                setAuthorizationHeader(userToken);
                UserService.getAccountManagementInfo(loggedUserId)
                        .then(res => {
                                if (res.status === 200) {
                                        if (res.data.results.responseId === false) {
                                                logoutUser();
                                                history.push("/user/login");
                                        } else {
                                                const data = res.data.data;
                                                const companyData = res.data.data.CompanyModelObject;
                                                setPartnerId(companyData.PartnerId);
                                                setCompanyName(companyData.PartnerName);
                                                setCompanyWebsite(companyData.URL);
                                                setCompanyPhoneNumber(companyData.PhoneNo);
                                                setCompanyFaxNumber(companyData.FaxNo);
                                                setMailingAddress1(companyData.Address1);
                                                setMailingAddress2(companyData.Address2);
                                                setMailingCity(companyData.City);
                                                setMailingState(companyData.State);
                                                setMailingCountry(companyData.Country.SelectedId);
                                                setMailingPostalCode(companyData.Zip);
                                                setShippingAddress1(companyData.ShipAddress1);
                                                setShippingAddress2(companyData.ShipAddress2);
                                                setShippingCity(companyData.ShipCity);
                                                setShippingState(companyData.ShipState);
                                                setShippingCountry(companyData.ShipCountry.SelectedId);
                                                setShippingPostalCode(companyData.ShipZip);
                                                const userData = data.UserModelObject;
                                                if (userData.Role === "Principal") {
                                                        setEditAllowed(true);
                                                }
                                        }
                                        setLoading(false);
                                }
                        }).catch(error => {
                                console.log("Error " + error);
                        });
        }, [])

        useEffect(() => {
                if (shippingAsMailingAddress === true) {
                        setShippingAddress1(mailingAddress1);
                        setShippingAddress2(mailingAddress2);
                        setShippingCity(mailingCity);
                        setShippingState(mailingState);
                        setShippingPostalCode(mailingPostalCode);
                        setShippingCountry(mailingCountry);
                }
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [shippingAsMailingAddress])

        async function submitCompanyInformation() {
                let isValid = true;
                if ((!companyName || /^\s*$/.test(companyName))) {
                        setCompanyNameErrorMessage("Please enter a company name.");
                        isValid = false;
                } else {
                        setCompanyNameErrorMessage(null);
                }
                if (!companyPhoneNumber) {
                        setCompanyPhoneNumberErrorMessage("Please enter a company phone number.");
                        isValid = false;
                } else {
                        if (/^\d+$/.test(companyPhoneNumber)) {
                                setCompanyPhoneNumberErrorMessage(null);
                        } else {
                                setCompanyPhoneNumberErrorMessage("Please enter a valid company phone number.");
                                isValid = false;
                        }
                }
                if (companyFaxNumber) {
                        if (/^\d+$/.test(companyFaxNumber)) {
                                setCompanyFaxNumberErrorMessage(null);
                        } else {
                                setCompanyFaxNumberErrorMessage("Please enter a valid company fax number.");
                                isValid = false;
                        }
                }
                if ((!mailingAddress1 || /^\s*$/.test(mailingAddress1))) {
                        setMailingAddress1ErrorMessage("Please enter a address.");
                        isValid = false;
                } else {
                        setMailingAddress1ErrorMessage(null);
                }
                if ((!mailingCity || /^\s*$/.test(mailingCity))) {
                        setMailingCityErrorMessage("Please enter a city.");
                        isValid = false;
                } else {
                        setMailingCityErrorMessage(null);
                }
                if ((!mailingState || /^\s*$/.test(mailingState))) {
                        setMailingStateErrorMessage("Please enter a state.");
                        isValid = false;
                } else {
                        setMailingStateErrorMessage(null);
                }
                if ((!mailingPostalCode || /^\s*$/.test(mailingPostalCode))) {
                        setMailingPostalCodeErrorMessage("Please enter a postal code.");
                        isValid = false;
                } else {
                        setMailingPostalCodeErrorMessage(null);
                }
                if ((!shippingAddress1 || /^\s*$/.test(shippingAddress1))) {
                        setShippingAddress1ErrorMessage("Please enter a address.");
                        isValid = false;
                } else {
                        setShippingAddress1ErrorMessage(null);
                }
                if ((!shippingCity || /^\s*$/.test(shippingCity))) {
                        setShippingCityErrorMessage("Please enter a city.");
                        isValid = false;
                } else {
                        setShippingCityErrorMessage(null);
                }
                if ((!shippingState || /^\s*$/.test(shippingState))) {
                        setShippingStateErrorMessage("Please enter a state.");
                        isValid = false;
                } else {
                        setShippingStateErrorMessage(null);
                }
                if ((!shippingPostalCode || /^\s*$/.test(shippingPostalCode))) {
                        setShippingPostalCodeErrorMessage("Please enter a postal code.");
                        isValid = false;
                } else {
                        setShippingPostalCodeErrorMessage(null);
                }
                if (isValid) {
                        UserService.saveProfileCompanyInformation(partnerId, companyName, companyWebsite,
                                mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingCountry, mailingPostalCode,
                                shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingCountry, shippingPostalCode,
                                companyPhoneNumber, companyFaxNumber)
                        .then(res => {
                                if (res.status === 200) {
                                        if (res.data.results.responseId === true) {
                                                history.push("/profile");
                                        }
                                }
                        }).catch(error => {
                                console.log("Error " + error);
                        });
                }
        }

        return(
                <div className="registration-form">
                <div className="container">
                        <div className="row">
                        <div className="col-md-11 offset-md-1">
                                <div className="title">
                                <h1>Company Profile</h1>
                                </div>
                                {loading ? <Loading /> :
                                editAllowed === true ?
                                <Fragment>
                                        <div className="line-break"></div>
                                        <div className="row">
                                                <div className="col-sm-3">
                                                        <h3>Company Registration</h3>
                                                </div>
                                                <div className="col-sm-9">
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-4">
                                                                <Form.Label>Company Name*</Form.Label>
                                                                <Form.Control type="text" name="companyName" value={companyName} onChange={(e) => handleChangeCompanyName(e.target.value)} className={companyNameErrorMessage ? "is-invalid" : ""} />
                                                                {companyNameErrorMessage ? <div className="invalid-feedback">{companyNameErrorMessage}</div> : null}
                                                        </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-4">
                                                                <Form.Label>Phone Number*</Form.Label>
                                                                <Form.Control type="text" name="companyPhoneNumber" value={companyPhoneNumber} onChange={(e) => handleChangeCompanyPhoneNumber(e.target.value)} className={companyPhoneNumberErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                                {companyPhoneNumberErrorMessage ? <div className="invalid-feedback">{companyPhoneNumberErrorMessage}</div> : null}
                                                        </Col>
                                                        <Col className="col-sm-4">
                                                                <Form.Label>Fax Number</Form.Label>
                                                                <Form.Control type="text" name="companyFaxNumber" value={companyFaxNumber} onChange={(e) => handleChangeCompanyFaxNumber(e.target.value)} className={companyFaxNumberErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                                {companyFaxNumberErrorMessage ? <div className="invalid-feedback">{companyFaxNumberErrorMessage}</div> : null}
                                                        </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                        <Col className="col-sm-4">
                                                                <Form.Label>Company Website</Form.Label>
                                                                <Form.Control type="text" name="companyWebsite" value={companyWebsite} onChange={(e) => handleChangeCompanyWebsite(e.target.value)} />
                                                        </Col>
                                                        </Form.Group>
                                                        <div className="line-break"></div>
                                                        <AddressDetails 
                                                                mailingAddress1={mailingAddress1}
                                                                mailingAddress1ErrorMessage={mailingAddress1ErrorMessage}
                                                                mailingAddress2={mailingAddress2}
                                                                mailingCity={mailingCity}
                                                                mailingCityErrorMessage={mailingCityErrorMessage}
                                                                mailingState={mailingState}
                                                                mailingStateErrorMessage={mailingStateErrorMessage}
                                                                mailingPostalCode={mailingPostalCode}
                                                                mailingPostalCodeErrorMessage={mailingPostalCodeErrorMessage}
                                                                mailingCountry={mailingCountry}
                                                                shippingAddress1={shippingAddress1}
                                                                shippingAddress1ErrorMessage={shippingAddress1ErrorMessage}
                                                                shippingAddress2={shippingAddress2}
                                                                shippingCity={shippingCity}
                                                                shippingCityErrorMessage={shippingCityErrorMessage}
                                                                shippingState={shippingState}
                                                                shippingStateErrorMessage={shippingStateErrorMessage}
                                                                shippingPostalCode={shippingPostalCode}
                                                                shippingPostalCodeErrorMessage={shippingPostalCodeErrorMessage}
                                                                shippingCountry={shippingCountry}
                                                                handleChangeMailingAddress1={handleChangeMailingAddress1}
                                                                handleChangeMailingAddress2={handleChangeMailingAddress2}
                                                                handleChangeMailingCity={handleChangeMailingCity}
                                                                handleChangeMailingState={handleChangeMailingState}
                                                                handleChangeMailingCountry={handleChangeMailingCountry}
                                                                handleChangeMailingPostalCode={handleChangeMailingPostalCode}
                                                                handleChangeShippingAddress1={handleChangeShippingAddress1}
                                                                handleChangeShippingAddress2={handleChangeShippingAddress2}
                                                                handleChangeShippingCity={handleChangeShippingCity}
                                                                handleChangeShippingState={handleChangeShippingState}
                                                                handleChangeShippingPostalCode={handleChangeShippingPostalCode}
                                                                handleChangeShippingCountry={handleChangeShippingCountry}
                                                                toggleShippingAsMailingAddress={toggleShippingAsMailingAddress}
                                                        />
                                                </div>
                                        </div>
                                        <div className="line-break"></div>
                                        <div className="row">
                                        <div className="col-sm-9 offset-sm-3">
                                                <Button type="button" onClick={submitCompanyInformation} >Save</Button>
                                                <a className="cancel" href="/profile" >Cancel</a>
                                        </div>
                                        </div>
                                </Fragment>
                                :
                                <Fragment>
                                        <table className="table table-sm table-key-value">
                                        <tbody>
                                                <tr>
                                                        <td>Company Name</td>
                                                        <td>{companyName}</td>
                                                </tr>
                                                <tr>
                                                        <td>Phone Number</td>
                                                        <td>{companyPhoneNumber}</td>
                                                </tr>
                                                {companyFaxNumber ?
                                                <tr>
                                                        <td>Fax Number</td>
                                                        <td>{companyFaxNumber}</td>
                                                </tr>
                                                : null}
                                                {companyWebsite ?
                                                <tr>
                                                        <td>Company Website</td>
                                                        <td>{companyWebsite}</td>
                                                </tr>
                                                : null}
                                                <tr>
                                                        <td>Mailing Address 1</td>
                                                        <td>{mailingAddress1}</td>
                                                </tr>
                                                {mailingAddress2 ?
                                                <tr>
                                                        <td>Mailing Address 2</td>
                                                        <td>{mailingAddress2}</td>
                                                </tr>
                                                : null}
                                                {mailingCity ?
                                                <tr>
                                                        <td>Mailing City</td>
                                                        <td>{mailingCity}</td>
                                                </tr>
                                                : null}
                                                {mailingState ?
                                                <tr>
                                                        <td>Mailing State</td>
                                                        <td>{mailingState}</td>
                                                </tr>
                                                : null}
                                                {mailingPostalCode ?
                                                <tr>
                                                        <td>Mailing Postal Code</td>
                                                        <td>{mailingPostalCode}</td>
                                                </tr>
                                                : null}
                                                {mailingCountry ?
                                                <tr>
                                                        <td>Mailing Country</td>
                                                        <td>{mailingCountry}</td>
                                                </tr>
                                                : null}
                                                <tr>
                                                        <td>Shipping Address 1</td>
                                                        <td>{shippingAddress1}</td>
                                                </tr>
                                                {shippingAddress2 ?
                                                <tr>
                                                        <td>Shipping Address 2</td>
                                                        <td>{shippingAddress2}</td>
                                                </tr>
                                                : null}
                                                {shippingCity ?
                                                <tr>
                                                        <td>Shipping City</td>
                                                        <td>{shippingCity}</td>
                                                </tr>
                                                : null}
                                                {shippingState ?
                                                <tr>
                                                        <td>Shipping State</td>
                                                        <td>{shippingState}</td>
                                                </tr>
                                                : null}
                                                {shippingPostalCode ?
                                                <tr>
                                                        <td>Shipping Postal Code</td>
                                                        <td>{shippingPostalCode}</td>
                                                </tr>
                                                : null}
                                                {shippingCountry ?
                                                <tr>
                                                        <td>Shipping Country</td>
                                                        <td>{shippingCountry}</td>
                                                </tr>
                                                : null}
                                        </tbody>
                                        </table>
                                        <div className="line-break"></div>
                                        <div className="row">
                                        <div className="col-sm-9 offset-sm-3">
                                                <Button type="button" href="/profile" >Back</Button>
                                        </div>
                                        </div>
                                </Fragment>
                                }
                        </div>
                        </div>
                </div>
                </div>
        );
}

const mapStateToProps = store => {
        const loggedUserId = getLoggedUserId(store);
        const userToken = getLoggedUserToken(store);
        return { loggedUserId, userToken };
}
    
export default connect(mapStateToProps, { logoutUser })(CompanyProfile);