import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class ProductSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
      };


      //HACK: Doing this until imagekit fixes filenames
      this.lowerExtension = (fileName) => {
          let ext = this.getExtension(fileName);
          let retFileName = fileName.replace(ext, ext.toLowerCase());
          return retFileName;
      };
      this.getExtension = (path) => {
          let baseName = path.split(/[\\/]/).pop(), // extracts file name from full path
              // (supports separators `\\` and `/`)
              pos = baseName.lastIndexOf("."); // gets the last position of `.`
          if (baseName === "" || pos < 1) // if the file name is empty or ...
              return ""; // the dot not found (-1) or comes first (0)
          return baseName.slice(pos + 1); // extracts extension ignoring "."
      };

  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    }

    

 

  render() {
    const images = this.props.images;
    return (
      <div>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
        >
            {images.map((image, index) =>
                <div key={index} >
                    <img src={process.env.REACT_APP_IMAGE_PATH + "/tr:h-400/" + image.fileName} key={index} alt={image.fileName} />
                </div>
            )}
        </Slider>
      </div>
    );
  }
}