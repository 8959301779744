var reactivePower = {
  VAR: {
    to_anchor: 1
  }, 
  mVAR: {
    to_anchor: .001
  }, 
  kVAR: {
    to_anchor: 1000
  }, 
  MVAR: {
    to_anchor: 1000000
  }, 
  GVAR: {
    to_anchor: 1000000000
  }
};

module.exports = {
  metric: reactivePower, 
  _anchors: {
    metric: {
      unit: 'VAR', 
      ratio: 1
    }
  }
};
