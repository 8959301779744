var frequency = {
  mHz: {
    to_anchor: 1/1000
  }, 
  Hz: {
    to_anchor: 1
  }, 
  kHz: {
    to_anchor: 1000
  },
  MHz: {
    to_anchor: 1000 * 1000
  }, 
  GHz: {
    to_anchor: 1000 * 1000 * 1000
  }, 
  THz: {
    to_anchor: 1000 * 1000 * 1000 * 1000
  }, 
  rpm: {
    to_anchor: 1/60
  }, 
  "deg/s": {
    to_anchor: 1/360
  }, 
  "rad/s": {
    to_anchor: 1/(Math.PI * 2)
  }
};

module.exports = {
  metric: frequency, 
  _anchors: {
    frequency: {
      unit: 'hz', 
      ratio: 1
    }
  }
};
