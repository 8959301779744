  
import React, { Fragment } from 'react'

const Register = ({ children }) => {
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default Register;