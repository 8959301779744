import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { ProductsList } from '../../components/powersports';
import { HomeHighlightProductItem } from '../../components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const PowerSportsCategory = () => {
    let { category } = useParams();
    const [categoryName, setCategoryName] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("powersportsCategories") != null) {
            const powersportsCategories = JSON.parse(localStorage.getItem("powersportsCategories"));
            // eslint-disable-next-line eqeqeq
            const filtered = powersportsCategories.filter(item => item.id == category);
            if (filtered) {
                setCategoryName(filtered[0].name);
            }
        }
    }, [category])
    
    const products = {
        30: [145755, 145572, 145737, 145616], //Axles
        33: [145875, 139139, 139142, 143887], //CV Boot Kits
        27: [141448, 143904, 143899, 139625], //Drive & Control system
        28: [139179, 139667, 141298, 141329], // Electrical & Ignition Systems
        14: [139196, 145775, 153621, 153623], // Engine & Fuel System
        34: [139215, 152471, 152547, 143866], // Oils & Lubricants
        29: [139155, 139156, 139503, 139154], //Steering & Suspension
        32: [139135, 139136, 139137, 139138], //U-Joint Kits
        31: [139450, 139147, 139144, 139437] //Wheel Bearing Kits
    };
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <FontAwesomeIcon icon={faAngleRight} />,
        prevArrow: <FontAwesomeIcon icon={faAngleLeft} />,
        responsive: [
          {
            breakpoint: 1469,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    

    return (
        <div className="category">
        <div className={"fixed-parallax category-power"}></div>
        <div className={"category-intro background-title"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="category-name">{categoryName}</h1>
                            </div>
                            {/* {products[category] ?
                                <div className="col-sm-12">
                                    <Slider {...settings} >
                                        {products[category].map((productId, index) =>
                                            <div key={index}>
                                                <HomeHighlightProductItem 
                                                    key={index}
                                                    productId={productId}
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            : null} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="search-results white-background" id="search-results" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        {categoryName ?
                            <ProductsList categoryName={categoryName} categoryId={category} />
                        : null}
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
  
export default PowerSportsCategory;