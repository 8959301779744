import React, { useState, useEffect, Fragment } from 'react';
import {Engine, Product, Loading, NoResults } from '../components';
import queryString from 'query-string';
import API from '../utils/API';
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

const DetailedSearch = () => {
    const history = useHistory();
    const itemsPerPage = 10
    const parsed = queryString.parse(window.location.search);
    const [oem, setOem] = useState(parsed.oem);
    const [brand, setBrand] = useState(parsed.brand);
    const [modelNumber, setModelNumber] = useState(parsed.modelNumber);
    const [year, setYear] = useState(parsed.year);
    const [hp, setHp] = useState(parsed.hp);
    const [sn, setSn] = useState(parsed.sn);
    const [engines, setEngines] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(history.location.state !== undefined ? history.location.state.page : 1);
    const [marketId, setMarketId] = useState(history.location.state !== undefined && history.location.state.marketId !== undefined ? history.location.state.marketId : process.env.REACT_APP_MARINE_ID);
    
    useEffect(() => {
        const p = queryString.parse(window.location.search);
        // eslint-disable-next-line eqeqeq
        if (brand != p.brand || modelNumber != p.modelNumber || year != p.year || hp != p.hp || sn != p.sn || oem != p.oem) {
            setPage(1);
        }
        setBrand(p.brand);
        setModelNumber(p.modelNumber);
        setYear(p.year);
        setHp(p.hp);
        setSn(p.sn);
        setOem(p.oem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.search])

    useEffect(() => {
        setLoading(true);
        setProducts([]);
        setEngines([]);
        if (oem) {
            getProducts();
        } else {
            getEngines();
        }
        window.scrollTo(0, 0);
    }, [page, brand, modelNumber, hp, oem, year, sn])

    const goToPage = (page) => {
        setPage(page)
        history.replace({
            pathname:  "/detailed-search",
            search: window.location.search,
            state: {
                page: page,
                marketId: marketId
            } 
        });
    }
    const resetBrand = () => setBrand(null);
    const resetModelNumber = () => {
        setModelNumber("");
        setPage(1);
    }
    const resetYear = () => {
        setYear("");
        setPage(1);
    }
    const resetHp = () => {
        setHp("");
        setPage(1);
    }
    const resetSn = () => {
        setSn("");
        setPage(1);
    }

    async function getEngines() {
        await API.get("/AdvancedSearch/Details/", {
            params: {
                id: 'brandmodel',
                brand: brand,
                modelNumber: modelNumber,
                year: year,
                hp: hp,
                serialNumber: sn,
                queryType: 'contains',
                pageSize: itemsPerPage,
                pageNumber: page,
                marketId: marketId,
                embedType: process.env.REACT_APP_EMBED_TYPE
            }
        }).then(response => {
            if (response.status === 200) {
                const data = response.data.Data;
                setLoading(false)
                setEngines(data);
                setTotalItemsCount(1);
                if (data && data[0]) {
                    const totalItems = data[0]?.totalRows;
                    setTotalItemsCount(totalItems);
                }
            }
        }).catch(function(error) {
            console.log(error);
        });
    }

    async function getProducts() {
        await API.get("/AdvancedSearch/Details/", {
            params: {
                id: 'interchange',
                oeNumber: oem,
                pageSize: itemsPerPage,
                pageNumber: page,
                marketId: marketId,
                embedType: process.env.REACT_APP_EMBED_TYPE
            }
        }).then(response => {
            if (response.status === 200) {
                setLoading(false)
                const data = response.data.Data;
                const products = []
                // eslint-disable-next-line no-lone-blocks
                {data.map(item =>
                    products.push({  
                        itemRow: item.productId,
                        saleItem: item.productNumber,
                        descShort: item.descShort,
                        categoryParent: item.categoryParent,
                        categoryChild: item.categoryChild,
                        imagePath: item.imagePath
                    })
                )}
                setProducts(products);
                const totalItems = response.data.Total;
                setTotalItemsCount(totalItems);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }
    
    return (
        <div className="search-results">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <h1>Detailed search</h1>
                        <div className="badges mb-3">
                            {brand !== undefined ? <span class="badge badge-pill badge-light">Brand: {brand} {oem !== undefined ? <button onClick={resetBrand} >X</button> : null} </span> : null}
                            {modelNumber !== undefined && modelNumber !== "" ? <span class="badge badge-pill badge-light">Model number: {modelNumber} <button onClick={resetModelNumber} >X</button></span> : null}
                            {year !== undefined && year !== "" ? <span class="badge badge-pill badge-light">Year: {year} <button onClick={resetYear} >X</button></span> : null}
                            {hp !== undefined && hp !== "" ? <span class="badge badge-pill badge-light">HP: {hp} <button onClick={resetHp} >X</button></span> : null}
                            {sn !== undefined && sn !== "" ? <span class="badge badge-pill badge-light">Serial number: {sn} <button onClick={resetSn} >X</button></span> : null}
                            {oem !== undefined && oem !== "" ? <span class="badge badge-pill badge-light">OEM: {oem}</span> : null}
                        </div>

                        {loading ? <Loading /> :
                            <Fragment>
                                {engines.length === 0 && products.length === 0 ? <NoResults /> :
                                    <Fragment>
                                        <div className="results">
                                            {engines.length > 0 ? engines.map((engine, index) =>
                                                <Engine
                                                    key={index}
                                                    horsePower={engine.horsepower}
                                                    brandName={engine.brandName}
                                                    modelNumber={engine.modelNumber}
                                                    engineId={engine.engineId}
                                                    startYear={engine.startYear}
                                                    stopYear={engine.stopYear}
                                                    horsepower={engine.horsepower}
                                                    stroke={engine.stroke}
                                                    cylinders={engine.cylinders}
                                                    startSerialNumber={engine.startSerialNumber}
                                                    stopSerialNumber={engine.stopSerialNumber}
                                                    marketId={marketId}
                                                />
                                            ) : null}
                                            {products.length > 0 ? products.map((product, index) =>
                                                <Product
                                                    key={index}
                                                    description={product.descShort}
                                                    categoryChild={product.categoryChild}
                                                    categoryParent={product.categoryParent}
                                                    saleItem={product.saleItem}
                                                    productId={product.itemRow}
                                                    imagePath={product.imagePath}
                                                    marketId={marketId}
                                                />
                                            ) : null}
                                        </div>
                                        {totalItemsCount > itemsPerPage ? 
                                        <div className="text-center mt-2">
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={page}
                                                itemsCountPerPage={itemsPerPage}
                                                totalItemsCount={totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={goToPage}
                                            />
                                        </div>
                                        : null}
                                    </Fragment>
                                }
                            </Fragment>
                        }   
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailedSearch;