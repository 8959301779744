import React, { useState, useEffect, Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import TournamentEntryService from '../../services/TournamentEntryService';

const DometicDay = () => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");


    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");

    const [address1, setAddress1] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");

    const [shirtSize1, setShirtSize1] = useState("");
    const [shirtSize2, setShirtSize2] = useState("");


    const [adultEntry, setAdultEntry] = useState(false);
    const toggleAdultEntry = () => setAdultEntry(!adultEntry);

    const [kids3to7, setKids3to7] = useState(false);
    const toggleKids3to7 = () => setKids3to7(!kids3to7);

    const [kids8to15, setKids8to15] = useState(false);
    const toggleKids8to15 = () => setKids8to15(!kids8to15);


    const [paymentType, setPaymentType] = useState("");


    const [agree, setAgree] = useState(false);
    const toggleAgree = () => setAgree(!agree);

    const [participantName, setParticipantName] = useState("");
    const [guardian, setGuardian] = useState("");


    const [dateOfBirth, setDateOfBirth] = useState("");

    const [entryExists, setEntryExists] = useState(false);
    const [oldEntry, setOldEntry] = useState(null);
    const [newEntry, setNewEntry] = useState(null);

    const [entryCreated, setEntryCreated] = useState(false);


    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.stopPropagation();
        }

        if (email !== confirmEmail) {
            event.stopPropagation();
            setValidated(false);
            setErrorMessage("Your Email and Confirmation email do not match.");
            setShowError(true);
        } else {
            setValidated(true);
            setShowError(false);
        }

        submitEntry();
    };
    const submitEntry = () => {
        if (validated) {
            var entry = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                emailConfirm: confirmEmail,
                address1: address1,
                city: city,
                state: state,
                postalCode: zip,
                phoneNumber: phoneNumber,
                shirtSize1: shirtSize1,
                shirtSize2: shirtSize2,
                adultEntry: adultEntry,
                kidsEntry7: kids3to7,
                kidsEntry15: kids8to15,

                paymentType: paymentType,
                agrees: agree,
                participantName: participantName,
                guardianName: guardian,
                dateOfBirth: dateOfBirth

            };

            saveEntry(entry);
        }

    }

    async function saveEntry(pEntry) {

        await TournamentEntryService.submitNewEntry(pEntry)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data.message, res.data.data);
                    if (res.data.success) {
                        //Created
                        setNewEntry(res.data.data);
                        setEntryExists(false);
                        setEntryCreated(true);


                    } else {
                        //Already Exists
                        setOldEntry(res.data.data);
                        setEntryExists(true);
                    }


                }

            }).catch(error => {
                console.log("Error " + error);
            });
    }

    return (
        <Fragment>
            <div className="contact">
                <div className="contact-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <h1>Dometic Day - Entry Form</h1>
                                        <p></p>
                                    </div>

                                </div>


                                {entryExists &&
                                    <div class="alert alert-danger" role="alert">
                                        You have already entered the tournament
                                    </div>
                                }

                                {showError &&
                                    <div class="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                }

                                {entryCreated &&
                                    <div class="alert alert-success" role="alert">
                                        Your entry has been submitted. Confirmation: {newEntry.confirmation}
                                    </div>
                                }


                                <h4>Saturday, September 10th ~ Lake Lou Yaeger, Litchfield Illinois ~ Marina 2 </h4>
                                <div className="row">
                                    <div className="col">

                                        <ul>
                                            <li>Adult Tournament - Register at 5:30am - Boats Launch at 6:30 a.m. </li>
                                            <li>Kids Derby - Register at 10:30 - Starts at 11:30 a.m.</li>

                                        </ul>


                                    </div>
                                </div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div class="col">


                                            <div className="row">
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="FirstName">First Name *</label>
                                                    <Form.Control required type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} id="FirstName" name="FirstName" placeholder="First Name" className="form-control" />
                                                </div>

                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="LastName">Last Name *</label>
                                                    <Form.Control required type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} id="LastName" name="LastName" placeholder="Last Name" className="form-control" />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="Email">Email *</label>
                                                    <Form.Control required type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="Email" name="Email" placeholder="Email" />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="ConfirmEmail">Confirm Email *</label>
                                                    <Form.Control isValid={confirmEmail == email} required type="email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} className="form-control" id="ConfirmEmail" name="ConfirmEmail" placeholder="Confirm Email" />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="Address1">Address1 *</label>
                                                    <Form.Control required type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} className="form-control" id="Address1" name="Address1" placeholder="Address 1" />
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="City">City *</label>
                                                    <Form.Control required type="text" value={city} onChange={(e) => setCity(e.target.value)} className="form-control" id="City" name="City" placeholder="City" />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label htmlFor="PostalCode">PostalCode *</label>
                                                    <Form.Control required type="text" value={zip} onChange={(e) => setZip(e.target.value)} className="form-control" id="PostalCode" name="PostalCode" placeholder="Postal Code" />
                                                </div>
                                                <div className="form-group col-lg-3">
                                                    <label htmlFor="State">State *</label>
                                                    <Form.Control required as="select" onChange={e => setState(e.target.value)} className="form-control" id="State" name="State">
                                                        <option value="">Select State</option>
                                                        <option value="AL">AL</option>
                                                        <option value="AK">AK</option>
                                                        <option value="AR">AR</option>
                                                        <option value="AZ">AZ</option>
                                                        <option value="CA">CA</option>
                                                        <option value="CO">CO</option>
                                                        <option value="CT">CT</option>
                                                        <option value="DC">DC</option>
                                                        <option value="DE">DE</option>
                                                        <option value="FL">FL</option>
                                                        <option value="GA">GA</option>
                                                        <option value="HI">HI</option>
                                                        <option value="IA">IA</option>
                                                        <option value="ID">ID</option>
                                                        <option value="IL">IL</option>
                                                        <option value="IN">IN</option>
                                                        <option value="KS">KS</option>
                                                        <option value="KY">KY</option>
                                                        <option value="LA">LA</option>
                                                        <option value="MA">MA</option>
                                                        <option value="MD">MD</option>
                                                        <option value="ME">ME</option>
                                                        <option value="MI">MI</option>
                                                        <option value="MN">MN</option>
                                                        <option value="MO">MO</option>
                                                        <option value="MS">MS</option>
                                                        <option value="MT">MT</option>
                                                        <option value="NC">NC</option>
                                                        <option value="NE">NE</option>
                                                        <option value="NH">NH</option>
                                                        <option value="NJ">NJ</option>
                                                        <option value="NM">NM</option>
                                                        <option value="NV">NV</option>
                                                        <option value="NY">NY</option>
                                                        <option value="ND">ND</option>
                                                        <option value="OH">OH</option>
                                                        <option value="OK">OK</option>
                                                        <option value="OR">OR</option>
                                                        <option value="PA">PA</option>
                                                        <option value="RI">RI</option>
                                                        <option value="SC">SC</option>
                                                        <option value="SD">SD</option>
                                                        <option value="TN">TN</option>
                                                        <option value="TX">TX</option>
                                                        <option value="UT">UT</option>
                                                        <option value="VT">VT</option>
                                                        <option value="VA">VA</option>
                                                        <option value="WA">WA</option>
                                                        <option value="WI">WI</option>
                                                        <option value="WV">WV</option>
                                                        <option value="WY">WY</option>

                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="MobilePhone">Mobile Phone</label>
                                                    <Form.Control required type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" id="MobilePhone" name="MobilePhone" placeholder="Mobile Phone" />
                                                </div>

                                            </div>


                                            <div className="row">

                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="ShirtSize1">Shirt Size 1</label>
                                                    <Form.Control required as="select" onChange={e => setShirtSize1(e.target.value)} className="form-control" id="ShirtSize1" name="ShirtSize1">
                                                        <option value="">Select Shirt Size 2</option>
                                                        <option value="xs">XS</option>
                                                        <option value="S">S</option>
                                                        <option value="M">M</option>
                                                        <option value="L">L</option>
                                                        <option value="XL">XL</option>
                                                        <option value="XXL">XXL</option>
                                                        <option value="XXL">XXXL</option>

                                                    </Form.Control>
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="ShirtSize2">Shirt Size 2</label>
                                                    <Form.Control required as="select" onChange={e => setShirtSize2(e.target.value)} className="form-control" id="ShirtSize2" name="ShirtSize2">
                                                        <option value="">Select Shirt Size 2</option>
                                                        <option value="xs">XS</option>
                                                        <option value="S">S</option>
                                                        <option value="M">M</option>
                                                        <option value="L">L</option>
                                                        <option value="XL">XL</option>
                                                        <option value="XXL">XXL</option>
                                                        <option value="XXL">XXXL</option>

                                                    </Form.Control>
                                                </div>
                                            </div>

                                            <div className="row pt-3">

                                                <div className="form-group col-lg-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="AdultEntry" onChange={toggleAdultEntry} />
                                                        <label class="form-check-label" for="AdultEntry">
                                                            <strong>Adult Entry</strong>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="Kids3to7" onChange={toggleKids3to7} />
                                                        <label class="form-check-label" for="Kids3to7">
                                                            <strong>Kids Derby Entry: Ages 3 - 7</strong>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="form-group col-lg-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="Kids8to15" onChange={toggleKids8to15} />
                                                        <label class="form-check-label" for="Kids8to15">
                                                            <strong> Kids Derby Entry: Ages 8 - 15</strong>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            <h3 className="pt-3">Tournament Entry Fees</h3>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul>
                                                        <li> Early Registration through August 22nd = $60.00
                                                            Includes 2 boaters, t-shirts, and lunch per boater</li>


                                                        <li> Registration August 23rd - day of event = $75.00
                                                            Includes 2 boaters, t-shirts, and lunch per boater</li>

                                                        <li>Kids Derby - Free</li>
                                                    </ul>



                                                </div>
                                            </div>

                                            <div className="row pt-3">

                                                <div className="form-group col-lg-6">
                                                    <label htmlFor="PaymentType"><strong>Payment Type</strong>

                                                    </label>
                                                    <Form.Control required as="select" onChange={e => setPaymentType(e.target.value)} className="form-control" id="PaymentType" name="PaymentType">
                                                        <option value="">Select Payment Type</option>
                                                        <option value="Paypal">PayPal</option>
                                                        <option value="Personal Check">Personal Check</option>
                                                        <option value="Money Order">Money Order</option>


                                                    </Form.Control>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <ul>
                                                        <li> PayPal payment - use @DometicDay - please include Full Name to verify registration paid.</li>
                                                        <li>Mailed payment must include copy of registration form to verify participant entry</li>
                                                        <li>Mail to: Dometic, Attn: Dometic Day Tournament, 1 Sierra Place, Litchfield, IL 62056</li>
                                                        <li>Questions: contact HR@dometic.com or call 217-441-8350 (business hours: 8:00 am - 4:30 pm)</li>
                                                    </ul>
                                                </div>
                                            </div>






                                            <div className="row pt-3">
                                                <div className="col-lg-12">
                                                    <h4>FOR PARENT/LEGAL GUARDIAN OF UNDERAGE CHILDREN (UNDER AGE 18 AT THE TIME OF
                                                        REGISTRATION):</h4>
                                                    <p>
                                                        This is to certify, that I, as parent/guardian with legal responsibility for this participant, by
                                                        signing the previous page, do consent and agree to indemnify and hold harmless the
                                                        releases from any and all liabilities incident to my minor child's involvement or
                                                        participation in Dometic Day Tournament, EVEN IF ARISING FROM THE NEGLIGENCE
                                                        OF DOMETIC, to the fullest extent permitted by law.</p>

                                                    <h4>PARTICIPANT AGREEMENT</h4>

                                                    <p>As a contestant, I ratify and approve entry in the listed tournament(s) and agree thereby
                                                        to be bound by all of the rules of the tournament by signature and/or participation in said
                                                        tournament.</p>

                                                    <p>I HAVE COMPLETED THE REGISTRATION FORM AND AM ENCLOSING THE
                                                        REGISTRATION FORM, MY DEPOSIT OR ENTRY FEE PAYABLE TO SIERRA,
                                                        ALONG WITH THIS SIGNED AND DATED PARTICIPATION AGREEMENT
                                                        CONFIRMATION.</p>

                                                    <p>In signing this Official Entry Form and Participation Agreement, I hereby agree to be
                                                        bound by and comply with all tournament rules, and regulations including payment
                                                        requirements. I expressly assume all risks associated with the tournament and I hereby
                                                        release Dometic, the host, the identified local charitable organization, sponsors and
                                                        tournament officials from all claims of death, injury and/or property damage incurred by
                                                        me in connection with my participation in this tournament.</p>

                                                    <p>All participating boats must have a permit to be on Lake Lou Yaeger. These are available
                                                        at the Marina 1 Store (217) 324-5441 located at 4943 Beach House Trail, Litchfield,
                                                        Illinois. I certify that I now have or will obtain prior to the tournament, boat owners liability
                                                        insurance (including coverage for third party property damage and bodily injury) with no
                                                        Tournament exclusion. I understand that I must provide Dometic with satisfactory
                                                        evidence of that insurance at registration if requested.</p>

                                                    <p>I hereby agree to waive my rights of privacy or publicity with the regard to the programs
                                                        of the tournament in which I appear and consent to Dometic (and those acting under
                                                        their permission or upon their authority), copyrighting, distributing, televising, publishing
                                                        and using in any way the audio and visual portions of any television videotape, film and
                                                        photographs or pictures of me or of interviews, scenes or other sequences in which I may
                                                        be included, and any reproduction thereof, anywhere at any time through any medium or
                                                        media for advertising, promotion, trade, television programming or other lawful purpose
                                                        whatsoever; and I shall not be entitled to receive any royalties or other compensation in
                                                        connection with such use.</p>

                                                    <p>I understand that I must be of good mental and physical health and I attest that I do not
                                                        have any physical impairment which would hinder my boater or endanger the safety
                                                        and/or well-being of my boater or myself. I further understand and agree that the
                                                        Tournament Director reserves the right to reject my registration for any reason and, upon
                                                        such rejection, to refund the deposit or entry fee.</p>
                                                </div>
                                            </div>



                                            <div className="row pt-3">
                                                <div className="col-lg-12">
                                                    <p>
                                                        I, the undersigned/participant/guardian, understand that by signing in the space below, certify that I have herby read,
                                                        understand, agree with and accept the Participant Agreement, Publicity Release and Acknowledgement of Risks
                                                        Identified on this entry form.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-lg-4">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="ParticipantAgrees" onChange={toggleAgree} />
                                                        <label class="form-check-label" for="ParticipantAgrees">
                                                            <strong> I Understand and Agree </strong>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">


                                                <div className="form-group col-lg-4">
                                                    <label htmlFor="ParticipantName">Participant Name *</label>
                                                    <Form.Control required type="text" value={participantName} onChange={(e) => setParticipantName(e.target.value)} id="ParticipantName" name="ParticipantName" placeholder="Participant Name" className="form-control" />
                                                </div>



                                                <div className="form-group col-lg-4">
                                                    <label htmlFor="DateOfBirth">Date of Birth *</label>
                                                    <Form.Control required type="date" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} id="DateOfBirth" name="DateOfBirth" placeholder="DateOfBirth" className="form-control" />
                                                </div>
                                                <div className="form-group col-lg-4">
                                                    <label htmlFor="Guardian">Parent/Legal Guardian *</label>
                                                    <Form.Control type="text" value={guardian} onChange={(e) => setGuardian(e.target.value)} id="Guardian" name="Guardian" placeholder="Parent or Guardian Name" className="form-control" />
                                                </div>
                                            </div>


                                            {entryExists &&
                                                <div class="alert alert-danger" role="alert">
                                                    You have already entered the tournament
                                                </div>
                                            }

                                            {showError &&
                                                <div class="alert alert-danger" role="alert">
                                                    {errorMessage}
                                                </div>
                                            }

                                            {entryCreated &&
                                                <div class="alert alert-success" role="alert">
                                                    Your entry has been submitted. Confirmation: {newEntry.confirmation}
                                                </div>
                                            }




                                            <Button type="submit" disabled={agree ? "" : "disabled"} className="btn btn-lg btn-primary">Submit Tournament Registration</Button>


                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DometicDay;