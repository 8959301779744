import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { UserService } from '../../services';
import { getLoggedUserId } from "../../redux/selectors";
import { removeFromPickList } from "../../redux/actions";

const PickListItemDelete = ({ pickListId, productId, callBackFunction, loggedUserId, removeFromPickList }) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);

    const handleConfirm = () => {
        if (loggedUserId) {
            UserService.deletePickListItem(pickListId, productId)
            .then(res => {
                if (res.status === 200) {
                    handleClose();
                    callBackFunction();
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            removeFromPickList(pickListId, productId);
            handleClose();
            callBackFunction();
        }
    }

    return (
        <Fragment>
            <button className="btn btn-secondary" onClick={handleShow} title="Delete product from pick list" ><FontAwesomeIcon icon={faMinus} className="go-to-category" /></button>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Are you sure want to delete product from pick list?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm} >
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )

}

const mapStateToProps = state => {
    const loggedUserId = getLoggedUserId(state);
    return { loggedUserId };
}

PickListItemDelete.propTypes = {
    pickListId: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired
}

export default connect(mapStateToProps, { removeFromPickList })(PickListItemDelete);