import { useEffect, useState } from 'react';
import { setClientToken } from '../../../ultra/utils/ScannerAPI';
import UltraService from '../../../services/UltraService';
import Profiles from './profiles/view';
import '../../styles/css/custom.css';
import '../../styles/scss/custom.scss';
import { UserService } from '../../../services'

const UltraHome = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    //let item = localStorage.getItem("kp_token");
    let item = null;
    if (item) {
      console.log("ultraHome.kp_token:", item);
      //setClientToken(item);
      setToken(item);
    } else {
      console.log("ultraHome.getUltraToken");
      UserService.getUltraToken().then(res => {
        const newUserData = res.data.data;
        console.log("ultraHome.getUltraToken.res", newUserData);
        localStorage.setItem("kp_token", newUserData.Token);

        UltraService.externalLogin({
          "accessToken": newUserData.Token,
          "companyId": newUserData.CompanyId,
          "expiration": newUserData.ExpirationStamp,
          "licenseExpiration": newUserData.LicenseExpirationStamp,
          "refreshExpiration": newUserData.RefreshExpirationStamp,
          "extendedPermissions": newUserData.ExtendedPermissions,
          "refreshToken": newUserData.RefreshToken,
          "token": newUserData.Token,
          "userType": newUserData.UserType
        }).then(res => {
          console.log("UltraHome.UltraService.externalLogin.res: ", res);
          if (res.status === 200) {
            item = res.data.accessToken;
            console.log("UltraHome.UltraService.externalLogin.res.token:", item);
            //localStorage.setItem("roles", res.data.extendedPermissions);
            //setClientToken(item);
            setToken(item);
          }
        }).catch(err => {
            setError(err);
            console.log("Error here:", err);
          });
      });
      
    }
  }, []);

  if (error) {
    return <div className='container py-5'><h5>Access Denied</h5></div>
  } else if (token) {
    return <Profiles ClientToken={token}/>
  } else {
    return <div><p>Loading</p></div>
  }
}

export default UltraHome;