import React from 'react';
import { HomeProductCategory } from '../components';



const HomeProductCategories = () => {

    return (
        <section className="home-section product-categories">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 section-title text-center">
                        <h3>Product categories</h3>
                    </div>
                    <div className="col-md-10 offset-md-1">
                    <div className="row">
                        <div className="col-lg-4">
                                <HomeProductCategory title="Marine" imagePath={process.env.REACT_APP_CDN_PATH + "/static/images/ilu/ilu-cat1-001.jpg"} url="/marine" text="Marine products are engineered to surpass today’s high horsepower engine demands and stand up to the harsh marine environment. All marine products both meet or exceed the original equipment part it replaces." />
                        </div>
                        <div className="col-lg-4">
                                <HomeProductCategory title="Garden" imagePath={process.env.REACT_APP_CDN_PATH + "/static/images/ilu/ilu-cat2-001.jpg"} url="/garden" text="One stop shop for the complete range of small engine service parts will keep your machines running smoothly. Covering all major makes and equipment types, regardless of the need we have you covered. Products both meet or exceed the original equipment part it replaces." />
                        </div>
                        <div className="col-lg-4">
                                <HomeProductCategory title="Power sports" imagePath={process.env.REACT_APP_CDN_PATH + "/static/images/ilu/ilu-cat3-001.jpg"} url="/power-sports" text="Whether you are playing hard or working hard, find your powersports service needs with our comprehensive line of replacement parts.  Covering all major OEM brands, regardless of your ride of choice we have you covered. Products both meet or exceed the original equipment part it replaces." />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
  
export default HomeProductCategories;