import axios from "axios";

let FourthShiftAPI = axios.create({
    baseURL: process.env.REACT_APP_FOURTHSHIFT_API_URL,
    responseType: "json"
});

export const setFourthshiftAuthorizationHeader = token => {
    if (token) {
        //console.log('setCatalogAuthorizationHeader - ' + token);
        FourthShiftAPI.defaults.headers.Authorization = `Bearer ${token}`;
    }
};

export default FourthShiftAPI;