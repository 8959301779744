import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from 'moment';

import en from "./locale/en.json"
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "en",
    debug: true,
    resources: { en },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      }
    },
    react: { 
      useSuspense: true
   }
  });
i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
});

export default i18n;