import React, { useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { validate } from 'email-validator';
import FooterCopyright from '../../components/FooterCopyright';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services';

const ForgotPassword  = () => {
      const history = useHistory();
      const [errorMessage, setErrorMessage] = useState(null);
      const [email, setEmail] = useState("");

      const onEmailChange = (value) => {
            setEmail(value);
      }

      async function submit() {
            if (email === "") {
                  setErrorMessage("Email is mandatory field");
                  return;
            } else if (!validate(email)) {
                  setErrorMessage("Please enter valid email address");
                  return;
            }
            setErrorMessage(null);
            await UserService.passwordForget(email)
                  .then(res => {
                        if (res.status === 200) {
                              if (res.data.results.responseId === true) {
                                    history.push("/user/login");
                              } else {
                                    setErrorMessage(res.data.results.Message);
                              }
                        }
                  }).catch(error => {
                        console.log("Error " + error);
                  });
            
      }
        
      return(
            <div className="user login-form">
                  <Link to="/user/login" className="back"><FontAwesomeIcon icon={faArrowLeft} />Back</Link>
                  <h2>Forgot Password</h2>
                  <p>To reset your password, enter the email address you used to sign up.</p>
                  <Form>
                        <Form.Control type="email" name="email" placeholder="Email Address" value={email} 
                              onChange={(e) => onEmailChange(e.target.value)} 
                              className={errorMessage !== null ? "is-invalid" : ""}
                              style={{marginBottom: 0}}
                        />
                        {errorMessage ? <div className="invalid-feedback">{errorMessage}</div> : null}
                        <Button type="button" onClick={submit} className="mt-4">Send</Button>
                  </Form>
                  <div className="row">
                        <FooterCopyright />
                  </div>
            </div>
      );

}
export default ForgotPassword;