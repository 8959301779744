import React, { useEffect, useState } from 'react'
import { getLoggedUserToken } from '../../redux/selectors';
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';

const BillingInfo = (props) => {
    const [PaymentType, setPaymentType] = useState(null);

    useEffect(() => {
        console.log("BillingInfo: useEffect: props", props);
        setPaymentType(props.paymentType);

   }, [props.paymentType])
 
    const handleChange = e => {
        setPaymentType(e.target.id);
        
        props.newPaymentType(e.target.id);

    };

    return (
        <div className='py-2 col-sm-12'>
            <p>Paying with:</p>
            <Form.Check // prettier-ignore
                type={"radio"}
                id={1}
                label={"Credit Card"}
                name={"groupPayment"}
                checked={(PaymentType==1)}
                onChange={handleChange}
            />

            <Form.Check
                // disabled
                type={"radio"}
                label={"Charge on Account"}
                id={2}
                name={"groupPayment"}
                checked={(PaymentType==2)}
                onChange={handleChange}
            />
        </div>
    );
};
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    return { userToken };
}

export default connect(mapStateToProps)(BillingInfo);