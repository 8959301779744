var metric, imperial;

metric = {
  'm/s': {
   to_anchor: 3.6
  }, 
  'kmh': {
    to_anchor: 1
  },
  'km/h': {
    to_anchor: 1
  },
  'per km': {
    to_anchor: 1
  },
}

imperial = {
  'm/h': {
    to_anchor: 1
  }, 
  'mph': {
    to_anchor: 1
  },
  knot: {
    to_anchor: 1.150779
  }, 
  'ft/s': {
    to_anchor: 0.681818
  },
  'per mile': {
    to_anchor: 1
  },
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 'km/h', 
      transform: function (v) { return v * 1.6 }
    }, 
    imperial: {
      unit: 'm/h', 
      ratio: 1.609344
    }
  }
};
