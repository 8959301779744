var daysInYear = 365.25;
var time = {
  ns: {
    to_anchor: 1/1000000000
  }, 
  mu: {
    to_anchor: 1/1000000
  }, 
  ms: {
    to_anchor: 1/1000
  }, 
  s: {
    to_anchor: 1
  }, 
  min: {
    to_anchor: 60
  }, 
  h: {
    to_anchor: 60 * 60 
  }, 
  d: {
    to_anchor: 60 * 60 * 24
  }, 
  week: {
    to_anchor: 60 * 60 * 24 * 7
  }, 
  month: {
    to_anchor: 60 * 60 * 24 * daysInYear / 12
  }, 
  year: {
    to_anchor: 60 * 60 * 24 * daysInYear
  }
};


module.exports = {
  metric: time , 
  _anchors: {
    metric: {
      unit: 's', 
      ratio: 1
    }
  }
};
