import React, { useState, useEffect, Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Loading, NoResults } from '../components';
import Pagination from "react-js-pagination";
import { Form, Button, Col } from "react-bootstrap";
import { CommonService } from '../services';

const Diagrams = () => {
    const itemsPerPage = 12;
    const history = useHistory();
    const { pathname } = useLocation();

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(history.location.state !== undefined ? history.location.state.page : 1);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [brandId, setBrandId] = useState(history.location.state !== undefined ? history.location.state.brandId : null);
    const [searchPattern, setSearchPattern] = useState(history.location.state !== undefined ? history.location.state.searchPattern : null);
    const [diagrams, setDiagrams] = useState([]);
    const [makes, setMakes] = useState([]);

    useEffect(() => {
        getMakes();
    }, []);

    useEffect(() => {
        getDiagrams();
    }, [page]);

    async function getDiagrams() {
        CommonService.getDiagrams(brandId, searchPattern, itemsPerPage, page)
        .then(response => {
            if (response.status === 200) {
                setTotalItemsCount(response.data.TotalRows);
                setDiagrams(response.data.Data);
                setLoading(false);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }

    async function getMakes() {
        CommonService.getMakesForDiagrams(process.env.REACT_APP_MARINE_ID).then(function(response) {
            if (response.status === 200) {
                let data = response.data.Data;
                data.sort(function(a, b) {
                    var textA = a.brandName.toUpperCase()
                    var textB = b.brandName.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                });
                setMakes(data);
            }
        }).catch(function(error) {
            console.log(error)
        });
    }

    const handleChangeSearchPattern = (value) => {
        if (value === "") {
            setSearchPattern(null);
        } else {
            setSearchPattern(value);
        }
    }
    const handleChangeBrand = (value) => {
        if (value != "") {
            setBrandId(value);
        } else {
            setBrandId(null);
        }
    }
    const goToPage = (page) => {
        setPage(page);
        history.replace({
            pathname: pathname,
            state: {
                page: page,
                brandId: brandId,
                searchPattern: searchPattern
            } 
        });
    }

    const handleSubmit = () => {
        setPage(1);
        getDiagrams();
        history.replace({
            pathname: pathname,
            state: {
                page: page,
                brandId: brandId,
                searchPattern: searchPattern
            } 
        });
    }

    return (
        <Fragment>
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-lg-10 offset-lg-1 col-xl-6 offset-xl-3">
                    <Form action="" className="diagram-filter" >
                        <React.Fragment>
                            <Form.Row>
                                <Form.Group as={Col} md="auto">
                                    <span class="h1">Diagrams</span>
                                </Form.Group>
                                <Form.Group as={Col} md="3">
                                    <Form.Control as="select" name="brand" onChange={(e) => handleChangeBrand(e.target.value)} >
                                    <option value="" >Manufacturer</option>
                                    {makes.length > 0 && makes.map((make, index) => 
                                        <option value={make.brandId} key={index} selected={make.brandId == brandId ? true : false} >{make.brandName}</option>
                                    )}
                                </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="3">
                                    <Form.Control type="text" name="searchPattern" value={searchPattern} onChange={(e) => handleChangeSearchPattern(e.target.value)} placeholder="Diagram Name" />
                                </Form.Group>
                                <Form.Group as={Col} md="auto">
                                    <Button type="button" onClick={handleSubmit} className="btn">SEARCH</Button>
                                </Form.Group>
                            </Form.Row>
                        </React.Fragment>
                    </Form>
                </div>
            </div>
        </div>
        <div className="diagrams-list">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            {loading === true ? <div className="col"><Loading /></div>
                            :
                            diagrams.length > 0 ?
                                diagrams.map((diagram, index) =>
                                    <div className="col-lg-6 col-xl-4" key={index} >
                                        <Link to={{
                                            pathname: "/diagrams/" + diagram.C_diagram_row,
                                            state: {
                                                displayName: diagram.displayname,
                                                brand: diagram.Brand,
                                                description: diagram.description,
                                                image: diagram.filename
                                            }
                                        }} className="diagram-link" >
                                            <div className="row">
                                                <div className="col-3">
                                                    <figure>
                                                        <img src={process.env.REACT_APP_DIAGRAM_IMAGE_PATH + "/" + diagram.filename} alt={diagram.displayname} />
                                                    </figure>
                                                </div>
                                                <div className="col-9">
                                                    <h3>{diagram.displayname}</h3>
                                                    {diagram.Brand ? <p>Brand: {diagram.Brand}</p> : null}
                                                    {diagram.description ? <p>Description: {diagram.description}</p> : null}
                                                </div>
                                            </div>
                                            <FontAwesomeIcon icon={faAngleRight} />
                                        </Link>
                                    </div>
                                )
                            : <NoResults />
                            }
                        </div>
                    </div>
                </div>
                {totalItemsCount > itemsPerPage ? 
                    <div className="text-center mt-2">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={page}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={goToPage}
                        />
                    </div>
                : null}
            </div>
        </div>
        </Fragment>
    )
}

export default Diagrams;