import React from 'react';
import PropTypes from "prop-types";
import { NavDropdown } from "react-bootstrap";
import MarineCategories from './marine/CategoryLinks';
import GardenCategories from './garden/CategoryLinks';
import PowerSportsCategories from './powersports/CategoryLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const MenuDropdown = ({categoryName, categoryLink, marketId, className}) => {
    const categories = {
        'marine':  MarineCategories(),
        'garden':  GardenCategories(),
        'power-sports':  PowerSportsCategories()
    };

    
    return (
        <NavDropdown title={categoryName} className={"dd-menu "+className} id={"collasible-nav-" + marketId}>
        {categories[categoryLink].slice(0, 15).map((category, index) =>
            <NavDropdown.Item 
                key={index} 
                href={"/" + categoryLink + "/" + category.id}
            >{category.name}</NavDropdown.Item>
        )}
        <NavDropdown.Item href={"/" + categoryLink} className="overview">{categoryName} overview<FontAwesomeIcon icon={faAngleRight} className="go-to-category" /></NavDropdown.Item>           
        </NavDropdown>
    )
}

MenuDropdown.propTypes = {
    categoryName: PropTypes.string.isRequired,
    categoryLink: PropTypes.string.isRequired,
    marketId: PropTypes.string.isRequired
};

export default MenuDropdown;

