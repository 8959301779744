import axios from "axios";

let NewUserAPI = axios.create({
    baseURL: process.env.REACT_APP_NEW_USER_API_URL,
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('kp_token')
    }
});

export const setAuthorizationHeader = token => {
    if (token) {
        NewUserAPI.defaults.headers.Authorization = `Bearer ${token}`;
    }
};

export default NewUserAPI;