import React from 'react';

const Returns = () => {
    return (
        <>
            <div className="text-page justify">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 offset-md-2">
                            <h1>B2B Return Policy</h1>
                            <p>Any request to return product to Dometic must be first approved by the customer service department.
                                Submit your request in writing along with a list of product(s) which you wish to return to LIT-MarineCS@dometic.com</p>
                            <ul>
                                <li>
                                    Customer must be able to provide proof of purchase from catalog.sierraparts.com. Return request for product not purchased directly from <a href="https://catalog.sierraparts.com/">www.catalog.sierraparts.com</a> will be denied
                                </li>
                                <li>
                                    All “NON-Defective” returned merchandise must have been purchased within the 3 months immediately preceding the date of return.
                                </li>
                                <li>
                                    Items must be in original standard packaging, undamaged, and in saleable condition.
                                </li>
                                <li>
                                    Credit will not be issued for broken packaging, missing parts, crushed packaging, sun-damaged or expired products.
                                </li>
                                <li>
                                    All returned product request are subject to a 25% restocking fee. Discontinued products will be allowed without a restock fee within 1 year of product(s) being obsoleted.
                                </li>
                                <li>
                                    Credit will be issued only upon inspection and solely at our discretion.
                                </li>
                            </ul>
                            <p>Upon approval, Dometic will issue an RGA (Return Goods Authorization) number and a return packing slip that will list the part numbers eligible for return.
                                UNDER NO CIRCUMSTANCES WILL CREDIT BE GIVEN WITHOUT AN RGA NUMBER. This is very important as this RGA number is used to track the package(s) through the returns process.
                                Goods returned without written authorization will be returned at the expense of the customer. To avoid delays in processing your return and to ensure proper credit,
                                please note the following guidelines:</p>
                            <ul>
                                <li>
                                    The RGA number must be marked clearly and legibly on the outside of your return.
                                </li>
                                <li>
                                    The customer is responsible for all shipping charges, handling charges and restocking fees on all returns.
                                </li>
                                <li>
                                    Any discrepancies could result in a delay or partial forfeiture of your credit.
                                </li>
                                <li>
                                    Dometic will not be responsible for products returned with damage due to improper packaging for shipment or mishandling on the part of the distributor,
                                    their freight carrier or other designated agents. Please be sure to purchase the proper freight insurance for the return package(s) and to keep proof of
                                    shipment until credit has been issued.
                                </li>
                                <li>
                                    All returned goods must be received within sixty (60) days from the date the RGA number was issued.
                                </li>
                                <li>
                                    Please allow 4 - 6 weeks for credit to be issued.
                                </li>
                            </ul>
                            <p>Dometic has the right to refuse any product that does not meet all required terms and conditions of this policy.
                                Continued abuse of the above-mentioned guidelines may result in the forfeiture of future returns.
                                Any product(s) received that does not meet all the above or have not been approved for return,
                                will be sent back to the distributor freight collect or scrapped at the distributors option without credit being issued.</p>
                            <strong><p>Return product freight prepaid to:</p></strong>
                            <h6>Dometic <br></br>
                                1 Sierra Place <br></br>
                                Litchfield, IL 62056</h6>
                            <br></br>
                        </div>
                    </div>
                    <footer class="text-center">
                        <p><strong>Contact Information.</strong>  If you have any questions, comments, or complaints concerning our
                            <br></br>return policy please contact us at the appropriate address below.  We will attempt to respond to
                            <br></br>your requests and to provide you with additional return related information.</p>
                        <br></br>
                        <p>E-Mail Contact: LIT-MarineCS@dometic.com</p>
                        Mail Contact: 1    Sierra Pl, Litchfield, IL 62056 United States
                        <br></br>Phone Contact: 1-800-648-3976
                    </footer>
                </div>
            </div></>
    );
}

export default Returns;