import React, { useState, useEffect, Fragment } from 'react';
import logo from '../../logo.svg';
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { FooterCopyright, Loading } from '../../components';
import { UserService } from "../../services";
import Moment from "moment";
import { CompanyInformation, CreateLogin, ProfileInformation, ReviewRegistration } from "../user/index";
 
const RegistrationStep = () => {
      const history = useHistory();
      const [step, setStep] = useState(1);
      const [loading, setLoading] = useState(false);
 
      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");
      const [homePhone, setHomePhone] = useState("");
      const [mobilePhone, setMobilePhone] = useState("");
      const [officePhone, setOfficePhone] = useState("");
      const [extension, setExtension] = useState("");
      const [salutation, setSalutation] = useState("");
      const [jobTitle, setJobTitle] = useState("");
      const [birthDate, setBirthDate] = useState("");
 
      const [companyName, setCompanyName] = useState("");
      const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
      const [companyWebsite, setCompanyWebsite] = useState("");
      const [companyFaxNumber, setCompanyFaxNumber] = useState("");
      const [mailingAddress1, setMailingAddress1] = useState("");
      const [mailingAddress2, setMailingAddress2] = useState("");
      const [mailingCity, setMailingCity] = useState(null);
      const [mailingState, setMailingState] = useState(null);
      const [mailingPostalCode, setMailingPostalCode] = useState("");
      const [mailingCountry, setMailingCountry] = useState("");
      const [shippingAsMailingAddress, setShippingAsMailingAddress] = useState(false);
      const [shippingAddress1, setShippingAddress1] = useState("");
      const [shippingAddress2, setShippingAddress2] = useState("");
      const [shippingCity, setShippingCity] = useState(null);
      const [shippingState, setShippingState] = useState(null);
      const [shippingPostalCode, setShippingPostalCode] = useState("");
      const [shippingCountry, setShippingCountry] = useState(null);
      //const [creditTerms, setCreditTerms] = useState("");
      //const [creditTerms, setCreditTerms] = useState("");
      const [taxExemptId, setTaxExemptId] = useState("");
      const [resellerCertificate, setResellerCertificate] = useState(null);
      const [resellerCertificateState, setResellerCertificateState] = useState(null);
      const [creditApplication, setCreditApplication] = useState(null)
 
 
      const [userMailingAddress1, setUserMailingAddress1] = useState("");
      const [userMailingAddress2, setUserMailingAddress2] = useState("");
      const [userMailingCity, setUserMailingCity] = useState("");
      const [userMailingState, setUserMailingState] = useState("");
      const [userMailingPostalCode, setUserMailingPostalCode] = useState("");
      const [userMailingCountry, setUserMailingCountry] = useState("");
      const [userShippingAsMailingAddress, setUserShippingAsMailingAddress] = useState(false);
      const [userShippingAddress1, setUserShippingAddress1] = useState("");
      const [userShippingAddress2, setUserShippingAddress2] = useState("");
      const [userShippingCity, setUserShippingCity] = useState("");
      const [userShippingState, setUserShippingState] = useState("");
      const [userShippingPostalCode, setUserShippingPostalCode] = useState("");
      const [userShippingCountry, setUserShippingCountry] = useState("");
 
      const [email, setEmail] = useState("");
      const [emailErrorMessage, setEmailErrorMessage] = useState("");
      const [emailConfirm, setEmailConfirm] = useState("");
      const [password, setPassword] = useState("");
      const [passwordConfirm, setPasswordConfirm] = useState("");
 
      const handleChangeCompanyName = (value) => setCompanyName(value);
      const handleChangeCompanyPhoneNumber = (value) => setCompanyPhoneNumber(value);
      const handleChangeCompanyWebsite = (value) => setCompanyWebsite(value);
      const handleChangeCompanyFaxNumber = (value) => setCompanyFaxNumber(value);
      const handleChangeMailingAddress1 = (value) => {
            setMailingAddress1(value);
            setUserMailingAddress1(value);
      }
      const handleChangeMailingAddress2 = (value) => {
            setMailingAddress2(value);
            setUserMailingAddress2(value);
      }
      const handleChangeMailingCity = (value) => {
            setMailingCity(value);
            setUserMailingCity(value);
      }
      const handleChangeMailingState = (value) => {
            setMailingState(value);
            setUserMailingState(value);
      }
      const handleChangeMailingCountry = (value) => {
            setMailingCountry(value);
            setUserMailingCountry(value);
      }
      const handleChangeMailingPostalCode = (value) => {
            setMailingPostalCode(value);
            setUserMailingPostalCode(value);
      }
      const handleChangeShippingAddress1 = (value) => {
            setShippingAddress1(value);
            setUserShippingAddress1(value);
      }
      const handleChangeShippingAddress2 = (value) => {
            setShippingAddress2(value);
            setUserShippingAddress2(value);
      }
      const handleChangeShippingCity = (value) => {
            setShippingCity(value);
            setUserShippingCity(value);
      }
      const handleChangeShippingState = (value) => {
            setShippingState(value);
            setUserShippingState(value);
      }
      const handleChangeShippingPostalCode = (value) => {
            setShippingPostalCode(value);
            setUserShippingPostalCode(value);
      }
      const handleChangeShippingCountry = (value) => {
            setShippingCountry(value);
            setUserShippingCountry(value);
      }
      //const handleChangeCreditTerms = (value) => setCreditTerms(value);
      //const handleChangeCreditTerms = (value) => setCreditTerms(value);
      const handleChangeTaxExemptId = (value) => setTaxExemptId(value);
 
      const handleChangeResellerCertificate = (e) => {
            const selectedFile = e.target.files[0]; // Get the first selected file
            if (selectedFile) {
                  setResellerCertificate(selectedFile);
            }
      };
 
      const handleChangeCreditApplication = (e) => {
            const selectedFile = e.target.files[0];
            if (selectedFile) {
                  setCreditApplication(selectedFile);
            }
      };
 
      const handleChangeEmail = (value) => setEmail(value);
      const handleChangeEmailConfirm = (value) => setEmailConfirm(value);
      const handleChangePassword = (value) => setPassword(value);
      const handleChangePasswordConfirm = (value) => setPasswordConfirm(value);
 
      const handleChangeFirstName = (value) => setFirstName(value);
      const handleChangeLastName = (value) => setLastName(value);
      const handleChangeHomePhone = (value) => setHomePhone(value);
      const handleChangeMobilePhone = (value) => setMobilePhone(value);
      const handleChangeOfficePhone = (value) => setOfficePhone(value);
      const handleChangeExtension = (value) => setExtension(value);
      const handleChangeSalutation = (value) => setSalutation(value);
      const handleChangeJobTitle = (value) => setJobTitle(value);
      const handleChangeBirthDate = (value) => setBirthDate(value);
      const handleChangeUserMailingAddress1 = (value) => setUserMailingAddress1(value);
      const handleChangeUserMailingAddress2 = (value) => setUserMailingAddress2(value);
      const handleChangeUserMailingCity = (value) => setUserMailingCity(value);
      const handleChangeUserMailingState = (value) => setUserMailingState(value);
      const handleChangeUserMailingCountry = (value) => setUserMailingCountry(value);
      const handleChangeUserMailingPostalCode = (value) => setUserMailingPostalCode(value);
      const handleChangeUserShippingAddress1 = (value) => setUserShippingAddress1(value);
      const handleChangeUserShippingAddress2 = (value) => setUserShippingAddress2(value);
      const handleChangeUserShippingCity = (value) => setUserShippingCity(value);
      const handleChangeUserShippingState = (value) => setUserShippingState(value);
      const handleChangeUserShippingPostalCode = (value) => setUserShippingPostalCode(value);
      const handleChangeUserShippingCountry = (value) => setUserShippingCountry(value);
      const handleChangeResellerCertificateState = (value) => setResellerCertificateState(value);
 
      const toggleShippingAsMailingAddress = () => setShippingAsMailingAddress(!shippingAsMailingAddress);
      const toggleUserShippingAsMailingAddress = () => setUserShippingAsMailingAddress(!userShippingAsMailingAddress);
      const changeStep = (value) => setStep(value);
      const profileGoBack = () => setStep(2);
      const reviewGoBack = () => setStep(3);
 
      useEffect(() => {
            if (shippingAsMailingAddress === true) {
                  setShippingAddress1(mailingAddress1);
                  setShippingAddress2(mailingAddress2);
                  setShippingCity(mailingCity);
                  setShippingState(mailingState);
                  setShippingPostalCode(mailingPostalCode);
                  setShippingCountry(mailingCountry);
 
                  setUserShippingAddress1(mailingAddress1);
                  setUserShippingAddress2(mailingAddress2);
                  setUserShippingCity(mailingCity);
                  setUserShippingState(mailingState);
                  setUserShippingPostalCode(mailingPostalCode);
                  setUserShippingCountry(mailingCountry);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [shippingAsMailingAddress])
 
      useEffect(() => {
            if (userShippingAsMailingAddress === true) {
                  setUserShippingAddress1(userMailingAddress1);
                  setUserShippingAddress2(userMailingAddress2);
                  setUserShippingCity(userMailingCity);
                  setUserShippingState(userMailingState);
                  setUserShippingPostalCode(userMailingPostalCode);
                  setUserShippingCountry(userMailingCountry);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [userShippingAsMailingAddress])
 
      async function validateAddress(street1, street2, city, state, country, zip) {
            try {
                  const res = await UserService.validateAddress(street1, street2, city, state, country, zip)
                  if (res.status === 200 && res.data.results.responseId != false) {
                        console.log("Address has been verified");
                        return res;
                  } else {
                        throw new Error("Address validation failed");
                    }      
            }
            catch (error) {
                  console.log("error verifying address " + error);
                  throw error;
            };
      }
 
 
      function readFileAsBase64(file) {
            return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                        resolve(reader.result.split(',')[1]); // Extract base64 string excluding data URL
                  };
                  reader.onerror = reject;
                  reader.readAsDataURL(file);
            });
      }
 
      async function validateCompanyInformation() {
            setLoading(true);
 
            // Convert file data to base64 string
            const resellerCertificateData = resellerCertificate ? await readFileAsBase64(resellerCertificate) : null;
            const creditApplicationData = creditApplication ? await readFileAsBase64(creditApplication) : null;
            await UserService.validateCompanyInformation(companyName, companyPhoneNumber, companyFaxNumber, companyWebsite,
                  mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingPostalCode, mailingCountry,
                  shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingPostalCode, shippingCountry, //creditTerms,
                  taxExemptId,
                  resellerCertificate ? resellerCertificate.name : null,
                  resellerCertificateData,
                  creditApplication ? creditApplication.name : null,
                  creditApplicationData)
                  .then(res => {
                        if (res.status === 200) {
                              if (res.data.results.responseId === true) {
                                    setStep(2);
                              }
                        }
                        setLoading(false);
                  }).catch(error => {
                        console.log("Error " + error);
                  });
      }
 
      async function validateLoginInformation() {
            setLoading(true);
            await UserService.validateLoginInformation(email, emailConfirm, password, passwordConfirm)
                  .then(res => {
                        if (res.status === 200) {
                              if (res.data.results.responseId === true) {
                                    setStep(3);
                              } else {
                                    const errorMessage = res.data.results.Message;
                                    setEmailErrorMessage(errorMessage);
                              }
                        }
                        setLoading(false);
                  }).catch(error => {
                        console.log("Error " + error);
                  });
      }
 
      async function validateProfileInformation() {
            setLoading(true);
            await UserService.validateProfileInformation(email, firstName, lastName,
                  userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingPostalCode, userMailingCountry,
                  userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingPostalCode, userShippingCountry)
                  .then(res => {
                        if (res.status === 200) {
                              if (res.data.results.responseId === true) {
                                    changeStep(4);
                              }
                              setLoading(false);
                        }
                  }).catch(error => {
                        console.log("Error " + error);
                  });
      }
 
      async function saveRegistration() {
            setLoading(true);
            let sBirthDate = "";
            if (birthDate) {
                  sBirthDate = Moment(birthDate).format('MM/DD/yyyy');
            }
 
            // Convert file data to base64 string
            const resellerCertificateData = resellerCertificate ? await readFileAsBase64(resellerCertificate) : null;
            const creditApplicationData = creditApplication ? await readFileAsBase64(creditApplication) : null;
 
            await UserService.saveRegistration(email, emailConfirm, password, passwordConfirm,
                  companyName, companyPhoneNumber, companyFaxNumber, companyWebsite,
                  firstName, lastName, homePhone, mobilePhone, officePhone, extension, salutation, jobTitle, sBirthDate,
                  mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingPostalCode, mailingCountry,
                  shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingPostalCode, shippingCountry,
                  userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingPostalCode, userMailingCountry,
                  userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingPostalCode, userShippingCountry,
                  taxExemptId,
                  resellerCertificateState,
                  resellerCertificate ? resellerCertificate.name : null,
                  resellerCertificateData,
                  creditApplication ? creditApplication.name : null,
                  creditApplicationData)
                  .then(res => {
                        if (res.status === 200) {
                              if (res.data.results.responseId === true) {
                                    history.push("/user/registration/confirm");
                              }
                        }
                        setLoading(false);
                  }).catch(error => {
                        console.log("Error " + error);
                  });
      }
 
      return (
            <div className="registration-form">
                  <header className="register-header">
                        <div className="container">
                              <div className="row">
                                    <div className="col-4 col-sm-3">
                                          <Link to="/" className="logo">
                                                <img src={logo} alt="logo" />
                                          </Link>
                                    </div>
                                    <div className="col-8 col-sm-6 text-center">
                                          <h1>Register</h1>
                                          <p>Step {step}/4</p>
                                    </div>
                              </div>
                        </div>
                  </header>
                  <Form>
                        <div className="container">
                              {loading === true ?
                                    <div className="row">
                                          <div className="col-md-11 offset-md-1 mt-3">
                                                <Loading />
                                          </div>
                                    </div>
                                    :
                                    <Fragment>
                                          {step === 1 ?
                                                <CompanyInformation
                                                      companyName={companyName}
                                                      companyPhoneNumber={companyPhoneNumber}
                                                      companyWebsite={companyWebsite}
                                                      companyFaxNumber={companyFaxNumber}
                                                      mailingAddress1={mailingAddress1}
                                                      mailingAddress2={mailingAddress2}
                                                      mailingCity={mailingCity}
                                                      mailingState={mailingState}
                                                      mailingPostalCode={mailingPostalCode}
                                                      mailingCountry={mailingCountry}
                                                      shippingAddress1={shippingAddress1}
                                                      shippingAddress2={shippingAddress2}
                                                      shippingCity={shippingCity}
                                                      shippingState={shippingState}
                                                      shippingPostalCode={shippingPostalCode}
                                                      shippingCountry={shippingCountry}
                                                      shippingAsMailingAddress={shippingAsMailingAddress}
                                                      //creditTerms={creditTerms}
                                                      //creditTerms={creditTerms}
                                                      taxExemptId={taxExemptId}
                                                      resellerCertificate={resellerCertificate}
                                                      resellerCertificateState={resellerCertificateState}
                                                      creditApplication={creditApplication}
                                                      handleChangeCompanyName={handleChangeCompanyName}
                                                      handleChangeCompanyPhoneNumber={handleChangeCompanyPhoneNumber}
                                                      handleChangeCompanyWebsite={handleChangeCompanyWebsite}
                                                      handleChangeCompanyFaxNumber={handleChangeCompanyFaxNumber}
                                                      handleChangeMailingAddress1={handleChangeMailingAddress1}
                                                      handleChangeMailingAddress2={handleChangeMailingAddress2}
                                                      handleChangeMailingCity={handleChangeMailingCity}
                                                      handleChangeMailingState={handleChangeMailingState}
                                                      handleChangeMailingCountry={handleChangeMailingCountry}
                                                      handleChangeMailingPostalCode={handleChangeMailingPostalCode}
                                                      handleChangeShippingAddress1={handleChangeShippingAddress1}
                                                      handleChangeShippingAddress2={handleChangeShippingAddress2}
                                                      handleChangeShippingCity={handleChangeShippingCity}
                                                      handleChangeShippingState={handleChangeShippingState}
                                                      handleChangeShippingPostalCode={handleChangeShippingPostalCode}
                                                      handleChangeShippingCountry={handleChangeShippingCountry}
                                                      //handleChangeCreditTerms={handleChangeCreditTerms}
                                                      //handleChangeCreditTerms={handleChangeCreditTerms}
                                                      handleChangeTaxExemptId={handleChangeTaxExemptId}
                                                      handleChangeResellerCertificate={handleChangeResellerCertificate}
                                                      handleChangeResellerCertificateState={handleChangeResellerCertificateState}
                                                      handleChangeCreditApplication={handleChangeCreditApplication}
                                                      toggleShippingAsMailingAddress={toggleShippingAsMailingAddress}
                                                      validateAddress={validateAddress}
                                                      validateCompanyInformation={validateCompanyInformation}
                                                />
                                                : null}
                                          {step === 2 ?
                                                <CreateLogin
                                                      email={email}
                                                      emailConfirm={emailConfirm}
                                                      emailErrorMessage={emailErrorMessage}
                                                      password={password}
                                                      passwordConfirm={passwordConfirm}
                                                      handleChangeEmail={handleChangeEmail}
                                                      handleChangeEmailConfirm={handleChangeEmailConfirm}
                                                      handleChangePassword={handleChangePassword}
                                                      handleChangePasswordConfirm={handleChangePasswordConfirm}
                                                      validateLoginInformation={validateLoginInformation}
                                                      changeStep={changeStep}
                                                />
                                                : null}
                                          {step === 3 ?
                                                <ProfileInformation
                                                      email={email}
                                                      firstName={firstName}
                                                      lastName={lastName}
                                                      homePhone={homePhone}
                                                      mobilePhone={mobilePhone}
                                                      officePhone={officePhone}
                                                      extension={extension}
                                                      salutation={salutation}
                                                      jobTitle={jobTitle}
                                                      birthDate={birthDate}
                                                      mailingAddress1={userMailingAddress1}
                                                      mailingAddress2={userMailingAddress2}
                                                      mailingCity={userMailingCity}
                                                      mailingState={userMailingState}
                                                      mailingPostalCode={userMailingPostalCode}
                                                      mailingCountry={userMailingCountry}
                                                      shippingAddress1={userShippingAddress1}
                                                      shippingAddress2={userShippingAddress2}
                                                      shippingCity={userShippingCity}
                                                      shippingState={userShippingState}
                                                      shippingPostalCode={userShippingPostalCode}
                                                      shippingCountry={userShippingCountry}
                                                      handleChangeFirstName={handleChangeFirstName}
                                                      handleChangeLastName={handleChangeLastName}
                                                      handleChangeHomePhone={handleChangeHomePhone}
                                                      handleChangeMobilePhone={handleChangeMobilePhone}
                                                      handleChangeOfficePhone={handleChangeOfficePhone}
                                                      handleChangeExtension={handleChangeExtension}
                                                      handleChangeSalutation={handleChangeSalutation}
                                                      handleChangeJobTitle={handleChangeJobTitle}
                                                      handleChangeBirthDate={handleChangeBirthDate}
                                                      handleChangeMailingAddress1={handleChangeUserMailingAddress1}
                                                      handleChangeMailingAddress2={handleChangeUserMailingAddress2}
                                                      handleChangeMailingCity={handleChangeUserMailingCity}
                                                      handleChangeMailingState={handleChangeUserMailingState}
                                                      handleChangeMailingCountry={handleChangeUserMailingCountry}
                                                      handleChangeMailingPostalCode={handleChangeUserMailingPostalCode}
                                                      handleChangeShippingAddress1={handleChangeUserShippingAddress1}
                                                      handleChangeShippingAddress2={handleChangeUserShippingAddress2}
                                                      handleChangeShippingCity={handleChangeUserShippingCity}
                                                      handleChangeShippingState={handleChangeUserShippingState}
                                                      handleChangeShippingPostalCode={handleChangeUserShippingPostalCode}
                                                      handleChangeShippingCountry={handleChangeUserShippingCountry}
                                                      toggleShippingAsMailingAddress={toggleUserShippingAsMailingAddress}
                                                      validateProfileInformation={validateProfileInformation}
                                                      profileGoBack={profileGoBack}
                                                />
                                                : null}
                                          {step === 4 ?
                                                <ReviewRegistration
                                                      companyName={companyName}
                                                      companyPhoneNumber={companyPhoneNumber}
                                                      companyWebsite={companyWebsite}
                                                      companyFaxNumber={companyFaxNumber}
                                                      mailingAddress1={mailingAddress1}
                                                      mailingAddress2={mailingAddress2}
                                                      mailingCity={mailingCity}
                                                      mailingState={mailingState}
                                                      mailingPostalCode={mailingPostalCode}
                                                      mailingCountry={mailingCountry}
                                                      shippingAddress1={shippingAddress1}
                                                      shippingAddress2={shippingAddress2}
                                                      shippingCity={shippingCity}
                                                      shippingState={shippingState}
                                                      shippingPostalCode={shippingPostalCode}
                                                      shippingCountry={shippingCountry}
                                                      //creditTerms={creditTerms}
                                                      //creditTerms={creditTerms}
                                                      taxExemptId={taxExemptId}
                                                      resellerCertificate={resellerCertificate}
                                                      resellerCertificateState={resellerCertificateState}
                                                      creditApplication={creditApplication}
                                                      email={email}
                                                      firstName={firstName}
                                                      lastName={lastName}
                                                      homePhone={homePhone}
                                                      mobilePhone={mobilePhone}
                                                      officePhone={officePhone}
                                                      extension={extension}
                                                      salutation={salutation}
                                                      jobTitle={jobTitle}
                                                      birthDate={birthDate}
                                                      userMailingAddress1={userMailingAddress1}
                                                      userMailingAddress2={userMailingAddress2}
                                                      userMailingCity={userMailingCity}
                                                      userMailingState={userMailingState}
                                                      userMailingPostalCode={userMailingPostalCode}
                                                      userMailingCountry={userMailingCountry}
                                                      userShippingAddress1={userShippingAddress1}
                                                      userShippingAddress2={userShippingAddress2}
                                                      userShippingCity={userShippingCity}
                                                      userShippingState={userShippingState}
                                                      userShippingPostalCode={userShippingPostalCode}
                                                      userShippingCountry={userShippingCountry}
                                                      saveRegistration={saveRegistration}
                                                      reviewGoBack={reviewGoBack}
                                                />
                                                : null}
                                    </Fragment>
                              }
                        </div>
                  </Form>
                  <footer>
                        <div className="container">
                              <div className="row text-center">
                                    <FooterCopyright />
                              </div>
                        </div>
                  </footer>
            </div>
      );
}
 
export default RegistrationStep;