import React from 'react';

const FooterCopyright = () => {

    const currentYear = new Date().getFullYear();

    return(
        <div className="footer-copyright">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <p>© 2001-{currentYear}  Copyright Sierra® All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterCopyright;