var convert, measures = {
  length: require('./definitions/length'), 
  area: require('./definitions/area'), 
  mass: require('./definitions/mass'), 
  volume: require('./definitions/volume'),
  each: require('./definitions/each'), 
  temperature: require('./definitions/temperature'), 
  time: require('./definitions/time'), 
  digital: require('./definitions/digital'), 
  partsPer: require('./definitions/partsPer'), 
  speed: require('./definitions/speed'), 
  pace: require('./definitions/pace'), 
  pressure: require('./definitions/pressure'), 
  current: require('./definitions/current'), 
  voltage: require('./definitions/voltage'), 
  power: require('./definitions/power'), 
  reactivePower: require('./definitions/reactivePower'), 
  apparentPower: require('./definitions/apparentPower'), 
  energy: require('./definitions/energy'), 
  reactiveEnergy: require('./definitions/reactiveEnergy'), 
  volumeFlowRate: require('./definitions/volumeFlowRate'), 
  illuminance: require('./definitions/illuminance'), 
  frequency: require('./definitions/frequency'), 
  angle : require('./definitions/angle')
}, Converter;

Converter = function (numerator, denominator) {
  if(denominator)
    this.val = numerator / denominator;
  else
    this.val = numerator;
};

/** Lets the converter know the source unit abbreviation **/
Converter.prototype.from = function (from) {
  if(this.destination)
    throw new Error('.from must be called before .to');

  this.origin = this.getUnit(from);

  if(!this.origin) {
    this.origin = from;
    this.throwUnsupportedUnitError(from);
  }

  return this;
};

/** Converts the unit and returns the value **/
Converter.prototype.to = function (to) {
  if(!this.origin)
    throw new Error('.to must be called after .from');

  this.destination = this.getUnit(to);

  var result, transform;

  if(!this.destination) {
    this.destination = to;
    this.throwUnsupportedUnitError(to);
  }

  // Don't change the value if origin and destination are the same
  if (this.origin === this.destination || this.origin.abbr === this.destination.abbr) {
    return this.val;
  }

  // You can't go from liquid to mass, for example
  if(this.destination.measure !== this.origin.measure) {
    throw new Error('Cannot convert incompatible measures of ' + this.destination.measure + ' and ' + this.origin.measure);
  }

  /** Convert from the source value to its anchor inside the system **/
  result = this.val * this.origin.unit.to_anchor;

  /**
  * For some changes it's a simple shift (C to K)
  * So we'll add it when convering into the unit (later)
  * and subtract it when converting from the unit
  */
  if (this.origin.unit.anchor_shift) {
    result -= this.origin.unit.anchor_shift
  }

  /**
  * Convert from one system to another through the anchor ratio. Some conversions
  * aren't ratio based or require more than a simple shift. We can provide a custom
  * transform here to provide the direct result
  **/
  if(this.origin.system !== this.destination.system) {
    transform = measures[this.origin.measure]._anchors[this.origin.system].transform;
    if (typeof transform === 'function') {
      result = transform(result)
    } else {
      result *= measures[this.origin.measure]._anchors[this.origin.system].ratio;
    }
  }

  /** This shift has to be done after the system conversion business **/
  if (this.destination.unit.anchor_shift) {
    result += this.destination.unit.anchor_shift;
  }

  /** Convert to another unit inside the destination system **/
  return result / this.destination.unit.to_anchor;
};

/** Finds the unit **/
Converter.prototype.getUnit = function (abbr) {
  var found;
  Object.keys(measures).some((measure, mIndex) => {
    let systems = measures[measure];
    Object.keys(systems).some((system, sIndex) => {
      if (system === '_anchors') {
        return false;
      }
      let units = systems[system];
      Object.keys(units).some((unit, uIndex) => {
        if (unit === abbr) {
          found = {
            abbr: abbr, 
            measure: measure, 
            system: system, 
            unit: units[unit]
          };
          return true;
        }
      });
      if (found) {
        return true;
      }
    });
    if (found) {
      return true;
    }
  });
  return found;
};

Converter.prototype.throwUnsupportedUnitError = function (what) {
  //console.log('Unsupported unit ' + what);
}

/** Returns the abbreviated measures that the value can be converted to. **/
Converter.prototype.measures = function () { return Object.keys(measures); };

convert = function (value) { return new Converter(value); };

module.exports = convert;