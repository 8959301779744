import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const ArticleNavigation = ({type, previousArticle, nextArticle}) => {

    return (
        <div className="col-12">
            <nav className="article-nav">
                <div className="row">
                    <div className="col-6">
                        <span className="article-type">{type}</span>
                    </div>
                    <div className="col-6 text-right">
                        {previousArticle ?
                            <Link to={{
                                pathname: "/news/article/" + previousArticle
                            }} ><FontAwesomeIcon icon={faAngleLeft} /></Link>
                        : null}
                        {nextArticle ?
                            <Link to={{
                                pathname: "/news/article/" + nextArticle
                            }} ><FontAwesomeIcon icon={faAngleRight} /></Link>
                        : null}
                    </div>
                </div>
            </nav>
        </div>
    )

}



export default ArticleNavigation;