import { useState, useEffect } from 'react';
import API from '../../utils/API';

 export const MarineCategories = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        async function getCategories() {
            const categoriesData = [];
            await API.get("/AdvancedSearch/Details/?id=getcategories", {
                params: {
                    marketid: process.env.REACT_APP_MARINE_ID,
                    embedType: process.env.REACT_APP_EMBED_TYPE
                }
            }).then(response => {
                if (response.status === 200) {
                    const categories = response.data.Data;
                    // eslint-disable-next-line no-lone-blocks
                    {categories.map((item, index) =>
                        categoriesData.push({
                            id: item.id,
                            name: item.category
                        })
                    )}
                    setCategories(categoriesData);
                    localStorage.setItem("marineCategories", JSON.stringify(categoriesData));
                }
            }).catch(function(error) {
                console.log(error);
            });
        }
        
        getCategories();
    }, []);

    return categories;
}

export default MarineCategories