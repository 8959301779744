var current;

current = {
  A: {
    to_anchor: 1
  }, 
  mA: {
    to_anchor: .001
  }, 
  kA: {
    to_anchor: 1000
  }
};

module.exports = {
  metric: current, 
  _anchors: {
    metric: {
      unit: 'A', 
      ratio: 1
    }
  }
};
