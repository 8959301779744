var metric, imperial;

metric = {
  Pa: {
    to_anchor: 1/1000
  }, 
  kPa: {
    to_anchor: 1
  }, 
  MPa: {
    to_anchor: 1000
  }, 
  hPa: {
    to_anchor: 1/10
  }, 
  bar: {
    to_anchor: 100
  }, 
  torr: {
    to_anchor: 101325/760000
  }
};

imperial = {
  psi: {
    to_anchor: 1/1000
  }, 
  ksi: {
    to_anchor: 1
  }
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 'kPa', 
      ratio: 0.00014503768078
    }, 
    imperial: {
      unit: 'psi', 
      ratio: 1/0.00014503768078
    }
  }
};
