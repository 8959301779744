import React, {useState, useEffect, Fragment} from 'react';
import { Form } from "react-bootstrap";
import { CommonService } from '../services';

const MakesDropdown = (props) => {
    const [makes, setMakes] = useState([]);

    useEffect(() => {
        window.onbeforeunload = function() {
            localStorage.removeItem("makes-" + process.env.REACT_APP_MARINE_ID);
            localStorage.removeItem("makes-" + process.env.REACT_APP_GARDEN_ID);
            localStorage.removeItem("makes-" + process.env.REACT_APP_POWERSPORTS_ID);
        }
    });

    useEffect(() => {
        const marketId = props.marketId;
        if (localStorage.getItem("makes-" + marketId) != null) {
            setMakes(JSON.parse(localStorage.getItem("makes-" + marketId)));
        } else {
            CommonService.getMakes(marketId).then(function(response) {
                if (response.status === 200) {
                    let data = response.data.Data
                    data.sort(function(a, b) {
                        var textA = a.brandName.toUpperCase()
                        var textB = b.brandName.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    });
                    localStorage.setItem("makes-" + marketId, JSON.stringify(data));
                    setMakes(data);
                }
            }).catch(function(error) {
                console.log(error)
            });
        }
    }, [props.marketId])

    return (
        <Fragment>
            <Form.Control as="select" name="brand" onChange={(e) => props.onChange(e.target.value)} isInvalid={props.errorMessage != null ? true : false} >
                <option value="" >Brand</option>
                {makes.length > 0 && makes.map((make, index) => 
                    <option value={make.brandName} key={index} selected={props.brandP === make.brandName ? true : false }>{make.brandName}</option>
                )}
            </Form.Control>
            {props.errorMessage != null ? <Form.Control.Feedback type="invalid" style={{display: 'inline'}} >{props.errorMessage}</Form.Control.Feedback> : null}
        </Fragment>
    )

}

export default MakesDropdown;
