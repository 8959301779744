import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { validate } from 'email-validator';
import { FooterCopyright, ReasonsModal } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { connect } from "react-redux";
import { loginUser, logoutUser } from "../../redux/actions";
import { getLoggedUserId } from "../../redux/selectors";

const Login  = ({ loggedUserId, loginUser }) => {
      const history = useHistory();
      const [modalShow, setModalShow] = useState(false);
      const [emailErrorMessage, setEmailErrorMessage] = useState(null);
      const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
      const [email, setEmail] = useState("");
      const [password, setPassword] = useState("");
      const [loading, setLoading] = useState(false);

      const onEmailChange = (value) => setEmail(value);
      const onPasswordChange = (value) => setPassword(value);

      useEffect(() => {
            setAuthorizationHeader(null);
      });

      useEffect(() => {
            console.log("loggedUserId: " + loggedUserId)
            if (!loggedUserId) {
                  //history.push("/");
                  console.log("Finish logging out object")
                  logoutUser();
            }
      }, [history, loggedUserId])

      async function login() {
            if (email === "") {
                  setEmailErrorMessage("Email is mandatory field");
            } else if (!validate(email)) {
                  setEmailErrorMessage("Please enter valid email address");
            } else {
                  setEmailErrorMessage(null);
            }
            if (password === "") {
                  setPasswordErrorMessage("Password is mandatory field");
            } else {
                  setPasswordErrorMessage(null);
            }
            if (email === "" || password === "") {
                  return;
            }
            setLoading(true);
            await UserService.login(email, password)
                  .then(res => {
                        if (res.status === 200) {
                              if (res.data.results.responseId === true) {
                                    loginUser(email, res.data.results.userGuid, res.data.token);
                                    setAuthorizationHeader(res.data.token);
                                    history.push("/");
                              } else {
                                    setPasswordErrorMessage(res.data.results.Message);
                              }
                        }
                        setLoading(false);
                  }).catch(error => {
                        console.log("Error " + error);
                        setLoading(false);
                  });
      }
        
      return(
            <div className="user login-form">
                  <Link to="/" className="back"><FontAwesomeIcon icon={faArrowLeft} />Back</Link>
                  <h2>Login</h2>
                  <p>Please, type in your Email - Username  and Password</p>
                  <Form>
                        <Form.Control type="text" name="email" placeholder="Email" value={email} 
                              onChange={(e) => onEmailChange(e.target.value)} 
                              className={emailErrorMessage !== null ? "is-invalid" : ""}
                              style={{marginBottom: 0}}
                        />
                        {emailErrorMessage ? <div className="invalid-feedback">{emailErrorMessage}</div> : null}
                        <Form.Control type="password" name="password" placeholder="Password" 
                              onChange={(e) => onPasswordChange(e.target.value)} 
                              className={passwordErrorMessage !== null ? "mt-2 is-invalid" : "mt-2"}
                              style={{marginBottom: 0}}
                        />
                        {passwordErrorMessage ? <div className="invalid-feedback">{passwordErrorMessage}</div> : null}
                        <Button type="button" onClick={login} className="mt-4" disabled={loading === true ? "disabled" : ""} >Login</Button>
                  </Form>
                  <p><Link to="/user/forgotpassword">Forgot password?</Link></p>
                  <p><Link to="/registration">New users - Sign up</Link></p>
                  <Button variant="primary" onClick={() => setModalShow(true)} className="modal-link">Reasons to Sign-up</Button>

                  <ReasonsModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                  />
                  <div className="row">
                        <FooterCopyright />
                  </div>
            </div>
      );
}

const mapStateToProps = store => {
      //const loggedUser = isLoggedUser(store);
      const loggedUserId = getLoggedUserId(store);
      return {loggedUserId };
}

export default connect(mapStateToProps, { loginUser })(Login);