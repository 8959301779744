// Base data element. Must contain at least these properties
export interface DataPoint {
  x: number;
  y: number | null;
}

export class LiveDataModel<T extends DataPoint> {
  // name of this buffer
  private _id: number;
  private _name: string = 'Untitled';
  private _quantityUnit: string = '';
  private _specfic: boolean;

  // raw data
  private data:any[] = [];

  constructor(id: number, name?: string, quantityUnit?: string, specific?:boolean) {
    this._id = id;
    this._name = name || 'Untitled';
    this._quantityUnit = quantityUnit || '';
    this._specfic = specific || false;
  }

  public get name() { return this._name; }

  public get id() { return this._id; }
  
  public get quantityUnit() { return this._quantityUnit; }
  public set quantityUnit(val: string) { this._quantityUnit = val; }
  
  public get length() { return this.data.length; }

  public get specific() { return this._specfic; }
  public set specific(val: boolean) { this._specfic = val; }

  public lastVisiblePoint() { return this.data[this.data.length - 1];}

  // Add new point to array. Maintains min & max values, indices and trend
  public addPoint(p: T) {
    if (!p) {
      return;
    }
    if (this.data.length === 0) {
      this.data.push({x: p.x, y: p.y});
    } else {
      this.data[0] = p; 
    }
  }
}
