import React from 'react';
import { Link } from "react-router-dom";

const ShopMarineBlock = () => {
    return(
        <section className="shop-marine-block">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-10 offset-md-1 col-lg-3 offset-lg-2">
                        <div className="image-placeholder">
                            <figure>
                                <img src={process.env.REACT_APP_CDN_PATH + "/static/images/shop.jpg"} alt="shop" />
                            </figure>
                            <Link className="sop-icon" to="/marine"></Link>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1 col-lg-5">
                        <div className="shop-info-text">
                            <p>Our industry-leading selection of over 10,000 marine products spanning from engine replacement parts to steering systems allows us to lead the way for our dealers.</p>
                            <p>Making it easier on our partners, we have deployed multiple search functionalities to choose what is right for you!</p>
                            <ul>
                                <li>Know your Sierra part number or the OEM part number? Make a quick search using our simple search bar!</li>
                                <li>Want to know what products we have available for your specific application? Use our Year, Make, Model, & Serial number.</li>
                                <li>Finally, not sure of either? Use our brand new Icon based searching to guide you to what you need!</li>
                            </ul>
                            <Link to="/marine">Search all marine products</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ShopMarineBlock;