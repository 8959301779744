
import React from 'react';
import Image from '../../assets/images/404.svg';

const Error404 = () => {
    return (
        <div className="error-page">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <h1>Error: 404 Page Not Found</h1>
                        <img src={Image} alt="404" />
                        <p>Sorry, the page you're looking for cannot be accessed.<br/>
                        Either check the URL, <a href="/">go home</a>, or feel free to <strong>report this issue</strong>.</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Error404;