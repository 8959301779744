import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from '../../assets/images/nav/Sierra-Logo.jpg';

const CategoryLink = ({categoryId, categoryName}) => {
    const catUrl = "/power-sports/" + categoryId;

    let imgSrc;
    try {
        imgSrc = require('../../assets/images/category/'+categoryId+'.svg').default;
    } catch {
            imgSrc = logo;
    }
    return (
        <div>
            <Link to={{
                pathname: catUrl,
                state: {
                    categoryId: categoryId,
                    categoryName: categoryName
                }
            }} className="category-link">
                 <img src={imgSrc} alt={categoryName} />
                
                <span>{categoryName}</span>
            </Link>
        </div>
    )
}

CategoryLink.propTypes = {
    categoryId: PropTypes.number.isRequired,
    categoryName: PropTypes.string.isRequired
};

export default CategoryLink;