//import Icon from '../../../../../../../components';
import { Icon } from '../../../../../../../components';
import LiveView from "../../../views/live/view";

const FreezeFrame = ({ fault, t, changeFreezeFrame, metricSystem }) => {
  if (!fault) {
    return <></>;
  }
  return (
    <div>
      <div className="bg-primary rounded d-flex align-items-center mb-3">
        <button onClick={() => changeFreezeFrame(null)} className="btn pt-0 pb-0">
          <Icon name='Back' height={16} width={16} />
        </button> 
        <span>{t('fault.freeze', { id: fault.id, description: fault.description })}</span>
      </div>  
      <LiveView t={t} data={fault.freezeFrame} noTitle={true} metricSystem={metricSystem} />
    </div>
  )
}

export default FreezeFrame;
