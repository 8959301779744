import React from 'react';
import icon from '../../assets/images/file/icons/pdf.png';

const ArticleBody = ({body, video, title, fileName}) => {

    return (
        <div className="article-body">
            <p>{body}</p>
            {fileName ? 
                <div className="article-files">
                    <a href={process.env.REACT_APP_NEWS_PATH + "/" + fileName + ".pdf"} >
                        <span className="file-icon">
                            <img src={icon} alt="pdf" />
                        </span>
                        <span className="file-name">{fileName}</span>
                    </a>
                </div>
            : null}
            {video ?
                <div className="video-holder">
                    <iframe width="500px" height="315" src={"https://www.youtube.com/embed/" + video} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                : null
            }
        </div>
    )

}



export default ArticleBody;