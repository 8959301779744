import React, { useEffect, useState } from "react";
import { getLoggedUserToken } from "../../redux/selectors";
import { connect } from "react-redux";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import PaymentService from "../../services/PaymentService";
import Spinner from "../Spinner";
import { useHistory } from "react-router-dom";

const CreditCardPayment = (props) => {
  const adyenDropInRef = React.createRef();

  const [OrderDetail, setOrderDetail] = useState(null);
  const [Checkout, setCheckout] = useState(null);
  const [Session, setSession] = useState(null);
  const [ShowLoading, setShowLoading] = useState(true);
  
  const history = useHistory();

  useEffect(() => {
    console.log("CreditCardPayment.useEffect.orderDetail: ", props.orderDetail);
    console.log("CreditCardPayment.useEffect.orderTotal: ", props.orderTotal);

    setOrderDetail(props.orderDetail);
    if (props.orderDetail && props.orderTotal)
      startCheckout((props.orderTotal*100), props.orderDetail).catch(console.error);

  }, [props.orderDetail, props.orderTotal]);

  useEffect(async () => {
    if (Session)
    {
      console.log("CreditCardPayment.useEffect.Session.createAdyenCheckout: ", Session);
      const checkout = await createAdyenCheckout(Session);
      setCheckout(checkout);
    }     
  }, [Session]);

  useEffect(() => {
    if (Checkout && adyenDropInRef)
    {
      console.log("CreditCardPayment.useEffect.Checkout.create: ", adyenDropInRef);
      Checkout.create("dropin", {
        onReady() {
          console.log("Dropin is ready");
        }
      }).mount(adyenDropInRef.current);
      setShowLoading(false);
    }     
  }, [Checkout]);

  async function startCheckout(total, detail) {
    console.log("CreditCardPayment.startCheckout: ", total);
    try {
      let _order = {
        Reference: detail.orderGuid,
        TotalAmount: total,
        LineItems: [
          // {
          //   Quantity: 1,
          //   AmountIncludingTax: 5000,
          //   Description: "fakeLineItem",
          // },
        ], 
      };
      let checkoutSessionResponse = await PaymentService.createSession(
        _order
      );
      setSession(checkoutSessionResponse)

    } catch (error) {
      console.info("error in StartCheckout(): " + error);
    }
  }

  function createAdyenCheckout(session) {
    const clientKey = process.env.REACT_APP_AdyenClientKey;
    const checkoutConfig = {
      environment: process.env.REACT_APP_AdyenEnvironment,
      clientKey: clientKey,
      analytics: {
        enabled: false,
      },
      setStatusAutomatically: false,
      session: session,
      showPayButton: true,
      beforeSubmit: (data, component, actions) => {
        component.setStatus('loading');
        actions.resolve(data);
      },
      onPaymentCompleted: (result, component) => {
        console.log('CreditCardPayment.onPaymentCompleted: ', result);
        switch (result.resultCode) {
          case "Authorised":
          case "Pending":
          case "Received":
            component.setStatus('success');
            history.push("/order/orderDetail/" + OrderDetail.orderGuid + "/success");                          
            break;
          default:
            component.setStatus('error', {message: 'Error Processing Transaction.'});
            break;
        }  
      },
      onError: (error, component) => {
        component.setStatus('error', {message: 'Error Processing Transaction.'});
        console.log('CreditCardPayment.onError: ', error);
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
          brands: ['mc','visa','amex'],
          styles: {
              base: {
                  // fontSize: '12px',
                  // height: '30px',
              },
          },
          data: {
            billingAddress:{
              country: "US"
            }
          }
        },
      },

    };
    return new AdyenCheckout(checkoutConfig);
  }

  return (<div>
            <Spinner showSpinner={ShowLoading} displayText={"Loading Payment Processor..."}/>
            <div ref={adyenDropInRef}></div>
          </div>);

};
const mapStateToProps = (state) => {
  const userToken = getLoggedUserToken(state);
  return { userToken };
};

export default connect(mapStateToProps)(CreditCardPayment);
