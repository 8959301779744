import React from 'react';
import { AppUtils } from '../../../../../../utils';

const TotalHoursCircle = ({ size, duration, t, cx, cy, fontSize=32 }) => {
  const color = 'white', opacity = 0.1;
  const localizedDuration = parseInt(AppUtils.localizedDurationValue(duration * 60));

  return (
    <g className="recharts-pie-total">
      <circle
        cx={cx}
        cy={cy}
        r={size || '10%'}
        fill={color}
        opacity={opacity}>
      </circle>

      <text
        x={cx}
        y={cy-fontSize}
        fontFamily="Gibson"
        fontWeight="normal"
        fontSize={fontSize / 1.5}
        textAnchor={'middle'}
        alignmentBaseline={'middle'}
        fill={color}>
        {t('total')}
      </text>

      <text
        x={cx}
        y={cy + fontSize}
        fontFamily="Gibson"
        fontWeight="normal"
        fontSize={fontSize / 1.5}
        textAnchor={'middle'}
        alignmentBaseline={'middle'}
        fill={color}>
        {localizedDuration ? t(AppUtils.localizedDurationUnit(duration * 60)+'_plural', { count: localizedDuration }) : ''}
      </text>

      <text
        x={cx}
        y={cy + fontSize / 4}
        fontFamily="Gibson"
        fontWeight="bold"
        fontSize={fontSize * 1.2}
        textAnchor={'middle'}
        alignmentBaseline={'middle'}
        fill={color}>
        {localizedDuration || '---'}
      </text>
    </g>
  );
}

export default TotalHoursCircle;