import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getPickLists, getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { addToPickList } from "../../redux/actions";
import { UserService } from '../../services';
import { setAuthorizationHeader } from '../../utils/UserAPI';

const PickListAddTo = ({productId, productDescription, productNumber, productImagePath, pLists, loggedUserId, userToken, addToPickList}) => {

    const [showDialog, setShowDialog] = useState(false);
    const [pickListId, setPickListId] = useState(-1);
    const [pickListName, setPickListName] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [quantityErrorMessage, setQuantityErrorMessage] = useState(null);
    const [showPickListNameInput, setShowPickListNameInput] = useState(true);
    const [pickLists, setPickLists] = useState([]);

    useEffect(() => {
        if (loggedUserId) {
            setAuthorizationHeader(userToken);
            loadPickLists();
        } else {
            setPickLists(pLists);
        }
    }, [productId])

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);

    const handleChangePickList = (value) => {
        setPickListId(value);
        if (value == "-1") {
            setShowPickListNameInput(true);
        } else {
            setPickListName(null);
            setShowPickListNameInput(false);
        }
    }

    const handleOnChangePickListName = (value) => {
        setPickListName(value);
    }

    const handleOnChangeQuantity = (value) => {
        if (value < 1) {
            setQuantityErrorMessage("Quantity must be greater than 0");
        } else {
            setQuantityErrorMessage(null);
        }
        setQuantity(value);
    }

    const handleAddToPickList = () => {
        if (quantity < 1) {
            return;
        }
        if (loggedUserId) {
            if (pickListId == "-1") {
                let pName = pickListName;
                if (pName == "" || pName == undefined) {
                    pName = "Pick list " + new Date().toLocaleString("en-US");
                }
                UserService.createPickList(pName, loggedUserId, productId, quantity)
                .then(res => {
                    if (res.status === 200) {
                        handleClose();
                        loadPickLists();
                    }
                }).catch(function(error) {
                    console.log(error);
                });
            } else {
                UserService.addPickListItem(pickListId, productId, quantity)
                .then(res => {
                    if (res.status === 200) {
                        handleClose();
                    }
                }).catch(function(error) {
                    console.log(error);
                });
            }
        } else {
            handleClose();
            addToPickList(pickListId, pickListName, productId, productDescription, productNumber, quantity, productImagePath, loggedUserId);
        }
    }

    function loadPickLists() {
        UserService.getPickLists(loggedUserId)
            .then(res => {
                if (res.status === 200) {
                    let pLists = [];
                    {res.data.map(item =>
                        pLists.push({
                            id: item.C_picklist_row,
                            title: item.displayName
                        })
                    )}
                    setPickLists(pLists);
                }
            }).catch(function(error) {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <button href="#" onClick={handleShow} className="add-to-pl big mb-4">+ Add to picklist</button>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Add {productNumber} to Pick List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="form-group">
                    <label>Select Pick list</label>
                    <select className="custom-select" onChange={(e) => handleChangePickList(e.target.value)} >
                        <option value="-1" >New list</option>
                        {pickLists.map((pickList, index) => 
                            <option value={pickList.id} key={index} selected={pickList.id == pickListId ? true : false } >{pickList.title}</option>
                        )}
                    </select>
                    </div>
                    {showPickListNameInput ?
                        <Fragment>
                            <div className="form-group">
                                <label>Pick list name</label>
                                <input type="text" className="form-control" value={pickListName} onChange={(e) => handleOnChangePickListName(e.target.value)} />
                            </div>
                        </Fragment>
                    : null}
                    <div className="form-group">
                        <label>Quantity</label>
                        <input type="number" min="1" max="10" className={quantityErrorMessage != null ? "form-control is-invalid" : "form-control"} value={quantity} onChange={(e) => handleOnChangeQuantity(e.target.value)} />
                        {quantityErrorMessage != null ? <div className="invalid-feedback">{quantityErrorMessage}</div> : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={handleAddToPickList}>Add to Pick List</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = state => {
    const loggedUserId = getLoggedUserId(state);
    const userToken = getLoggedUserToken(state);
    const pLists = getPickLists(state, loggedUserId);
    return { pLists, loggedUserId, userToken };
}

PickListAddTo.propTypes = {
    productId: PropTypes.number.isRequired,
    productNumber: PropTypes.string.isRequired
};

export default connect(mapStateToProps, { addToPickList })(PickListAddTo);