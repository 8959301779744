import React, { useEffect, useState } from 'react'
import { faEdit, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatalogService from '../../services/CatalogService';
import DataTableComponent from '../DataTableComponent';
import { getLoggedUserToken } from '../../redux/selectors';
import { connect } from "react-redux";
import { setCatalogAuthorizationHeader } from '../../utils/NewCatalogAPI';

const Orders = (props) => {

    const [AccountData, setAccountData] = useState([]);
    const [SearchText, setSearchText] = useState('');
    const [DataLoading, setDataLoading] = useState(false);
    const [IsAdmin, setIsAdmin] = useState(false);

    const columns = [
        {
            id:1,
            cell: (row) => <a type='button' href={handleButtonClick(row)}><FontAwesomeIcon icon={faEdit} className="fa-1x" /></a>,
            width: '3%',
            sortable:false,
    
        },
        {
            id:8,
            cell: (row) => <a type='button' onClick={() => handlePDFClick(row)} ><FontAwesomeIcon icon={faFilePdf}  size='lg' className="text-danger" /></a>,
            width: '3%',
            sortable:false,
            conditionalCellStyles: [
                {
                    when: row => row.invoiceAttachment == null,
                    style: {
                        visibility: 'hidden'
                    },
                },
            ],
    
        },
        {
            //TODO: get some type of an actual Invoice Number
            id:2,
            name: 'Invoice',
            width: '14%',
            selector: row => row.invoiceNumber ?? "",
            sortable:true
        },
        {
            id:9,
            name: 'Reference',
            width: '14%',
            selector: row => row.coNumber ?? "",
            sortable:false
        },
        {
            id:3,
            name: 'Customer',
            width: '25%',
            cell: (row) => <div className="ml-3"><div className="row mb-1">{row?.partner?.partnerName}</div><div className="row small text-muted">{row?.createdBy}</div></div>,
            sortable:true
        },
        {
            id:4,
            name: 'Items',
            width: '6%',
            selector: row => row.itemCount,
            sortable:true
        },
        {
            id:5,
            name: 'Status',
            width: '11%',
            selector: row => IsAdmin ? row.internalStatus : row.orderStatusDesc,
        },
        {
            id:6,
            name: 'Total',
            width: '11%',
            selector: row => (row.totalPricePlusShipping).toFixed(2),
            sortable:true
        },
        {
            id:7,
            name: 'Date',
            width: '11%',
            selector: row => new Date(row.updateDate).toLocaleDateString("en-US"),
            sortable:true
        },
    ];
    
    const handleButtonClick = (row) => {
        //console.log("Row Id", row.orderId);
        let url = '/order/OrderDetail/' + row.orderGuid;
        if (row.orderStatusDesc=='Cart')
        {
            url = '/order/OrderCart/' + row.orderGuid;
        }
    
        return url;
    };
    
    const handlePDFClick = (row) => {
        if (row.invoiceAttachment != null){
            CatalogService.getFile(row.invoiceAttachment)
            .then( res =>{
                console.log("handlePdfClick, ", res);
                
                return res.data;
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch(error => {
                console.error(error);
             });
        }
    }

    useEffect(() => {
        console.log("orders: useEffect: props", props);
        setSearchText(props.searchText);
        setCatalogAuthorizationHeader(props.userToken);

        if (props.isAdmin!==undefined)
        {
            if (props.isAdmin) {
                setIsAdmin(true);
                getAllOrders();
            }
            else{
                getOrders();
            }   
        }    
   }, [props.userToken, props.isAdmin])

   const getAllOrders = () => {
    setDataLoading(true);
    CatalogService.getOrdersAdmin().then(response => {
        console.log("Orders.getOrdersAdmin.Response:", response);

        //console.log(response);
        if (response.status === 200) {
            if (response.data.data)
            {
                //console.log(response.data.data);
                setAccountData(response.data.data);
                setDataLoading(false);
                return true;
            }
        }
    }).catch(function (error) {
        console.log("Orders.getOrdersAdmin.Error:");
        console.log(error);
        return error;
    });
}

    const getOrders = (userId) => {
        setDataLoading(true);
        CatalogService.getOrders().then(response => {
            console.log("Orders.getOrders.Response:", response);

            //console.log(response);
            if (response.status === 200) {
                if (response.data.data)
                {
                    //console.log(response.data.data);
                    setAccountData(response.data.data);
                    setDataLoading(false);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("Orders.getOrders.Error:");
            console.log(error);
            return error;
        });
    }

    return (
        <DataTableComponent dataLoading={DataLoading} columns={columns} data={AccountData} searchText={SearchText} sortField={7} sortAsc={false} showQuickAdd={false} showSearch={true}></DataTableComponent>
    );
};
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    return { userToken };
}

export default connect(mapStateToProps)(Orders);