import React from 'react';
import { HomeHighlightProductItem } from '../../components';
import { Link } from "react-router-dom";

const HomePowerSportsSection = () => {
    const products = [152471, 139667, 145755, 139136];

    return (
        <div>
            <div className="parallax background-title power-sports-bg">
                <h2>Power sports</h2>
            </div>
            <section className="home-section category-grid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <div className="col-sm-7">
                                    <h5>New products highlight</h5>
                                </div>
                                <div className="col-sm-5 text-right">
                                        <p>
                                            <Link to="/power-sports">Search All Categories</Link>
                                        </p>
                                </div>
                            </div>
                            <div className="row">
                                {products.map((productId, index) =>
                                    <div className="col-md-6 col-lg-3" key={index}>
                                        <HomeHighlightProductItem 
                                            key={index}
                                            productId={productId}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePowerSportsSection;