var bits, bytes;

bits = {
  b: {
    to_anchor: 1
  }, 
  Kb: {
    to_anchor: 1024
  }, 
  Mb: {
    to_anchor: 1048576
  }, 
  Gb: {
    to_anchor: 1073741824
  }, 
  Tb: {
    to_anchor: 1099511627776
  }
};

bytes = {
  B: {
    to_anchor: 1
  }, 
  KB: {
    to_anchor: 1024
  }, 
  MB: {
    to_anchor: 1048576
  }, 
  GB: {
    to_anchor: 1073741824
  }, 
  TB: {
    to_anchor: 1099511627776
  }
};

module.exports = {
  bits: bits, 
  bytes: bytes, 
  _anchors: {
    bits: {
      unit: 'b', 
      ratio: 1/8
    }, 
    bytes: {
      unit: 'B', 
      ratio: 8
    }
  }
};
