import React, { useEffect, useState } from 'react'
import DataTableComponent from '../DataTableComponent';
import { getLoggedUserToken } from '../../redux/selectors';
import { connect } from "react-redux";
import { FourthShiftService } from '../../services';
import { setCatalogAuthorizationHeader } from '../../utils/NewCatalogAPI';
import { setFourthshiftAuthorizationHeader } from '../../utils/FourthShiftAPI';

const BillingAddress = (props) => {

    const [AddressData, setAddressData] = useState([]);
    const [SearchText, setSearchText] = useState('');
    const [DataLoading, setDataLoading] = useState(false);
    const [ShowAddAddress, setShowAddAddress] = useState(false);    

    const columns = [
        {
            id:1,
            cell: (row) => <button name="radShipToId" type='radio' onClick={(e)=>handleButtonClick(e, row.shipToID)} value={row.shipToID} className='btn btn-sm btn-primary'>Use</button>,
            width: '80px',
            sortable:false,
        },
        {
            id:2,
            name: 'Name',
            selector: row => row.shipToName,
            sortable:true
        },
        {
            id:3,
            name: 'Address 1',
            selector: row => row.shipToAddress1,
            sortable:true
        },
        {
            id:4,
            name: 'Address 2',
            selector: row => row.shipToID,
        },
        {
            id:5,
            name: 'City, St Zip',
            selector: row => row.shipToCity + ", " + row.shipToState + " " + row.shipToZip,
            sortable:false
        }
    ];

    useEffect(() => {
        console.log("BillingAddress: useEffect: props", props);
        setSearchText("Find Address");
        setCatalogAuthorizationHeader(props.userToken);
        setFourthshiftAuthorizationHeader(props.userToken);
        setShowAddAddress(props.addAddressPermission);
       
        if (props.custId!==null)
            getAddresses(props.custId);
   }, [props.userToken])

   const handleButtonClick = (e, addressId) => {
    e.preventDefault();
    props.handleAddressSave(e, addressId);

    };

   const getAddresses = (custId) => {
        setDataLoading(true);

        console.log("billingAddress.getAddresses", custId);
        return FourthShiftService.getAddresses(custId).then(response => {
            console.log("billingAddress.getAddresses.Response:");

            if (response.status === 200) {
                if (response.data.data)
                {
                    //console.log(response.data.data);
                    setAddressData(response.data.data);
                    setDataLoading(false);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("billingAddress.getAddresses.Error:");
            console.log(error);
            return error;
        });
        
    }

    return (
        <div className="container">
            <DataTableComponent handleAddAddress={() => props.handleAddAddress()} showAddAddress={ShowAddAddress}  dataLoading={DataLoading} columns={columns} data={AddressData} searchText={SearchText} sortField={7} sortAsc={false} showQuickAdd={false} showSearch={true}></DataTableComponent>
        </div>       
    );
};
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    return { userToken };
}

export default connect(mapStateToProps)(BillingAddress);