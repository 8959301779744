var voltage;

voltage = {
  V: {
    to_anchor: 1
  }, 
  mV: {
    to_anchor: .001
  }, 
  kV: {
    to_anchor: 1000
  }
};

module.exports = {
  metric: voltage, 
  _anchors: {
    metric: {
      unit: 'V', 
      ratio: 1
    }
  }
};
