import WarrantyAPI from '../utils/WarrantyAPI';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async search(rgaNumber, fromDate, toDate, page) {
        return WarrantyAPI.post('/SearchRga', {
            FromDate: `${fromDate}`,
            ToDate: `${toDate}`,
            RgaNumber: `${rgaNumber}`,
            pageNumber: page
        })
    },
    async createWarrantyRequest() {
        return WarrantyAPI.get('/CreateWarrantyRequest');
    },
    async showWarrantyRequest(rgaNumber) {
        return WarrantyAPI.post('/WarrantyReview?rgaNumber=' + rgaNumber);
    },
    async showClaimItem(id) {
        return WarrantyAPI.post('/ViewItemDetails?id=' + id);
    },
    async saveClaim(rgaNumber, warrantyType, laborAmount, shopRate, laborHours, otherAmount, claimDescription) {
        return WarrantyAPI.post('/SubmitWarrantyRequest', {
            RgaNumber: `${rgaNumber}`,
            CurrentStatus: "New",
            LaborAmount: laborAmount,
            ShopRate: shopRate,
            LaborHours: laborHours,
            OtherAmount: otherAmount,
            ClaimDescription: claimDescription ? `${claimDescription}` : "",
            WarrantyType: {
                SelectedId: `${warrantyType}`
            }
        })
    },
    async saveClaimItem(rgaNumber, itemNumber, quantity, reason, requestType) {
        return WarrantyAPI.post('/MaintainLineItem', {
            Action: "AddLineItem",
            RgaNumber: `${rgaNumber}`,
            Item: `${itemNumber}`,
            Quantity: `${quantity}`,
            Reason: `${reason}`,
            RequestType: {
                SelectedId: `${requestType}`
            }
        })
    },
    async getClaimItem(claimId) {
        return WarrantyAPI.get('/EditLineItemModal?id=' + claimId);
    },
    async deleteClaimItem(rgaNumber, claimId) {
        return WarrantyAPI.post('/DeleteLineItem', {
            rga: `${rgaNumber}`,
            id: `${claimId}`
        })
    },
    async getWarrantyRequest(rgaNumber) {
        return WarrantyAPI.get('/MaintainWarrantyRequest?rga=' + rgaNumber);
    },
    async uploadFile(rgaNumber, file) {
        let formData = new FormData();
        formData.append('uploadFile', file);
        formData.append('description', file.name);
        return WarrantyAPI.post('/UploadFile?rga=' + rgaNumber, formData);
    },
    async deleteAttachment(attachmentId, rgaNumber) {
        return WarrantyAPI.post('/DeleteAttachment/' + attachmentId, {
            rga: `${rgaNumber}`
        })
    },
    async downloadAttachment(attachmentId, rgaNumber) {
        return WarrantyAPI.post('/DownloadAttachment/' + attachmentId, {
            rga: `${rgaNumber}`
        }, {
            responseType: 'blob'
        });
    },
    async printPackingSlip(rgaNumber) {
        return WarrantyAPI.get('/PrintPackingSlip?rga=' + rgaNumber);
    }    
}