/* eslint-disable eqeqeq */
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { PickListItem, PickListHeader, PickListRename, PickListDelete, PickListExport, Loading } from '../components';
import { getPickLists, getLoggedUserId, getLoggedUserToken } from "../redux/selectors";
import { CommonService, UserService } from '../services';
import { setAuthorizationHeader } from '../utils/UserAPI';

const PickList = ({ pLists, loggedUserId, userToken }) => {
    const [selectedPickListId, setSelectedPickListId] = useState();
    const [selectedPickListName, setSelectedPickListName] = useState();
    const [pickLists, setPickLists] = useState([]);
    const [products, setProducts] = useState([]);
    const [productsTemp, setProductsTemp] = useState([]);
    const [numberOfProducts, setNumberOfProducts] = useState(0);
    const [counter, setCounter] = useState(0);
    const [total, setTotal] = useState(0);
    const [productsLoading, setProductsLoading] = useState(false);

    useEffect(() => {
        setProducts([]);
        loadPickLists();
    }, [])
    
    useEffect(() => {
        populatePickListProducts();
        calculateTotal();
    }, [selectedPickListId])

    useEffect(() => {
        if (loggedUserId) {
            if (productsTemp.length == numberOfProducts) {
                let data = productsTemp;
                data.sort(function(a, b) {
                    var textA = a.modelNumber.toUpperCase()
                    var textB = b.modelNumber.toUpperCase()
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                });
                setProducts(data);
                setProductsLoading(false);
                calculateTotal();
            }
        }
    }, [counter, productsTemp])

    const handleChangePickList = (value) => {
        setSelectedPickListId(value);
        let picklist = pickLists.find(obj => obj.id == value);
        setSelectedPickListName(picklist.title);
    }

    async function populatePickListProducts() {
        console.log("populatePickListProducts");
        setProducts([]);
        setProductsTemp([]);
        setProductsLoading(true);
        if (selectedPickListId != "-1") {
            let picklist = pickLists.find(obj => obj.id == selectedPickListId);
            if (picklist == undefined) {
                picklist = pickLists[0];
            }
            if (picklist != undefined) {
                setSelectedPickListId(picklist.id);
                setSelectedPickListName(picklist.title);
                if (loggedUserId) {
                    UserService.getPickListItems(picklist.id, loggedUserId)
                    .then(res => {
                        if (res.status === 200) {
                            const tbPickListLineItems = res.data.tbPickListLineItems.filter(item => item.C_item_row > 0);
                            setNumberOfProducts(tbPickListLineItems.length);
                            setCounter(tbPickListLineItems.length);
                            let lProducts = [];
                            {tbPickListLineItems.map(item =>
                                CommonService.getProductDetails(item.C_item_row)
                                .then(pRes => {
                                    if (pRes.status === 200) {
                                        let price = null;
                                        
                                        const product = pRes.data.Data[0];
                                        let imagePath = null;
                                        const images = product.imageDetails.filter(item => item.fileName.indexOf("pdf") < 0);
                                        if (images) {
                                            imagePath = images[0].fileName;
                                        }
                                        CommonService.getProductAdditionalInformation(item.C_item_row)
                                        .then(paiResponse => {
                                            if (paiResponse.status === 200) {
                                                const productSpecs = paiResponse.data.Data[0].productSpecs;
                                                price = productSpecs.length > 0 && productSpecs[0].listPrice != null && loggedUserId ? 
                                                productSpecs[0].listPrice
                                                : null
                                            }
                                            lProducts.push({
                                                productId: item.C_item_row,
                                                pickListItemId: item.C_picklist_li_row,
                                                quantity: item.qty,
                                                description: product.descriptionShort,
                                                modelNumber: product.sale_item,
                                                imagePath: imagePath,
                                                price: price
                                            })
                                            setProductsTemp(lProducts);
                                            setCounter(lProducts.length);
                                        });
                                    }           
                                })
                            )}
                        }
                    }).catch(function(error) {
                        console.log(error);
                    });
                } else {
                    let lProducts = [];
                    {picklist.products.map(item =>
                        lProducts.push({...item, pickListItemId: item.productId})
                    )}
                    setProducts(lProducts);
                    setProductsLoading(false);
                }
            }
        }
        calculateTotal();
    }

    function loadPickLists(selectedId) {
        if (loggedUserId) {
            setAuthorizationHeader(userToken);
            UserService.getPickLists(loggedUserId)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        let pLists = [];
                        {res.data.map(item =>
                            pLists.push({
                                id: item.C_picklist_row,
                                title: item.displayName
                            })
                        )}
                        setPickLists(pLists);
                        if (selectedId) {
                            setSelectedPickListId(selectedId);
                        } else {
                            if (pLists.length > 0) {
                                setSelectedPickListId(pLists[0].id);
                            }
                        }
                    }
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            setPickLists(pLists);
            if (pLists.length > 0) {
                setSelectedPickListId(pLists[0].id);
            }
        }
        calculateTotal();
    }
    function calculateTotal() {
        if (products.length > 0) {
            let plTotal = 0;
            {products.map(item =>
                plTotal = plTotal + item.price * item.quantity
            )}
            setTotal(plTotal);
        }
    }

    return (
        <Fragment>
            <div className="search-results">
                <PickListHeader />
                {pickLists.length > 0 ?
                <div className="container">
                    <div className="row mb-2">
                        <div className="col offset-md-2">
                            {pickLists.length > 0 ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            <select className="select-picklist" onChange={(e) => handleChangePickList(e.target.value)} >
                                                {pickLists.map((pickList, index) => 
                                                    <option value={pickList.id} key={index} selected={selectedPickListId == pickList.id ? true : false} >{pickList.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col picklist-actions">
                                        <PickListRename 
                                            pickListId={selectedPickListId}
                                            pickListTitle={selectedPickListName}
                                            callBackFunction={loadPickLists}
                                        />
                                        <PickListDelete className="remove-pl" 
                                            pickListId={selectedPickListId}
                                            callBackFunction={loadPickLists}
                                        />
                                        <PickListExport
                                            pickListId={selectedPickListId}
                                            pickListTitle={selectedPickListName}
                                            products={products}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                            : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="row">
                                <div className="col-sm-12">
                                    {productsLoading === true ? <Loading /> :
                                        <div className="results pl-results">
                                            <div className="pl-header">
                                                <div className="row">
                                                    <div className="l-count">
                                                        {products.length} items
                                                    </div>
                                                    <div className="col all">
                                                    <div className="result-id">
                                                    </div>
                                                    <div className="result-description">
                                                    </div>
                                                    <div className="result-id float-right">
                                                        quantity
                                                    </div>
                                                    </div>
                                                    <div className="result-action text-center">
                                                        remove
                                                    </div>
                                                </div>
                                            </div>
                                            {products.length > 0 ?
                                                products.map((product, index) =>
                                                    <PickListItem 
                                                        key={index}
                                                        index={index}
                                                        pickListId={selectedPickListId}
                                                        id={product.productId}
                                                        pickListItemId={product.pickListItemId}
                                                        description={product.description}
                                                        modelNumber={product.modelNumber}
                                                        quantity={product.quantity}
                                                        imagePath={product.imagePath}
                                                        price={product.price}
                                                        callBackFunction={populatePickListProducts}
                                                    />
                                                )
                                                
                                            : <span className="empty-picklist">Pick list is empty</span>}
                                            
                                            {loggedUserId && total > 0 && products.length > 0 ? 
                                                <div className="pl-footer">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <strong>Total:</strong>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                            <strong>${total}</strong>
                                                        </div>
                                                    </div>
                                                </div> :
                                                null}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : 
                <div className="container">
                    <div className="row mb-2">
                        <div className="col"><p className="lead text-center">There are no saved picklists.</p></div>
                    </div>
                </div>
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    const loggedUserId = getLoggedUserId(state);
    const userToken = getLoggedUserToken(state);
    const pLists = getPickLists(state, loggedUserId);
    return { pLists, loggedUserId, userToken };
}

export default connect(mapStateToProps)(PickList);