import React, { Fragment, useState, useEffect } from 'react';
import { connect } from "react-redux";
import logo from '../../assets/images/icli.png';
import { Link, useHistory } from "react-router-dom";
import { UserService } from '../../services';
import { logoutUser } from '../../redux/actions';
import { getLoggedUserId, getLoggedUserToken } from "../../redux/selectors";
import { Loading } from '../../components';
import { setAuthorizationHeader } from '../../utils/UserAPI';

const UltraProfile = ({ loggedUserId, userToken, logoutUser }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [partnerId, setPartnerId] = useState();
    const [role, setRole] = useState("");
    const [userId, setUserId] = useState("");
    const [firstName, setFirstName] = useState();
    const [companyName, setCompanyName] = useState();
    const [warrantyAccess, setWarrantyAccess] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (loggedUserId) {
            setAuthorizationHeader(userToken);
            UserService.getAccountManagementInfo(loggedUserId)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.results.responseId === false) {
                        logoutUser();
                        history.push("/user/login");
                    } else {
                        const data = res.data.data;
                        const userData = data.UserModelObject;
                        const companyData = data.CompanyModelObject;
                        const permissionsData = res.data.data.ParticipantPermissionsModelObject.AllPermissions.filter(item => item.ParticipantId === userData.Id);
                        if (permissionsData !== undefined) {
                            setWarrantyAccess(permissionsData[0].HasWarrantyAccess);
                        }
                        setPartnerId(companyData.PartnerId);
                        setRole(userData.Role);
                        setFirstName(userData.FirstName);
                        setCompanyName(companyData.PartnerName);
                        setUserId(userData.Id);
                        window.sessionStorage.setItem("accountManagementInfo-" + loggedUserId, JSON.stringify(data));
                    }
                }
                setLoading(false);
            }).catch(error => {
                console.log("Error " + error);
            });
        }
    }, [loggedUserId])

    return (
        <div className="profile">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 text-center">
                        {loading === false ?
                            <Fragment>
                                <h1>Welcome {firstName}</h1>
                                <h5>{companyName}</h5>
                                <p style={{display: 'none'}} >Praesent nulla quam, imperdiet ac libero ut, scelerisque vehicula metus.</p>
                                <p style={{display: 'none'}}  >Since Your last visit we have new 234 products and updates you might be interested in… prodcuts Vivamus tellus quam, lacinia eu viverra in, pharetra vel sapien. in Praesent nulla quam, imperdiet ac libero ut, scelerisque vehicula metus. Vivamus tellus quam, lacinia eu viverra in, pharetra vel sapien.Since Your last visit we have new 234 products and updates you might be interested in… prodcuts Vivamus tellus quam, lacinia eu viverra in, pharetra vel sapien. in Praesent nulla quam, imperdiet ac libero ut, scelerisque vehicula metus. Vivamus tellus quam, lacinia eu viverra in, pharetra vel sapien.</p>
                                <div className="row int-list">
                                    <div className="col-sm-4">
                                        <Link to="/profile/personal">
                                            <img src={logo} alt="Personal Profile" />
                                            <h4>Personal Profile</h4>
                                        </Link>
                                    </div>
                                    <div className="col-sm-4">
                                        <Link to="/profile/company" >
                                            <img src={logo} alt="Company Profile" />
                                            <h4>Company Profile</h4>
                                        </Link>
                                    </div>
                                    {role === "Principal" ?
                                        <Fragment>
                                            <div className="col-sm-4">
                                                <Link to="/profile/users/manage" >
                                                    <img src={logo} alt="Manage users"/>
                                                    <h4>Manage users</h4>
                                                </Link>
                                            </div>
                                            <div className="col-sm-4">
                                                <Link to="/profile/permissions/participant" >
                                                    <img src={logo} alt="Participant Permissions" />
                                                    <h4>Participant Permissions</h4>
                                                </Link>
                                            </div>
                                            <div className="col-sm-4">
                                                <Link to={{
                                                    pathname: "/profile/users/invite",
                                                    state: {
                                                        partnerId: partnerId
                                                    }
                                                }} >
                                                    <img src={logo} alt="Invite User" />
                                                    <h4>Invite User</h4>
                                                </Link>
                                            </div>
                                        </Fragment>
                                    : null}
                                    {userId !== "" ? 
                                    <div className="col-sm-4">
                                        <Link to="/profile/password/change" >
                                            <img src={logo} alt="Change Password" />
                                            <h4>Change Password</h4>
                                        </Link>
                                    </div>
                                    : null}
                                </div>
                            </Fragment>
                        : <Loading />}
                        <h5>TOOLS</h5>
                        <div className="row int-list">
                            <div className="col-sm-4">
                                <Link to="/account/user">
                                    <img src={logo} alt="Order History" />
                                    <h4>View Order History</h4>
                                </Link>
                            </div>
                            <div className="col-sm-4" style={{display: 'none'}}>
                                <Link to="">
                                    <img src={logo} alt="Search History" />
                                    <h4>Search History</h4>
                                </Link>
                            </div>
                            {warrantyAccess === false ? null :
                                <Fragment>
                                    <div className="col-sm-4">
                                        <Link to="/warranty-tracker" >
                                            <img src={logo} alt="Warranty Tracker" />
                                            <h4>Warranty Tracker</h4>
                                        </Link>
                                    </div>
                                    <div className="col-sm-4">
                                        <Link to="/claim-request" >
                                            <img src={logo} alt="Claim Request" />
                                            <h4>Claim Request</h4>
                                        </Link>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUserId = getLoggedUserId(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUserId, userToken };
}

export default connect(mapStateToProps, { logoutUser })(UltraProfile);