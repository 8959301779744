import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";

const ClaimItemDelete = (props) => {
    const [showDialog, setShowDialog] = useState(false);

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);

    const handleConfirm = () => {
        handleClose();
        props.deleteClaimItem(props.cartItemId);
    }

    return (
        <Fragment>
            <Button onClick={handleShow} className="btn btn-danger ml-1">
                <FontAwesomeIcon icon={faMinus} />
            </Button>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Are you sure want to delete item?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleConfirm} >
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default ClaimItemDelete;