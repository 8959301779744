import React, { useState, useEffect } from 'react';
import logo from '../logo.svg';
import { faBullhorn, faUser, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedUser, getLoggedUserName, getLoggedUserToken } from "../redux/selectors";
import { logoutUser } from '../redux/actions';
import { Navbar, NavDropdown, Modal, Form, Alert, Carousel, CarouselItem } from "react-bootstrap";
import SidebarLinks from "./sidebarLinks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { setAuthorizationHeader } from '../utils/UserAPI';
import { UserService, CatalogService } from '../services';
import { PERMISSIONS } from '../utils/constants';

const Header = ({ loggedUser, logoutUser, userName, userToken }) => {

    const [show, setShow] = useState(false);
    const [optedIn, setOptedIn] = useState(false);
    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setOptedIn(false);
    } 
    const handleShow = () => setShow(true);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");

    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("Great job!");
    const [variant, setVariant] = useState("success");
    const [validated, setValidated] = useState(false);

    const [UserInfo, setUserInfo] = useState(null);
    const [HasCartAccess, setHasCartAccess] = useState(false);
    const [HasUltraAccess, setHasUltraAccess] = useState(false);
    const [Promotions, setPromotions] = useState([]);
    const [ActivePromoName, setActivePromoName] = useState("");
    const [ActivePromoDescription, setActivePromoDescription] = useState("");
    const [ActivePromoStartDate, setActivePromoStartDate] = useState("");
    const [ActivePromoEndDate, setActivePromoEndDate] = useState("");
    const [showPromo, setShowPromo] = useState(false);
    const handleClosePromo = () => {
        setShowPromo(false);
        setActivePromoName("");
        setActivePromoDescription("");
        setActivePromoStartDate("");
        setActivePromoEndDate("");
    }

    const { pathname } = useLocation();
    const history = useHistory();

    const handleLogout = () => {
        logoutUser();
        history.push("/");
    }

    const [isActiveSidebar, setActiveSidebar] = useState(false);

    //check if "Address" field is filled in. If so, don't send request and display a message. 
    const SendEmailForm = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const form = e.currentTarget;

        setValidated(true);
            
        if (address.length < 1 && form.checkValidity() === true) {
            console.log(optedIn);
            axios({
                method: 'post',
                url: e.target.action,
                data: JSON.stringify({
                    Email: email,
                    PhoneNumber: phone,
                    FirstName: firstName,
                    LastName: lastName,
                    OptIn: optedIn
                }),
                headers: {
                    'Content-Type': 'application/JSON',
                }
            }).then(res => {
                if (res.data.Status === "Success") {
                    setMessage(res.data.Message);
                    setVariant("success");
                    setShowAlert(true);
    
                    setTimeout(function () {
                        setShowAlert(false);
                        handleClose();
                    }, 2000);
    
                } else {
                    setMessage(res.data.Message);
                    setVariant("danger");
                    setShowAlert(true);
                    setTimeout(function () {
                        setShowAlert(false);
                    }, 2000);
                }
            }).catch(error => {
                console.log(error);
            });
        } 
    };

    useEffect(() => {
        if (loggedUser)
        {
            setAuthorizationHeader(userToken);

            if (UserInfo==null)
            {
                console.log("userInfo.get: ", userToken);
                UserService.getUserInfo().then(user => {
                    console.log("userInfo.get.response: ", user.data);
                    if (user.status == 200)
                    {
                        if (user.data.results && user.data.results.Message &&
                            user.data.results.Message.search("not authorized")>0)
                            {
                                handleLogout();
                            }
                        setUserInfo(user.data.data);

                        if (user.data.data?.Permissions.some(i=>i.PermissionGroupsId==PERMISSIONS.ShoppingCartAccess))
                            setHasCartAccess(true);

                        if (user.data.data?.Permissions.some(i=>i.PermissionGroupsId==PERMISSIONS.HasUltraAccess))
                            setHasUltraAccess(true);
                    }
                });
            
            }
        }
        //getPromotions(); !USED FOR PROMOTIONS!
    }, [userToken]);


    useEffect(() => {
        closeSidebar();
    }, [pathname])

    const toggleSidebar = () => setActiveSidebar(!isActiveSidebar);
    const closeSidebar = () => setActiveSidebar(false);

    const sidebarDropdownTitle = (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="3" width="20" height="2"></rect>
        <rect y="9" width="20" height="2"></rect>
        <rect y="15" width="20" height="2"></rect>
    </svg>);

    const getPromotions = () => {
        CatalogService.getActivePromotions().then(res => {
            if (res.status == 200){
                setPromotions(res.data.data);
            }
        });
    }

    const handlePromotionOnClick = (promotion) => { //maybe change to just setPromotion and conditionally render in the modal
        setActivePromoName(promotion.promotionName);
        setActivePromoDescription(promotion.description);
        setActivePromoStartDate(new Date(promotion.startDate).toLocaleDateString('en-US', {timeZone: 'UTC' }));
        setActivePromoEndDate(new Date(promotion.endDate).toLocaleDateString('en-US', {timeZone: 'UTC' }));
        setShowPromo(true);
    }

    return (
        <header className="App-header">
            <SidebarLinks cn={isActiveSidebar === false ? "sidebar-links" : "sidebar-links active"} toggleSidebar={toggleSidebar} />
            <div className="header-top">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg-7">
                            <div className="row align-items-center">
                                <div className="col hamburger">
                                    <Navbar>
                                        <NavDropdown title={sidebarDropdownTitle} className="sidebar-dd" id="sidebar">
                                            <NavDropdown.Item className="close">
                                                <FontAwesomeIcon icon={faTimes} className="close-sidebar" />
                                            </NavDropdown.Item>                                           
                                            {/* todo:  also check for elevated status */}
                                            {/* {loggedUser === true ?
                                                <NavDropdown.Item href="/admin" className="sidebar-category">Admin Dashboard <FontAwesomeIcon icon={faAngleRight} /></NavDropdown.Item>
                                                : null} */}
                                            <NavDropdown.Header>CATEGORIES</NavDropdown.Header>
                                            <NavDropdown.Item href="/marine" className="sidebar-category">Marine <FontAwesomeIcon icon={faAngleRight} /></NavDropdown.Item>
                                            <NavDropdown.Item href="/power-sports" className="sidebar-category">Power sports <FontAwesomeIcon icon={faAngleRight} /></NavDropdown.Item>
                                            <NavDropdown.Item href="/garden" className="sidebar-category">Garden <FontAwesomeIcon icon={faAngleRight} /></NavDropdown.Item>

                                            <NavDropdown.Header>In focus</NavDropdown.Header>
                                            {loggedUser === false ?
                                                <NavDropdown.Item href="/user/login">Login to Sierra Pro</NavDropdown.Item>
                                                : null}
                                            {HasCartAccess && loggedUser === true ?
                                                <div>
                                                    {UserInfo?.IsAdmin===true ? (
                                                        <div>
                                                        <NavDropdown.Item href="/account/admin">Admin Order History</NavDropdown.Item>
                                                        {/* <NavDropdown.Item href="/promotions">Manage Promotions</NavDropdown.Item> */}
                                                        </div>
                                                    ) : null}
                                                    <NavDropdown.Item href="/account/user">View Order History</NavDropdown.Item>
                                                    <NavDropdown.Item href="/order/quickorder">Quick Order</NavDropdown.Item>
                                                </div>
                                                : ""
                                            }
                                             
                                            {loggedUser === true && 
                                                HasUltraAccess ?
                                                <div>
                                                    <NavDropdown.Item href="/ultra/home">Ultra</NavDropdown.Item>
                                                </div>
                                            :""
                                            }
                                            <NavDropdown.Item href="/diagrams">Diagrams and Exploded Views</NavDropdown.Item>
                                            <NavDropdown.Item href="/news">News</NavDropdown.Item>
                                            <NavDropdown.Item href="#" onClick={handleShow}>Subscribe</NavDropdown.Item>
                                            <a href="http://www.seastarbom.com" target="_blank" rel="noreferrer" className="dropdown-item">Hydraulic Steering System Builder</a>
                                            <NavDropdown.Item href="/marine-pdf">2023 Sierra Catalog</NavDropdown.Item>
                                            <NavDropdown.Item href="/primeline-pdf">2021 Primeline Catalog</NavDropdown.Item>
                                            <NavDropdown.Header>Help</NavDropdown.Header>
                                            <NavDropdown.Item href="/terms">Terms & Conditions</NavDropdown.Item>
                                            <NavDropdown.Item href="/terms#privacy">Privacy Policy</NavDropdown.Item>
                                            <NavDropdown.Item href="/returns">Return Policy</NavDropdown.Item>
                                            {loggedUser === false ?
                                                <NavDropdown.Item href="/warranty-policy">Warranty</NavDropdown.Item>
                                                : <NavDropdown.Item href="/warranty">Warranty</NavDropdown.Item>}
                                            <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
                                        </NavDropdown>
                                    </Navbar>                                   
                                </div>
                                <div className="col col-logo">
                                    <Link to="/" className="logo">
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <div className="col">
                                    <Carousel /*prevIcon="" nextIcon=""*/ controls={false} fade={true} interval="6000">
                                        <CarouselItem width="300px" >
                                            <div className="col">
                                                <span>View our large product assortment that span three different markets: &shy;Marine, Lawn & Garden, & Powersports</span>
                                            </div>
                                        </CarouselItem>
                                        {/* {Promotions.map((item, index) => {
                                        return (
                                            (item.promotionName != null && item.bannerText != null) ?
                                            <Carousel.Item key={index} role="button" onClick={() => handlePromotionOnClick(item)}>
                                                <div className="col">
                                                    <span>
                                                        <h4>{item.promotionName}</h4>
                                                        <p>{item.bannerText}</p>
                                                    </span>
                                                </div>
                                            </Carousel.Item> 
                                            : null                                     
                                        );
                                        })} */}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-5 text-right" >
                            <ul className="list-none inline-block uppercase top-links">
                                <li className="news-link">
                                    <a href="#" onClick={handleShow}>
                                        <FontAwesomeIcon icon={faEnvelope} /> Subscribe
                                    </a>
                                </li>
                                <li className="news-link">
                                    <Link to="/news">
                                        <FontAwesomeIcon icon={faBullhorn} /> News
                                    </Link>
                                </li>

                                <li className="user-menu">
                                    {loggedUser === false ?
                                        <a href="/user/login"><FontAwesomeIcon icon={faUser} /> Log in</a>
                                        :
                                        <NavDropdown title={userName.substring(0, userName.lastIndexOf("@"))} className="user-dd">
                                            <NavDropdown.Item
                                                href="/profile"
                                            >Profile</NavDropdown.Item>
                                            <NavDropdown.Item
                                                onClick={e => handleLogout()}
                                                href="/"
                                            >Log out</NavDropdown.Item>
                                        </NavDropdown>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} className="email-signup-form ">
                <Modal.Header closeButton>
                    <Modal.Title>Subscribe to updates from Sierra.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form action="/searchapi/api/emaillist/signup" method="POST" noValidate validated={validated} onSubmit={SendEmailForm}>
                        <Form.Group className="mb-3" controlId="Email">

                            <Form.Control required type="email" placeholder="Your Email Address" name="Email" onChange={(e) => setEmail(e.target.value)} />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="grpFirstName">
                            <Form.Control required type="text" placeholder="First Name" name="FirstName" onChange={(e) => setFirstName(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="grpLastName">
                            <Form.Control required type="text" placeholder="Last Name" name="LastName" onChange={(e) => setLastName(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter your last name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="grpPhone">
                            <Form.Control required type="text" placeholder="Phone" name="PhoneNumber" onChange={(e) => setPhone(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please enter a valid phone number.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="grpOptIn">
                            <Form.Check type="checkbox" label="I consent to receiving e-mails from Dometic." name="OptIn" onClick={() => setOptedIn(!optedIn)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="grpAddress" onChange={(e) => setAddress(e.target.value)}>
                            <Form.Control type="hidden" placeholder="Address" name="Address" hidden />
                        </Form.Group>
                        <button className="btn btn-primary" type="submit" disabled={optedIn === false}>
                            SUBMIT
                        </button>
                    </Form>
                    <br/>
                    <Alert show={showAlert} variant={variant}>
                        {message}
                    </Alert>
                </Modal.Body>
            </Modal>

            <Modal show={showPromo} onHide={handleClosePromo} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{ActivePromoName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{ActivePromoDescription}</p>
                    <p className="text-muted">This offer is valid between {ActivePromoStartDate} and {ActivePromoEndDate}.</p>
                </Modal.Body>
            </Modal>
        </header>
    );
}


const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userName = getLoggedUserName(store);
    const userToken = getLoggedUserToken(store)
    return { loggedUser, userName, userToken };
}

export default connect(mapStateToProps, { logoutUser })(Header);