import { TextField } from '@material-ui/core';

const FilterComponent = ({ searchText, filterText, onFilter, onClear }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder={searchText}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}

        />

    </>
);

export default FilterComponent;