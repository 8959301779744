import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isLoggedUser, getLoggedUserToken } from "../../redux/selectors";
import { useBarcode } from '@createnextapp/react-barcode';
import { WarrantyService } from '../../services';
import { setAuthorizationHeader } from '../../utils/WarrantyAPI';
import { Loading } from '../../components';

const Barcode = ({ loggedUser, userToken }) => {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [companyName, setCompanyName] = useState();
    const [name, setName] = useState();
    const [address1, setAddress1] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [zipCode, setZipCode] = useState();
    const [sendDate, setSendDate] = useState();
    const [message, setMessage] = useState(false);

    let { rgaNumber } = useParams();
    const { inputRef } = useBarcode({
        value: rgaNumber
    });
    
    const print = () => {
        window.print();
    }

    useEffect(() => {
        if (loggedUser === false) {
            history.push("/user/login");
        }
        printPackingSlip();
    }, [history, loggedUser]);

    async function printPackingSlip() {
        setLoading(true);
        setAuthorizationHeader(userToken);
        await WarrantyService.printPackingSlip(rgaNumber)
            .then(res => {
                if (res.status === 200) {
                    const data = res.data;
                    if (data.success == false) {
                        if (data.message != '') {
                            setMessage(data.message);
                        }
                        setLoading(false);
                    } else {
                        setCompanyName(data.CompanyName);
                        setName(data.Name);
                        setAddress1(data.Address1);
                        setCity(data.City);
                        setState(data.State);
                        setZipCode(data.ZipCode);
                        setSendDate(data.SendDate);
                        setMessage(false);
                        setLoading(false);
                    }
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }
    console.log(message)

    return (
        <div className="print-page">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">
                        {message ?
                            <div className="rga-error">
                                <p>{message}</p> 
                            </div>
                        :
                            <div>
                                <div className="actions-block">
                                    <button className="btn btn-default btn-home" onClick={print}>Print Page</button>
                                    <Link to={{
                                        pathname: "/warranty/" + rgaNumber
                                    }} className="cancel">Back</Link>
                                </div>
                                
                                <div className="print-details text-center">
                                    <div className="barcode">
                                        <div className="bc-info">
                                            <h1>RGA #: {rgaNumber}</h1>
                                            <table>
                                                <tr>
                                                    <th>From:</th>
                                                    <td>
                                                        {companyName}<br/>
                                                        {name}<br/>
                                                        {address1},<br/>
                                                        {zipCode} {city}, {state}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Ship To:
                                                    </th>
                                                    <td className="border">
                                                    Dometic Marine US Corp - Litchfield Division<br/>
                                                    1 Sierra Place
                                                    </td>
                                                </tr>
                                            </table>
                                            <svg ref={inputRef} />
                                        </div>
                                    </div>
                                    <div className="note">
                                        <div className="bc-info">
                                            <p><strong>NOTE: </strong> Please provide the following invoices:</p>
                                            <ol>
                                                <li>Distributor Invoice showing part number of purchased item being returned</li>
                                                <li>Original customer billed invoice</li>
                                                <li>Estimate or Repair Invoice itemizing parts and labor requested for reimbursement</li>
                                            </ol>
                                            
                                            <p>Attach Top to Carton</p>
                                            <p>Retain Bottom for your record</p>
                                            <p><strong>RGA #{rgaNumber}<br/>
                                                {dateFormat(sendDate, "yyyy-mm-dd hh:MM:ss TT")}
                                            </strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    const userToken = getLoggedUserToken(store);
    return { loggedUser, userToken};
}

export default connect(mapStateToProps)(Barcode);