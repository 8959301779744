import axios from 'axios';

let ScannerAPI = axios.create({
  baseURL: process.env.REACT_APP_SCANNER_URL,
  timeout: 10000,
  headers: { 
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('kp_token')
   }

});

export const setClientToken = token => {
  if (token) {
    ScannerAPI.defaults.headers.Authorization = `${token}`;
  } else {
    delete ScannerAPI.defaults.headers.Authorization;
  }
};

export default ScannerAPI;