import React from 'react';

const InfoBlock = () => {
    return(
        <section className="info-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="info choice">
                                    <h6>Wide choice</h6>
                                    <p>With over 58 years in business, we are the leading supplier of marine engine and drive parts, offering thousands of parts.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="info shipping-time">
                                    <h6>WORLD CLASS CUSTOMER SERVICE</h6>
                                    <p>Our customer support representatives are one of the best teams in the business to answer your questions and respond to your requests.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="info payment-methods">
                                    <h6>Industry Leading Warranty</h6>
                                    <p>Our quality products are backed by our industry leading warranty. We pay posted shop labor, haul out and towing fees. Check the warranty policy section.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoBlock;