import React, { useState, useEffect, Fragment } from 'react';
import { Product, Loading, NoResults } from '../../components';
import Pagination from "react-js-pagination";
import { Form, Row, Col } from "react-bootstrap";
import { CommonService } from '../../services';

const ProductsList = ({categoryId, categoryName}) => {

    const itemsPerPage = 10;
    const [products, setResults] = useState([])
    const [filters, setFilters] = useState([])
    const [page, setPage] = useState(1)
    const [totalItemsCount, setTotalItemsCount] = useState(1)
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [filterParams, setFilterParams] = useState(new Map());
    const [checkedFilters, setCheckedFilters] = useState(new Map());
    const [additionalParams, setAdditionalParams] = useState("");
    const [expandedFilters, setExpandedFilters] = useState([]);

    useEffect(() => {
        if (window.sessionStorage.getItem("gardenCategoryFilterChecked-" + categoryId) !== null) {
            const cFilter = new Map(JSON.parse(window.sessionStorage.getItem("gardenCategoryFilterChecked-" + categoryId)));
            setCheckedFilters(cFilter);
        }
        if (window.sessionStorage.getItem("gardenCategoryFilterParams-" + categoryId) !== null) {
            const cFilterParams = new Map(JSON.parse(window.sessionStorage.getItem("gardenCategoryFilterParams-" + categoryId)));
            setFilterParams(cFilterParams);
        }
        if (window.sessionStorage.getItem("gardenCategoryFilter-" + categoryId) !== null) {
            setAdditionalParams(window.sessionStorage.getItem("gardenCategoryFilter-" + categoryId));
        }
    }, [categoryId])

    useEffect(() => {
        if (additionalParams.length === 0) {
            if (window.sessionStorage.getItem("gardenCategoryFilter-" + categoryId) === null || window.sessionStorage.getItem("gardenCategoryFilter-" + categoryId) === "") {
                getResults();
            }
        } else {
            getFilteredResults();
        }
    }, [page, categoryId, additionalParams]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    async function getFilters() {
        setLoadingFilters(true);
        let tFilters = [];
        if (window.sessionStorage.getItem("gardenFilters-" + categoryId) !== null) {
            tFilters = JSON.parse(window.sessionStorage.getItem("gardenFilters-" + categoryId));
            setFilters(tFilters);
            setLoadingFilters(false);
        } else {
            CommonService.getCategoryFilters(categoryName, process.env.REACT_APP_GARDEN_ID)
            .then(function(response) {
                if (response.status === 200) {
                    const filters = response.data.Data;
                    window.sessionStorage.setItem("gardenFilters-" + categoryId, JSON.stringify(filters));
                    tFilters = filters;
                    setFilters(tFilters);
                    setLoadingFilters(false);
                }
            }).catch(function(error) {
                console.log(error);
            });
        }
        
        
    }

    async function getResults() {
        setLoadingProducts(true);
        CommonService.getProducts(process.env.REACT_APP_GARDEN_ID, categoryName, itemsPerPage, page)
        .then(response => {
            if (response.status === 200) {
                const data = response.data.Data
                setResults(data);
                setTotalItemsCount(1);
                if (data.length > 0) {
                    const totalItems = data[0].totalRows;
                    setTotalItemsCount(totalItems);
                }
                setLoadingProducts(false);
            }
        }).catch(function(error) {
            console.log(error)
        });
    }

    async function getFilteredResults() {
        setLoadingProducts(true);
        if (filters.length > 0) {
            let mFilters = [...filters];
            mFilters.forEach(item => {
                item.disabled = true;
            });
            setFilters(mFilters);
        }
        CommonService.getProductsFiltered(process.env.REACT_APP_GARDEN_ID, categoryName, additionalParams, itemsPerPage, page)
        .then(response => {
            if (response.status === 200) {
                const data = response.data.Data
                const products = []
                // eslint-disable-next-line no-lone-blocks
                {data.map(item =>
                    products.push({  
                        itemRow: item.itemRow,
                        saleItem: item.filterSetFilter[0].saleItem,
                        descriptionShort: item.filterSetFilter[0].descriptionShort,
                        categoryParent: item.filterSetFilter[0].categoryParent,
                        categoryChild: item.filterSetFilter[0].categoryChild,
                        image: item.filterSetFilter[0].image
                    })
                )}
                setResults(products)
                setTotalItemsCount(1);
                if (products.length > 0) {
                    const totalItems = data[0].totalRows;
                    setTotalItemsCount(totalItems);
                }
                if (filters.length > 0) {
                    let mFilters = [...filters];
                    mFilters.forEach(item => {
                        item.disabled = false;
                    });
                    setFilters(mFilters);
                }
                setLoadingProducts(false);
            }
        }).catch(function(error) {
            console.log(error)
        });
    }

    const goToPage = (page) => setPage(page);
    const resetFilters = () => {
        window.sessionStorage.removeItem("gardenCategoryFilterParams-" + categoryId);
        window.sessionStorage.removeItem("gardenCategoryFilter-" + categoryId);
        window.sessionStorage.removeItem("gardenCategoryFilterChecked-" + categoryId);
        window.sessionStorage.removeItem("gardenCategoryFilterExpanded-" + categoryId);
        filterParams.clear();
        setCheckedFilters(new Map());
        setFilters([]);
        getFilters();
        setFilterParams(filterParams);
        setAdditionalParams("");
        setExpandedFilters([]);
    }
    const showMoreFilters = (value) => {
        let mFilters = [...filters];
        mFilters.forEach(item => {
            if (item.filterParameter === value) {
                item.expanded = true;
            }
        });
        setFilters(mFilters);
    }

    const filterProducts = (key, value, checked, index) => {
        let values = filterParams.get(key)
        if (values === undefined) {
            values = [];
        }
        if (checked === true) {
            values = [...values, value];
            checkedFilters.set(key + "-" + value + "-" + index, checked);
        } else {
            values = values.filter(item => item !== value);
            checkedFilters.delete(key + "-" + value + "-" + index);
        }
        if (values.length === 0) {
            filterParams.delete(key)
        } else {
            filterParams.set(key, values)
        }
        let params = "";
        let iIndex = 0;
        for (let item of filterParams.entries()) {
            const iValues = item[1];
            params += "&dict[" + iIndex + "].Key=" + item[0];
            // eslint-disable-next-line no-loop-func
            iValues.forEach(value => {
                params += "&dict[" + iIndex + "].Value=" + value.replace("&", "%26");
            })
            iIndex++;
        }
        window.sessionStorage.setItem("gardenCategoryFilterParams-" + categoryId, JSON.stringify(Array.from(filterParams.entries())));
        window.sessionStorage.setItem("gardenCategoryFilter-" + categoryId, params);
        window.sessionStorage.setItem("gardenCategoryFilterChecked-" + categoryId, JSON.stringify(Array.from(checkedFilters.entries())));
        setCheckedFilters(checkedFilters);
        setAdditionalParams(params)
        setFilterParams(filterParams)
        setExpandedFilters(expandedFilters);
        setPage(1);
    }
    for (let item of checkedFilters.entries()) {
        var filterData = item[0].split('-');
        if ((filterData[2] > 5) && (expandedFilters.indexOf(filterData[0]) < 0)) {
            expandedFilters.push(filterData[0]);
        }
    }
   // setExpandedFilters(expandedFilters);
    console.log(expandedFilters)
       /*
    for (let item of checkedFilters.entries()) {
        var filterData = item[0].split('-');
        console.log(filterData)
    }
   // window.sessionStorage.setItem("gardenCategoryFilterExpanded-" + categoryId, JSON.stringify(Array.from(expandedFilters.entries())));
   // setExpandedFilters(expandedFilters);

    setExpandedFilters([...new Set(expandedFilters)]);
    console.log(expandedFilters)
        if (filterData[2] > 5) {
            expandedFilters.push(filterData[0]);
        }
*/
    return (
        <div className="row">
            <div className="col-lg-3">
                <aside>
                    <div className="search-filter">
                        {loadingFilters ? <Loading /> :
                            <Fragment>
                            {additionalParams.length > 0 ? <button className="btn clear-btn" onClick={resetFilters} >Clear All Filters</button> : null}
                            {filters.map((item, index) => 
                                <Form.Group as={Row} key={index} className={(item.expanded || expandedFilters.indexOf(item.filterParameter) > -1) ? "filter-expanded" : ""} >
                                    <Form.Label as="legend" column sm={12} >{item.filter}</Form.Label>
                                    <Col sm={12}>
                                        {item.value.map((filterValue, filterIndex) => 
                                            <Form.Check
                                                key={filterIndex}
                                                type="checkbox"
                                                label={filterValue}
                                                name={item.filter}
                                                id={"filter-" + index + "-" + filterIndex}
                                                onChange={(e) => filterProducts(item.filterParameter, filterValue, e.target.checked, filterIndex)}
                                                checked={checkedFilters.get(item.filterParameter + "-" + filterValue + "-" + filterIndex) === true ? "checked" : ""}
                                                disabled={item.disabled ? "disabled" : ""}
                                                className={filterIndex > 5 ? "hidden" : ""}
                                            />
                                        )}
                                    </Col>
                                    {item.value.length > 5 ? 
                                        <Col sm={12}>
                                            <button className="link shom-more" onClick={e => showMoreFilters(item.filterParameter)} >Show more</button>
                                        </Col> 
                                    : null}
                                </Form.Group>
                            )}
                            </Fragment>
                        }
                    </div>
                </aside>
            </div>
            <div className="col-lg-9">
                {loadingProducts ? <Loading /> :
                    products.length > 0 ? 
                    <Fragment>
                        <div className="results">
                            {products.map((product, index) =>
                                <Product
                                    key={index}
                                    description={product.descriptionShort}
                                    categoryChild={product.categoryChild}
                                    categoryParent={product.categoryParent}
                                    saleItem={product.saleItem}
                                    productId={product.itemRow}
                                    imagePath={product.image}
                                    filterAnalytics={additionalParams}
                                    filterAnalyticsCategoryId={categoryId}
                                    filterAnalyticsMarketId={process.env.REACT_APP_GARDEN_ID}
                                />
                            )}
                            {totalItemsCount > itemsPerPage ? (
                                <div className="text-center mt-2">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={page}
                                        itemsCountPerPage={itemsPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={goToPage}
                                    />
                                </div>
                                ) : null}
                        </div>
                    </Fragment>
                : <NoResults />}
            </div>
        </div>
    );

}

export default ProductsList;