import React, { useCallback, useState } from 'react';
import { AppUtils } from '../../../../../../utils';
import Icon from '../../../../../../components/icon';
import { Fault } from '../../../../../../../ultra/models/fault';
import FreezeFrame from "./freeze-frame/view";

const FaultsView = ({ data, t, metricSystem }) => {
  
  const [selectedFault, setSelectedFault] = useState(null);

  const changeFreezeFrame = useCallback((fault) => setSelectedFault(fault), []);

  return (
    <div>
      {!selectedFault && <Faults data={data} t={t} changeFreezeFrame={changeFreezeFrame} />}
      {selectedFault && <FreezeFrame t={t} fault={selectedFault} changeFreezeFrame={changeFreezeFrame} metricSystem={metricSystem} />}
    </div>
  )
}

const Faults = ({ data, t, changeFreezeFrame }) => {
  const [critical, setCritical] = useState([]);
  const [active, setActive] = useState([]);
  const [inactive, setInactive] = useState([]);

  useState(() => {
    if (!data) {
      return;
    }
    data.forEach((item, index) => {
      if (item.type === 'CRITICAL') {
        setCritical(getFaultModels(item.type, item.values, t));
      } else if (item.type === 'ACTIVE') {
        setActive(getFaultModels(item.type, item.values, t));
      } else if (item.type === 'INACTIVE') {
        setInactive(getFaultModels(item.type, item.values, t));
      }
    })
  }, [data]);

  return (
    <div>
      <p>{t('fault.display')}</p>
      <div className="card">
        <div className="card-body pt-0 pb-0" >
          {active.length > 0 &&
            <div>
              <span>{t('fault.type.active')}</span>
              <div className="warning">{active.map((item, index) => renderItem(item, t, changeFreezeFrame))}</div>
            </div>
          }
          {inactive.length > 0 &&
            <div>
              <span>{t('fault.type.inactive')}</span>
              <div className="inactive">{inactive.map((item, index) => renderItem(item, t, changeFreezeFrame))}</div>
            </div>
          }
          {critical.length > 0 &&
            <div>
              <span>{t('fault.type.critical')}</span>
              <div className="critical">{critical.map((item, index) => renderItem(item, t, changeFreezeFrame))}</div>
            </div>
          }
          </div>
      </div>
    </div>
  )
}

const renderItem = (item, t, changeFreezeFrame) => (
  <div className="row row-ligt" key={item.id} >
    <div className="col col-3 col-sm-2 list-border-right">
      <p className="mb-0">{item.code}</p>
    </div>
    <div className="col col-3 col-sm-4">
      {item.description && <p className="mb-0">{item.description}</p>}
    </div>
    <div className="col col-4 flex-grow"><p className="text-right mb-0">{AppUtils.localizedDuration(t, item.duration)}</p></div>
    <div className="col col-2 text-center">{item.freezeFrame && <button className="btn p-0" onClick={() => changeFreezeFrame(item)}><Icon name="Freeze" width={20} height={20} invert={true} /></button>}</div>
  </div>
)

const getFaultModels = (type, data, t) => {
  let models = [];
  if (!data) {
    return <></>
  }
  data.forEach(obj => {
    let model = new Fault(obj.id, obj.groupId);
    model.code = obj.id.toString();
    model.description = obj.description;
    model.duration = obj.duration;
    model.active = obj.active;
    model.freezeFrame = obj.freezeFrame;
    if (obj.groupId) {
      model.name = t('eim.faults.group.' + obj.groupId + "." + obj.id);
    } else {
      if (type === 'CRITICAL') {
        model.name = t('eim.faults.critical.' + obj.id);
      } else {
        model.name = t('eim.faults.' + obj.id);
      }
    }
    models.push(model.getJSON());
  });
  return models;
}

export default FaultsView;