import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Orders from '../../components/Orders/Orders';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import UserService from '../../services/UserService';
import { getLoggedUserToken, isLoggedUser } from '../../redux/selectors';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

const AdminAccount = (props) => {
    const [UserInfo, setUserInfo] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (props.loggedUser)
        {
            setAuthorizationHeader(props.userToken);

            if (UserInfo==null)
            {
                console.log("userInfo.get: ", props.userToken);
                UserService.getUserInfo().then(user => {
                    console.log("userInfo.get.response: ", user);
                    if (user.status == 200)
                        setUserInfo(user.data.data);

                });
            
            }
        }
        else{
            history.push("/user/login");
        }

}, [props.userToken]);

useEffect(() => {
    if (UserInfo != null)
    {
        if (UserInfo.IsAdmin==false)
            history.push("/user/login");
    }

}, [UserInfo]);

    return (
    <div className="container">
        <hr/>
        <div className="row">
            <div className="col-lg-12">
                <Card>
                    <Card.Body>
                        <Card.Title>Admin - Order History</Card.Title>
                        <Orders isAdmin={UserInfo?.IsAdmin} searchText={"Search Customers"}></Orders>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <br></br>
    </div>
    )
}
  
const mapStateToProps = state => {
    const loggedUser = isLoggedUser(state);
    const userToken = getLoggedUserToken(state);
    return { userToken, loggedUser };
}

export default connect(mapStateToProps)(AdminAccount);
