import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import {Form, Button, Row, Col } from "react-bootstrap";
import { UserService } from '../../services';
import { connect } from "react-redux";
import { getLoggedUserId, getLoggedUserEmail } from "../../redux/selectors";

const PasswordChange = ({ loggedUserId, loggedUserEmail }) => {
    const history = useHistory();

    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState();
    const [passwordErrorMessage, setPasswordErrorMessage] = useState();
    const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] = useState();

    const handleChangeOldPassword = (value) => setOldPassword(value);
    const handleChangePassword = (value) => setPassword(value);
    const handleChangePasswordConfirm = (value) => setPasswordConfirm(value);

    const passwordChange = () => {
        let isValid = true;
        if ((!oldPassword || /^\s*$/.test(oldPassword))) {
            setOldPasswordErrorMessage("Please enter a password.");
            isValid = false;
        } else {
            setOldPasswordErrorMessage(null);
        }
        if ((!password || /^\s*$/.test(password))) {
            setPasswordErrorMessage("Please enter a password.");
            isValid = false;
        } else {
            setPasswordErrorMessage(null);
        }
        if ((!passwordConfirm || /^\s*$/.test(passwordConfirm))) {
            setPasswordConfirmErrorMessage("Please enter a password.");
            isValid = false;
        } else {
            setPasswordConfirmErrorMessage(null);
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            setPasswordErrorMessage("Password must contain at least one special character.");
            isValid = false;
        } else {
            setPasswordConfirmErrorMessage(null);
        }
        if (password.length < 6) {
            isValid = false;
            setPasswordErrorMessage("The Password must be at least 6 characters long.");
        } else {
            setPasswordConfirmErrorMessage(null);
        }
        if (password && passwordConfirm) {
            if (password !== passwordConfirm) {
                setPasswordConfirmErrorMessage("Passwords are not the same.");
                isValid = false;
            } else {
                setPasswordConfirmErrorMessage(null);
            }
        }
        if (isValid) {
            UserService.passwordChange(loggedUserId, loggedUserEmail, oldPassword, password, passwordConfirm)
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.results.responseId === true) {
                            history.push("/profile");
                        } else {
                            setOldPasswordErrorMessage(res.data.results.Message);
                        }
                    }
                }).catch(error => {
                    console.log("Error " + error);
                });
        }
    }

    return (
        <div className="registration-form">
            <div className="container">
                <div className="row">
                    <div className="col-md-11 offset-md-1">
                        <div className="title">
                            <h1>Password Change</h1>
                        </div>
                        <div className="line-break"></div>
                        <div className="row">
                            <div className="col-sm-3">
                                <h3>&nbsp;</h3>
                            </div>
                            <div className="col-sm-9">
                            <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="text" name="email" value={loggedUserEmail} readOnly />
                                </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                    <Form.Label>Old password*</Form.Label>
                                    <Form.Control type="password" name="email" value={oldPassword} onChange={(e) => handleChangeOldPassword(e.target.value)} className={oldPasswordErrorMessage ? "is-invalid" : ""} />
                                    {oldPasswordErrorMessage ? <div className="invalid-feedback">{oldPasswordErrorMessage}</div> : null}
                                </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                    <Form.Label>New Password*</Form.Label>
                                    <Form.Control type="password" name="password" value={password} onChange={(e) => handleChangePassword(e.target.value)} className={passwordErrorMessage ? "is-invalid" : ""} />
                                    {passwordErrorMessage ? <div className="invalid-feedback">{passwordErrorMessage}</div> : null}
                                </Col>
                                </Form.Group>
                                <div className='small'>
                                
                                <ul className='list-group text-muted'>New Password requirements:
                                    <li className='ml-4'>Password must be at least 6 characters long</li>
                                    <li className='ml-4 mb-4'>Password must contain at least one special character</li>
                                </ul>

                                </div>
                                <Form.Group as={Row}>
                                <Col className="col-sm-4">
                                    <Form.Label>Retype Password*</Form.Label>
                                    <Form.Control type="password" name="passwordConfirm" value={passwordConfirm} onChange={(e) => handleChangePasswordConfirm(e.target.value)} className={passwordConfirmErrorMessage ? "is-invalid" : ""} />
                                    {passwordConfirmErrorMessage ? <div className="invalid-feedback">{passwordConfirmErrorMessage}</div> : null}
                                </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="line-break"></div>
                        <div className="row">
                        <div className="col-sm-9 offset-sm-3">
                            <Button type="button" onClick={passwordChange} >Save</Button>
                            <a className="cancel" href="/profile" >Cancel</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    const loggedUserId = getLoggedUserId(store);
    const loggedUserEmail = getLoggedUserEmail(store);
    return { loggedUserId, loggedUserEmail };
}

export default connect(mapStateToProps)(PasswordChange);