import {React, useState, useEffect} from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';

const Product = (props) => {
    const productUrl = "/product/" + props.productId;
    const ImageBaseUrl = process.env.REACT_APP_IMAGE_PATH + "/tr:w-70/";
    const [Source,setSource] = useState(null);

    useEffect(() => {
        console.log("imagePath: ", props.imagePath);
        if (props.imagePath != null && props.imagePath != "" ){
             setSource(ImageBaseUrl + props.imagePath);
            //console.log("validateImage:", props.imagePath);
            validateImage(ImageBaseUrl + props.imagePath).then(res =>{
                if (!res){
                    if (props.imagePath.includes("JPG")){
                        setSource(ImageBaseUrl + props.imagePath.replace("JPG", "jpg"));
                    }
                    else {
                        setSource(ImageBaseUrl + props.imagePath.replace("jpg", "JPG"));
                    }
                } 
            });
        }
        else {
            setSource(ImageBaseUrl + "NoImage.jpg");
        }
        
    }, [props.imagePath]);

    function validateImage(image) {
        return fetch(image).then(res=>{
            if (res.status!==200)
            {
                return false;
            }
            else
            {
                return true;
            }
        
        })
        .then(validImg=>{
            return validImg;
        })
    };

    return (
        <Link to={{
            pathname: productUrl,
            state: {
                filterAnalytics: props.filterAnalytics,
                filterAnalyticsCategoryId: props.filterAnalyticsCategoryId,
                filterAnalyticsMarketId: props.filterAnalyticsMarketId
            }
            }} className="result-item simple">
            <div className="row">
                <div className="result-image">
                    <figure>
                        {<img src={Source} alt={props.description} />}
                    </figure>
                </div>
                <div className="result-id">
                    <span className="saleItem">{props.saleItem}</span>
                </div>
                <div className="result-description">
                    <h4>{props.description}</h4>
                    <span>{props.categoryParent}</span> / <span>{props.categoryChild}</span>
                    {props.oeNumber ? <Fragment><br/><span>OEM part: {props.oeNumber}</span></Fragment> : null}
                        {props.listPrice ? 
                        <div className="product-price">${props.listPrice} MSRP</div>
                        : null}
                </div>
                <div className="result-link">
                    <FontAwesomeIcon icon={faAngleRight} className="go-to-category" />
                </div>

            </div>
        </Link>
    )
}

Product.propTypes = {
    description: PropTypes.string,
    categoryChild: PropTypes.string.isRequired,
    categoryParent: PropTypes.string.isRequired,
    saleItem: PropTypes.string.isRequired,
    oeNumber: PropTypes.string,
    productId: PropTypes.number.isRequired,
    imagePath: PropTypes.string
};

export default Product;