var apparentPower;

apparentPower = {
  VA: {
    to_anchor: 1
  }, 
  mVA: {
    to_anchor: .001
  }, 
  kVA: {
    to_anchor: 1000
  }, 
  MVA: {
    to_anchor: 1000000
  }, 
  GVA: {
    to_anchor: 1000000000
  }
};

module.exports = {
  metric: apparentPower, 
  _anchors: {
    metric: {
      unit: 'VA', 
      ratio: 1
    }
  }
};
