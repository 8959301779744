import { Pie, PieChart, LabelList, ResponsiveContainer, Label } from "recharts";
import { AppUtils } from '../../../../../../utils';

import TotalHoursCircle from './total-hours';

const RunHistoryPieChart = ({ data, t, total, showHours, print }) => {

  const simpleData = data.map((e) => { 
    if (typeof e.duration === 'number') {
      return { 
        duration: e.duration
      };
    }
  });

  const dataWithColors = AppUtils.mapColors(simpleData);

  const totalHours = (props) => {
    const {
      viewBox: { cx, cy, innerRadius }
    } = props;
    return (
      <TotalHoursCircle
        t={t}
        cx={cx}
        cy={cy}
        size={innerRadius * 0.93}
        duration={total}
        fontSize={innerRadius / 3}
      />
    );
  };

  return (
    <div style={!print ? { width: '100%', height: '40vh' } :{ display: 'flex', justifyContent: 'center' }}>
      <ResponsiveContainer width={print ? 240 : '100%'} height={print ? 240 : '100%'}>
        <PieChart>
          <Pie
            startAngle={0}
            endAngle={360}
            isAnimationActive={false}
            data={dataWithColors}
            dataKey="duration"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={"40%"}
            stroke="none"
            paddingAngle={2}
          >
            <LabelList
              dataKey="duration"
              position="inside"
              fill="white"
              fontFamily="Gibson"
              formatter={(val) => showHours ? AppUtils.localizedDuration(t, val * 60) : (Math.round((val / total) * 100) + "%")}
            />
            <Label content={totalHours} />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RunHistoryPieChart;