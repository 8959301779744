import React from 'react'
import logo from '../../logo.svg';
import { Link } from "react-router-dom";

const User = ({ children }) => {

    return (
        <div className="login-layout">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5 login-bg">
                        <Link to="/" className="logo">
                            <img src={logo} alt="logo" />
                        </Link>
                        <div className="text-center">
                            <h1>The parts You need </h1>
                            <h2>when you need them</h2>
                            <p>View our large assortment of more<br/>
                            than 1,000,000 spare parts</p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-6 offset-lg-1">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default User;