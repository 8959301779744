import React from 'react';

const FlipCatalog = (props) => {

    return (

        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src={props.frameUrl} seamless="seamless" scrolling="no" allowtransparency="true" allowfullscreen="true" ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlipCatalog;