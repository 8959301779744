import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import { Loading } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../services';

const Diagram = () => {
    let { diagramId } = useParams();
    const data = useLocation();

    const [displayName, setDisplayName] = useState(data.state !== undefined ? data.state.displayName : null);
    const [brand, setBrand] = useState(data.state !== undefined ? data.state.brand : null);
    const [description, setDescription] = useState(data.state !== undefined ? data.state.description : null);
    const [image, setImage] = useState(data.state !== undefined ? data.state.image : null);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        getDiagram();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagramId])

    async function getDiagram() {
        setLoading(true);
        CommonService.getDiagram(diagramId)
            .then(res => {
                if (res.status === 200) {
                    setItems(res.data.Results);
                    setImage(res.data.FileName);
                    setDisplayName(res.data.DisplayName);
                    if (res.data.Results) {
                        setBrand(res.data.Results[0].Brand);
                    }
                    setLoading(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    const renderInterchanges = (interchanges) => {
        if (!interchanges || interchanges.length === 0) {
            return <></>
        }
        var renderedContent = [];
        var addedBrands = [];
        interchanges.forEach((object, index) => {
            var brandId = object.C_Brand_Id;
            if (!addedBrands.includes(brandId)) {
                renderedContent.push(<span key={index} >{object.Brand}</span>);
                interchanges.forEach((iObject, iIndex) => {
                    if (iObject.C_Brand_Id === brandId) {
                        renderedContent.push(<Fragment key={iIndex} ><span><Link to={{ pathname: "/product/" + iObject.C_Item_Row }}>{iObject.OE_Number}</Link></span></Fragment>)
                    }
                });
                renderedContent.push(<br />);
                addedBrands.push(brandId);
            }
        });
        return renderedContent;
    }

    return (
        <div className="diagram-full-view">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="diagram-header">
                            <h1>{displayName}</h1>
                            <h5>Diagram</h5>
                            <p>{brand !== null ? ("Brand: " + brand) : ""}{description !== null ? (", Description: " + description) : ""}</p>
                        </div>
                        <div className="diagram-body">
                            {image ?
                                <div className="diagram-image text-center mb-2">
                                    <img src={process.env.REACT_APP_DIAGRAM_IMAGE_PATH + "/" + image} alt="diagramImage" />
                                </div>
                                : null}
                            {loading === true ? <Loading /> :
                                <div className="d-list">
                                    {items && items.map((item, index) =>
                                        <div className="d-list-item" key={index}>
                                            <div className="row">
                                                <div className="col-auto index">
                                                    <span>{item.Reference}</span>
                                                   
                                                </div>
                                                <div className="col-auto image">
                                                    {item.Image ?
                                                        <img src={process.env.REACT_APP_IMAGE_PATH + "/" + item.Image} alt={item.Item} />
                                                        : null}
                                                </div>
                                                <div className="col-auto multi-row">
                                                    <div className="top">
                                                      
                                                        <div className="row">
                                                            <div className="col-auto id  align-self-start">
                                                                <span>
                                                                    {item.NSS ? item.NSS_OE : <a href='/product/{item.C_Item_Row}'>{item.Sale_Item}</a>}
                                                                </span>
                                                                {item.Item_Stat === "O" ? <strong>Obsolete</strong> : null}
                                                            </div>
                                                           
                                                            <div className="col-auto description   align-self-start">
                                                                {item.NSS ? item.NSS_Description : item.DescShort}<br/>
                                                     
                                                                <small>Qty: {item.Quantity}</small>
                                                 
                                                            </div>
                                                            
                                                        </div>

                                                    </div>
                                                    {item.NSS === false ?
                                                        <div className="bottom">
                                                            <div className="row">
                                                                <div className="col-auto id   align-self-start">
                                                                    <span>OE Numbers</span>
                                                                </div>
                                                                <div className="col-auto description">
                                                                    <div className="numbers" >{renderInterchanges(item.Interchange)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="col-auto action" style={{ display: 'none' }} >
                                                    <button className="add">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diagram;