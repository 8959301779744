import React, { Fragment } from 'react'
import { Header, Footer, InfoBlock, Menu } from '../';

const Plain = ({ children }) => {
    return (
        <Fragment>
            <Header />
            <Menu hideDetailedSearch="true" />
            {children}
            <InfoBlock />
            <Footer />
        </Fragment>
    )
}

export default Plain;
