import React, { useState, useEffect } from 'react';
import {Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { AddressDetails } from "../../../components";
 
const ProfileInformation = (props) => {
 
        const [maxDate, setMaxDate] = useState(new Date());
 
        const [firstNameErrorMessage, setFirstNameErrorMessage] = useState("");
        const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
        const [homePhoneErrorMessage, setHomePhoneErrorMessage] = useState("");
        const [mobilePhoneErrorMessage, setMobilePhoneErrorMessage] = useState("");
        const [officePhoneErrorMessage, setOfficePhoneErrorMessage] = useState("");
        const [extensionErrorMessage, setExtensionErrorMessage] = useState("");
 
        const [mailingAddress1ErrorMessage, setMailingAddress1ErrorMessage] = useState(null);
        const [mailingCityErrorMessage, setMailingCityErrorMessage] = useState(null);
        const [mailingStateErrorMessage, setMailingStateErrorMessage] = useState(null);
        const [mailingPostalCodeErrorMessage, setMailingPostalCodeErrorMessage] = useState(null);
       
        const [shippingAddress1ErrorMessage, setShippingAddress1ErrorMessage] = useState(null);
        const [shippingCityErrorMessage, setShippingCityErrorMessage] = useState(null);
        const [shippingStateErrorMessage, setShippingStateErrorMessage] = useState(null);
        const [shippingPostalCodeErrorMessage, setShippingPostalCodeErrorMessage] = useState(null);
 
        useEffect(() => {
                let maxDate = new Date();
                maxDate.setFullYear(maxDate.getFullYear() - 18);
                setMaxDate(maxDate);
        }, [])
 
        async function validateProfileInformation() {
                let isValid = true;
                if ((!props.firstName || /^\s*$/.test(props.firstName))) {
                        setFirstNameErrorMessage("Please enter a first name.");
                        isValid = false;
                } else {
                        setFirstNameErrorMessage(null);
                }
                if ((!props.lastName || /^\s*$/.test(props.lastName))) {
                        setLastNameErrorMessage("Please enter a last name.");
                        isValid = false;
                } else {
                        setLastNameErrorMessage(null);
                }
                if ((!props.mailingAddress1 || /^\s*$/.test(props.mailingAddress1))) {
                        setMailingAddress1ErrorMessage("Please enter a address.");
                        isValid = false;
                } else {
                        setMailingAddress1ErrorMessage(null);
                }
                if ((!props.mailingCity || /^\s*$/.test(props.mailingCity))) {
                        setMailingCityErrorMessage("Please enter a city.");
                        isValid = false;
                } else {
                        setMailingCityErrorMessage(null);
                }
                if ((!props.mailingState || /^\s*$/.test(props.mailingState))) {
                        setMailingStateErrorMessage("Please enter a state.");
                        isValid = false;
                } else {
                        setMailingStateErrorMessage(null);
                }
                if ((!props.mailingPostalCode || /^\s*$/.test(props.mailingPostalCode))) {
                        setMailingPostalCodeErrorMessage("Please enter a postal code.");
                        isValid = false;
                } else {
                        setMailingPostalCodeErrorMessage(null);
                }
                if ((!props.shippingAddress1 || /^\s*$/.test(props.shippingAddress1))) {
                        setShippingAddress1ErrorMessage("Please enter a address.");
                        isValid = false;
                } else {
                        setShippingAddress1ErrorMessage(null);
                }
                if ((!props.shippingCity || /^\s*$/.test(props.shippingCity))) {
                        setShippingCityErrorMessage("Please enter a city.");
                        isValid = false;
                } else {
                        setShippingCityErrorMessage(null);
                }
                if ((!props.shippingState || /^\s*$/.test(props.shippingState))) {
                        setShippingStateErrorMessage("Please enter a state.");
                        isValid = false;
                } else {
                        setShippingStateErrorMessage(null);
                }
                if ((!props.shippingPostalCode || /^\s*$/.test(props.shippingPostalCode))) {
                        setShippingPostalCodeErrorMessage("Please enter a postal code.");
                        isValid = false;
                } else {
                        setShippingPostalCodeErrorMessage(null);
                }
                if (props.homePhone && !/^\d+$/.test(props.homePhone)) {
                        setHomePhoneErrorMessage("Please enter a valid phone number.");
                        isValid = false;
                } else {
                        setHomePhoneErrorMessage(null);
                }
                if (props.mobilePhone && !/^\d+$/.test(props.mobilePhone)) {
                        setMobilePhoneErrorMessage("Please enter a valid mobile number.");
                        isValid = false;
                } else {
                        setMobilePhoneErrorMessage(null);
                }
                if (props.officePhone && !/^\d+$/.test(props.officePhone)) {
                        setOfficePhoneErrorMessage("Please enter a valid office number.");
                        isValid = false;
                } else {
                        setOfficePhoneErrorMessage(null);
                }
                if (props.extension && !/^\d+$/.test(props.extension)) {
                        setExtensionErrorMessage("Please enter a valid extension number.");
                        isValid = false;
                } else {
                        setExtensionErrorMessage(null);
                }
                if (isValid) {
                        props.validateProfileInformation();
                }
        }
 
 
        return (
                <div className="container">
                <div className="row">
                        <div className="col-md-11 offset-md-1">
                                <div className="row">
                                <div className="col-md-3">
                                        <h3>PROFILE information</h3>
                                </div>
                                <div className="col-md-9">
                                        <Form.Group as={Row}>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>First Name*</Form.Label>
                                                        <Form.Control type="text" name="firstName" value={props.firstName} onChange={(e) => props.handleChangeFirstName(e.target.value)} className={firstNameErrorMessage ? "is-invalid" : ""} />
                                                        {firstNameErrorMessage ? <div className="invalid-feedback">{firstNameErrorMessage}</div> : null}
                                                </Col>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Last Name*</Form.Label>
                                                        <Form.Control type="text" name="lastName" value={props.lastName} onChange={(e) => props.handleChangeLastName(e.target.value)} className={lastNameErrorMessage ? "is-invalid" : ""} />
                                                        {lastNameErrorMessage ? <div className="invalid-feedback">{lastNameErrorMessage}</div> : null}
                                                </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Email Address</Form.Label>
                                                        <Form.Control type="text" name="emailAddress" value={props.email} readOnly />
                                                </Col>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Salutation</Form.Label>
                                                        {/* <Form.Control type="text" name="salutation" value={props.salutation} onChange={(e) => props.handleChangeSalutation(e.target.value)} maxLength="5" /> */}
                                                        <Form.Control as="select" onChange={e => props.handleChangeSalutation(e.target.value)}>
                                                                <option value="">Select a Salutation</option>
                                                                <option value="Mr">Mr</option>
                                                                <option value="Ms">Ms</option>
                                                                <option value="Mrs">Mrs</option>
                                                                <option value="Dr">Dr</option>
                                                        </Form.Control>
                                                </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Home Phone</Form.Label>
                                                        <Form.Control type="text" name="homePhone" value={props.homePhone} onChange={(e) => props.handleChangeHomePhone(e.target.value)} className={homePhoneErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                        {homePhoneErrorMessage ? <div className="invalid-feedback">{homePhoneErrorMessage}</div> : null}
                                                </Col>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Mobile Phone</Form.Label>
                                                        <Form.Control type="text" name="mobilePhone" value={props.mobilePhone} onChange={(e) => props.handleChangeMobilePhone(e.target.value)} className={mobilePhoneErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                        {mobilePhoneErrorMessage ? <div className="invalid-feedback">{mobilePhoneErrorMessage}</div> : null}
                                                </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Office Phone</Form.Label>
                                                        <Form.Control type="tel" name="officePhone" value={props.officePhone} onChange={(e) => props.handleChangeOfficePhone(e.target.value)} className={officePhoneErrorMessage ? "is-invalid" : ""} maxLength="20" />
                                                        {officePhoneErrorMessage ? <div className="invalid-feedback">{officePhoneErrorMessage}</div> : null}
                                                </Col>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Extension</Form.Label>
                                                        <Form.Control type="tel" name="extension" value={props.extension} onChange={(e) => props.handleChangeExtension(e.target.value)} className={extensionErrorMessage ? "is-invalid" : ""} maxLength="5" placeholder="Enter extension" />
                                                        {extensionErrorMessage ? <div className="invalid-feedback">{extensionErrorMessage}</div> : null}  
                                                </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Date of Birth</Form.Label>
                                                        <DatePicker
                                                                locale="en"
                                                                selected={props.birthDate}
                                                                onChange={date => props.handleChangeBirthDate(date)}
                                                                maxDate={new Date()}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode='select'
                                                        />
                                                </Col>
                               
                                                <Col className="col-sm-6 col-lg-4">
                                                        <Form.Label>Job Title</Form.Label>
                                                        <Form.Control as="select" onChange={e => props.handleChangeJobTitle(e.target.value)}>
                                                                <option value="">Select a Job Title</option>
                                                                <option value="Business Owner">Business Owner</option>
                                                                <option value="Office Manager">Office Manager</option>
                                                                <option value="Parts Counter">Parts Counter</option>
                                                                <option value="Technician">Technician</option>
                                                                <option value="Receptionist">Receptionist</option>
                                                                <option value="Sales Representative">Sales Representative</option>
                                                                <option value="Customer Service">Customer Service</option>
                                                                <option value="Other">Other</option>
                                                        </Form.Control>
                                                </Col>
                                        </Form.Group>
                                        <div className="line-break"></div>
                                        <AddressDetails
                                                mailingAddress1={props.mailingAddress1}
                                                mailingAddress1ErrorMessage={mailingAddress1ErrorMessage}
                                                mailingAddress2={props.mailingAddress2}
                                                mailingCity={props.mailingCity}
                                                mailingCityErrorMessage={mailingCityErrorMessage}
                                                mailingState={props.mailingState}
                                                mailingStateErrorMessage={mailingStateErrorMessage}
                                                mailingPostalCode={props.mailingPostalCode}
                                                mailingPostalCodeErrorMessage={mailingPostalCodeErrorMessage}
                                                mailingCountry={props.mailingCountry}
                                                shippingAddress1={props.shippingAddress1}
                                                shippingAddress1ErrorMessage={shippingAddress1ErrorMessage}
                                                shippingAddress2={props.shippingAddress2}
                                                shippingCity={props.shippingCity}
                                                shippingCityErrorMessage={shippingCityErrorMessage}
                                                shippingState={props.shippingState}
                                                shippingStateErrorMessage={shippingStateErrorMessage}
                                                shippingPostalCode={props.shippingPostalCode}
                                                shippingPostalCodeErrorMessage={shippingPostalCodeErrorMessage}
                                                shippingCountry={props.shippingCountry}
                                                handleChangeMailingAddress1={props.handleChangeMailingAddress1}
                                                handleChangeMailingAddress2={props.handleChangeMailingAddress2}
                                                handleChangeMailingCity={props.handleChangeMailingCity}
                                                handleChangeMailingState={props.handleChangeMailingState}
                                                handleChangeMailingCountry={props.handleChangeMailingCountry}
                                                handleChangeMailingPostalCode={props.handleChangeMailingPostalCode}
                                                handleChangeShippingAddress1={props.handleChangeShippingAddress1}
                                                handleChangeShippingAddress2={props.handleChangeShippingAddress2}
                                                handleChangeShippingCity={props.handleChangeShippingCity}
                                                handleChangeShippingState={props.handleChangeShippingState}
                                                handleChangeShippingPostalCode={props.handleChangeShippingPostalCode}
                                                handleChangeShippingCountry={props.handleChangeShippingCountry}
                                                toggleShippingAsMailingAddress={props.toggleShippingAsMailingAddress}
                                        />
                                </div>
                                </div>
                                <div className="line-break"></div>
                                <div className="row align-items-center">
                                        <div className="col-3">
                                                <Link to="#" onClick={(e) => props.profileGoBack()} className="back-link">Back</Link>
                                        </div>
                                        <div className="col-9">
                                                <Button type="button" onClick={validateProfileInformation} >NEXT</Button>
                                                <a className="cancel" href="/user/login" >Cancel</a>
                                        </div>
                                        <div className="col-sm-9 offset-sm-3">
                                                <p>In the next step, we will create your profile.</p>
                                        </div>
                                </div>
                        </div>
                </div>
                </div>
        )
}
 
export default ProfileInformation;