import React from 'react';
import Icon from '../../components/icon';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components';

const Redirect = () => {
  const { t } = useTranslation();

  return (
    <Layout onlyChildren={true}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="text-center"><Icon name="Logo" width={128} height={128} /></p>
        <p className="text-center">{t('redirect.display')}</p>
      </div>
    </Layout>
  );
}

export default Redirect;