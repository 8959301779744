import React from 'react'

const Loader = () => {
    return (
        <div className="col-sm-12">
            <div className="text-center loading pt-3 pb-3">
                <div className="spinner-border">
                    <span className="sr-only" >Loading...</span>
                </div>
            </div>
        </div>
    )
}

export default Loader;