import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Loading, NoResults, NewsArticle } from '../../components';
import { CommonService } from '../../services';
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';
import { Form, Button, Row, Col } from "react-bootstrap";

const News = () => {
   
    const history = useHistory();
    const itemsPerPage = 12;
    const [query, setQuery] = useState(history.location.state !== undefined ? history.location.state.query : '');
    const [startDate, setStartDate] = useState(history.location.state !== undefined ? history.location.state.startDate : '');
    const [type, setType] = useState(history.location.state !== undefined ? history.location.state.type : "");
    const [view, setView] = useState(history.location.state !== undefined ? history.location.state.view : 'thumbs');
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [types, setTypes] = useState([]);
    const [message, setMessage] = useState();
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [page, setPage] = useState(history.location.state !== undefined ? history.location.state.page : 1);
    const [activeTab, setActiveTab] = useState(history.location.state !== undefined ? history.location.state.activeTab : 'all');

    const today = new Date();
    const last7days =  dateFormat(
        new Date(
            today.getFullYear(),
            today.getMonth(), 
            today.getDate() - 7
        ), 
        "yyyy-mm-dd");
    const last30days =  dateFormat(
        new Date(
            today.getFullYear(),
            today.getMonth(), 
            today.getDate() - 30
        ), 
        "yyyy-mm-dd");
    const last3months =  dateFormat(
        new Date(
            today.getFullYear(),
            today.getMonth() - 3, 
            today.getDate()
        ), 
        "yyyy-mm-dd");
    const last6months =  dateFormat(
        new Date(
            today.getFullYear(),
            today.getMonth() - 6, 
            today.getDate()
        ), 
        "yyyy-mm-dd");
    
    const handleView = (value) => { setView(value); }
    const handleTab = (tab) => {
        setActiveTab(tab);
        setLoading(true);
        getItems(page, tab);
        history.replace({
            pathname:  "/news",
            state: {
                page: page,
                query: query,
                type: type,
                startDate: startDate,
                view: view,
                activeTab: tab
            } 
        });
    }
    let readArticles = [];
    if (localStorage.getItem("readArticles") != null) {
        readArticles = JSON.parse(localStorage.getItem("readArticles"));
    }
    
    const onStartDateChange = (value) => { setStartDate(value); }
    const handleSearchTermChange = (value) => { setQuery(value); }
    const handleTypeChange = (value) => {
        if (value != "") {
            setType(value);
        } else {
            setType(null);
        }
    }

    useEffect(() => {
        setLoading(true);
        getTypes();
        getItems(page, activeTab);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const goToPage = (page) => {
        setPage(page);
        setLoading(true);
        getItems(page, activeTab);
        history.replace({
            pathname:  "/news",
            state: {
                page: page,
                query: query,
                type: type,
                startDate: startDate,
                view: view,
                activeTab: activeTab
            } 
        });
    }

    async function submitSearch() {
        setLoading(true);
        setPage(1);
        getItems(1, activeTab);
        history.replace({
            pathname:  "/news",
            state: {
                page: page,
                query: query,
                type: type,
                startDate: startDate,
                view: view,
                activeTab: activeTab
            } 
        });
    }

    async function getItems(page, tab) {
        if (tab == 'unread') {
            CommonService.getLatestArticles(readArticles, itemsPerPage, page)
                .then(function(response) {
                    if (response.status === 200) {
                        if (response.data.Data) {
                            setItems(response.data.Data);
                        } else {
                            setItems([]);
                        }
                        if (response.data.message) {
                            setMessage(response.data.message);
                        }
                        setTotalItemsCount(response.data.Total);
                        setLoading(false);
                    }
                }).catch(function(error) {
                    console.log(error);
                });
        } else {
            CommonService.getNewsArticles(query, startDate, type, itemsPerPage, page)
                .then(function(response) {
                    if (response.status === 200) {
                        const data = response.data.Data;
                        setItems(data);
                        if (data.length > 0) {
                            const totalItems = response.data.Total;
                            setTotalItemsCount(totalItems);
                        }
                        setLoading(false);
                    }
                }).catch(function(error) {
                    console.log(error);
                });
        }
    }

    async function getTypes() {
        CommonService.getNewsArticleTypes()
        .then(function(response) {
            if (response.status === 200) {
                setTypes(response.data);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }

    return (
        <div className={"news " + view}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="breadcrumbs">
                            <li><Link to="/">Home</Link></li>
                            <li className="divider">/</li>
                            <li>News & Announcements</li>
                        </ul>
                    </div>
                    <div className="col-lg-10 offset-lg-1">
                        <div className="header-tab">
                            <div className="row align-center">
                                <div className="col-12">
                                    <h1>News & Announcements</h1>
                                </div>
                                <div className="col tabbs">
                                    <ul>
                                        <li>
                                            <button className={activeTab == 'all' ? 'active' : null} onClick={() => handleTab('all')}>All News</button>
                                        </li>
                                        <li>
                                            <button className={activeTab == 'unread' ? 'active' : null} onClick={() => handleTab('unread')}>Unread</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col toggle-view text-right">
                                    <button className="set-list-view" onClick={() => handleView('list')}>List</button>/<button className="set-thumbs-view" onClick={() => handleView('thumbs')}>Thumbnail</button>
                                </div>
                            </div>
                        </div>
                        {loading === false ?
                        <div className="news-results">
                            {activeTab == 'all' ? 
                            <div className="news-search">
                                <Form action="/news">
                                    <Row className="align-flex-end">
                                        <Col className="search-text col-md-12 col-lg">
                                            <Form.Control type="text" name="searchTerm" placeholder="Search by title" value={query} onChange={(e) => handleSearchTermChange(e.target.value)} />
                                        </Col>
                                        <Col className="search-parameter col-md-12">
                                            <Form.Control as="select" name="type" onChange={(e) => handleTypeChange(e.target.value)} >
                                                <option value="" >All Types</option>
                                                {types.map((sType, index) =>
                                                    <option value={sType} selected={sType == type ? true : false} key={index} >{sType}</option>
                                                )}
                                            </Form.Control>
                                        </Col>
                                        <Col className="search-parameter col-md-12">
                                            <Form.Control as="select" name="startDate" value={startDate} onChange={(e) => onStartDateChange(e.target.value)}>
                                                <option value="1970-01-01">All</option>
                                                <option value={last7days} selected={startDate == last7days ? true : false}>Last 7 days</option>
                                                <option value={last30days} selected={startDate == last30days ? true : false}>Last 30 days</option>
                                                <option value={last3months} selected={startDate == last3months ? true : false}>Last 3 months</option>
                                                <option value={last6months} selected={startDate == last6months ? true : false}>Last 6 months</option>
                                            </Form.Control>
                                        </Col>
                                        <Col className="search-button col-md-12">
                                            <Button type="button" onClick={submitSearch} class="btn btn-primary">SEARCH</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            : null}
                            {items.length > 0 ? 
                                <div className="row">
                                    {items.map((item, index) =>
                                        <NewsArticle 
                                            id={item.Id}
                                            className={readArticles.indexOf(''+item.id) > -1 ? 'read article-line' : 'article-line'}
                                            title={item.Title}
                                            type={item.Type}
                                            startDate={dateFormat(item.StartDate, "mmmm dd, yyyy")}
                                            description={item.description}
                                            fileName={item.FileName}
                                            key={index}
                                        />
                                    )}
                                    {totalItemsCount > itemsPerPage ? (
                                        <div className="col-12 text-center mt-2">
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={page}
                                                itemsCountPerPage={itemsPerPage}
                                                totalItemsCount={totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={goToPage}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                             : <NoResults message={activeTab == 'unread' ? message : null} />}
                        </div>
                         :  <Loading />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News;