import { createSlice } from '@reduxjs/toolkit';

export const metricSystemSlice = createSlice({
  name: 'metricSystem',
  initialState: {
    object: 'imperial'
  },
  reducers: {
    setMetricSystem: (state, action) => {
      state.object = action.payload;
    }
  }
})

export const { setMetricSystem } = metricSystemSlice.actions;
export default metricSystemSlice.reducer;