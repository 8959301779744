import React, { Fragment, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../services';
import PropTypes from "prop-types";

const ParticipantPermissionsChange = (props) => {

    const [showDialog, setShowDialog] = useState(false);
    const [hasWarrantyAccess, setHasWarrantyAccess] = useState(props.warrantyAccess);
    const [shoppingCartAccess, setShoppingCartAccess] = useState(props.shoppingCartAccess);
    const [addShippingAccess, setAddShippingAccess] = useState(props.addShippingAccess);

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);
    const toggleHasWarrantyAccess = () => setHasWarrantyAccess(!hasWarrantyAccess);
    const toggleShoppingCartAccess = () => setShoppingCartAccess(!shoppingCartAccess);
    const toggleAddShippingAccess = () => setAddShippingAccess(!addShippingAccess);

    const handleConfirm = () => {
        UserService.saveUserPermissions(props.participantId, hasWarrantyAccess, shoppingCartAccess, addShippingAccess)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.result.responseId === true) {
                        handleClose();
                        props.saveUserPermissionsCallback();
                    }
                }
            }).catch(error => {
                console.log("Error " + error);
            });
    }

    return (
        <Fragment>
            <div className="picklist-actions">
                <Button className="btn btn-secondary" onClick={handleShow} ><FontAwesomeIcon icon={faPen} /></Button>
            </div>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Permissions for {props.participantFullName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group as={Row}>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Warranty Full Access"
                                name="warrantyAccess"
                                onChange={toggleHasWarrantyAccess}
                                defaultChecked={props.warrantyAccess}
                                id="warrantyAccess"
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Create Orders"
                                name="shoppingCartAccess"
                                onChange={toggleShoppingCartAccess}
                                defaultChecked={props.shoppingCartAccess}
                                id="shoppingCartAccess"
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Create New Ship-To Addresses"
                                name="addShippingAccess"
                                onChange={toggleAddShippingAccess}
                                defaultChecked={props.addShippingAccess}
                                id="addShippingAccess"
                            />
                        </Col>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirm} >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

ParticipantPermissionsChange.propTypes = {
    participantId: PropTypes.string.isRequired,
    participantFullName: PropTypes.string.isRequired,
    warrantyAccess: PropTypes.bool.isRequired,
    shoppingCartAccess: PropTypes.bool.isRequired,
    addShippingAccess: PropTypes.bool.isRequired,
    saveUserPermissionsCallback: PropTypes.func.isRequired
};

export default ParticipantPermissionsChange;