var metric, imperial;

metric = {
  mcg: {
    to_anchor: 1/1000000
  }, 
  mg: {
    to_anchor: 1/1000
  }, 
  g: {
    to_anchor: 1
  }, 
  kg: {
    to_anchor: 1000
  }, 
  mt: {
    to_anchor: 1000000
  }
};

imperial = {
  oz: {
    to_anchor: 1/16
  }, 
  lb: {
    to_anchor: 1
  }, 
  t: {
    to_anchor: 2000,
  },
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 'g', 
      ratio: 1/453.592
    }, 
    imperial: {
      unit: 'lb', 
      ratio: 453.592
    }
  }
};
