import React, { useState, useEffect, Fragment } from 'react';
import { Item, Product, Engine, Loading, NoResults } from '../components';
import queryString from 'query-string';
import Pagination from "react-js-pagination";
import { useHistory, useLocation } from "react-router-dom";
import { CommonService } from '../services';
import { isLoggedUser } from "../redux/selectors";
import { connect } from "react-redux";

const Search = ({ loggedUser }) => {
    const history = useHistory();
    const location = useLocation();
    const itemsPerPage = 10
    const parsed = queryString.parse(window.location.search);
    const [query, setQuery] = useState(parsed.q);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(history.location.state !== undefined ? history.location.state.page : 1);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [onlyProducts, setOnlyProducts] = useState(false);
    const [onlyEngines, setOnlyEngines] = useState(false);
    
    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        setQuery(parsed.q);
        // eslint-disable-next-line eqeqeq
        if (parsed.q != query) {
            setPage(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    useEffect(() => {
        setLoading(true);
        setOnlyProducts(false);
        setOnlyEngines(false);
        getItems();
        window.scrollTo(0, 0);
    }, [page, query])

    const goToPage = (page) => {
        setPage(page);
        history.replace({
            pathname:  "/search",
            search: window.location.search,
            state: {
                page: page
            } 
        });
    }

    async function getItems() {
        CommonService.getSimpleSearch(query, itemsPerPage, page)
            .then(function(response) {
                if (response.status === 200) {
                    setLoading(false);
                    const data = response.data.Data;
                    const engines = data.filter(item => item.engineId !== "");
                    const products = data.filter(item => item.saleItem !== "");
                    if (engines.length === 0 && products.length > 0) {
                        setOnlyProducts(true);
                    }
                    if (products.length === 0 && engines.length > 0) {
                        setOnlyEngines(true);
                    }
                    setItems(data);
                    setTotalItemsCount(1);
                    if (data.length > 0) {
                        const totalItems = data[0].totalRows;
                        setTotalItemsCount(totalItems);
                    }
                }
            }).catch(function(error) {
                console.log(error);
            });
    }
    
    return (
        <div className="search-results">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>Narrow search</h1>
                                <div className="badges mb-3">
                                    <span class="badge badge-pill badge-light">Results for: {query}</span>
                                </div>
                                {loading ? <Loading /> : 
                                    <Fragment>
                                        {items.length > 0 ? 
                                            <Fragment>
                                            <div className="results">
                                                {onlyProducts ?
                                                    items.map((item, index) =>
                                                        <Product
                                                            key={index}
                                                            productId={item.itemRow}
                                                            saleItem={item.saleItem}
                                                            oeNumber={item.oeNumber}
                                                            categoryChild={item.categoryChild}
                                                            categoryParent={item.categoryParent}
                                                            description={item.description}
                                                            imagePath={item.imagePath}
                                                            listPrice={loggedUser == true ? item.listPrice : null}
                                                        />
                                                    )
                                                : null}
                                                {onlyEngines ?
                                                    items.map((item, index) =>
                                                        <Engine
                                                            key={index}
                                                            engineId={item.engineId}
                                                            brandName={item.brandName}
                                                            horsePower={item.horsePower}
                                                            stroke={item.stroke}
                                                            cylinders={item.cylinders}
                                                            modelNumber={item.modelNumber}
                                                            startYear={item.startYear}
                                                            stopYear={item.stopYear}
                                                        />
                                                    )
                                                : null}
                                                {onlyEngines === false && onlyProducts === false ?
                                                    items.map((item, index) =>
                                                        <Item
                                                            key={index}
                                                            productId={item.itemRow}
                                                            engineId={item.engineId}
                                                            saleItem={item.item}
                                                            categoryChild={item.categoryChild}
                                                            categoryParent={item.categoryParent}
                                                            description={item.description}
                                                            brandName={item.brandName}
                                                            horsePower={item.horsePower}
                                                            modelNumber={item.modelNumber}
                                                            startYear={item.startYear}
                                                            stopYear={item.stopYear}
                                                            imagePath={item.imagePath}
                                                            listPrice={loggedUser == true ? item.listPrice : null}
                                                        />
                                                    )
                                                : null}
                                            </div>
                                            {totalItemsCount > itemsPerPage ? (
                                            <div className="text-center mt-2">
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={page}
                                                    itemsCountPerPage={itemsPerPage}
                                                    totalItemsCount={totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={goToPage}
                                                />
                                            </div>
                                            ) : null}
                                            </Fragment>
                                        : <NoResults />
                                        }
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = store => {
    const loggedUser = isLoggedUser(store);
    return { loggedUser };
}
export default connect(mapStateToProps)(Search);