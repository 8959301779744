var metric, imperial;

metric = {
  'lx': {
    to_anchor: 1
  }
};

imperial = {
  'ft-cd': {
    to_anchor: 1
  }
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: 'lx',
      ratio: 1/10.76391
    },
    imperial: {
      unit: 'ft-cd',      
	    ratio: 10.76391
    }
  }
};
