import React from 'react';

const WarrantyPolicy = () => {


    return (
        <div className="text-page">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 offset-md-2">


                        <h1>Warranty – Mechanical, Hydraulic and Jack Plates</h1>
                        <h3>2-Year Limited Warranty (Mechanical, Hydraulic, and Jack Plate Products)</h3>
                        <p>Dometic warrants its products to be free from defects in materials and workmanship for a period of two years from the date of original retail purchase, provided, however, the warranty period for SeaStar products used commercially or in any rental or other income producing activity shall be as follows:</p>
                        <ul>
                            <li>Ninety days from the date of original purchase for mechanical and electrical products</li>
                            <li>One year from the date of original purchase for hydraulic products.</li>
                        </ul>
                        <p>We will provide replacement product without charge for any SeaStar product covered by this warranty, which is returned <strong>(freight prepaid)</strong> within the warranty period to the dealer from whom such products were purchased, or to us at the appropriate address. In any such case, SeaStar products found to be defective and covered by this warranty will be replaced or repaired at Dometic option and returned to the customer.</p>
                        <p>Dometic sole responsibility under this warranty is limited to the repair or replacement of product which is, in Dometic opinion, defective. Dometic is not responsible for charges connected with the removal of such product or reinstallation of replacement or repaired parts.</p>
                        <p><em>We will have no obligations under this warranty for any product which:</em></p>
                        <ul>
                            <li>
                                <em>has been improperly installed;</em>
                            </li>
                            <li>
                                <em>has been used in an installation other than as recommended in our installation or operation instructions or specifications;</em>
                            </li>
                            <li>
                                <em>has failed or has been damaged due to an accident or abnormal operation including racing, misuse or alterations outside our factory;</em>
                            </li>
                            <li>
                                <em>has been repaired or modified by entities other than Dometic;</em>
                            </li>
                            <li>
                                <em>has been used on an engine/boat combination where the engine horsepower exceeds the rating established by the boat manufacturer;</em>
                            </li>
                            <li>
                                <em>has been used with other product(s) which, in Dometic opinion, are incompatible with SeaStar product.</em>
                            </li>
                        </ul>
                        <p>THE EXPRESSED WARRANTY SET FORTH ABOVE IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. DOMETIC EMPLOYEES OR REPRESENTATIVES’ ORAL OR OTHER WRITTEN STATEMENTS DO NOT CONSTITUTE WARRANTIES, SHALL NOT BE RELIED UPON BY CUSTOMER, AND ARE NOT A PART OF THE WARRANTY STATED HEREIN. THIS WARRANTY WILL BE THE CUSTOMER’S EXCLUSIVE REMEDY. IN NO EVENT WILL DOMETIC BE LAIBLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES FOR BREACH OF ANY EXPRESS OR IMPLIED WARRANTY RELATING TO THE PRODUCTS. Some states do not allow limitations on an implied warranty, or the exclusion of incidental or consequential damages, so the above exclusions may not apply to you. You may also have other rights which vary from state to state.</p>
                        <p>If any part of this Limited Warranty is determined to be void or illegal, the remainder shall remain in full force and effect.</p>
                        <p><strong>SeaStar products returned under this warranty must be tagged with the customer’s name, street address, and phone number to ensure proper handling, and returned freight prepaid to the selling dealer or to the Dometic Centralized Distribution facility in Litchfield, IL.</strong></p>





                        <h2 className="text-center">Warranty – Sierra and Shields</h2>
                        <h3 className="text-center">Sierra Customer Satisfaction Warranty (Professional Installer)</h3>
                        <h4>Limited Warranty (to include Sierra and Shields Product Groups):</h4>
                        <p>Dometic Marine US Corp - Litchfield Division (“SIERRA”) warrants its products to be free of defects in workmanship and materials for the useful life of the product (the “Warranty”). If a SIERRA product fails to comply with the Warranty, SIERRA will repair or replace the defective product free of charge.<br/>
                        The Warranty is subject to the additional terms, conditions and limitations set forth below.</p>
                        <h4>Warranty Claims for Professionally Installed Products:</h4>
                        <p>For any SIERRA product that was originally installed on a vessel by a dealer or other professional installer that fails to comply with the Warranty, in addition to repairing or replacing the product free of charge, SIERRA will also reimburse the customer for reasonable labor charges incurred to replace the product, and reasonable towing and other similar incidental expenses incurred as a result of the failure of the product to comply with the Warranty. Labor will be calculated based on the installer’s posted shop rate that is competitive with their local market rates. The original work order along with the original invoice and a copy of the new re-work order must be provided to SIERRA to file a claim for reimbursement of labor expenses. Towing charges will also be paid based on reasonable and customary rates in the local trading area. A copy of the original invoice for the towing service must be provided to SIERRA to file a claim for reimbursement of towing expenses.</p>
                        <h4>ALL PROFESSIONAL INSTALLER WARRANTY CLAIMS MUST BE SUBMITTED IN ACCORDANCE WITH THE FOLLOWING PROCEDURE:</h4>
                        <p>Dealers may file all warranty claims on our <strong>Sierra Parts Catalog</strong> website at <strong>catalog.sierraparts.com</strong></p>
                        <p>A free user account is required. Dealers may also continue to use the options below.</p>
                        <p><strong>WITHOUT LABOR CLAIM</strong> - The dealer may return any allegedly defective SIERRA product to the distributor from whom it was purchased for analysis. The product shall be tagged with the SIERRA part number, date of purchase and the alleged cause of failure. The distributor will then forward the product to SIERRA with a request for credit. If SIERRA determines that the part failed to comply with the Warranty and otherwise qualifies for Warranty coverage under the terms hereof, SIERRA will issue credit for the product to the distributor. The distributor will reimburse the dealer. SIERRA will not issue credit for any products that are not returned to SIERRA. Products not manufactured or distributed by SIERRA will be held for disposition for 30 days.</p>
                        <p><strong>WITH LABOR CLAIM – All warranty claims for which reimbursement of labor and/or towing</strong> expenses is sought are subject to prior authorization. In addition to catalog.sierraparts.com, dealers may call 217-441-8332, option 3, to discuss any such claim with a SIERRA representative. In order to process all claims quickly and efficiently, the following must be shipped directly to SIERRA via a traceable and insurable method (i.e. UPS, Federal Express, Registered U.S. Mail, etc.):</p>
                        <ol>
                            <li>
                                The allegedly defective product and any related damaged parts.
                            </li>
                            <li>
                                A written estimate detailing the following information:
                                <ol>
                                    <li>A complete list, with part numbers, of all products required for the warranty repair.</li>
                                    <li>The shop labor rate and a breakdown of the time required for the repair.</li>
                                    <li>The year, model and serial number of the warranted engine or drive.</li>
                                    <li>The name and address of the distributor the products were purchased from.</li>
                                    <li>The name, address, and phone number of the end consumer.</li>
                                </ol>
                            </li>
                            <li>The original work order or receipt detailing the initial installation of the allegedly defective product.</li>
                            <li>Any receipts detailing additional expenses.</li>
                        </ol>
                        <p>Failure to provide required documentation and information may void all or part of the Warranty coverage.</p>
                        <p><strong>Proof of delivery will be required on all lost shipments.</strong></p>
                        <p><strong>NOTE:</strong> Concerning labor claims on older products that cannot be rectified due to the unavailability of OEM replacement parts; SIERRA reserves the right to extend only the market value of the OEMproduct.</p>
                        <p>
                            <strong>Send claims to:</strong><br/>
                            <strong>TECHNICAL SERVICE</strong><br/>
                            DOMETIC<br/>
                            1 Sierra Place<br/>
                            Litchfield, IL 62056-3029<br/>
                            <strong>217-441-8332 (option 3)</strong>
                        </p>
                        <p><strong>Limitations:</strong></p>
                        <p>THE EXPRESS WARRANTY SET FORTH ABOVE IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SIERRA does not warrant against, and the Warranty shall be void with respect to, damages or defects arising out of any of the following:</p>
                        <ul>
                            <li>improper or abnormal use or handling of SIERRA’s products,</li>
                            <li>installation or use of a SIERRA product in a manner that is inconsistent with SIERRA’s application information, guidelines, instructions and/or specifications,</li>
                            <li>defects in products or components not manufactured by SIERRA,</li>
                            <li>non-SIERRA made products or components,</li>
                            <li>SIERRA products transferred from a vessel on which they were originally installed,</li>
                            <li>SIERRA products transferred from the engine on which they were originally installed,</li>
                            <li>failure to maintain SIERRA products in accordance with SIERRA’s application information, guidelines, instructions and/or specifications,</li>
                            <li>ordinary wear and tear resulting from the operation and/or use of the SIERRA product.</li>
                        </ul>
                        <p>This warranty also does not apply to products which have been altered or upon which repairs have been affected or attempted by persons other than pursuant to written authorization by SIERRA.</p>
                        <p>THIS STATEMENT OF LIMITED WARRANY IS NOT A STATEMENT OF THE USEFUL LIFE OF ANY SIERRA PRODUCT. WHEN SIERRA WARRANTS ITS PRODUCTS FOR THE USEFUL LIFE OF THE PRODUCT, THIS DOES NOT GUARANTEE THAT THE PRODUCT WILL LAST FOREVER OR FOR THE LIFE OF THE VESSEL. THE LENGTH OF THE USEFUL LIFE OF SIERRA’S PRODUCTS WILL VARY DEPENDING ON THE TYPE OF PRODUCT, AS SPECIFIED IN SIERRA’S APPLICATION INFORMATION, GUIDELINES, INSTRUCTIONS AND SPECIFICATIONS FOR EACH PARTICULAR PRODUCT. PLEASE REFER TO THE INFORMATION PROVIDED WITH YOUR SIERRA PRODUCT IN ORDER TO DETERMINE THE USEFUL LIFE OF THAT PRODUCT.</p>
                        <p>The sole and exclusive obligation of SIERRA shall be to repair or replace the defective products in the manner set forth above. SIERRA shall not have any other obligation with respect to the products or any part thereof, whether based on contract, tort, strict liability or otherwise. Under no circumstances, whether based on this Warranty or otherwise, shall SIERRA be liable for any special, punitive, or consequential damages.</p>
                        <p>SIERRA’s employees or representatives’ ORAL OR OTHER WRITTEN STATEMENTS DO NOT CONSTITUTE WARRANTIES, shall not be relied upon by customer, and are not a part of the warranty stated herein.</p>
                        <p>If any part of this Limited Warranty is determined to be void or illegal, the remainder shall remain in full force and effect.</p>







                        <h2 className="text-center">Moeller Customer Satisfaction Warranty</h2>
                        <p>Moeller warrants the designated products to be free of manufacturing defects in material and workmanship. Warranty is null and void if the product has been used or handled other than the instructions in the owner’s manual, misused, accidently damaged, neglected, and /or tampered with anyone other than Moeller authorized personnel. All products have a 1-year warranty from date of purchase, 2 years on above deck tanks and 3 years on bilge pumps.</p>
                        <h2 className="text-center">FIVE-YEAR LIMITED WARRANTY (CFX3 COOLERS)</h2>
                        <h3 className="text-center">FIRST THREE YEARS: PARTS, LABOR, AND FREIGHT.</h3>
                        <h3 className="text-center">FOLLOWING TWO YEARS: PARTS ONLY FOR COMPRESSOR (EXCLUDES ELECTRONIC MODULE)</h3>
                        <h3 className="text-center">COVERED PRODUCTS: CFX3 25, CFX3 35, CFX3 45, CFX3 55IM, CFX3 75DZ, CFX3 95DZ, CFX3 100</h3>
                        <p>THE SELLER NAMED BELOW MAKES THE FOLLOWING WARRANTY WITH RESPECT TO THE DOMETIC PRODUCT:</p>
                        <ol>
                            <li>This Warranty is made only to the first purchaser (hereinafter referred to as the “Original Purchaser”), who acquires the Dometic Product for his or her own use and when the Dometic Product is installed and operated within the continental United States and Canada.</li>
                            <li>WARRANTY PERIODS:</li>
                            <ol type="a">
                                <li>THREE-YEAR LIMITED WARRANTY (PARTS, LABOR, AND FREIGHT): This Limited Warranty covers flat rate labor, specified parts, and freight for a period of the first three (3) years from the date of purchase by the Original Purchaser. The Original Purchaser should retain a copy of the dated bill of sale as evidence of the date of purchase.</li>
                                <li>TWO YEAR LIMITED WARRANTY (PARTS ONLY): Following the initial three-year Limited Warranty period, the remaining two-year Limited Warranty period covers compressor ONLY (excludes electronic module). During this two-year Limited Warranty period the Original Purchaser is responsible for all labor and freight costs.</li>
                            </ol>
                            <li>WARRANTY: This Warranty covers labor, specified parts, and freight. The Dometic Product shall be free from defects in material and workmanship at the time of sale and under normal use. All Dometic Products (except those specifically built for commercial use) are warranted only when installed per the Seller’s installation instructions. This Warranty does NOT cover conditions unrelated to the material and workmanship of the Dometic Product.  Such unrelated conditions include, but are not limited to: (a) damage not reported within 10 days of ownership; (b) damage or failure caused by installation of accessories not manufactured and marketed by the Seller or any non-Dometic parts that are installed as replacement parts; (c) the need for normal maintenance and any damage resulting from the failure to provide such maintenance; (d) failure to follow Seller’s instructions for use of this Dometic Product; (e) reduced performance due to high altitude; (f) radio frequency interference (RFI) or electromagnetic interference (EMI); (g) 12 VDC system chassis ground decay and corrosion; (k) animal or insect infiltration which damages unit or inhibits performance; (l) any accident to, or misuse of, any part of the Dometic Product and any alteration by anyone other than the Seller or its authorized representative; and (k) normal wear.</li>
                            <li>WARRANTY COVERS DOMETIC COMPONENT PARTS: All Dometic component parts are covered under this Limited Warranty.</li>
                            <li>WARRANTY REMEDY: To obtain the benefits of this Warranty, the Original Purchaser has the following two options during the</li>
                            WARRANTY PERIODS:
                            <ol type="a">
                                <li>Preferred option: Deliver the Dometic Product for inspection to the nearest Authorized Dometic Service Center during the Warranty Periods. To obtain the location of the nearest Authorized Dometic Service Center, refer to https://www.dometic.com/en-us/us/find-a-dealer. The Authorized Dometic Service Center will work with the Seller to obtain Warranty coverage if a Seller defect is identified. CONFIRM THE SERVICE CENTER IS AN AUTHORIZED DOMETIC SERVICE CENTER. DURING THE INITIAL THREE-YEAR LIMITED WARRANTY PERIOD, DO NOT PAY THE SERVICE CENTER FOR WARRANTY REPAIRS. DURING THE FINAL TWO-YEAR WARRANTY PERIOD, THE  ORIGINAL PURCHASER IS RESPONSIBLE FOR ALL LABOR AND FREIGHT COSTS.</li>
                                <li>Second option: If it is not feasible under the circumstances to deliver the Dometic Product to an Authorized Dometic Service Center, please call 1-800-544-4881 or email customersupportcenter@dometic.com for additional Warranty assistance. Seller prefers option a. first and only option b. if option a. is determined not to be feasible under the circumstances.</li>
                            </ol>
                            <li>Any item returned in the manner described in paragraph 5 will be examined by the Authorized Dometic Service Center. If it is found that the returned item was defective in material and workmanship at the time of sale, the Authorized Dometic Service Center will contact the Seller for Warranty coverage. During the Warranty Periods, the Seller shall, at Dometic’s sole option, repair or replace the Dometic Product, or refund Original Purchaser’s purchase price. If the Seller determines that repairs to the Dometic Product are to be made, then only authorized Dometic parts will be used.</li>
                            <li>The Seller does not authorize any person or company to create any Warranty obligations or liability on its behalf. This Warranty is not extended by the length of time which you are deprived of the use of the Dometic Product.</li>
                            <li>IN NO EVENT SHALL SELLER BE LIABLE FOR EITHER INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS INCLUDES ANY DAMAGE TO ANOTHER PRODUCT OR PRODUCTS RESULTING FROM SUCH A DEFECT. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</li>
                            <li>ANY IMPLIED WARRANTY, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY AND FITNESS FOR ANY PURPOSE, IS LIMITED TO THE DURATION OF THIS LIMITED WARRANTY. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</li>
                            <li>THIS WARRANTY GIVES SPECIFIC LEGAL RIGHTS, YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. No action to enforce this Warranty shall be commenced later than ninety (90) days after the expiration of the Warranty Period. Claims must be submitted in writing to Dometic Customer Support.</li>
                            <li>The Seller reserves the right to change the design of any Dometic product without notice and with no obligation to make corresponding changes in Dometic products previously manufactured.</li>
                        </ol>







                        <h2 className="text-center">LIMITED SEVEN-YEAR WARRANTY (PATROL COOLERS)</h2>
                        <p>THE SELLER NAMED BELOW MAKES THE FOLLOWING WARRANTY WITH RESPECT TO THE DOMETIC PRODUCT:</p>
                        <ol>
                            <li>This Warranty is made only to the first purchaser (hereinafter referred to as the “Original Purchaser”), who acquires the Dometic Product for his or her own use and when the Dometic Product is installed and operated within the continental United States and Canada.</li>
                            <li>WARRANTY PERIOD: This Warranty will be in effect for seven (7) years from the date of purchase by the Original Purchaser. The Original Purchaser should retain a copy of the dated bill of sale as evidence of the date of purchase.</li>
                            <li>WARRANTY: This Warranty covers labor, specified parts, and freight. The Dometic Product shall be free from defects in material and workmanship at the time of sale and under normal use. All Dometic Products (except those specifically built for commercial use) are warranted only when installed per the Seller’s installation instructions on vehicles built to R.V.I.A. A119-2 and C.R.V.A. Z-240 Standards. This Warranty does NOT cover conditions unrelated to the material and workmanship of the Dometic Product. Such unrelated conditions include, but are not limited to: (a) damage not reported within 10 days of ownership; (b) faulty installation or installation that does not comply with the Seller’s instructions or R.V.I.A. and C.R.V.A. standards, and any damage resulting from such; (c) damage or failure caused by installation of accessories not manufactured and marketed by the Seller or any non-Dometic parts that are installed as replacement parts; (d) the need for normal maintenance and any damage resulting from the failure to provide such maintenance; (e) failure to follow Seller’s instructions for use of this Dometic Product; (f) reduced performance due to high altitude; (g) radio frequency interference (RFI) or electromagnetic interference (EMI); (h) 12 VDC system chassis ground decay and corrosion; (i) animal or insect infiltration which damages unit or inhibits performance; (j) any accident to, or misuse of, any part of the Dometic Product and any alteration by anyone other than the Seller or its authorized representative; and (k) normal wear.</li>
                            <li>WARRANTY COVERS DOMETIC COMPONENT PARTS: All Dometic component parts are covered under this Limited Warranty. However, parts used in conjunction with the installation of the Dometic Product are not covered under this Limited Warranty.</li>
                            <li> WARRANTY REMEDY: To obtain the benefits of this Warranty, the Original Purchaser has the following two options during the WARRANTY PERIOD:
                                <ol>
                                    <li>Preferred option: Deliver the Dometic Product for inspection to the nearest Authorized Dometic Service Center during the Warranty Period. To obtain the location of the nearest Authorized Dometic Service Center, refer to https://www.dometic.com/en-us/us/find-a-dealer. The Authorized Dometic Service Center will work with the Seller to obtain Warranty coverage if a Seller defect is identified. CONFIRM THE SERVICE CENTER IS AN AUTHORIZED DOMETIC SERVICE CENTER. DO NOT PAY THE SERVICE CENTER FOR WARRANTY REPAIRS.</li>
                                    <li>Second option: If it is not feasible under the circumstances to deliver the Dometic Product to an Authorized Dometic Service Center, please call 1-800-544-4881 for additional Warranty assistance. Seller prefers option a. first and only option b. if option a. is determined not to be feasible under the circumstances.</li>
                                </ol>
                            </li>
                            <li>Any item returned in the manner described in paragraph 5 will be examined by the Authorized Dometic Service Center. If it is found that the returned item was defective in material and workmanship at the time of sale, the Authorized Dometic Service Center will contact the Seller for Warranty coverage. The Seller shall, at Dometic’s sole option, repair or replace the Dometic Product, or refund Original Purchaser’s purchase price. If the Seller determines that repairs to the Dometic Product are to be made, then only authorized Dometic parts will be used.</li>
                            <li>The Seller does not authorize any person or company to create any Warranty obligations or liability on its behalf. This Warranty is not extended by the length of time which you are deprived of the use of the Dometic Product.</li>
                            <li>IN NO EVENT SHALL SELLER BE LIABLE FOR EITHER INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS INCLUDES ANY DAMAGE TO ANOTHER PRODUCT OR PRODUCTS RESULTING FROM SUCH A DEFECT. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</li>
                            <li>ANY IMPLIED WARRANTY, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY AND FITNESS FOR ANY PURPOSE, IS LIMITED TO THE DURATION OF THIS LIMITED WARRANTY. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</li>
                            <li>THIS WARRANTY GIVES SPECIFIC LEGAL RIGHTS, YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. No action to enforce this Warranty shall be commenced later than ninety (90) days after the expiration of the Warranty Period. Claims must be submitted in writing to the Dometic Warranty Department.</li>
                            <li>The Seller reserves the right to change the design of any Dometic product without notice and with no obligation to make corresponding changes in Dometic products previously manufactured.</li>
                        </ol>





                        <h2 className="text-center">LIMITED ONE-YEAR WARRANTY FOR DOMETIC BRANDED PORTABLE SANITATION</h2>
                        <p>THE SELLER NAMED BELOW MAKES THE FOLLOWING WARRANTY:</p>
                        <ol>
                            <li>This Warranty is made only to the first purchaser (hereinafter referred to as the “Original Purchaser”), who acquires the Dometic Product for his or her own use and when the Dometic Product is installed and operated within the continental United States and Canada.</li>
                            <li>WARRANTY PERIOD: This Warranty will be in effect for one (1) year from the date of purchase by the Original Purchaser if used for personal, family or household purposes. If used for commercial or business use, the warranty will be in effect for ninety (90) days from the date of original purchase. The Original Purchaser should retain a copy of the dated bill of sale as evidence of the date of purchase.</li>
                            <li>WARRANTY: This Warranty covers labor, specified parts, and freight. The Dometic Product shall be free from defects in material and workmanship at the time of sale and under normal use. All Dometic Products (except those specifically built for commercial use) are warranted only when installed per the Seller’s installation instructions on boats built to American Boat and Yacht Council (&quot;ABYC&quot;) Standards, or with respect to vehicles, R.V.I.A. A119-2 and C.R.V.A. Z-240 Standards. This Warranty does NOT cover conditions unrelated to the material and workmanship of the Dometic Product. Such unrelated conditions include, but are not limited to: (a) damage not reported within 10 days of ownership; (b) failure or damage caused by storms, rain, or any acts of God; (c) faulty installation or installation that does not comply with the Seller’s instructions or with respect to boats, American Boat and Yacht Council (&quot;ABYC&quot;) Standards, or with respect to vehicles, R.V.I.A. and C.R.V.A. Standards, and any damage resulting from such; (d) damage or failure caused by installation of accessories not manufactured and marketed by the Seller or any non-Dometic parts that are installed as replacement parts; (e) the need for normal maintenance and any damage resulting from the failure to provide such maintenance; (f) failure to follow Seller’s instructions for use of this Dometic Product; (g) any accident to, or misuse of, any part of the Dometic Product and any alteration by anyone other than the Seller or its authorized representative; (h) cosmetic issues caused during installation; (i) normal wear; and (j) Dometic Product abuse.</li>
                            <li>WARRANTY COVERS DOMETIC COMPONENT PARTS: All Dometic component parts are covered under this Limited Warranty.</li>
                            <li>It is suggested that the Original Purchaser provide preventative maintenance on a monthly or yearly basis. The preventative maintenance suggested is detailed in your owner&#39;s manual for the product. The cost of this preventative maintenance is the Original Purchaser’s responsibility.</li>
                            <li>WARRANTY REMEDY: To obtain the benefits of this Warranty, the Original Purchaser has the following two options during the WARRANTY PERIOD:
                                <ol>
                                    <li>Preferred option: Deliver the Dometic Product for inspection to the nearest Authorized Dometic Service Center during the Warranty Period. To obtain the location of the nearest Authorized Dometic Service Center, refer to https://www.dometic.com/en-us/us/find-a-dealer. The Authorized Dometic Service Center will work with the Seller to obtain Warranty coverage if a Seller defect is identified. CONFIRM THE SERVICE CENTER IS AN AUTHORIZED DOMETIC SERVICE CENTER. DO NOT PAY THE SERVICE CENTER FOR WARRANTY REPAIRS.</li>
                                    <li>Second option: If it is not feasible under the circumstances to deliver the Dometic Product to an Authorized Dometic Service Center, please call 1-800-321-9886 for additional Warranty assistance. Seller prefers option a. first and only option b. if option a. is determined not to be feasible under the circumstances.</li>
                                </ol>
                            </li>
                            <li>Any item returned in the manner described in paragraph 6 will be examined by the Authorized Dometic Service Center. If it is found that the returned item was defective in material and workmanship at the time of sale, the Authorized Dometic Service Center will contact the Seller for Warranty coverage. The Seller shall, at Dometic’s sole option, repair or replace the Dometic Product, or refund Original Purchaser’s purchase price. If the Seller determines that repairs to the Dometic Product are to be made, then only authorized Dometic parts will be used.</li>
                            <li>The Seller does not authorize any person or company to create any Warranty obligations or liability on its behalf. This Warranty is not extended by the length of time which you are deprived of the use of the Dometic Product.</li>
                            <li>IN NO EVENT SHALL SELLER BE LIABLE FOR EITHER INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS INCLUDES ANY DAMAGE TO ANOTHER PRODUCT OR PRODUCTS RESULTING FROM SUCH A DEFECT. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</li>
                            <li>ANY IMPLIED WARRANTY, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY AND FITNESS FOR ANY PURPOSE, IS LIMITED TO THE DURATION OF THIS LIMITED WARRANTY. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</li>
                            <li>THIS WARRANTY GIVES SPECIFIC LEGAL RIGHTS, YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. No action to enforce this Warranty shall be commenced later than ninety (90) days after the expiration of the Warranty Period. Claims must be submitted in writing to the Dometic Customer Support Center.</li>
                            <li>The Seller reserves the right to change the design of any Dometic product without notice and with no obligation to make corresponding changes in Dometic products previously manufactured.</li>
                        </ol>





                    </div>
                </div>
            </div>
        </div>
    );
}

export default WarrantyPolicy;