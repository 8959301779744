import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import OrderItems from '../../components/Orders/OrderItems';
import { Button } from 'react-bootstrap';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatalogService from '../../services/CatalogService';
import FourthShiftService from '../../services/FourthShiftService';
import { getLoggedUserToken, isLoggedUser } from '../../redux/selectors';
import { connect } from "react-redux";
import { setCatalogAuthorizationHeader } from '../../utils/NewCatalogAPI';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import UserService from '../../services/UserService';
import ReactGA from 'react-ga4';
import Spinner from '../../components/Spinner';
 
const Item = {
    quantity: -1,
    price: -1,
    subTotal: -1,
    priceChecked: false,
    inventoryChecked: false,
    itemDetail: {},
    inventory: -1
};

const QuickOrder = (props) => {
    let { orderGuid } = useParams();
    const [OrderGuid, setOrderGuid] = useState(null);
    const [NewOrderGuid, setNewOrderGuid] = useState(null);
    const [ItemData, setItemData] = useState([]);
    const [Item, setItem] = useState(null);
    const [ItemNumber, setItemNumber] = useState(null);
    const [Message, setMessage] = useState();
    const [ItemsProcessed, setItemsProcessed] = useState(0);
    const [DataLoading, setDataLoading] = useState(false);
    const [FormLoading, setFormLoading] = useState(true);
    const [UserInfo, setUserInfo] = useState(null);
    const history = useHistory();

    useEffect(() => {
        console.log("quickOrder.loggedUser:", props.loggedUser);
 
        if (props.loggedUser)
        {
            setCatalogAuthorizationHeader(props.userToken);
            setAuthorizationHeader(props.userToken);
   
            if (UserInfo==null)
            {
                UserService.getUserInfo().then(user => {
                    console.log("userInfo.get.response: ", user.data.data);
                    if (user.status == 200)
                    {
                        setUserInfo(user.data.data);  
                        setFormLoading(false);   
                    }
                });
            }
        }
        else{
            history.push("/user/login");
        }
    }, [props.userToken]);
 
    useEffect(() => {
   
        if (OrderGuid == null && orderGuid?.length>0)
            setOrderGuid(orderGuid);
 
        console.log("OrderGuid: " + orderGuid);
   
        if (orderGuid && UserInfo) {
            setDataLoading(true);
            getOrder(orderGuid);            
        }
 
   }, [orderGuid, UserInfo])
 
   useEffect(() => {
        console.log("useEffect.Item");

        if (Item!==null)
        {  
            //If item already exists, just increment the quantity by 1
            let itemExists = ItemData.find((rec) => {
                return rec.itemDetail.id===Item.itemDetail.id;
            })
 
            if (itemExists!==undefined)
            {
                let editData = ItemData.map((item) =>
                    item.itemDetail.id === Item.itemDetail.id ? { 
                        ...item, 
                        quantity: parseInt(item.quantity)+1, 
                        subTotal: ((parseInt(item.quantity)+1) * item.price),
                        isValid: ((parseInt(item.quantity)+1) <= item.inventoryQty && (parseInt(item.quantity)+1)>0)
                    } : item
                )
                setItemData(editData);
            } 
            else {
                if (Item.itemDetail.id!==0)
                {
                    setItemData(prevItems => [...prevItems, Item]);
                }
                else{
                    console.log("Item not found: " + ItemNumber);
                    setMessage("This item is not available to purchase through the B2B portal. Contact your preferred distributor or partner to purchase. " + ItemNumber)
                }
                
            }
            setItem(null);
        }
 
   }, [Item])
 
    useEffect(() => {
       console.log("useEffect.ItemData");

        let _itemData = [...ItemData]
        
        if (_itemData.filter(w => w.priceChecked==false).length>0)
        {
            console.log("_totalItemsToPrice: ", _itemData.filter(w => w.priceChecked==0).length)
            const _item = ItemData.filter(f => f.priceChecked==false)[0];
            updatePrice(_item).then(res=>{
                console.log("updatePrice:res", res);
                 let editData = ItemData.map((i) =>
                    i.itemDetail.id === res.itemDetail.id ? res : i
                )

                setItemData(editData);
            });
        }

        if (_itemData.filter(w => w.inventoryChecked==false).length>0)
        {
            console.log("_totalItemsForInventoryCheck: ", _itemData.filter(w => w.inventoryChecked==0).length)
            const _item = ItemData.filter(f => f.inventoryChecked==false)[0];
            updateAvailableQty(_item).then(res=>{
                console.log("updateAvailableQty:res", res);
                 let editData = ItemData.map((i) =>
                    i.itemDetail.id === res.itemDetail.id ? res : i
                )

                setItemData(editData);
            });
        }

    }, [ItemData])

    const updatePrice = (item) => {
            console.log("updatePrice", item);

            item.priceChecked = true;
            item.subTotal = 0; //default
            return FourthShiftService.getPrice(UserInfo?.PartnerHdrCustId, item.itemDetail.itemNum).then(res=>{
                item.price = 0;
                if (res.status==200) {
                    item.price = res.data.data.itemPrice ?? 0;
                    
                    if (item.quantity>0)
                        item.subTotal = item.quantity * item.price;
                }
                return item;
            });
    }

    const updateAvailableQty = (item) => {
        console.log("updateAvailableQty", item);

        item.inventoryChecked = true;
        item.inventoryQty = -1; //default to zero prior to the check.
        item.subTotal = 0; //default

        return FourthShiftService.getItemAvailability(item.itemDetail.itemNum).then(res=>{
            item.inventoryQty = 0;
            if (res.status==200) {
                console.log("updateAvailableQty:res:", res.data.data);
                item.inventoryQty = ((res.data.data.availableQty ?? 0));
                item.inventoryQtyDisplay = res.data.data.availableQty ? res.data.data.availableQty - item.quantity : 0 ;

                let minQuantity = res.data.data.moq;
                let standardPack = item.itemDetail.innerPack > 1 ? item.itemDetail.innerPack : 1;
                item.itemDetail.minQuantity = (minQuantity == 0) ? standardPack : minQuantity;

                item.isValid = (item.quantity <= item.inventoryQty && item.quantity>0 && (item.quantity % item.itemDetail.minQuantity == 0 ));

                if (!item.isValid){
                    if (item.quantity % item.itemDetail.minQuantity != 0 )
                        item.errorMessage = `Qty must be in increments of ${item.itemDetail.minQuantity}.`
                }
                if (item.price>0)
                    item.subTotal = item.quantity * item.price;
            }
            return item;
        });
    }
 
    useEffect(() =>
    {
        if (NewOrderGuid!=null)
        {
            let _itemsProcessed = 0;
            ItemData.forEach(r => {
                console.log("handleAddToCart: ItemDataForEach:", r);
 
                CatalogService.saveOrderItem(UserInfo.PartnerHdrCustId, 0, 0, OrderGuid??"", r.itemDetail.id, r.quantity, r.price).then(response => {
                   
                    if (response.status==200) {
                        _itemsProcessed++;
                        console.log("setItemsProcessed: ", _itemsProcessed);
                        setItemsProcessed(_itemsProcessed);
                    }
                }).catch(error => {
                    console.log("handleAddToCart: saveOrderItem: error:");
                    console.log(error);
                });
            });
        }
    },[NewOrderGuid])
 
    useEffect(() => {
        if (ItemsProcessed>0)
        {
            //Make sure all items have been saved before navigating to OrderCart
            if (ItemsProcessed==ItemData.length)
                history.push("/order/orderCart/" + NewOrderGuid);
        }
    }, [ItemsProcessed])
  
    const getOrder = (gId) => {
        console.log("quickOrder.getOrder");
        CatalogService.getOrder(gId).then(async response => {
            if (response.status === 200) {
                if (response.data.data) {
                    console.log("quickOrder.getOrder.Response:", response.data.data);
                    if (response.data.data.orderItems?.items) {
                        setItemData(response.data.data.orderItems?.items);
                    }
 
                    setDataLoading(false);
                    return true;
                }
            }
        }).catch(function (error) {
            console.log("quickOrder.getOrder.Error:");
            console.log(error);
            setDataLoading(false);
            return error;
        });
    }
  
    const handleAdd = (e, itemNumber) => {
        e.preventDefault();
        console.log("quickOrder.AddButton", itemNumber);
        setMessage();
        setItemNumber(itemNumber);
 
        console.log("quickOrder.Add.custId: ", UserInfo.PartnerHdrCustId);
        CatalogService.getItemByNumber(UserInfo.PartnerHdrCustId, itemNumber).then(response => {
            console.log("quickOrder.Add.getItem.Response:", response.data);
 
            if (response.status === 200) {
                if (response.data && response.data.data)
                {
                    //console.log(response.data);
                    var _item = {
                        quantity: 1,
                        price: -1,
                        subTotal: -1,
                        priceChecked: false,
                        inventoryChecked:false,
                        itemDetail: response.data.data,
                        inventoryQty: -1
                    };
 
                    setItem(_item);
                }
            }
        }).catch(function (error) {
            console.log("quickOrder.Add.getItem.Error:");
            console.log(error);
            return error;
        });
    }
 
    const handleAddToCart = (e) => {
        console.log("orderCheckout.handleAddToCart");
        ReactGA.event({
            category: 'User',
            action: 'quickorder_add_to_cart'
        });
        e.preventDefault();

        CatalogService.saveOrder(UserInfo.PartnerHdrId).then(response => {
            console.log("handleAddToCart: saveOrderItem: response:");

            if (response.status === 200) {
                if (response.data && response.data.data)
                    setNewOrderGuid(response.data.data);
            }
        }).catch(error => {
            console.log("handleAddToCart: saveOrderItem: error:");
            console.log(error);
        });

    }
 
    const handleQtyChange = (e,row) => {
       
        console.log("quickOrder: handleQtyChange", row);
        
        const isValid = (e.target.value <= row.inventoryQty) && (e.target.value > 0) && (e.target.value % row.itemDetail.minQuantity == 0);
        let errorMessage = '';

        if (!isValid) {
            if (e.target.value % row.itemDetail.minQuantity !== 0) {
                errorMessage = `Qty must be in increments of ${row.itemDetail.minQuantity}.`;
            }
        }

        let editData = ItemData.map((item) =>
            item.itemDetail.id === row.itemDetail.id ? { ...item, quantity: e.target.value, subTotal: (e.target.value * item.price), inventoryQtyDisplay: (item.inventoryQty - e.target.value >= 0 ? item.inventoryQty - e.target.value : 0), isValid: isValid, errorMessage: errorMessage  } : item
        )
        
        setItemData(editData);
    };
 
    const handleDeleteItem = (e,row) => {
        console.log("quickOrder: handleDeleteItem: ");
        if (row?.itemDetail?.id != null)
        {
            let editData = ItemData.filter(item => item.itemDetail.id !== row.itemDetail.id);
            setItemData(editData);
 
        }
    };
 
    return (
    <div className="container">
        <hr/>
        <div className="row">
            <div className="col-lg-12">
                <Card>
                    <Card.Body>
                       
                        <Card.Title>Quick Order</Card.Title>
                        {
                            OrderGuid!==null ?
                            <p>Load items from order: {OrderGuid}</p> : ""
                        }
                        <p class="text-danger text-center">{Message}</p>
                        {UserInfo?.PartnerHdrCustId!=null ?
                            <OrderItems dataLoading={DataLoading} editMode={true} itemData={ItemData} showQuickAdd={true} handleDeleteItem={handleDeleteItem} handleQtyChange={handleQtyChange} handleAddClick={handleAdd} showInventoryQty={true}></OrderItems>
                        : 
                            <div className="text-center" style={{ padding: '30px' }}>
                                <Spinner displaySize = "30px" displayText="Quick Order loading..." animation="border" size="sm" showSpinner={FormLoading} margin="auto"/>
                            </div>
                        }
                        <div className='float-right'>
                            {UserInfo?.PartnerHdrCustId!=null ?
                                <Button size={"sm"} onClick={handleAddToCart} disabled={ItemData.some((item) => item.isValid === false)}><FontAwesomeIcon icon={faCartPlus} className="fa-1x" /> Add Items to Cart</Button>
                            : null}  
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
        <br></br>
    </div>
    )
};
const mapStateToProps = state => {
    const loggedUser = isLoggedUser(state);
    const userToken = getLoggedUserToken(state);
    return { userToken, loggedUser };
}
 
export default connect(mapStateToProps)(QuickOrder);