import React from 'react';
import * as Icons from '../assets/icons';

const Icon = ({ name, height = 24, width = 24, invert }) => {
    if (!name) {
        return <></>
    }
    for (let icon in Icons) {
        if (icon === name) {
            return <img src={Icons[icon].default} alt={name} width={width} height={height} style={invert ? { filter: 'invert(1)'} : {}} />
        }
    }
    return <></>

}

export default Icon;