import { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import OrderItems from '../../components/Orders/OrderItems';
import { Button, Table, Alert } from 'react-bootstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CatalogService from '../../services/CatalogService';
import { getLoggedUserToken, isLoggedUser } from '../../redux/selectors';
import { connect } from "react-redux";
import { setCatalogAuthorizationHeader } from '../../utils/NewCatalogAPI';
import { setAuthorizationHeader } from '../../utils/UserAPI';
import { ShoppingCartContext } from '../../components/ShoppingCartMenuComponent';
import { FourthShiftService } from '../../services';
import { UserService } from '../../services';
import ReactGA from 'react-ga4';
import { PERMISSIONS } from '../../utils/constants';
import Spinner from '../../components/Spinner';

const OrderCart = (props) => {
    let { orderGuid } = useParams();
    const [OrderGuid, setOrderGuid] = useState(null);
    const [ItemData, setItemData] = useState([]);
    const [OrderDetailCheck, setOrderDetailCheck] = useState(null);
    const [OrderSubTotal, setOrderSubTotal] = useState(null);
    const [OrderTotalItems, setOrderTotalItems] = useState(null);
    const [DataLoading, setDataLoading] = useState(false);
    const [FormLoading, setFormLoading] = useState(true);
    const [refreshCart, setRefreshCart] = useContext(ShoppingCartContext);
    const history = useHistory();
    const [UserInfo, setUserInfo] = useState(null);
    const [FreeShipping, setFreeShipping] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [HasCartAccess, setHasCartAccess] = useState(null);
    const [InvalidOrder, setInvalidOrder] = useState(null);

    useEffect(() => {
        if (props.loggedUser) {
            
            console.log("orderCart: useEffect: props: ", props);
            console.log("orderCart: useEffect: ", orderGuid);

            setCatalogAuthorizationHeader(props.userToken);
            setAuthorizationHeader(props.userToken);

            if (OrderGuid == null && orderGuid != 0 && orderGuid?.length > 0) {
                setOrderGuid(orderGuid);
                getOrder(orderGuid);
            }
            else {
                getCurrentCart();
            }

            if (UserInfo == null) {
                UserService.getUserInfo().then(user => {
                    console.log("orderCart.userInfo.get.response: ", user.data.data);
                    if (user.status == 200)
                    {
                        setUserInfo(user.data.data);

                        setHasCartAccess(false);
                        if (user.data.data?.Permissions.some(i=>i.PermissionGroupsId==PERMISSIONS.ShoppingCartAccess))
                            setHasCartAccess(true);

                        setFormLoading(false);
                    }

                });
            }
        }
        else {
            history.push("/user/login");
        }


    }, [orderGuid])

    useEffect(() => {
        if (ItemData != null) {
            console.log("orderCart: ItemData: Totals: ");

            var orderSum = 0;

            ItemData.forEach(item => {
                orderSum += item.subTotal;
            });

            setOrderSubTotal(orderSum.toFixed(2));

            let itemCountLabel = " items";
            if (ItemData.filter(w => w.subTotal > 0).length == 1)
                itemCountLabel = " item";

            setOrderTotalItems("(" + ItemData.filter(w => w.subTotal > 0).length + itemCountLabel + ")");

            if (ItemData.filter(w => w.inventoryChecked == false).length > 0)
                setOrderDetailCheck(ItemData);

            checkFreeShipping(orderSum.toFixed(2));
        }
    }, [ItemData])

    useEffect(() => {
        console.log("useEffect: OrderDetailCheck: ");
        if (OrderDetailCheck != null) {
            //Check item availability
            let _itemData = [...OrderDetailCheck]
            let _items = _itemData.filter(w => w.inventoryChecked == false);
            if (_items.length > 0) {
                console.log("_totalItemsForInventoryCheck: ", _items.length)
                const _item = _items[0];
                updateAvailableQty(_item).then(res => {
                    //console.log("updateAvailableQty:res", res);
                    let editData = ItemData.map((i) =>
                        i.itemDetail.id === res.itemDetail.id ? res : i
                    )

                    setItemData(editData);
                });
            }
        }

    }, [OrderDetailCheck])

    const checkFreeShipping = (orderTotal) => {
        //Calculate Shipping
        console.log("orderCart.checkShipping");
        console.log("orderCart.checkShipping:PartnerHdrShipThreshold: ", UserInfo?.PartnerHdrShipThreshold);
        console.log("orderCart.checkShipping:OrderSubTotal: ", orderTotal);
        setFreeShipping(false);
        if (UserInfo?.PartnerHdrShipThreshold && orderTotal > 0) {
            if (parseFloat(UserInfo?.PartnerHdrShipThreshold) > 0 && (parseFloat(UserInfo?.PartnerHdrShipThreshold) <= parseFloat(orderTotal)))
                setFreeShipping(true);

        }
    }

    const updateAvailableQty = (item) => {
        console.log("updateAvailableQty", item);

        item.inventoryChecked = true;
        item.inventoryQty = -1; //default to zero prior to the check.
        item.subTotal = 0; //default

        return FourthShiftService.getItemAvailability(item.itemDetail.itemNum).then(res => {
            item.inventoryQty = 0;
            if (res.status == 200) {
                //Note: The inventoryQty proc already accounts for the item reservation in this particular cart.  
                //So we need to add the item we've selected back in to get the available Qty to show correctly for this cart.
                item.inventoryQty = ((res.data.data.availableQty ?? 0) + item.quantity);
                item.inventoryQtyDisplay = res.data.data.availableQty ?? 0;
                item.isValid = (item.quantity <= item.inventoryQty && item.quantity > 0);

                let minQuantity = res.data.data.moq;
                let standardPack = item.itemDetail.innerPack > 1 ? item.itemDetail.innerPack : 1;
                item.itemDetail.minQuantity = (minQuantity == 0) ? standardPack : minQuantity;

                //validate qty vs min qty
                if (item.itemDetail.minQuantity>1 && item.quantity % item.itemDetail.minQuantity !== 0) {
                    item.errorMessage = `Qty must be in increments of ${item.itemDetail.minQuantity}.`;
                    item.isValid=false;
                }
                
                if (item.price > 0)
                    item.subTotal = item.quantity * item.price;
            }
            return item;
        });
    }

    const getCurrentCart = () => {
        //console.log("orderCart: getCurrentCart");

        setDataLoading(true);

        CatalogService.getCart().then(response => {
            console.log("orderCart: getCurrentCart:res: ", response);
            if (response.status === 200) {
                if (response.data.data && response.data.data.orderGuid != null) {
                    //console.log(response.data.data);
                        setOrderGuid(response.data.data.orderGuid);
                        getOrder(response.data.data.orderGuid);

                    return true;
                }
                else {
                    //Cart doesn't exist
                    setDataLoading(false);
                    setInvalidOrder(false);
                    
                }
            }
            if (response.status===201){
                history.push("/user/login");
            }
        }).catch(function (error) {
            console.log("OrderCart.getCurrentCart.Error:");
            console.log(error);
            return error;
        });
    }
    
    const getOrder = (gId) => {
        setDataLoading(true);
        console.log("OrderCart.getOrder");
        CatalogService.getOrder(gId).then(response => {

            if (response.status === 200) {
                if (response.data.data) {
                    console.log("OrderCart.getOrder:res: ", response.data.data);

                    setInvalidOrder(false);
                    if (response.data.data?.orderStatusDesc !== "Cart"){
                        setInvalidOrder(true);
                        console.log("setInvalidOrder(true)");
                    }   

                    if (response.data.data.orderItems?.items)
                        setItemData(response.data.data.orderItems?.items);

                    setDataLoading(false);
                    setRefreshCart();
                    return true;
                }
            }

        }).catch(function (error) {
            console.log("OrderCart.getOrder.Error:");
            console.log(error);
            return error;
        });
    }

    const handleQtyChange = (e, row) => {

        console.log("orderCart: handleQtyChange");

        const isValid = (e.target.value <= row.inventoryQty) && (e.target.value > 0) && (e.target.value % row.itemDetail.minQuantity == 0);
        let errorMessage = '';

        if (!isValid) {
            if (e.target.value % row.itemDetail.minQuantity !== 0) {
                errorMessage = `Qty must be in increments of ${row.itemDetail.minQuantity}.`;
            }
        }

        let editData = ItemData.map((item) =>
            item.orderItemId === row.orderItemId
                ? { ...item, quantity: e.target.value, subTotal: (e.target.value * item.price), inventoryQtyDisplay: (item.inventoryQty - e.target.value >= 0 ? item.inventoryQty - e.target.value : 0), isDirty: true, isValid: isValid, errorMessage: errorMessage }
                : item
        );

        setItemData(editData);

    };

    const handleProceedClick = (e, nav = true) => {
        console.log("orderCart: handleProceedClick");
        e.preventDefault();

        let _totalItemsToUpdate = ItemData.filter((w) => w.isDirty === true).length;
        console.log("orderCart: handleProceedClick: totalItemsToUpdate: ", _totalItemsToUpdate);

        if (_totalItemsToUpdate > 0) {
            ItemData.forEach((i) => {
                if (i.isDirty) {
                    CatalogService.saveOrderItem(0, i.orderItemId, i.orderId, OrderGuid, i.itemRow, i.quantity, i.price)
                        .then((res) => {
                            if (res.status === 200) {
                                console.log("orderCart: handleProceedClick:saveOrderItem:res", res);
                                _totalItemsToUpdate--;

                                if (_totalItemsToUpdate === 0 && nav == true) {
                                    history.push("/order/orderCheckout/" + OrderGuid);
                                }
                                else {
                                    setIsAlertVisible(true);
                                    setTimeout(() => { setIsAlertVisible(false); }, 5000);
                                }
                            }
                        });
                }
            });
            ReactGA.event({
                category: 'User',
                action: 'proceed_to_checkout'
            });
        }
        else {
            if (nav == true) {
                history.push("/order/orderCheckout/" + OrderGuid);
                ReactGA.event({
                    category: 'User',
                    action: 'proceed_to_checkout'
                });
            }
        }

    };

    const handleDeleteItem = (e, row) => {
        console.log("orderCart: handleDeleteItem: ");
        if (row?.orderItemId != null) {
            CatalogService.deleteOrderItem(row?.orderItemId, OrderGuid).then(response => {
                console.log("OrderCart.handleDeleteItem.deleteOrderItem.Response:");

                //console.log(response);
                if (response.status === 200) {
                    if (response.data.data) {
                        let editData = ItemData.filter(item => item.orderItemId !== row.orderItemId);
                        setItemData(editData);
                        setRefreshCart();
                        return true;
                    }
                }

            }).catch(function (error) {
                console.log("OrderCart.getOrder.Error:");
                console.log(error);
                return error;
            });
        }
    };


    return (
        <div className="container">           
            {
            FormLoading ?
            <div className="text-center" style={{ padding: '30px' }}>
                <Spinner displaySize = "30px" displayText="Cart loading..." animation="border" size="sm" showSpinner={FormLoading} margin="auto"/>
            </div>
            :
            HasCartAccess ?             
            
            InvalidOrder==false ?
            <div>
            <hr />
            <div className="col-lg-12 row">
                <Link to={"/account/user/"}><FontAwesomeIcon icon={faArrowLeft} className="fa-1x" /> Back to Order History</Link>
            </div>
            <div className="row">
            <div className="col-lg-12">
                <Card>
                    <Card.Body>
                        <div className="col-lg-4 pb-3">
                            <Card>
                                <Card.Body>
                                <Card.Title>Shopping Cart Details</Card.Title>
                                    <div className='small'>
                                        <Table borderless size="sm">
                                            <tr>
                                                <td>Order Total:</td>
                                                <td>{OrderSubTotal} {OrderTotalItems}</td>
                                            </tr>
                                        </Table>
                                            <label className='text-muted pl-1'>
                                                {FreeShipping ? "Free Shipping Available!" : null}
                                            </label>
                                            {ItemData?.filter(w => w.subTotal > 0).length > 0 ?
                                                <div className='text-center'>
                                                    <Button size="sm" block onClick={(e) => handleProceedClick(e)} disabled={ItemData.some((item) => item.isValid === false)}>
                                                        Proceed to Checkout
                                                    </Button>
                                                </div>
                                                : null}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <OrderItems dataLoading={DataLoading} editMode={true} itemData={ItemData} showQuickAdd={false} showSearch={false} handleDeleteItem={handleDeleteItem} handleQtyChange={handleQtyChange} showInventoryQty={true}></OrderItems>
                            {ItemData?.filter(w => w.subTotal > 0).length > 0 ?
                                <div>

                                    <div className='float-right'>
                                        <Button size="sm" block onClick={(e) => handleProceedClick(e)} disabled={ItemData.some((item) => item.isValid === false)}>
                                            Proceed to Checkout
                                        </Button>
                                    </div>
                                    <div className='float-right mr-2'>
                                        <Button size="sm" block onClick={(e) => handleProceedClick(e, false)} disabled={ItemData.some((item) => item.isValid === false)}>
                                            Save Cart
                                        </Button>
                                    </div>
                                    <div className='float-right mr-2'>
                                        <span>{isAlertVisible && <div className='text-success'>Cart Saved!</div>}</span>
                                    </div>

                                    <label><small className='text-muted'>Note: Items with no pricing will not be added to order.
                                        <br></br>Save Cart to update item quantity</small></label>
                                </div>
                                : null}
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <br></br>
            </div>
            :
            InvalidOrder ? 
            <div className='text-center'>
                <Alert variant='primary'>This is an invalid order for cart.</Alert>
            </div>
            :
            <div></div>
            :
            HasCartAccess==false ? 
            <div className='text-center'>
                <Alert variant='primary'>You do not have Shopping Cart Permissions.</Alert>
            </div>
            :
            <div></div>
            }
        </div>
    )
}
const mapStateToProps = state => {
    const userToken = getLoggedUserToken(state);
    const loggedUser = isLoggedUser(state);
    return { userToken, loggedUser };
}

export default connect(mapStateToProps)(OrderCart);