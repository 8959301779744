import React from 'react';
import CategoryLink from '../components/marine/CategoryLink';
import MarineCategories from '../components/marine/CategoryLinks';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';


const MarineCategory = () => {
    const categories = MarineCategories();
    
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <FontAwesomeIcon icon={faAngleRight} />,
        prevArrow: <FontAwesomeIcon icon={faAngleLeft} />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return (
        <div className="category">
          <div className="marine-bg fixed-parallax"></div>
            <div className="category-top background-title">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h1>Marine</h1>
                            <p>The parts You need  - When you need them</p>
                        </div>
                    </div>
                </div>
            </div>
            <section>
            <div className="container cat-list">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                    <div>
                        <Slider {...settings} >
                            {categories.map((category, index) =>
                                <CategoryLink
                                    key={index}
                                    categoryId={category.id}
                                    categoryName={category.name}
                                />
                            )}
                        </Slider>
                    </div>
                    </div>
                </div>
            </div>
            </section>

        </div>
    );
}

export default MarineCategory;