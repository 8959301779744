import React, { Fragment, useState } from 'react'
import { Header, Footer, Menu } from '..';
import { ShoppingCartContext } from '../ShoppingCartMenuComponent';
import { setRef } from '@material-ui/core';

const Minimal = ({ children }) => {
    const [refreshCount, setRefreshCount] = useState(0);
    const setRefresh = () => {
        setRefreshCount((prev)=>prev+1);
    };
    return (
        <Fragment>
            <ShoppingCartContext.Provider value={[refreshCount, setRefresh]}>
                <Header />
                <Menu hideDetailedSearch="true" hideAlerts={true} />
                {children}
                <Footer />
            </ShoppingCartContext.Provider>
        </Fragment>
    )
}

export default Minimal;
