import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("Error " + error);
    }

    render() {
        if (this.state.hasError) {
            return <Redirect to="/error/500" />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;