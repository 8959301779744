var metric, imperial;

metric = {
  'mm3/s': {
    to_anchor: 1/1000000
  }, 
  'cm3/s': {
    to_anchor: 1/1000
  }, 
  'ml/s': {
    to_anchor: 1/1000
  }, 
  'cl/s': {
    to_anchor: 1/100
  }, 
  'dl/s': {
    to_anchor: 1/10
  }, 
  'l/s': {
    to_anchor: 1
  }, 
  'l/min': {
    to_anchor: 1/60
  }, 
  'l/h': {
    to_anchor: 1/3600
  }, 
  'kl/s': {
    to_anchor: 1000
  }, 
  'kl/min': {
    to_anchor: 50/3
  }, 
  'kl/h': {
    to_anchor: 5/18
  }, 
  'm3/s': {
    to_anchor: 1000
  }, 
  'm3/min': {
    to_anchor: 50/3
  }, 
  'm3/h': {
    to_anchor: 5/18
  }, 
  'km3/s': {
    to_anchor: 1000000000000
  }
};

imperial = {
  'tsp/s': {
    to_anchor: 1/6
  }, 
  'Tbs/s': {
    to_anchor: 1/2
  }, 
  'in3/s': {
    to_anchor: 0.55411
  }, 
  'in3/min': {
    to_anchor: 0.55411/60
  }, 
  'in3/h': {
    to_anchor: 0.55411/3600
  }, 
  'fl-oz/s': {
    to_anchor: 1
  }, 
  'fl-oz/min': {
    to_anchor: 1/60
  }, 
  'fl-oz/h': {
    to_anchor: 1/3600
  }, 
  'cup/s': {
    to_anchor: 8
  }, 
  'pnt/s': {
    to_anchor: 16
  }, 
  'pnt/min': {
    to_anchor: 4/15
  }, 
  'pnt/h': {
    to_anchor: 1/225
  }, 
  'qt/s': {
    to_anchor: 32
  }, 
  'gal/s': {
    to_anchor: 128
  }, 
  'gal/min': {
    to_anchor: 32/15
  }, 
  'gal/h': {
    to_anchor: 8/225
  }, 
  'ft3/s': {
    to_anchor: 957.506
  }, 
  'ft3/min': {
    to_anchor: 957.506/60
  }, 
  'ft3/h': {
    to_anchor: 957.506/3600
  }, 
  'yd3/s': {
    to_anchor: 25852.7
  }, 
  'yd3/min': {
    to_anchor: 25852.7/60
  }, 
  'yd3/h': {
    to_anchor: 25852.7/3600
  }
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 'l/s', 
      ratio: 33.8140227
    }, 
    imperial: {
      unit: 'fl-oz/s', 
      ratio: 1/33.8140227
    }
  }
};
