import React from 'react'

const PickListHeader = () => {
    return (
        <div className="picklist-header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="top">
                            <span className="picklist-link"></span>
                            <strong>Picklists</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickListHeader;
