import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = (props) =>
{
    const [ShowSpinner, setShowSpinner] = useState(false);
    const [DisplayText, setDisplayText] = useState(null);
    const [DisplaySize, setDisplaySize] = useState("30px");
    const [Margin, setMargin] = useState("0px");

    useEffect(() => {
        console.log("ShowSpinner: ", props.showSpinner);
        setShowSpinner(props.showSpinner);
        setDisplayText(props.displayText);
        
        if (props.displaySize && props.displaySize.length > 0)
          setDisplaySize(props.displaySize);

        if (props.margin && props.margin.length > 0)
          setMargin(props.margin);

    }, [props.showSpinner, props.displayText, props.displaySize, props.margin]);

    const rotate360 = keyframes`
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  `;

  const Spinner = styled.div`
      margin: ${Margin};
      animation: ${rotate360} 1s linear infinite;
      transform: translateZ(0);
      border-top: 2px solid grey;
      border-right: 2px solid grey;
      border-bottom: 2px solid grey;
      border-left: 4px solid black;
      background: transparent;
      width: ${DisplaySize};
      height: ${DisplaySize};
      border-radius: 50%;
      
  `;

  return (<div>{ShowSpinner ? <div className='align-items-center spinner1'><Spinner /> {DisplayText}</div> : null}</div>)

}
export default Spinner;