import UserAPI from '../utils/UserAPI';
 
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async login(email, password) {
        return UserAPI.post('/Account/Login', {
            Email: `${email}`,
            Password: `${password}`,
            RememberMe: false
        })
    },
    async passwordForget(email) {
        return UserAPI.post('/Registration/ForgotPassword', {
            email: `${email}`
        })
    },
    async passwordChange(userId, email, oldPassword, password, passwordConfirm) {
        return UserAPI.post('/Registration/SaveChangePassword', {
            userGuid: `${userId}`,
            email: `${email}`,
            oldPassword: `${oldPassword}`,
            password: `${password}`,
            passwordConfirm: `${passwordConfirm}`,
        })
    },
    async passwordReset(userId, password, passwordConfirm, code) {
        return UserAPI.post('/Registration/ResetPassword', {
            userGuid: `${userId}`,
            code: `${code}`,
            password: `${password}`,
            confirmPassword: `${passwordConfirm}`,
        })
    },
    async validateCompanyInformation(companyName, companyPhoneNumber, companyFaxNumber, companyWebsite,
        mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingPostalCode, mailingCountry,
        shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingPostalCode, shippingCountry, //creditTerms,
        taxExemptId,
        resellerCertificateName, resellerCertificateData, creditApplicationName, creditApplicationData
        ) {
 
        var filesModel = [];
        if (resellerCertificateData != null) {
            var resellerModel = {FileName: `${resellerCertificateName}`,
            FileTypeId: 2,
            File: {
                Content: `${resellerCertificateData}`
            }}
            filesModel.push(resellerModel);
        }
 
        if (creditApplicationData != null) {
            var creditModel = {FileName: `${creditApplicationName}`,
            FileTypeId: 1,
            File: {
                Content: `${creditApplicationData}`
            }}
            filesModel.push(creditModel)
        }
           
        return UserAPI.post('/Registration/SaveCompanyInfoStep1', {
            PartnerName: `${companyName}`,
            PhoneNo: `${companyPhoneNumber}`,
            Address1: `${mailingAddress1}`,
            Address2: mailingAddress2 ? `${mailingAddress2}` : "",
            City: `${mailingCity}`,
            State: `${mailingState}`,
            Zip: `${mailingPostalCode}`,
            Country: {
                SelectedId: `${mailingCountry}`
            },
            GetPostalCode: `${mailingPostalCode}`,
            GetCompanyMailingAddressNo: {
                City: `${mailingCity}`,
                Country: `${mailingCountry}`,
                Line1: `${mailingAddress1}`,
                Line2: mailingAddress2 ? `${mailingAddress2}` : "",
                PostalCode: `${mailingPostalCode}`,
                State: `${mailingState}`
            },
            ShipAddress1: `${shippingAddress1}`,
            ShipAddress2: shippingAddress2 ? `${shippingAddress2}` : "",
            ShipCity: `${shippingCity}`,
            ShipCountry: {
                SelectedId: `${shippingCountry}`
            },
            ShipState: `${shippingState}`,
            ShipZip: `${shippingPostalCode}`,
            GetCompanyShippingAddressNo: {
                City: `${shippingCity}`,
                Country: `${shippingCountry}`,
                Line1: `${shippingAddress1}`,
                Line2: shippingAddress2 ? `${shippingAddress2}` : "",
                PostalCode: `${shippingPostalCode}`,
                State: `${shippingState}`
            },
            //CreditTerms: `${creditTerms}`,
            //CreditTerms: `${creditTerms}`,
            TaxExemptId: `${taxExemptId}`,
            Files: filesModel,      
        })
    },
    async validateLoginInformation(email, emailConfirm, password, passwordConfirm) {
        return UserAPI.post('/Registration/SaveLoginInfoStep2', {
            Email: `${email}`,
            EmailConfirm: `${emailConfirm}`,
            Password: `${password}`,
            PasswordConfirm: `${passwordConfirm}`
        })
    },
    async validateAddress(address1, address2, city, state, country, zip) {
        return UserAPI.post('/Registration/ValidateAddress', {
            Street1: `${address1}`,
            Street2: `${address2}`,
            City: `${city}`,
            State: `${state}`,
            Country: `${country}`,
            Zip: `${zip}`
        })
    },
    async validateProfileInformation(email, firstName, lastName,
        userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingPostalCode, userMailingCountry,
        userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingPostalCode, userShippingCountry) {
        return UserAPI.post('/Registration/SavePersonalInfoStep3', {
            userModel: {
                Email: `${email}`,
                FirstName: `${firstName}`,
                LastName: `${lastName}`,
                Address1: `${userMailingAddress1}`,
                Address2: userMailingAddress2 ? `${userMailingAddress2}` : "",
                City: `${userMailingCity}`,
                State: `${userMailingState}`,
                Zip: `${userMailingPostalCode}`,
                Country: {
                    SelectedId: `${userMailingCountry}`
                },
                ShipAddress1: `${userShippingAddress1}`,
                ShipAddress2: userShippingAddress2 ? `${userShippingAddress2}` : "",
                ShipCity: `${userShippingCity}`,
                ShipState: `${userShippingState}`,
                ShipZip: `${userShippingPostalCode}`,
                ShipCountry: {
                    SelectedId: `${userShippingCountry}`
                }
            }
        })
    },
    async saveRegistration(email, emailConfirm, password, passwordConfirm,
            companyName, companyPhoneNumber, companyFaxNumber, companyWebsite,
            firstName, lastName, homePhone, mobilePhone, officePhone, extension, salutation, jobTitle, birthDate,
            mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingPostalCode, mailingCountry,
            shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingPostalCode, shippingCountry,
            userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingPostalCode, userMailingCountry,
            userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingPostalCode, userShippingCountry, //creditTerms,
            taxExemptId, resellerCertificateState, resellerCertificateName, resellerCertificateData, creditApplicationName, creditApplicationData) {
 
        var filesModel = [];
        if (resellerCertificateData != null) {
            var resellerModel = {FileName: `${resellerCertificateName}`,
            FileTypeId: 2,
            State: resellerCertificateState,
            File: {
                Content: `${resellerCertificateData}`
            }}
            filesModel.push(resellerModel);
        }
 
        if (creditApplicationData != null) {
            var creditModel = {FileName: `${creditApplicationName}`,
            FileTypeId: 1,
            File: {
                Content: `${creditApplicationData}`
            }}
            filesModel.push(creditModel)
        }
        return UserAPI.post('/Registration/CompleteRegistrationStep4', {
            loginModel: {
                Email: `${email}`,
                EmailConfirm: `${emailConfirm}`,
                Password: `${password}`,
                PasswordConfirm: `${passwordConfirm}`
            },
            userModel: {
                Email: `${email}`,
                EmailConfirm: `${emailConfirm}`,
                Password: `${password}`,
                PasswordConfirm: `${passwordConfirm}`,
                FirstName: `${firstName}`,
                LastName: `${lastName}`,
                Address1: `${userMailingAddress1}`,
                Address2: userMailingAddress2 ? `${userMailingAddress2}` : "",
                City: `${userMailingCity}`,
                State: `${userMailingState}`,
                Zip: `${userMailingPostalCode}`,
                Country: {
                    SelectedId: `${userMailingCountry}`
                },
                ShipAddress1: `${userShippingAddress1}`,
                ShipAddress2: userShippingAddress2 ? `${userShippingAddress2}` : "",
                ShipCity: `${userShippingCity}`,
                ShipState: `${userShippingState}`,
                ShipZip: `${userShippingPostalCode}`,
                ShipCountry: {
                    SelectedId: `${userShippingCountry}`
                },
                Phone: officePhone ? `${officePhone}` : "",
                Extension: extension? `${extension}` : "",
                PhoneNo: homePhone ? `${homePhone}` : "",
                MobilePhoneNo: mobilePhone ? `${mobilePhone}` : "",
                DoB: `${birthDate}`,
                Salutation: salutation ? `${salutation}` : "",
                JobTitle: {
                    SelectedId: `${jobTitle}`
                }
            },
            companyModel: {
                PartnerName: `${companyName}`,
                URL: companyWebsite ? `${companyWebsite}` : "",
                PhoneNo: `${companyPhoneNumber}`,
                FaxNo: companyFaxNumber ? `${companyFaxNumber}` : "",
                Address1: `${mailingAddress1}`,
                Address2: mailingAddress2 ? `${mailingAddress2}` : "",
                City: `${mailingCity}`,
                State: `${mailingState}`,
                Zip: `${mailingPostalCode}`,
                Country: {
                    SelectedId: `${mailingCountry}`
                },
                GetPostalCode: `${mailingPostalCode}`,
                GetCompanyMailingAddressNo: {
                    City: `${mailingCity}`,
                    Country: `${mailingCountry}`,
                    Line1: `${mailingAddress1}`,
                    Line2: mailingAddress2 ? `${mailingAddress2}` : "",
                    PostalCode: `${mailingPostalCode}`,
                    State: `${mailingState}`
                },
                ShipAddress1: `${shippingAddress1}`,
                ShipAddress2: shippingAddress2 ? `${shippingAddress2}` : "",
                ShipCity: `${shippingCity}`,
                ShipCountry: {
                    SelectedId: `${shippingCountry}`
                },
                ShipState: `${shippingState}`,
                ShipZip: `${shippingPostalCode}`,
                GetCompanyShippingAddressNo: {
                    City: `${shippingCity}`,
                    Country: `${shippingCountry}`,
                    Line1: `${shippingAddress1}`,
                    Line2: shippingAddress2 ? `${shippingAddress2}` : "",
                    PostalCode: `${shippingPostalCode}`,
                    State: `${shippingState}`
                },
                //CreditTerms: `${creditTerms}`,
                //CreditTerms: `${creditTerms}`,
                TaxExemptId: `${taxExemptId}`,
                Files: filesModel,
            }
        })
    },
    async getAccountManagementInfo(userId) {
        return await UserAPI.get('/Registration/GetAccountManagementInfo?userGuid=' + userId);
    },
    async getAccountManagementInfoWithPermissions(userId) {
        return await UserAPI.get('/Registration/GetAccountManagementInfoWithPermissions?userGuid=' + userId);
    },
    async getUserInfo() {
        return await UserAPI.get('/Registration/GetUserInfo');
    },
    async saveProfileCompanyInformation(partnerId, partnerName, url, address1, address2, city, state, country, zip,
            shipAddress1, shipAddress2, shipCity, shipState, shipCountry, shipZip, phoneNo, faxNo) {
        return UserAPI.post('/Registration/AccountManagementSaveCompanyProfile', {
            partnerId: partnerId,
            partnerName: `${partnerName}`,
            url: url ? `${url}` : "",
            address1: `${address1}`,
            address2: address2 ? `${address2}` : "",
            city: `${city}`,
            state: `${state}`,
            country: {
                SelectedId: `${country}`
            },
            zip: `${zip}`,
            shipAddress1: `${shipAddress1}`,
            shipAddress2: shipAddress2 ? `${shipAddress2}` : "",
            shipCity: `${shipCity}`,
            shipState: `${shipState}`,
            shipZip: `${shipZip}`,
            shipCountry: {
                SelectedId: `${shipCountry}`
            },
            phoneNo: `${phoneNo}`,
            faxNo: faxNo ? `${faxNo}` : ""
        });
    },
    async saveProfilePersonalInformation(userId, participantId, partnerId, firstName, lastName, salutation, email,
            address1, address2, city, state, country, zip,
            shipAddress1, shipAddress2, shipCity, shipState, shipCountry, shipZip,
            homePhone, officePhone, extension, mobilePhone, jobTitle, birthDate) {
        return UserAPI.post('/Registration/AccountManagementSavePersonalProfile', {
            userGuid: `${userId}`,
            id: participantId,
            partnerId: partnerId,
            firstName: `${firstName}`,
            lastName: `${lastName}`,
            salutation: salutation ? `${salutation}` : "",
            email: `${email}`,
            address1: `${address1}`,
            address2: address2 ? `${address2}` : "",
            city: `${city}`,
            state: `${state}`,
            country: {
                SelectedId: `${country}`
            },
            zip: `${zip}`,
            shipAddress1: `${shipAddress1}`,
            shipAddress2: shipAddress2 ? `${shipAddress2}` : "",
            shipCity: `${shipCity}`,
            shipState: `${shipState}`,
            shipZip: `${shipZip}`,
            shipCountry: {
                SelectedId: `${shipCountry}`
            },
            phone: homePhone ? `${homePhone}` : "",
            phoneNo: officePhone ? `${officePhone}`: "",
            extension: extension ? `${extension}` : "",
            mobilePhoneNo: mobilePhone ? `${mobilePhone}` : "",
            jobTitle: {
                SelectedId: `${jobTitle}`
            },
            dob: birthDate
        });
    },
    async getInvites(partnerId) {
        return UserAPI.get('/Registration/InviteManagementInfo?partnerId=' + partnerId);
    },
    async sendInvite(userId, firstName, lastName, emailAddress, message) {
        return UserAPI.post('/Registration/SendInvite', {
            userGuid: `${userId}`,
            firstName: `${firstName}`,
            lastName: `${lastName}`,
            emailAddress: `${emailAddress}`,
            message: `${message}`
        });
    },
    async resendInvite(id) {
        return UserAPI.post('/Registration/ReSendInvite?id=' + id);
    },
    async getInvitationRegistration(inviteCode) {
        return UserAPI.post('/Registration/ClaimInvite?inviteCode=' + inviteCode);
    },
    async saveInvitationRegistration(partnerId, companyName, url,
            address1, address2, city, state, country, zip,
            shipAddress1, shipAddress2, shipCity, shipState, shipCountry, shipZip, phoneNo, faxNo,
        firstName, lastName, homePhone, officePhone, extension, mobilePhone, salutation, jobTitle, birthDate,
        mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingPostalCode, mailingCountry,
        shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingPostalCode, shippingCountry,
        email, emailConfirm, password, passwordConfirm, inviteCode) {
        return UserAPI.post('/Registration/EmployeeCreatePersonalProfile', {
            CompanyModelObject: {
                PartnerId: partnerId,
                PartnerName: `${companyName}`,
                URL: url ? `${url}` : "",
                Address1: `${address1}`,
                Address2: address2 ? `${address2}` : "",
                City: `${city}`,
                State: `${state}`,
                Zip: `${zip}`,
                Country: {
                    SelectedId: `${country}`
                },
                PhoneNo: `${phoneNo}`,
                FaxNo: faxNo ? `${faxNo}` : "",
                ShipAddress1: `${shipAddress1}`,
                ShipAddress2: shipAddress2 ? `${shipAddress2}` : "",
                ShipCity: `${shipCity}`,
                ShipState: `${shipState}`,
                ShipZip: `${shipZip}`,
                ShipCountry: {
                    SelectedId: `${shipCountry}`
                }
            },
            UserModelObject: {
                Id: 0,
                Action: null,
                PartnerId: partnerId,
                FirstName: `${firstName}`,
                LastName: `${lastName}`,
                FullName: null,
                Address1: `${mailingAddress1}`,
                Address2: mailingAddress2 ? `${mailingAddress2}` : "",
                City: `${mailingCity}`,
                State: `${mailingState}`,
                Zip: `${mailingPostalCode}`,
                Country: {
                    SelectedId: `${mailingCountry}`
                },
                ShipAddress1: `${shippingAddress1}`,
                ShipAddress2: shippingAddress2 ? `${shippingAddress2}` : "",
                ShipCity: `${shippingCity}`,
                ShipState: `${shippingState}`,
                ShipZip: `${shippingPostalCode}`,
                ShipCountry: {
                    SelectedId: `${shippingCountry}`
                },
                Salutation: salutation ? `${salutation}` : "",
                DoB: `${birthDate}`,
                Phone: homePhone ? `${homePhone}` : "",
                PhoneNo: officePhone ? `${officePhone}` : "",
                extension: extension ? `${extension}` : "",
                MobilePhoneNo: mobilePhone ? `${mobilePhone}` : "",
                JobTitle: {
                    SelectedId: `${jobTitle}`
                },
                Email: `${email}`,
                SelectedPartnerRoleId: 2
            },
            LoginInfoModelObject: {
                Email: `${email}`,
                EmailConfirm: `${emailConfirm}`,
                Password: `${password}`,
                PasswordConfirm: `${passwordConfirm}`,
            },
            InviteCode: `${inviteCode}`,
            ReturningUser: false
        });
    },
    async getUserPermissions(userId) {
        return UserAPI.get('/Registration/getpermissions?id=' + userId);
    },
    async saveUserPermissions(userId, hasWarrantyAccess, shoppingCartAccess, addShippingAccess) {
        return UserAPI.post('/Registration/ManagePermissions', {
            ParticipantId: userId,
            HasWarrantyAccess: hasWarrantyAccess,
            ShoppingCartAccess: shoppingCartAccess,
            AddShippingAccess: addShippingAccess
        });
    },
    async getUserStatusInfo(userId) {
        return UserAPI.get('/Registration/GetUserStatusInfo?id=' + userId);
    },
    async saveUserStatusChange(userId, statusId) {
        return UserAPI.post('/Registration/SaveUserStatusChange', {
            Id: userId,
            PartnerStatus: {
                SelectedId: `${statusId}`
            }
        });
    },
    async createPickList(displayName, userGuid, itemId, quantity) {
        return await UserAPI.post("/PickList/CreateList?userGuid=" + userGuid + "&NewList.displayName=" + displayName + "&LineItems[0].C_item_row=" + parseInt(itemId) + "&LineItems[0].qty=" + parseInt(quantity))
    },
    async renamePickList(pickListId, userGuid, newName) {
        return await UserAPI.post("/PickList/RenamePickList?pickListId=" + pickListId + "&userGuid=" + userGuid + "&newName=" + newName);
    },
    async deletePickList(pickListId) {
        return await UserAPI.post("/PickList/DeleteList?pickListId=" + pickListId);
    },
    async getPickLists(userGuid) {
        return await UserAPI.get("/PickList/GetAll", {
            params: {
                userGuid: userGuid
            }
        })
    },
    async getPickListItems(pickListId, userGuid) {
        return await UserAPI.get("/PickList/GetList", {
            params: {
                pickListId: pickListId,
                userGuid: userGuid
            }
        })
    },
    async addPickListItem(pickListId, itemId, quantity) {
        return await UserAPI.post("/PickList/AddLineItem?pickListId=" + parseInt(pickListId) + "&LineItems[0].C_item_row=" + parseInt(itemId) + "&LineItems[0].qty=" + parseInt(quantity));
    },
    async deletePickListItem(pickListId, pickListLineItemId) {
        return await UserAPI.post("/PickList/RemoveLineItem?pickListId=" + pickListId + "&lineItemId=" + pickListLineItemId);
    },
    async changePickListItemQuantity(pickListLineItemId, quantity) {
        return await UserAPI.post("/PickList/UpdateItemQty?lineItemRow=" + pickListLineItemId + "&qty=" + quantity);
    },
 
    async getUltraToken(){
        return await UserAPI.get("/Account/GetUltraToken");
    },
}