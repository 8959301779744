var metric, imperial;

metric = {
  mm2: {
    to_anchor: 1/1000000
  },
  cm2: {
    to_anchor: 1/10000
  }, 
  m2: {
    to_anchor: 1
  }, 
  ha: {
    to_anchor: 10000
  }, 
  km2: {
    to_anchor: 1000000
  }
};

imperial = {
  'in2': {
    to_anchor: 1/144
  }, 
  yd2: {
    to_anchor: 9
  }, 
  ft2: {
    to_anchor: 1
  }, 
  ac: {
    to_anchor: 43560
  }, 
  mi2: {
    to_anchor: 27878400
  }
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 'm2', 
      ratio: 10.7639
    }, 
    imperial: {
      unit: 'ft2', 
      ratio: 1/10.7639
    }
  }
};
