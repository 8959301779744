var energy = {
  Wh: {
    to_anchor: 3600
  }, 
  mWh: {
    to_anchor: 3.6
  }, 
  kWh: {
    to_anchor: 3600000
  }, 
  MWh: {
    to_anchor: 3600000000
  }, 
  GWh: {
    to_anchor: 3600000000000
  }, 
  J: {
    to_anchor: 1
  }, 
  kJ: {
    to_anchor: 1000
  }
};

module.exports = {
  metric: energy, 
  _anchors: {
    metric: {
      unit: 'J',
      ratio: 1
    }
  }
};
