import React from 'react';
import { Switch, Route } from 'react-router';
import Marine from './pages/marine';
import MarineCategory from './pages/marine/category';
import GardenCategory from './pages/garden/category';
import PowerSportsCategory from './pages/powersports/category';
import { Error404, Error500 } from './pages/error';
import { FlipCatalog, Home, Engine, WarrantyPolicy, Contact, Search, DetailedSearch, Product, Sports, Garden, Profile, ManageUsers, PersonalProfile, CompanyProfile, InviteUsers, PasswordChange, ParticipantPermissions, Terms, Warranty, WarrantyTracker, ClaimRequest, WarrantyRequestView, Barcode, News, Article, Returns, Promotions } from './pages';
import { BaseComponent } from './components';
import DometicDay from './pages/misc/dometicday';
import { AdminAccount, UserAccount } from './pages/account';
import { OrderDetail, QuickOrder, OrderCart, OrderCheckout } from './pages/order';
import {UltraHome, LogPreview, ManageServiceShops, UpdateServiceShop} from './ultra/pages';

const Routes = () => (
    <Switch>
        <Route exact path="/" render={() => <BaseComponent><Home /></BaseComponent>} />
        <Route exact path="/marine" render={() => <BaseComponent><Marine /></BaseComponent>} />
        <Route exact path="/garden" render={() => <BaseComponent><Garden /></BaseComponent>} />
        <Route exact path="/garden/:category" render={() => <BaseComponent><GardenCategory /></BaseComponent>} />
        <Route exact path="/power-sports" render={() => <BaseComponent><Sports /></BaseComponent>} />
        <Route exact path="/power-sports/:category" render={() => <BaseComponent><PowerSportsCategory /></BaseComponent>} />
        <Route exact path="/marine/:category" render={() => <BaseComponent><MarineCategory /></BaseComponent>} />
        <Route exact path="/search" render={() => <BaseComponent><Search /></BaseComponent>} />
        <Route exact path="/detailed-search" render={() => <BaseComponent><DetailedSearch /></BaseComponent>} />
        <Route exact path="/engine/:engineId" render={() => <BaseComponent><Engine /></BaseComponent>} />
        <Route exact path="/product/:productId" render={() => <BaseComponent><Product /></BaseComponent>} />
        <Route exact path="/warranty-policy" component={WarrantyPolicy} />

        <Route exact path="/account/admin" component={AdminAccount} />
        {/* <Route exact path="/promotions" component={Promotions} />  */}
        <Route exact path="/account/user" component={UserAccount} />  
        <Route exact path="/order/quickorder" component={QuickOrder} />  
        <Route exact path="/order/quickorder/:orderGuid" component={QuickOrder} />  
        <Route exact path="/order/orderdetail/:orderGuid" render={() => <OrderDetail />} />
        <Route exact path="/order/orderdetail/:orderGuid/:status" render={() => <OrderDetail />} />
        <Route exact path="/order/orderCart/:orderGuid" component={OrderCart} />  
        <Route exact path="/order/orderCheckout/:orderGuid" component={OrderCheckout} />  

        <Route exact path="/warranty" component={Warranty} />
        <Route exact path="/warranty-tracker" render={() => <BaseComponent><WarrantyTracker /></BaseComponent>} />
        <Route exact path="/warranty/:rgaNumber" render={() => <BaseComponent><WarrantyRequestView /></BaseComponent>} />
        <Route exact path="/claim-request" render={() => <BaseComponent><ClaimRequest /></BaseComponent>} />
        <Route exact path="/barcode/:rgaNumber" component={Barcode} />

        <Route exact path="/contact" component={Contact} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/returns" component={Returns} />
       

        <Route exact path="/marine-pdf" render={props => (
            <FlipCatalog {...props} frameUrl="https://dometic.filecamp.com/s/d/p14Ezhog8gnD8zBd/"/>
        )} />

        <Route exact path="/primeline-pdf" render={props => (
            <FlipCatalog {...props} frameUrl="https://online.fliphtml5.com/wxok/stwc/"/>
        )} />

        <Route exact path="/news" render={() => <BaseComponent><News /></BaseComponent>} />
        <Route exact path="/news/article/:articleId" render={() => <BaseComponent><Article /></BaseComponent>} />

        <Route exact path="/profile" render={() => <BaseComponent><Profile /></BaseComponent>} />
        <Route exact path="/profile/users/manage" render={() => <BaseComponent><ManageUsers /></BaseComponent>} />
        <Route exact path="/profile/users/invite" render={() => <BaseComponent><InviteUsers /></BaseComponent>} />
        <Route exact path="/profile/personal" render={() => <BaseComponent><PersonalProfile /></BaseComponent>} />
        <Route exact path="/profile/company" render={() => <BaseComponent><CompanyProfile /></BaseComponent>} />
        <Route exact path="/profile/permissions/participant" render={() => <BaseComponent><ParticipantPermissions /></BaseComponent>} />
        <Route exact path="/profile/password/change" render={() => <BaseComponent><PasswordChange /></BaseComponent>} />

        <Route exact path="/error/500" component={Error500} />
        <Route exact path="/dometicday" component={DometicDay} />

        <Route exact path="/ultra/home" component={UltraHome} />
        <Route exact path="/ultra/log/:id/preview" component={LogPreview} />
        <Route exact path ="/ultra/serviceShops" component={ManageServiceShops} />
        <Route exact path ="/ultra/updateServiceShop/:id" component={UpdateServiceShop} />
        <Route component={Error404} />
    </Switch>
);

export default Routes;