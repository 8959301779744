var metric, imperial;

metric = {
  mm3: {
    to_anchor: 1/1000000
  }, 
  cm3: {
    to_anchor: 1/1000
  }, 
  ml: {
    to_anchor: 1/1000
  }, 
  cl: {
    to_anchor: 1/100
  }, 
  dl: {
    to_anchor: 1/10
  }, 
  l: {
    to_anchor: 1
  }, 
  kl: {
    to_anchor: 1000
  }, 
  m3: {
    to_anchor: 1000
  }, 
  km3: {
    to_anchor: 1000000000000
  }, 
  krm: {
    to_anchor: 1/1000
  }, 
  tsk: {
    to_anchor: 5/1000
  }, 
  msk: {
    to_anchor: 15/1000
  }, 
  kkp: {
    to_anchor: 150/1000
  }, 
  glas: {
    to_anchor: 200/1000
  }, 
  kanna: {
    to_anchor: 2.617
  }
};

imperial = {
  tsp: {
    to_anchor: 1/6
  }, 
  Tbs: {
    to_anchor: 1/2
  }, 
  in3: {
    to_anchor: 0.55411
  }, 
  'fl-oz': {
    to_anchor: 1
  }, 
  cup: {
    to_anchor: 8
  }, 
  pnt: {
    to_anchor: 16
  }, 
  qt: {
    to_anchor: 32
  }, 
  gal: {
    to_anchor: 128
  }, 
  ft3: {
    to_anchor: 957.506
  }, 
  yd3: {
    to_anchor: 25852.7
  }
};

module.exports = {
  metric: metric, 
  imperial: imperial, 
  _anchors: {
    metric: {
      unit: 'l', 
      ratio: 33.8140226
    }, 
    imperial: {
      unit: 'fl-oz', 
      ratio: 1/33.8140226
    }
  }
};
