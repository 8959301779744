import React, { Fragment, useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import ReactExport from "react-data-export";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const PickListExport = ({ pickListId, pickListTitle, products}) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const [showDialog, setShowDialog] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [excelData, setExcelData] = useState([]);
    

    const handleClose = () => setShowDialog(false);
    const handleShow = () => setShowDialog(true);
    
    useEffect(() => {
        const csvHeaders = [
            { label: "Sale item", key: "modelNumber" },
            { label: "Description", key: "description" },
            { label: "Quantity", key: "quantity" }
        ];
        setCsvHeaders(csvHeaders);
        setCsvData(products);

        const excelHeaders = [
            {title: "Sale item", width: {wpx: 100}},
            {title: "Description", width: {wpx: 300}},
            {title: "Quantity", width: {wpx: 100}},
        ]
        let eData = [];
        products.forEach(item => {
            eData.push(
                [
                    {value: item.modelNumber},
                    {value: item.description},
                    {value: item.quantity, style: {numFmt: "0"}},
                ]
            );
        })

        setExcelData([{
            columns: excelHeaders,
            data: eData
        }])

    }, [pickListId, products])

    return (
        <Fragment>
            <button className="btn btn-secondary ml-1" onClick={handleShow} title="Export pick list" ><FontAwesomeIcon icon={faFileExcel} className="go-to-category" /></button>
            <Modal show={showDialog} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Export pick list</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {products.length > 0 ?
                        <ExcelFile element={<button className="btn btn-primary" >Export to excel</button>} filename={pickListTitle} >
                            <ExcelSheet dataSet={excelData} name="Products" />
                        </ExcelFile>
                    : null}
                    {csvData.length > 0 ?
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={pickListTitle + ".csv"}
                            className="btn btn-primary ml-1"
                            target="_blank"
                        >Export to CSV</CSVLink>
                    : null}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

PickListExport.propTypes = {
    pickListId: PropTypes.number.isRequired,
    products: PropTypes.array.isRequired
}

export default PickListExport;