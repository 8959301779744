import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import DataTableComponent from '../DataTableComponent';

const ShippingServiceModal = (props) => {
    const [Services, setServices] = useState([]);
    const [SelectedService, setSelectedService] = useState("");
    const [DebugData, setDebugData] = useState("");
    const [DataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        console.log("ShippingServices: useEffect: props", props);
        setServices(props.shippingServices);
        setSelectedService(props.selectedService);

        let _service = props.shippingServices.filter(f => (f.carrier+"."+f.service)==props.selectedService);
        setDebugData(JSON.stringify(_service[0], null, 4));

        setDataLoading(false);
    }, [props.shippingServices])

    const columns = [
        {
            id:1,
            cell: (row) => <Form.Check
                            type={"radio"}
                            id={row.carrier+"."+row.service}
                            name={"shippingService"}
                            defaultChecked={(row.carrier+"."+row.service==(SelectedService??""))}
                            onChange={(e)=>handleChange(e)}
                        />,
            width: '60px',
            sortable:false,
        },
        {
            id:2,
            name: 'Carrier',
            selector: row => row.carrier,
            sortable:true
        },
        {
            id:3,
            name: 'Service',
            selector: row => row.service,
            sortable:true
        },
        {
            id:4,
            name: 'Rate',
            selector: row => row.rate.toFixed(2),
        }
    ];
 
    const handleChange = e => {
        console.log(e.target.id);
        props.newSelectedService(e.target.id);

        let _service = Services.filter(f => (f.carrier+"."+f.service)==e.target.id);
        //console.log("DebugData: ", JSON.stringify(_service[0]));
        setDebugData(JSON.stringify(_service[0], null, 4));

    };

    return (
        <div>
            <DataTableComponent dataLoading={DataLoading} columns={columns} data={Services} sortField={4} sortAsc={true} showQuickAdd={false} showSearch={false}></DataTableComponent>
            Debug Data:
            <div>
                <textarea className='small text-muted' defaultValue={DebugData} cols="90" rows="10"></textarea>
            </div>
        </div>
    );
};

export default ShippingServiceModal;