import React, { useState, useEffect, Fragment } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { MakesDropdown} from '../components';
import ReactGA from 'react-ga4';

const MenuDetailedSearch = () => {
    const { pathname } = useLocation();
    const history = useHistory();

    const parsed = queryString.parse(window.location.search);
    const currentYear = new Date().getFullYear();
    const startYear = 1951;
    const years = Array(currentYear-startYear).fill().map((d, i) => currentYear - i);
    const [marketId, setMarketId] = useState(process.env.REACT_APP_MARINE_ID);
    const [isActiveDS, setActiveDS] = useState("false");
    const [searchByModel, setSearchByModel] = useState(parsed.oem ? false : true);
    const [searchByOem, setSearchByOem] = useState(parsed.oem ? true : false);
    const [brand, setBrand] = useState(parsed.brand);
    const [modelNumber, setModelNumber] = useState(parsed.modelNumber);
    const [year, setYear] = useState(parsed.year);
    const [hp, setHp] = useState(parsed.hp);
    const [sn, setSn] = useState(parsed.sn);
    const [oem, setOem] = useState(parsed.oem);
    const [errorMessage, setErrorMessage] = useState();

    const handleToggleDS = () => setActiveDS(!isActiveDS);

    const handleSearchByModel = () => {
        setSearchByModel(true);
        setSearchByOem(false);
    }
    const handleSearchByOem = () => {
        setSearchByModel(false);
        setSearchByOem(true);
    }
    const onOemChange = (value) => setOem(value);
    const onBrandChange = (value) => setBrand(value);
    const onModelNumberChange = (value) => setModelNumber(value);
    const onHpChange = (value) => setHp(value);
    const onSnChange = (value) => setSn(value);
    const onYearChange = (value) => setYear(value);

    useEffect(() => {
        setErrorMessage(null);
    }, [searchByOem, searchByModel, pathname])

    useEffect(() => {
        if (pathname.indexOf("detailed-search") < 0) {
            setSearchByModel(true);
            setSearchByOem(false);
        }
        setBrand(null);
        setModelNumber("");
        setYear(null);
        setHp("");
        setSn("");
        setOem("");
        if (pathname.indexOf("power") > 0) {
            setMarketId(process.env.REACT_APP_POWERSPORTS_ID);
        }
        if (pathname.indexOf("garden") > 0) {
            setMarketId(process.env.REACT_APP_GARDEN_ID);
        }
        if (pathname === "/") {
            setMarketId(process.env.REACT_APP_MARINE_ID);
        }
    }, [pathname])

    const submitDetailedSearch = () => {
        const parsedHash = {};
        if (searchByModel === true) {
            if (brand === undefined || brand === "" || brand === null) {
                setErrorMessage("Brand is a required field");
                return;
            }
            parsedHash.brand = brand;
            if (modelNumber) {
                parsedHash.modelNumber = modelNumber;
            }
            if (year) {
                parsedHash.year = year;
            }
            if (hp) {
                parsedHash.hp = hp;
            }
            if (sn) {
                parsedHash.sn = sn;
            }
        }
        if (searchByOem === true) {
            if ((!oem || /^\s*$/.test(oem))) {
                setErrorMessage("OEM is a required field");
                return;
            }
            parsedHash.oem = oem;
        }
        setErrorMessage(null);
        const stringified = queryString.stringify(parsedHash);
        history.push({
            pathname: "/detailed-search",
            search: stringified,
            state: {
                page: 1,
                marketId: marketId
            } 
        });
        ReactGA.event({
            category: 'User',
            action: 'search_find_your_parts'
        });
        setBrand(null);
        setModelNumber("");
        setYear(null);
        setHp("");
        setSn("");
        setOem("");
    }

    return (
        <div className={isActiveDS ? "detailed-search-form relative" : "detailed-search-form relative active"}>
            <div className="only-active">
                <strong>Detailed Search</strong>
                <Form.Group as={Row} className="filter-dc">
                    <Col>
                        <Form.Check
                            type="radio"
                            label="Model"
                            name="search_type"
                            id="model"
                            checked={searchByModel ? "checked" : ""}
                            onChange={handleSearchByModel}
                            inline 
                        />
                        <Form.Check
                            type="radio"
                            label="OEM/Competitive Part #"
                            name="search_type"
                            id="oem"
                            checked={searchByOem ? "checked" : ""}
                            onChange={handleSearchByOem}
                            inline 
                        />
                    </Col>
                </Form.Group>
            </div>
            <Form action="/detailed-search">
                <span className="form-title">Find Your Parts</span>
                {searchByModel ? 
                    <React.Fragment>
                        <MakesDropdown marketId={marketId} brandP={brand} onChange={onBrandChange} errorMessage={errorMessage} />
                        <Form.Control type="text" name="modelNumber" placeholder="Model" value={modelNumber} onChange={(e) => onModelNumberChange(e.target.value)} />
                        <Form.Control as="select" name="year" onChange={(e) => onYearChange(e.target.value)}>
                            <option value="">Year</option>
                            {years.map((item, index) =>
                                // eslint-disable-next-line eqeqeq
                                <option value={item} key={index} selected={year == item ? true : false } >{item}</option>
                            )}
                        </Form.Control> 
                        <Form.Control type="text" name="hp" placeholder="HP" value={hp} onChange={(e) => onHpChange(e.target.value)} />
                        <Form.Control type="text" name="sn" placeholder="Serial Number" value={sn} onChange={(e) => onSnChange(e.target.value)} />
                    </React.Fragment>
                    : null}
                {searchByOem ? 
                    <Fragment>
                        <Form.Control type="text" name="oem" placeholder="OEM"value={oem} onChange={(e) => onOemChange(e.target.value)} isInvalid={errorMessage != null ? true : false}  />
                        {errorMessage != null ? <Form.Control.Feedback type="invalid" style={{display: 'inline'}} >{errorMessage}</Form.Control.Feedback> : null}
                    </Fragment>
                    : null}
                <Button type="button" onClick={submitDetailedSearch} >SEARCH</Button>
            </Form>
            <FontAwesomeIcon icon={faAngleDown} onClick={handleToggleDS} className="toggle-dc" />
        </div>
    )
}

export default MenuDetailedSearch;