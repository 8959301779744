var power = {
  W: {
    to_anchor: 1
  }, 
  mW: {
    to_anchor: .001
  }, 
  kW: {
    to_anchor: 1000
  }, 
  MW: {
    to_anchor: 1000000
  }, 
  GW: {
    to_anchor: 1000000000
  }
};

module.exports = {
  metric: power, 
  _anchors: {
    metric: {
      unit: 'W', 
      ratio: 1
    }
  }
};
