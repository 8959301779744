/* eslint-disable import/no-anonymous-default-export */
export default {
  async createSession(orderDto) {
    const res = await fetch(
      process.env.REACT_APP_PAYMENT_API_URL + "/adyen/sessions",
      {
        method: "POST",
        body: orderDto ? JSON.stringify(orderDto) : "",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return await res.json();
  },
};
