/* eslint-disable import/no-anonymous-default-export */
import FourthShiftAPI from '../utils/FourthShiftAPI';

export default {
    async getPrice(custId, itemNum) {
        return await FourthShiftAPI.get("/pricing/get/" + custId + "/" + itemNum)
    },

    async getAddress(custId, shipToId) {
        return FourthShiftAPI.get("/shipping/get/" + custId + '/' + shipToId)
    },

    async getAddresses(custId) {
        return FourthShiftAPI.get("/shipping/GetShipToAddresses/" + custId)
    },

    async getDefaultAddress(custId) {
        return FourthShiftAPI.get("/shipping/GetDefaultAddress/" + custId)
    },

    async getItemAvailability(itemId) {
        return FourthShiftAPI.get("/inventory/Get/" + itemId)
    },
}