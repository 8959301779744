import React, { Fragment, useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { Link, useParams, useHistory } from "react-router-dom";
import logo from '../../../logo.svg';
import Moment from "moment";
import { Form } from "react-bootstrap";
import { FooterCopyright, Loading } from '../../../components';
import { CreateLogin, ProfileInformation, ReviewRegistration } from "../../user/index";
import { UserService } from "../../../services";
 
const RegistrationInvitation = () => {
    let { invitationCode } = useParams();
    const history = useHistory();
 
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
 
    const [partnerId, setPartnerId] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [homePhone, setHomePhone] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [officePhone, setOfficePhone] = useState("");
    const [extension, setExtension] = useState("");
    const [salutation, setSalutation] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [birthDate, setBirthDate] = useState("");
 
    const [companyName, setCompanyName] = useState("");
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyFaxNumber, setCompanyFaxNumber] = useState("");
    const [mailingAddress1, setMailingAddress1] = useState("");
    const [mailingAddress2, setMailingAddress2] = useState("");
    const [mailingCity, setMailingCity] = useState("");
    const [mailingState, setMailingState] = useState("");
    const [mailingPostalCode, setMailingPostalCode] = useState("");
    const [mailingCountry, setMailingCountry] = useState("USA");
    const [shippingAddress1, setShippingAddress1] = useState("");
    const [shippingAddress2, setShippingAddress2] = useState("");
    const [shippingCity, setShippingCity] = useState("");
    const [shippingState, setShippingState] = useState("");
    const [shippingPostalCode, setShippingPostalCode] = useState("");
    const [shippingCountry, setShippingCountry] = useState("USA");
 
    const [userMailingAddress1, setUserMailingAddress1] = useState("");
    const [userMailingAddress2, setUserMailingAddress2] = useState("");
    const [userMailingCity, setUserMailingCity] = useState("");
    const [userMailingState, setUserMailingState] = useState("");
    const [userMailingPostalCode, setUserMailingPostalCode] = useState("");
    const [userMailingCountry, setUserMailingCountry] = useState("USA");
    const [userShippingAsMailingAddress, setUserShippingAsMailingAddress] = useState(false);
    const [userShippingAddress1, setUserShippingAddress1] = useState("");
    const [userShippingAddress2, setUserShippingAddress2] = useState("");
    const [userShippingCity, setUserShippingCity] = useState("");
    const [userShippingState, setUserShippingState] = useState("");
    const [userShippingPostalCode, setUserShippingPostalCode] = useState("");
    const [userShippingCountry, setUserShippingCountry] = useState("USA");
 
    const [email, setEmail] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState();
    const [emailConfirm, setEmailConfirm] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
 
    useEffect(() => {
        setLoading(true);
        UserService.getInvitationRegistration(invitationCode)
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === true) {
                    const userData = res.data.data.UserModelObject;
                    const companyData = res.data.data.CompanyModelObject;
                    setPartnerId(companyData.PartnerId);
                    setCompanyName(companyData.PartnerName);
                    setCompanyWebsite(companyData.URL);
                    setMailingAddress1(companyData.Address1);
                    setMailingAddress2(companyData.Address2);
                    setMailingCity(companyData.City);
                    setMailingState(companyData.State);
                    setMailingPostalCode(companyData.Zip);
                    setMailingCountry(companyData.Country.SelectedId);
                    setCompanyPhoneNumber(companyData.PhoneNo);
                    setCompanyFaxNumber(companyData.FaxNo);
                    setShippingAddress1(companyData.ShipAddress1);
                    setShippingAddress2(companyData.ShipAddress2);
                    setShippingCity(companyData.ShipCity);
                    setShippingState(companyData.ShipState);
                    setShippingPostalCode(companyData.ShipZip);
                    setShippingCountry(companyData.ShipCountry.SelectedId);
                    setEmail(userData.Email);
                    setEmailConfirm(userData.Email);
                    setFirstName(userData.FirstName);
                    setLastName(userData.LastName);
                    setLoading(false);
                } else {
                    if (res.data.results.Message == "Invalid invite code"){
                        console.log("Invalid code");
                        setStep(4);
                        setLoading(false);
                    } else{
                        history.push("/");
                    } 
                    
                }
            } else if (res.status === 500) {
                history.push("/");
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }, [invitationCode])
 
    useEffect(() => {
        if (userShippingAsMailingAddress === true) {
            setUserShippingAddress1(userMailingAddress1);
            setUserShippingAddress2(userMailingAddress2);
            setUserShippingCity(userMailingCity);
            setUserShippingState(userMailingState);
            setUserShippingPostalCode(userMailingPostalCode);
            setUserShippingCountry(userMailingCountry);
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userShippingAsMailingAddress])
 
    const handleChangeEmail = (value) => setEmail(value);
    const handleChangeEmailConfirm = (value) => setEmailConfirm(value);
    const handleChangePassword = (value) => setPassword(value);
    const handleChangePasswordConfirm = (value) => setPasswordConfirm(value);
 
    const handleChangeFirstName = (value) => setFirstName(value);
    const handleChangeLastName = (value) => setLastName(value);
    const handleChangeHomePhone = (value) => setHomePhone(value);
    const handleChangeMobilePhone = (value) => setMobilePhone(value);
    const handleChangeOfficePhone = (value) => setOfficePhone(value);
    const handleChangeExtension = (value) => setExtension(value);
    const handleChangeSalutation = (value) => setSalutation(value);
    const handleChangeJobTitle = (value) => setJobTitle(value);
    const handleChangeBirthDate = (value) => setBirthDate(value);
    const handleChangeUserMailingAddress1 = (value) => setUserMailingAddress1(value);
    const handleChangeUserMailingAddress2 = (value) => setUserMailingAddress2(value);
    const handleChangeUserMailingCity = (value) => setUserMailingCity(value);
    const handleChangeUserMailingState = (value) => setUserMailingState(value);
    const handleChangeUserMailingCountry = (value) => setUserMailingCountry(value);
    const handleChangeUserMailingPostalCode = (value) => setUserMailingPostalCode(value);
    const handleChangeUserShippingAddress1 = (value) => setUserShippingAddress1(value);
    const handleChangeUserShippingAddress2 = (value) => setUserShippingAddress2(value);
    const handleChangeUserShippingCity = (value) => setUserShippingCity(value);
    const handleChangeUserShippingState = (value) => setUserShippingState(value);
    const handleChangeUserShippingPostalCode = (value) => setUserShippingPostalCode(value);
    const handleChangeUserShippingCountry = (value) => setUserShippingCountry(value);
 
    const toggleUserShippingAsMailingAddress = () => setUserShippingAsMailingAddress(!userShippingAsMailingAddress);
    const changeStep = (value) => setStep(value);
    const profileGoBack = () => setStep(1);
    const reviewGoBack = () => setStep(2);
 
    async function validateLoginInformation() {
        setLoading(true);
        await UserService.validateLoginInformation(email, emailConfirm, password, passwordConfirm)
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === true) {
                    setStep(2);
                } else {
                    setEmailErrorMessage(res.data.results.Message);
                }
                setLoading(false);
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }
 
    async function validateProfileInformation() {
        setLoading(true);
        await UserService.validateProfileInformation(email, firstName, lastName,
            userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingPostalCode, userMailingCountry,
            userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingPostalCode, userShippingCountry)
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === true) {
                    changeStep(3);
                }
                setLoading(false);
            }
        }).catch(error => {
            console.log("Error " + error);
        });
    }
 
    async function saveRegistration() {
        setLoading(true);
        let sBirthDate = "";
        if (birthDate) {
            sBirthDate = Moment(birthDate).format('MM/DD/yyyy');
        }
        await UserService.saveInvitationRegistration(partnerId, companyName, companyWebsite,
            mailingAddress1, mailingAddress2, mailingCity, mailingState, mailingCountry, mailingPostalCode,
            shippingAddress1, shippingAddress2, shippingCity, shippingState, shippingCountry, shippingPostalCode, companyPhoneNumber, companyFaxNumber,
            firstName, lastName, homePhone, officePhone, extension, mobilePhone, salutation, jobTitle, sBirthDate,
            userMailingAddress1, userMailingAddress2, userMailingCity, userMailingState, userMailingPostalCode, userMailingCountry,
            userShippingAddress1, userShippingAddress2, userShippingCity, userShippingState, userShippingPostalCode, userShippingCountry,
            email, emailConfirm, password, passwordConfirm, invitationCode)
        .then(res => {
            if (res.status === 200) {
                if (res.data.results.responseId === true) {
                    history.push("/user/registration/confirm");
                }
            }
            setLoading(false);
        }).catch(error => {
            console.log("Error " + error);
        });
    }
 
    return (
        <div className="registration-form">
            <header className="register-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <Link to="/" className="logo">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div className="col-sm-6 text-center">
                            <h1>Register</h1>
                            {step <= 3 ? <p>Step {step}/3</p> : null }
                        </div>
                    </div>
                </div>
            </header>
            <Form>
                <div className="container">
                    {loading === true ?
                        <div className="row">
                            <div className="col-md-11 offset-md-1 mt-3">
                                <Loading />
                            </div>
                        </div>
                    :
                    <Fragment>
                        {step === 1 ?
                            <CreateLogin
                                email={email}
                                emailConfirm={emailConfirm}
                                password={password}
                                passwordConfirm={passwordConfirm}
                                emailErrorMessage={emailErrorMessage}
                                handleChangeEmail={handleChangeEmail}
                                handleChangeEmailConfirm={handleChangeEmailConfirm}
                                handleChangePassword={handleChangePassword}
                                handleChangePasswordConfirm={handleChangePasswordConfirm}
                                validateLoginInformation={validateLoginInformation}
                                emailReadonly="true"
                                hideBack="true"
                            />
                        : null}
                        {step === 2 ?
                            <ProfileInformation
                                email={email}
                                firstName={firstName}
                                lastName={lastName}
                                homePhone={homePhone}
                                mobilePhone={mobilePhone}
                                officePhone={officePhone}
                                extension={extension}
                                salutation={salutation}
                                jobTitle={jobTitle}
                                birthDate={birthDate}
                                mailingAddress1={userMailingAddress1}
                                mailingAddress2={userMailingAddress2}
                                mailingCity={userMailingCity}
                                mailingState={userMailingState}
                                mailingPostalCode={userMailingPostalCode}
                                mailingCountry={userMailingCountry}
                                shippingAddress1={userShippingAddress1}
                                shippingAddress2={userShippingAddress2}
                                shippingCity={userShippingCity}
                                shippingState={userShippingState}
                                shippingPostalCode={userShippingPostalCode}
                                shippingCountry={userShippingCountry}
                                handleChangeFirstName={handleChangeFirstName}
                                handleChangeLastName={handleChangeLastName}
                                handleChangeHomePhone={handleChangeHomePhone}
                                handleChangeMobilePhone={handleChangeMobilePhone}
                                handleChangeOfficePhone={handleChangeOfficePhone}
                                handleChangeExtension={handleChangeExtension}
                                handleChangeSalutation={handleChangeSalutation}
                                handleChangeJobTitle={handleChangeJobTitle}
                                handleChangeBirthDate={handleChangeBirthDate}
                                handleChangeMailingAddress1={handleChangeUserMailingAddress1}
                                handleChangeMailingAddress2={handleChangeUserMailingAddress2}
                                handleChangeMailingCity={handleChangeUserMailingCity}
                                handleChangeMailingState={handleChangeUserMailingState}
                                handleChangeMailingCountry={handleChangeUserMailingCountry}
                                handleChangeMailingPostalCode={handleChangeUserMailingPostalCode}
                                handleChangeShippingAddress1={handleChangeUserShippingAddress1}
                                handleChangeShippingAddress2={handleChangeUserShippingAddress2}
                                handleChangeShippingCity={handleChangeUserShippingCity}
                                handleChangeShippingState={handleChangeUserShippingState}
                                handleChangeShippingPostalCode={handleChangeUserShippingPostalCode}
                                handleChangeShippingCountry={handleChangeUserShippingCountry}
                                toggleShippingAsMailingAddress={toggleUserShippingAsMailingAddress}
                                validateProfileInformation={validateProfileInformation}
                                profileGoBack={profileGoBack}
                            />
                        : null}
                        {step === 3 ?
                            <ReviewRegistration
                                email={email}
                                firstName={firstName}
                                lastName={lastName}
                                homePhone={homePhone}
                                mobilePhone={mobilePhone}
                                officePhone={officePhone}
                                extension={extension}
                                salutation={salutation}
                                jobTitle={jobTitle}
                                birthDate={birthDate}
                                userMailingAddress1={userMailingAddress1}
                                userMailingAddress2={userMailingAddress2}
                                userMailingCity={userMailingCity}
                                userMailingState={userMailingState}
                                userMailingPostalCode={userMailingPostalCode}
                                userMailingCountry={userMailingCountry}
                                userShippingAddress1={userShippingAddress1}
                                userShippingAddress2={userShippingAddress2}
                                userShippingCity={userShippingCity}
                                userShippingState={userShippingState}
                                userShippingPostalCode={userShippingPostalCode}
                                userShippingCountry={userShippingCountry}
                                saveRegistration={saveRegistration}
                                reviewGoBack={reviewGoBack}
                            />
                        : null}
                        {step === 4 ? 
                         <Alert variant="primary">This invite code is invalid.</Alert>
                        : null}
                    </Fragment>
                    }
                </div>
            </Form>
            <footer>
                <div className="container">
                    <div className="row text-center">
                        <FooterCopyright />
                    </div>
                </div>
            </footer>
        </div>
    )
}
 
export default RegistrationInvitation;