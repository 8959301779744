/* eslint-disable import/no-anonymous-default-export */
import CatalogAPI from '../utils/NewCatalogAPI';

export default {

    async getItem(id) {
        return CatalogAPI.get("/item/" + id)
    },

    async getItemByNumber(custId, item) {
        return CatalogAPI.get("/item/GetByItem/" + custId + "/" + item)
    },

    async getOrdersAdmin(){
        return CatalogAPI.get('/order/getAll');
    },

    async getOrders(){
        return CatalogAPI.get('/order/getAllByUser');
    },

    async getOrder(gId) {
        return CatalogAPI.get('/order/getById/' + gId);
    },

    async getShippingEstimate(gId, zipCode, stateCode, city, isResidential) {
        return CatalogAPI.post('/shipping/getEstimate/', {
            orderId: gId,
            zipCode: zipCode,
            stateCode: stateCode,
            city: city,
            //countryCode:"",
            residential: isResidential,
        });
    },

    // async getShippingEstimate(gId) {
    //     return CatalogAPI.post('/shipping/getEstimate/', {
    //         orderId: gId
    //     });
    // },

    async getCart() {
        return CatalogAPI.get('/order/getByUser');
    },

    async getCartCount() {
        return CatalogAPI.get('/order/getCountByUser');
    },

    async saveOrder(partnerId) {
        return CatalogAPI.post('/order/create/' + partnerId);
    },

    async updateOrderShipTo(gId, shipToId) {
        return CatalogAPI.get('/order/updateShipTo/' + gId + '/' + shipToId);
    },

    async updateOrderStatus(gId, newStatus) {
        return CatalogAPI.get('/order/UpdateStatus/' + gId + '/' + newStatus);
    },

    async updatePaymentType(gId, paymentType) {
        return CatalogAPI.get('/order/updatePaymentType/' + gId + '/' + paymentType);
    },

    async savePONumber(gId, poNumber) {
        return CatalogAPI.get('/order/UpdatePONumber/' + gId + '/' + poNumber);
    },


    async updateShippingDetail(gId, shippingDetail, rate) {
        return CatalogAPI.post('/order/updateShippingDetail', {
            orderId: gId,
            shippingService: shippingDetail,
            rate: rate
        });
    },

    async saveOrderItem(partnerId, orderItemId, orderId, orderGuid, itemRow, quantity, price) {
        return CatalogAPI.post('/order/saveItem', {
            partnerId: partnerId,
            OrderItemId: orderItemId,
            OrderId: orderId,
            OrderGuid: orderGuid,
            ItemRow: itemRow,
            Quantity: quantity,
            Price: price,
        })
    },    
    
    async deleteOrderItem(orderItemId, orderGuid) {
        return CatalogAPI.post('/order/deleteItem', {
            OrderItemId: orderItemId,
            OrderGuid: orderGuid
        })
    },

    getFile(fileName) {
        return CatalogAPI.get("/order/GetFile/" + fileName, {
            responseType: 'blob'
        });
    },

    async addNewAddress(orderId, customerId, name, address1, address2, state, city, postalCode, country) {
        return CatalogAPI.post("/order/addNewAddressFourthShift", {
            CustomerId: customerId,
            OrderId: orderId,
            ShipToName: name,
            ShipToAddress1: address1,
            ShipToAddress2: address2,
            ShipToState: state,
            ShipToCity: city,
            ShipToZip: postalCode,
            ShipToCountry: country
        });
    },

    async getAddressStatus(orderId, custId) {
        return CatalogAPI.get("/order/getAddressStatus/" + orderId + "/" + custId);
    },

    async checkHazmatRestrictions(orderId) {
        return CatalogAPI.get("/order/CheckHazmatRestrictions/" + orderId);
    },

    async getPromotions() {
        return CatalogAPI.get("/promotion/GetAll");
    },

    async getActivePromotions() {
        return CatalogAPI.get("/promotion/GetAllActive");
    },

    async getRateTypes() {
        return CatalogAPI.get("/promotion/GetRateTypes");
    },

    async getTypes() {
        return CatalogAPI.get("/promotion/GetTypes")
    },

    async savePromotion(promotionId, promotionName, badgeText, bannerText, startDate, endDate, discountCode, 
                        maxCustomerUse, promotionTypeId, rateTypeId, rateValue, threshold, createDate, createdBy, 
                        updateDate, updatedBy, description) {
                        if (threshold == "")
                            threshold = null;
            return CatalogAPI.post("/promotion/save", {
                PromotionId: promotionId,
                PromotionName: promotionName,
                BadgeText: badgeText,
                BannerText: bannerText,
                StartDate: startDate,
                EndDate: endDate,
                DiscountCode: discountCode,
                MaxCustomerUse: maxCustomerUse,
                PromotionTypeId: promotionTypeId,
                RateTypeId: rateTypeId,
                RateValue: rateValue,
                Threshold: threshold,
                Description: description,
                CreateDate: createDate,
                CreatedBy: createdBy,
                UpdateDate: updateDate,
                UpdatedBy: updatedBy
            });
    },

    async getPromotionByDiscountCode(discountCode, orderGuid) {
        return CatalogAPI.get("/promotion/getByDiscountCode/" + discountCode + "/" + orderGuid);
    },

    async getPromotionsSiteWide(orderGuid) {
        if (orderGuid != null)
            return CatalogAPI.get("/promotion/getSitewide" + "/" + orderGuid);
        else
            return CatalogAPI.get("/promotion/getSitewide");
    }

}