import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';

const MenuSimpleSearch = () => {
    const { pathname } = useLocation();
    const history = useHistory();

    const [searchPatternErrorMessage, setSearchPatternErrorMessage] = useState();
    const [searchPattern, setSearchPattern] = useState("");

    const handleOnChangeSearchPattern = (value) => setSearchPattern(value);

    useEffect(() => {
        setSearchPatternErrorMessage(null);
    }, [pathname])

    const submitSearch = () => {
        if ((!searchPattern || /^\s*$/.test(searchPattern))) {
            setSearchPatternErrorMessage("Please enter an item, brand, model, part number");
            return;
        }
        ReactGA.event({
            category: 'User',
            action: 'search_by_item',
            value: {searchPattern}
        });
        setSearchPattern("");
        setSearchPatternErrorMessage(null);
        const parsedHash = {};
        parsedHash.q = searchPattern;
        const stringified = queryString.stringify(parsedHash);
        history.push({
            pathname:  "/search",
            search: stringified,
            state: {
                page: 1
            } 
        });
        
    }

    return (
        <Form action="/search">
            <Form.Control type="text" name="q" value={searchPattern} onChange={(e) => handleOnChangeSearchPattern(e.target.value)} placeholder="Search by item, brand, or OEM/Competitor…" />
            {searchPatternErrorMessage != null ? <Form.Control.Feedback type="invalid" style={{display: 'inline'}} >{searchPatternErrorMessage}</Form.Control.Feedback> : null}
            <Button type="button" onClick={submitSearch} ><FontAwesomeIcon icon={faSearch} className="close-sidebar" /><span>SEARCH</span></Button>
            <div className="ac-results"></div>
        </Form>
    )
}

export default MenuSimpleSearch;