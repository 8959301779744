  
import React, { Fragment, useEffect, useState } from 'react'
import { Header, Menu, Footer, InfoBlock } from '../';
import { ShoppingCartContext } from '../ShoppingCartMenuComponent';

const Main = ({ children }) => {

    const [scrolled,setScrolled]=React.useState(false);
    const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 200 ){
        setScrolled(true);
    }
    else if(offset===0){
        setScrolled(false);
    }
    }

    useEffect(() => {
    window.addEventListener('scroll',handleScroll)
    })

    const [refreshCount, setRefreshCount] = useState(0);
    const setRefresh = () => {
        setRefreshCount((prev)=>prev+1);
    };

    return (
        <Fragment>
            <div className={scrolled ? "main sticky": "main"}>
                <ShoppingCartContext.Provider value={[refreshCount, setRefresh]}>
                    <Header />
                    <Menu />
                    {children}
                    <InfoBlock />
                    <Footer />
                </ShoppingCartContext.Provider>
            </div>
        </Fragment>
    )
}

export default Main;